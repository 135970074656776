/* eslint-disable */
//@ts-nocheck
/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.proto = require('./model_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.StatsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.StatsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.StatsDashboardRequest,
 *   !proto.proto.StatsOperatorDashboardResponse>}
 */
const methodDescriptor_StatsService_GetOperatorDashboardStats = new grpc.web.MethodDescriptor(
  '/proto.StatsService/GetOperatorDashboardStats',
  grpc.web.MethodType.UNARY,
  proto.proto.StatsDashboardRequest,
  proto.proto.StatsOperatorDashboardResponse,
  /**
   * @param {!proto.proto.StatsDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.StatsOperatorDashboardResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.StatsDashboardRequest,
 *   !proto.proto.StatsOperatorDashboardResponse>}
 */
const methodInfo_StatsService_GetOperatorDashboardStats = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.StatsOperatorDashboardResponse,
  /**
   * @param {!proto.proto.StatsDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.StatsOperatorDashboardResponse.deserializeBinary
);


/**
 * @param {!proto.proto.StatsDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.StatsOperatorDashboardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.StatsOperatorDashboardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatsServiceClient.prototype.getOperatorDashboardStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatsService/GetOperatorDashboardStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_GetOperatorDashboardStats,
      callback);
};


/**
 * @param {!proto.proto.StatsDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.StatsOperatorDashboardResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatsServicePromiseClient.prototype.getOperatorDashboardStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatsService/GetOperatorDashboardStats',
      request,
      metadata || {},
      methodDescriptor_StatsService_GetOperatorDashboardStats);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.HaulingListingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.HaulingListingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.HaulListingRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_CreateHaulingListing = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/CreateHaulingListing',
  grpc.web.MethodType.UNARY,
  proto.proto.HaulListingRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.HaulListingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.HaulListingRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_CreateHaulingListing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.HaulListingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.HaulListingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.createHaulingListing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/CreateHaulingListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_CreateHaulingListing,
      callback);
};


/**
 * @param {!proto.proto.HaulListingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.createHaulingListing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/CreateHaulingListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_CreateHaulingListing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.HaulListingRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_UpdateHaulingListing = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/UpdateHaulingListing',
  grpc.web.MethodType.UNARY,
  proto.proto.HaulListingRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.HaulListingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.HaulListingRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_UpdateHaulingListing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.HaulListingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.HaulListingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.updateHaulingListing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/UpdateHaulingListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_UpdateHaulingListing,
      callback);
};


/**
 * @param {!proto.proto.HaulListingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.updateHaulingListing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/UpdateHaulingListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_UpdateHaulingListing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_CancelHaulingListing = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/CancelHaulingListing',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_CancelHaulingListing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.cancelHaulingListing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/CancelHaulingListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_CancelHaulingListing,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.cancelHaulingListing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/CancelHaulingListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_CancelHaulingListing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.HaulingListingListResponse>}
 */
const methodDescriptor_HaulingListingService_GetHaulingListings = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/GetHaulingListings',
  grpc.web.MethodType.UNARY,
  proto.proto.QueryRequest,
  proto.proto.HaulingListingListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HaulingListingListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.HaulingListingListResponse>}
 */
const methodInfo_HaulingListingService_GetHaulingListings = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.HaulingListingListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.HaulingListingListResponse.deserializeBinary
);


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.HaulingListingListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.HaulingListingListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.getHaulingListings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/GetHaulingListings',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetHaulingListings,
      callback);
};


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.HaulingListingListResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.getHaulingListings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/GetHaulingListings',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetHaulingListings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.BidListResponse>}
 */
const methodDescriptor_HaulingListingService_GetBidsForListing = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/GetBidsForListing',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.BidListResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BidListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.BidListResponse>}
 */
const methodInfo_HaulingListingService_GetBidsForListing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BidListResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BidListResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BidListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BidListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.getBidsForListing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/GetBidsForListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetBidsForListing,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BidListResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.getBidsForListing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/GetBidsForListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetBidsForListing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.BidResponse>}
 */
const methodDescriptor_HaulingListingService_GetCompanyBid = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/GetCompanyBid',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.BidResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BidResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.BidResponse>}
 */
const methodInfo_HaulingListingService_GetCompanyBid = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BidResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BidResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BidResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BidResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.getCompanyBid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/GetCompanyBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetCompanyBid,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BidResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.getCompanyBid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/GetCompanyBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetCompanyBid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.BidRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_BidOnListing = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/BidOnListing',
  grpc.web.MethodType.UNARY,
  proto.proto.BidRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.BidRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.BidRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_BidOnListing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.BidRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.BidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.bidOnListing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/BidOnListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_BidOnListing,
      callback);
};


/**
 * @param {!proto.proto.BidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.bidOnListing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/BidOnListing',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_BidOnListing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AcceptBidRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_AcceptBid = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/AcceptBid',
  grpc.web.MethodType.UNARY,
  proto.proto.AcceptBidRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.AcceptBidRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.AcceptBidRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_AcceptBid = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.AcceptBidRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AcceptBidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.acceptBid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/AcceptBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_AcceptBid,
      callback);
};


/**
 * @param {!proto.proto.AcceptBidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.acceptBid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/AcceptBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_AcceptBid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.BidRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_UpdateBid = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/UpdateBid',
  grpc.web.MethodType.UNARY,
  proto.proto.BidRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.BidRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.BidRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_UpdateBid = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.BidRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.BidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.updateBid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/UpdateBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_UpdateBid,
      callback);
};


/**
 * @param {!proto.proto.BidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.updateBid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/UpdateBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_UpdateBid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_WithdrawBid = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/WithdrawBid',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_WithdrawBid = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.withdrawBid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/WithdrawBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_WithdrawBid,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.withdrawBid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/WithdrawBid',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_WithdrawBid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.BidSuggestionRequest,
 *   !proto.proto.IdResponse>}
 */
const methodDescriptor_HaulingListingService_PlaceBidSuggestion = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/PlaceBidSuggestion',
  grpc.web.MethodType.UNARY,
  proto.proto.BidSuggestionRequest,
  proto.proto.IdResponse,
  /**
   * @param {!proto.proto.BidSuggestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.IdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.BidSuggestionRequest,
 *   !proto.proto.IdResponse>}
 */
const methodInfo_HaulingListingService_PlaceBidSuggestion = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.IdResponse,
  /**
   * @param {!proto.proto.BidSuggestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.IdResponse.deserializeBinary
);


/**
 * @param {!proto.proto.BidSuggestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.IdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.IdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.placeBidSuggestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/PlaceBidSuggestion',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_PlaceBidSuggestion,
      callback);
};


/**
 * @param {!proto.proto.BidSuggestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.IdResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.placeBidSuggestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/PlaceBidSuggestion',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_PlaceBidSuggestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_DeclineBidSuggestion = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/DeclineBidSuggestion',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_DeclineBidSuggestion = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.declineBidSuggestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/DeclineBidSuggestion',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_DeclineBidSuggestion,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.declineBidSuggestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/DeclineBidSuggestion',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_DeclineBidSuggestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.BillingDefaultsResponse>}
 */
const methodDescriptor_HaulingListingService_GetBillingDefaults = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/GetBillingDefaults',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.BillingDefaultsResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BillingDefaultsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.BillingDefaultsResponse>}
 */
const methodInfo_HaulingListingService_GetBillingDefaults = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BillingDefaultsResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BillingDefaultsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BillingDefaultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BillingDefaultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.getBillingDefaults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/GetBillingDefaults',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetBillingDefaults,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BillingDefaultsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.getBillingDefaults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/GetBillingDefaults',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_GetBillingDefaults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.BillingDefaultsRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_HaulingListingService_UpdateBillingDefaults = new grpc.web.MethodDescriptor(
  '/proto.HaulingListingService/UpdateBillingDefaults',
  grpc.web.MethodType.UNARY,
  proto.proto.BillingDefaultsRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.BillingDefaultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.BillingDefaultsRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_HaulingListingService_UpdateBillingDefaults = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.BillingDefaultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.BillingDefaultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HaulingListingServiceClient.prototype.updateBillingDefaults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.HaulingListingService/UpdateBillingDefaults',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_UpdateBillingDefaults,
      callback);
};


/**
 * @param {!proto.proto.BillingDefaultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.HaulingListingServicePromiseClient.prototype.updateBillingDefaults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.HaulingListingService/UpdateBillingDefaults',
      request,
      metadata || {},
      methodDescriptor_HaulingListingService_UpdateBillingDefaults);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.FieldTicketServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.FieldTicketServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.FieldTicketsPageRequest,
 *   !proto.proto.FieldTicketsPageResponse>}
 */
const methodDescriptor_FieldTicketService_GetFieldTicketsPageData = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/GetFieldTicketsPageData',
  grpc.web.MethodType.UNARY,
  proto.proto.FieldTicketsPageRequest,
  proto.proto.FieldTicketsPageResponse,
  /**
   * @param {!proto.proto.FieldTicketsPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FieldTicketsPageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.FieldTicketsPageRequest,
 *   !proto.proto.FieldTicketsPageResponse>}
 */
const methodInfo_FieldTicketService_GetFieldTicketsPageData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.FieldTicketsPageResponse,
  /**
   * @param {!proto.proto.FieldTicketsPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FieldTicketsPageResponse.deserializeBinary
);


/**
 * @param {!proto.proto.FieldTicketsPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.FieldTicketsPageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.FieldTicketsPageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.getFieldTicketsPageData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/GetFieldTicketsPageData',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_GetFieldTicketsPageData,
      callback);
};


/**
 * @param {!proto.proto.FieldTicketsPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.FieldTicketsPageResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.getFieldTicketsPageData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/GetFieldTicketsPageData',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_GetFieldTicketsPageData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.FieldTicketResponse>}
 */
const methodDescriptor_FieldTicketService_GetFieldTicketData = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/GetFieldTicketData',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.FieldTicketResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FieldTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.FieldTicketResponse>}
 */
const methodInfo_FieldTicketService_GetFieldTicketData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.FieldTicketResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FieldTicketResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.FieldTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.FieldTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.getFieldTicketData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/GetFieldTicketData',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_GetFieldTicketData,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.FieldTicketResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.getFieldTicketData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/GetFieldTicketData',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_GetFieldTicketData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.FieldTicketCommentRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_FieldTicketService_CommentOnFieldTicket = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/CommentOnFieldTicket',
  grpc.web.MethodType.UNARY,
  proto.proto.FieldTicketCommentRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.FieldTicketCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.FieldTicketCommentRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_FieldTicketService_CommentOnFieldTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.FieldTicketCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.FieldTicketCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.commentOnFieldTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/CommentOnFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_CommentOnFieldTicket,
      callback);
};


/**
 * @param {!proto.proto.FieldTicketCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.commentOnFieldTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/CommentOnFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_CommentOnFieldTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.FieldTicketUpdateRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_FieldTicketService_UpdateFieldTicket = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/UpdateFieldTicket',
  grpc.web.MethodType.UNARY,
  proto.proto.FieldTicketUpdateRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.FieldTicketUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.FieldTicketUpdateRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_FieldTicketService_UpdateFieldTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.FieldTicketUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.FieldTicketUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.updateFieldTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/UpdateFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_UpdateFieldTicket,
      callback);
};


/**
 * @param {!proto.proto.FieldTicketUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.updateFieldTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/UpdateFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_UpdateFieldTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ApprovalRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_FieldTicketService_ApproveFieldTicket = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/ApproveFieldTicket',
  grpc.web.MethodType.UNARY,
  proto.proto.ApprovalRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ApprovalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ApprovalRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_FieldTicketService_ApproveFieldTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ApprovalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ApprovalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.approveFieldTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/ApproveFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_ApproveFieldTicket,
      callback);
};


/**
 * @param {!proto.proto.ApprovalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.approveFieldTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/ApproveFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_ApproveFieldTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DenyRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_FieldTicketService_DenyFieldTicket = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/DenyFieldTicket',
  grpc.web.MethodType.UNARY,
  proto.proto.DenyRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.DenyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DenyRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_FieldTicketService_DenyFieldTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.DenyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DenyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.denyFieldTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/DenyFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_DenyFieldTicket,
      callback);
};


/**
 * @param {!proto.proto.DenyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.denyFieldTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/DenyFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_DenyFieldTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_FieldTicketService_SubmitFieldTicketForApproval = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/SubmitFieldTicketForApproval',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_FieldTicketService_SubmitFieldTicketForApproval = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.submitFieldTicketForApproval =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/SubmitFieldTicketForApproval',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_SubmitFieldTicketForApproval,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.submitFieldTicketForApproval =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/SubmitFieldTicketForApproval',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_SubmitFieldTicketForApproval);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.InvoiceRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_FieldTicketService_InvoiceFieldTicket = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/InvoiceFieldTicket',
  grpc.web.MethodType.UNARY,
  proto.proto.InvoiceRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.InvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.InvoiceRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_FieldTicketService_InvoiceFieldTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.InvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.InvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.invoiceFieldTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/InvoiceFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_InvoiceFieldTicket,
      callback);
};


/**
 * @param {!proto.proto.InvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.invoiceFieldTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/InvoiceFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_InvoiceFieldTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_FieldTicketService_ArchiveFieldTicket = new grpc.web.MethodDescriptor(
  '/proto.FieldTicketService/ArchiveFieldTicket',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_FieldTicketService_ArchiveFieldTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.FieldTicketServiceClient.prototype.archiveFieldTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.FieldTicketService/ArchiveFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_ArchiveFieldTicket,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.FieldTicketServicePromiseClient.prototype.archiveFieldTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.FieldTicketService/ArchiveFieldTicket',
      request,
      metadata || {},
      methodDescriptor_FieldTicketService_ArchiveFieldTicket);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.JobServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.JobServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ProvisionAttachmentRequest,
 *   !proto.proto.ProvisionedURLResponse>}
 */
const methodDescriptor_JobService_ProvisionUploadForAttachment = new grpc.web.MethodDescriptor(
  '/proto.JobService/ProvisionUploadForAttachment',
  grpc.web.MethodType.UNARY,
  proto.proto.ProvisionAttachmentRequest,
  proto.proto.ProvisionedURLResponse,
  /**
   * @param {!proto.proto.ProvisionAttachmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProvisionedURLResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ProvisionAttachmentRequest,
 *   !proto.proto.ProvisionedURLResponse>}
 */
const methodInfo_JobService_ProvisionUploadForAttachment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ProvisionedURLResponse,
  /**
   * @param {!proto.proto.ProvisionAttachmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProvisionedURLResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ProvisionAttachmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ProvisionedURLResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProvisionedURLResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.JobServiceClient.prototype.provisionUploadForAttachment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.JobService/ProvisionUploadForAttachment',
      request,
      metadata || {},
      methodDescriptor_JobService_ProvisionUploadForAttachment,
      callback);
};


/**
 * @param {!proto.proto.ProvisionAttachmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProvisionedURLResponse>}
 *     Promise that resolves to the response
 */
proto.proto.JobServicePromiseClient.prototype.provisionUploadForAttachment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.JobService/ProvisionUploadForAttachment',
      request,
      metadata || {},
      methodDescriptor_JobService_ProvisionUploadForAttachment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AssignDriverRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_JobService_AssignDriver = new grpc.web.MethodDescriptor(
  '/proto.JobService/AssignDriver',
  grpc.web.MethodType.UNARY,
  proto.proto.AssignDriverRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.AssignDriverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.AssignDriverRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_JobService_AssignDriver = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.AssignDriverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AssignDriverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.JobServiceClient.prototype.assignDriver =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.JobService/AssignDriver',
      request,
      metadata || {},
      methodDescriptor_JobService_AssignDriver,
      callback);
};


/**
 * @param {!proto.proto.AssignDriverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.JobServicePromiseClient.prototype.assignDriver =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.JobService/AssignDriver',
      request,
      metadata || {},
      methodDescriptor_JobService_AssignDriver);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_JobService_StartJob = new grpc.web.MethodDescriptor(
  '/proto.JobService/StartJob',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_JobService_StartJob = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.JobServiceClient.prototype.startJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.JobService/StartJob',
      request,
      metadata || {},
      methodDescriptor_JobService_StartJob,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.JobServicePromiseClient.prototype.startJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.JobService/StartJob',
      request,
      metadata || {},
      methodDescriptor_JobService_StartJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_JobService_EndJob = new grpc.web.MethodDescriptor(
  '/proto.JobService/EndJob',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_JobService_EndJob = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.JobServiceClient.prototype.endJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.JobService/EndJob',
      request,
      metadata || {},
      methodDescriptor_JobService_EndJob,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.JobServicePromiseClient.prototype.endJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.JobService/EndJob',
      request,
      metadata || {},
      methodDescriptor_JobService_EndJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.JobActionRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_JobService_PerformJobAction = new grpc.web.MethodDescriptor(
  '/proto.JobService/PerformJobAction',
  grpc.web.MethodType.UNARY,
  proto.proto.JobActionRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.JobActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.JobActionRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_JobService_PerformJobAction = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.JobActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.JobActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.JobServiceClient.prototype.performJobAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.JobService/PerformJobAction',
      request,
      metadata || {},
      methodDescriptor_JobService_PerformJobAction,
      callback);
};


/**
 * @param {!proto.proto.JobActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.JobServicePromiseClient.prototype.performJobAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.JobService/PerformJobAction',
      request,
      metadata || {},
      methodDescriptor_JobService_PerformJobAction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_JobService_CancelJob = new grpc.web.MethodDescriptor(
  '/proto.JobService/CancelJob',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_JobService_CancelJob = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.JobServiceClient.prototype.cancelJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.JobService/CancelJob',
      request,
      metadata || {},
      methodDescriptor_JobService_CancelJob,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.JobServicePromiseClient.prototype.cancelJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.JobService/CancelJob',
      request,
      metadata || {},
      methodDescriptor_JobService_CancelJob);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.WaypointServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.WaypointServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SaveWaypointsRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_WaypointService_SaveWaypoints = new grpc.web.MethodDescriptor(
  '/proto.WaypointService/SaveWaypoints',
  grpc.web.MethodType.UNARY,
  proto.proto.SaveWaypointsRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.SaveWaypointsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SaveWaypointsRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_WaypointService_SaveWaypoints = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.SaveWaypointsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.SaveWaypointsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.WaypointServiceClient.prototype.saveWaypoints =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.WaypointService/SaveWaypoints',
      request,
      metadata || {},
      methodDescriptor_WaypointService_SaveWaypoints,
      callback);
};


/**
 * @param {!proto.proto.SaveWaypointsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.WaypointServicePromiseClient.prototype.saveWaypoints =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.WaypointService/SaveWaypoints',
      request,
      metadata || {},
      methodDescriptor_WaypointService_SaveWaypoints);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.Waypoint>}
 */
const methodDescriptor_WaypointService_GetWaypointStreamForJob = new grpc.web.MethodDescriptor(
  '/proto.WaypointService/GetWaypointStreamForJob',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.proto.IdRequest,
  proto.proto.Waypoint,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Waypoint.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.Waypoint>}
 */
const methodInfo_WaypointService_GetWaypointStreamForJob = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Waypoint,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Waypoint.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Waypoint>}
 *     The XHR Node Readable Stream
 */
proto.proto.WaypointServiceClient.prototype.getWaypointStreamForJob =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.WaypointService/GetWaypointStreamForJob',
      request,
      metadata || {},
      methodDescriptor_WaypointService_GetWaypointStreamForJob);
};


/**
 * @param {!proto.proto.IdRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Waypoint>}
 *     The XHR Node Readable Stream
 */
proto.proto.WaypointServicePromiseClient.prototype.getWaypointStreamForJob =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.WaypointService/GetWaypointStreamForJob',
      request,
      metadata || {},
      methodDescriptor_WaypointService_GetWaypointStreamForJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.WaypointResponse>}
 */
const methodDescriptor_WaypointService_GetWaypointsForJob = new grpc.web.MethodDescriptor(
  '/proto.WaypointService/GetWaypointsForJob',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.WaypointResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.WaypointResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.WaypointResponse>}
 */
const methodInfo_WaypointService_GetWaypointsForJob = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.WaypointResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.WaypointResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.WaypointResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.WaypointResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.WaypointServiceClient.prototype.getWaypointsForJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.WaypointService/GetWaypointsForJob',
      request,
      metadata || {},
      methodDescriptor_WaypointService_GetWaypointsForJob,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.WaypointResponse>}
 *     Promise that resolves to the response
 */
proto.proto.WaypointServicePromiseClient.prototype.getWaypointsForJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.WaypointService/GetWaypointsForJob',
      request,
      metadata || {},
      methodDescriptor_WaypointService_GetWaypointsForJob);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LoginRequest,
 *   !proto.proto.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/proto.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.proto.LoginRequest,
  proto.proto.LoginResponse,
  /**
   * @param {!proto.proto.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.LoginResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.LoginRequest,
 *   !proto.proto.LoginResponse>}
 */
const methodInfo_AuthService_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.LoginResponse,
  /**
   * @param {!proto.proto.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.proto.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.EmptyRequest,
 *   !proto.proto.GetOpenIdForDeveloperIdentityResponse>}
 */
const methodDescriptor_AuthService_GetOpenIdTokenForDeveloperIdentity = new grpc.web.MethodDescriptor(
  '/proto.AuthService/GetOpenIdTokenForDeveloperIdentity',
  grpc.web.MethodType.UNARY,
  proto.proto.EmptyRequest,
  proto.proto.GetOpenIdForDeveloperIdentityResponse,
  /**
   * @param {!proto.proto.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetOpenIdForDeveloperIdentityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.EmptyRequest,
 *   !proto.proto.GetOpenIdForDeveloperIdentityResponse>}
 */
const methodInfo_AuthService_GetOpenIdTokenForDeveloperIdentity = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.GetOpenIdForDeveloperIdentityResponse,
  /**
   * @param {!proto.proto.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetOpenIdForDeveloperIdentityResponse.deserializeBinary
);


/**
 * @param {!proto.proto.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.GetOpenIdForDeveloperIdentityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetOpenIdForDeveloperIdentityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthServiceClient.prototype.getOpenIdTokenForDeveloperIdentity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthService/GetOpenIdTokenForDeveloperIdentity',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetOpenIdTokenForDeveloperIdentity,
      callback);
};


/**
 * @param {!proto.proto.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetOpenIdForDeveloperIdentityResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AuthServicePromiseClient.prototype.getOpenIdTokenForDeveloperIdentity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthService/GetOpenIdTokenForDeveloperIdentity',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetOpenIdTokenForDeveloperIdentity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ChangePasswordRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_AuthService_ChangePassword = new grpc.web.MethodDescriptor(
  '/proto.AuthService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.proto.ChangePasswordRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ChangePasswordRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_AuthService_ChangePassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePassword,
      callback);
};


/**
 * @param {!proto.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AuthServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateUserRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_AuthService_StartUserRegistration = new grpc.web.MethodDescriptor(
  '/proto.AuthService/StartUserRegistration',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateUserRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateUserRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_AuthService_StartUserRegistration = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthServiceClient.prototype.startUserRegistration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthService/StartUserRegistration',
      request,
      metadata || {},
      methodDescriptor_AuthService_StartUserRegistration,
      callback);
};


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AuthServicePromiseClient.prototype.startUserRegistration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthService/StartUserRegistration',
      request,
      metadata || {},
      methodDescriptor_AuthService_StartUserRegistration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateUserRequest,
 *   !proto.proto.UserResponse>}
 */
const methodDescriptor_AuthService_CompleteUserRegistration = new grpc.web.MethodDescriptor(
  '/proto.AuthService/CompleteUserRegistration',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateUserRequest,
  proto.proto.UserResponse,
  /**
   * @param {!proto.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateUserRequest,
 *   !proto.proto.UserResponse>}
 */
const methodInfo_AuthService_CompleteUserRegistration = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.UserResponse,
  /**
   * @param {!proto.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthServiceClient.prototype.completeUserRegistration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthService/CompleteUserRegistration',
      request,
      metadata || {},
      methodDescriptor_AuthService_CompleteUserRegistration,
      callback);
};


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AuthServicePromiseClient.prototype.completeUserRegistration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthService/CompleteUserRegistration',
      request,
      metadata || {},
      methodDescriptor_AuthService_CompleteUserRegistration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.PasswordResetRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_AuthService_StartPasswordReset = new grpc.web.MethodDescriptor(
  '/proto.AuthService/StartPasswordReset',
  grpc.web.MethodType.UNARY,
  proto.proto.PasswordResetRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.PasswordResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.PasswordResetRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_AuthService_StartPasswordReset = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.PasswordResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthServiceClient.prototype.startPasswordReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthService/StartPasswordReset',
      request,
      metadata || {},
      methodDescriptor_AuthService_StartPasswordReset,
      callback);
};


/**
 * @param {!proto.proto.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AuthServicePromiseClient.prototype.startPasswordReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthService/StartPasswordReset',
      request,
      metadata || {},
      methodDescriptor_AuthService_StartPasswordReset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ChangePasswordRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_AuthService_CompletePasswordReset = new grpc.web.MethodDescriptor(
  '/proto.AuthService/CompletePasswordReset',
  grpc.web.MethodType.UNARY,
  proto.proto.ChangePasswordRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ChangePasswordRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_AuthService_CompletePasswordReset = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AuthServiceClient.prototype.completePasswordReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AuthService/CompletePasswordReset',
      request,
      metadata || {},
      methodDescriptor_AuthService_CompletePasswordReset,
      callback);
};


/**
 * @param {!proto.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AuthServicePromiseClient.prototype.completePasswordReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AuthService/CompletePasswordReset',
      request,
      metadata || {},
      methodDescriptor_AuthService_CompletePasswordReset);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.UserListResponse>}
 */
const methodDescriptor_UserService_GetDriversForGroup = new grpc.web.MethodDescriptor(
  '/proto.UserService/GetDriversForGroup',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.UserListResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.UserListResponse>}
 */
const methodInfo_UserService_GetDriversForGroup = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.UserListResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserListResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.UserListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UserListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.getDriversForGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/GetDriversForGroup',
      request,
      metadata || {},
      methodDescriptor_UserService_GetDriversForGroup,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UserListResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.getDriversForGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/GetDriversForGroup',
      request,
      metadata || {},
      methodDescriptor_UserService_GetDriversForGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.UserResponse>}
 */
const methodDescriptor_UserService_GetUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.UserResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.UserResponse>}
 */
const methodInfo_UserService_GetUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.UserResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUser,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.UserListResponse>}
 */
const methodDescriptor_UserService_GetUserList = new grpc.web.MethodDescriptor(
  '/proto.UserService/GetUserList',
  grpc.web.MethodType.UNARY,
  proto.proto.QueryRequest,
  proto.proto.UserListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.UserListResponse>}
 */
const methodInfo_UserService_GetUserList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.UserListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserListResponse.deserializeBinary
);


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.UserListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UserListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.getUserList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/GetUserList',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserList,
      callback);
};


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UserListResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.getUserList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/GetUserList',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUserList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateUserRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_UserService_CreateUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateUserRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateUserRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_UserService_CreateUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser,
      callback);
};


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UserRequest,
 *   !proto.proto.UserResponse>}
 */
const methodDescriptor_UserService_UpdateUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.proto.UserRequest,
  proto.proto.UserResponse,
  /**
   * @param {!proto.proto.UserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UserRequest,
 *   !proto.proto.UserResponse>}
 */
const methodInfo_UserService_UpdateUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.UserResponse,
  /**
   * @param {!proto.proto.UserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser,
      callback);
};


/**
 * @param {!proto.proto.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_UserService_DeleteUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_UserService_DeleteUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteUser,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.EmptyRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_UserService_AcceptTermsAndConditions = new grpc.web.MethodDescriptor(
  '/proto.UserService/AcceptTermsAndConditions',
  grpc.web.MethodType.UNARY,
  proto.proto.EmptyRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.EmptyRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_UserService_AcceptTermsAndConditions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.acceptTermsAndConditions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/AcceptTermsAndConditions',
      request,
      metadata || {},
      methodDescriptor_UserService_AcceptTermsAndConditions,
      callback);
};


/**
 * @param {!proto.proto.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.acceptTermsAndConditions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/AcceptTermsAndConditions',
      request,
      metadata || {},
      methodDescriptor_UserService_AcceptTermsAndConditions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeviceIdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_UserService_UpdateDeviceID = new grpc.web.MethodDescriptor(
  '/proto.UserService/UpdateDeviceID',
  grpc.web.MethodType.UNARY,
  proto.proto.DeviceIdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.DeviceIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeviceIdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_UserService_UpdateDeviceID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.DeviceIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeviceIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.updateDeviceID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/UpdateDeviceID',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateDeviceID,
      callback);
};


/**
 * @param {!proto.proto.DeviceIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.updateDeviceID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/UpdateDeviceID',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateDeviceID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.PendingRegistrationsResponse>}
 */
const methodDescriptor_UserService_GetPendingRegistrations = new grpc.web.MethodDescriptor(
  '/proto.UserService/GetPendingRegistrations',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.PendingRegistrationsResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.PendingRegistrationsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.PendingRegistrationsResponse>}
 */
const methodInfo_UserService_GetPendingRegistrations = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.PendingRegistrationsResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.PendingRegistrationsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.PendingRegistrationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.PendingRegistrationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.getPendingRegistrations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/GetPendingRegistrations',
      request,
      metadata || {},
      methodDescriptor_UserService_GetPendingRegistrations,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.PendingRegistrationsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.getPendingRegistrations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/GetPendingRegistrations',
      request,
      metadata || {},
      methodDescriptor_UserService_GetPendingRegistrations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_UserService_DeletePendingRegistration = new grpc.web.MethodDescriptor(
  '/proto.UserService/DeletePendingRegistration',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_UserService_DeletePendingRegistration = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.deletePendingRegistration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/DeletePendingRegistration',
      request,
      metadata || {},
      methodDescriptor_UserService_DeletePendingRegistration,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.deletePendingRegistration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/DeletePendingRegistration',
      request,
      metadata || {},
      methodDescriptor_UserService_DeletePendingRegistration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.ResyncResponse>}
 */
const methodDescriptor_UserService_ShouldResync = new grpc.web.MethodDescriptor(
  '/proto.UserService/ShouldResync',
  grpc.web.MethodType.UNARY,
  proto.proto.QueryRequest,
  proto.proto.ResyncResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ResyncResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.ResyncResponse>}
 */
const methodInfo_UserService_ShouldResync = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ResyncResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ResyncResponse.deserializeBinary
);


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ResyncResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ResyncResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.shouldResync =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/ShouldResync',
      request,
      metadata || {},
      methodDescriptor_UserService_ShouldResync,
      callback);
};


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ResyncResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.shouldResync =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/ShouldResync',
      request,
      metadata || {},
      methodDescriptor_UserService_ShouldResync);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ErrorReportRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_UserService_ErrorReport = new grpc.web.MethodDescriptor(
  '/proto.UserService/ErrorReport',
  grpc.web.MethodType.UNARY,
  proto.proto.ErrorReportRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ErrorReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ErrorReportRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_UserService_ErrorReport = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.ErrorReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ErrorReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.errorReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/ErrorReport',
      request,
      metadata || {},
      methodDescriptor_UserService_ErrorReport,
      callback);
};


/**
 * @param {!proto.proto.ErrorReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.errorReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/ErrorReport',
      request,
      metadata || {},
      methodDescriptor_UserService_ErrorReport);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.GroupServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.GroupServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateGroupRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_GroupService_CreateGroup = new grpc.web.MethodDescriptor(
  '/proto.GroupService/CreateGroup',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateGroupRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.CreateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateGroupRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_GroupService_CreateGroup = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.CreateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.GroupServiceClient.prototype.createGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.GroupService/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateGroup,
      callback);
};


/**
 * @param {!proto.proto.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.GroupServicePromiseClient.prototype.createGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.GroupService/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_GroupService_DeleteGroup = new grpc.web.MethodDescriptor(
  '/proto.GroupService/DeleteGroup',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_GroupService_DeleteGroup = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.GroupServiceClient.prototype.deleteGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.GroupService/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_DeleteGroup,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.GroupServicePromiseClient.prototype.deleteGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.GroupService/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_DeleteGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GroupRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_GroupService_UpdateGroup = new grpc.web.MethodDescriptor(
  '/proto.GroupService/UpdateGroup',
  grpc.web.MethodType.UNARY,
  proto.proto.GroupRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.GroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.GroupRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_GroupService_UpdateGroup = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.GroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.GroupServiceClient.prototype.updateGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.GroupService/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateGroup,
      callback);
};


/**
 * @param {!proto.proto.GroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.GroupServicePromiseClient.prototype.updateGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.GroupService/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.GroupListResponse>}
 */
const methodDescriptor_GroupService_GetAllAssociatedGroups = new grpc.web.MethodDescriptor(
  '/proto.GroupService/GetAllAssociatedGroups',
  grpc.web.MethodType.UNARY,
  proto.proto.QueryRequest,
  proto.proto.GroupListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GroupListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.GroupListResponse>}
 */
const methodInfo_GroupService_GetAllAssociatedGroups = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.GroupListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GroupListResponse.deserializeBinary
);


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.GroupListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GroupListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.GroupServiceClient.prototype.getAllAssociatedGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.GroupService/GetAllAssociatedGroups',
      request,
      metadata || {},
      methodDescriptor_GroupService_GetAllAssociatedGroups,
      callback);
};


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GroupListResponse>}
 *     Promise that resolves to the response
 */
proto.proto.GroupServicePromiseClient.prototype.getAllAssociatedGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.GroupService/GetAllAssociatedGroups',
      request,
      metadata || {},
      methodDescriptor_GroupService_GetAllAssociatedGroups);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.SiteServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.SiteServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SiteRequest,
 *   !proto.proto.SiteResponse>}
 */
const methodDescriptor_SiteService_CreateSite = new grpc.web.MethodDescriptor(
  '/proto.SiteService/CreateSite',
  grpc.web.MethodType.UNARY,
  proto.proto.SiteRequest,
  proto.proto.SiteResponse,
  /**
   * @param {!proto.proto.SiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SiteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SiteRequest,
 *   !proto.proto.SiteResponse>}
 */
const methodInfo_SiteService_CreateSite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.SiteResponse,
  /**
   * @param {!proto.proto.SiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SiteResponse.deserializeBinary
);


/**
 * @param {!proto.proto.SiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.SiteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.SiteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.createSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/CreateSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_CreateSite,
      callback);
};


/**
 * @param {!proto.proto.SiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.SiteResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.createSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/CreateSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_CreateSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_SiteService_DeleteSite = new grpc.web.MethodDescriptor(
  '/proto.SiteService/DeleteSite',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_SiteService_DeleteSite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.deleteSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/DeleteSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_DeleteSite,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.deleteSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/DeleteSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_DeleteSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SiteRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_SiteService_UpdateSite = new grpc.web.MethodDescriptor(
  '/proto.SiteService/UpdateSite',
  grpc.web.MethodType.UNARY,
  proto.proto.SiteRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.SiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SiteRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_SiteService_UpdateSite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.SiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.SiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.updateSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/UpdateSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_UpdateSite,
      callback);
};


/**
 * @param {!proto.proto.SiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.updateSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/UpdateSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_UpdateSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SiteAccessChangeRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_SiteService_ChangeSiteAccessPeriods = new grpc.web.MethodDescriptor(
  '/proto.SiteService/ChangeSiteAccessPeriods',
  grpc.web.MethodType.UNARY,
  proto.proto.SiteAccessChangeRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.SiteAccessChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SiteAccessChangeRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_SiteService_ChangeSiteAccessPeriods = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.SiteAccessChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.SiteAccessChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.changeSiteAccessPeriods =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/ChangeSiteAccessPeriods',
      request,
      metadata || {},
      methodDescriptor_SiteService_ChangeSiteAccessPeriods,
      callback);
};


/**
 * @param {!proto.proto.SiteAccessChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.changeSiteAccessPeriods =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/ChangeSiteAccessPeriods',
      request,
      metadata || {},
      methodDescriptor_SiteService_ChangeSiteAccessPeriods);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.SiteResponse>}
 */
const methodDescriptor_SiteService_GetSite = new grpc.web.MethodDescriptor(
  '/proto.SiteService/GetSite',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.SiteResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SiteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.SiteResponse>}
 */
const methodInfo_SiteService_GetSite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.SiteResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SiteResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.SiteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.SiteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.getSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/GetSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_GetSite,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.SiteResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.getSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/GetSite',
      request,
      metadata || {},
      methodDescriptor_SiteService_GetSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.SiteListResponse>}
 */
const methodDescriptor_SiteService_GetSiteList = new grpc.web.MethodDescriptor(
  '/proto.SiteService/GetSiteList',
  grpc.web.MethodType.UNARY,
  proto.proto.QueryRequest,
  proto.proto.SiteListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SiteListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.QueryRequest,
 *   !proto.proto.SiteListResponse>}
 */
const methodInfo_SiteService_GetSiteList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.SiteListResponse,
  /**
   * @param {!proto.proto.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SiteListResponse.deserializeBinary
);


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.SiteListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.SiteListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.getSiteList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/GetSiteList',
      request,
      metadata || {},
      methodDescriptor_SiteService_GetSiteList,
      callback);
};


/**
 * @param {!proto.proto.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.SiteListResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.getSiteList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/GetSiteList',
      request,
      metadata || {},
      methodDescriptor_SiteService_GetSiteList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.MissingSiteReportRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_SiteService_MissingSiteReport = new grpc.web.MethodDescriptor(
  '/proto.SiteService/MissingSiteReport',
  grpc.web.MethodType.UNARY,
  proto.proto.MissingSiteReportRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.MissingSiteReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.MissingSiteReportRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_SiteService_MissingSiteReport = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.MissingSiteReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.MissingSiteReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.missingSiteReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/MissingSiteReport',
      request,
      metadata || {},
      methodDescriptor_SiteService_MissingSiteReport,
      callback);
};


/**
 * @param {!proto.proto.MissingSiteReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.missingSiteReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/MissingSiteReport',
      request,
      metadata || {},
      methodDescriptor_SiteService_MissingSiteReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodDescriptor_SiteService_LogSiteActivity = new grpc.web.MethodDescriptor(
  '/proto.SiteService/LogSiteActivity',
  grpc.web.MethodType.UNARY,
  proto.proto.IdRequest,
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.IdRequest,
 *   !proto.proto.ErrorResponse>}
 */
const methodInfo_SiteService_LogSiteActivity = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ErrorResponse,
  /**
   * @param {!proto.proto.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ErrorResponse.deserializeBinary
);


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SiteServiceClient.prototype.logSiteActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SiteService/LogSiteActivity',
      request,
      metadata || {},
      methodDescriptor_SiteService_LogSiteActivity,
      callback);
};


/**
 * @param {!proto.proto.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ErrorResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SiteServicePromiseClient.prototype.logSiteActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SiteService/LogSiteActivity',
      request,
      metadata || {},
      methodDescriptor_SiteService_LogSiteActivity);
};


module.exports = proto.proto;

