import React, { Fragment, useEffect, useState } from "react";
import { FieldTicketResponse, FieldTicketsPageRequest, IdRequest, QueryRequest } from '../../proto/model_pb.js'
import { FieldTicketServiceClient, UserServiceClient, WaypointServiceClient } from '../../proto/model_grpc_web_pb.js'
import SearchBar from "./SearchBar"
import FieldTicketSectionSelector from "./FieldTicketSectionSelector"
import { OriginalOrder } from "./OriginalOrder"
import { TicketSection } from "./TicketSection"
import { useTable, ReactTable } from 'react-table'
import Modal from 'react-modal'
import config from '../../config';
import UtilService  from '../../services/util.service'
import BigNumber from 'bignumber.js'
import { NavBar } from '../../components/navbar/Navbar';
import { connect } from 'react-redux';
import { FieldTicketsRoute } from '../../_types/nav.types'
import './FieldTickets.scss'
import './Sidebar.css'



Modal.setAppElement("#root");

class FieldTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {fieldTickets: [], filteredTickets:[], selectedSection: 0, selectedTicket: null};

    this.update = this.update.bind(this);
    this.openTicketModal = this.openTicketModal.bind(this);
    this.closeTicketModal = this.closeTicketModal.bind(this);
    this.filterTickets = this.filterTickets.bind(this);

    const pathName = window.location.pathname.split('/');

    if (pathName.length === 3) {
      const id = pathName[2];

      const ticketService = new FieldTicketServiceClient(config.apiUrl);
      const request = new IdRequest();
      request.setId(id);
      request.setToken(this.props.token);

      ticketService.getFieldTicketData(request, {}, (err, resp) => {
        if (err) {
          this.props.history.push('/field-tickets');
        };
        if (resp.getError()) {
          this.props.history.push('/field-tickets');
          alert(resp.getError().getMessage())
        } else {
          const ticket = resp.getTicket();

          if (ticket && ticket.getActionrecordsList()) {
            this.setState({
              selectedTicket: ticket,
              selectedSection: ticket.getStatus()
            })
          } else {
            this.props.history.push('/field-tickets');
          }
        }
      })

    }
  }

  componentDidMount() {
    this.update()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.selectedSection !== this.state.selectedSection) {
      this.update()
    }
  }

  update() {
    var ticketService = new FieldTicketServiceClient(config.apiUrl);

    // Request new field ticket data depending on the selected section
    var query = new QueryRequest()
    query.setToken(this.props.token)
    query.setLimit(0)
    query.setOffset(0)

    var request = new FieldTicketsPageRequest()
    request.setQueryparameters(query)
    request.setIncludestatus(this.state.selectedSection)

    ticketService.getFieldTicketsPageData(
      request,
      {},
      (err, response) => {
        if (err != null) {
          return
        }

        if (response.getError()) {
          alert(response.getError().getMessage())
        } else {
          this.setState({fieldTickets: response.getTicketsList()})
          this.filterTickets(null)
        }
      }
    )
  }
  
  openTicketModal(ticketToOpen) {
    this.props.history.push(`/field-tickets/${ticketToOpen.getId()}`);
    this.setState({selectedTicket: ticketToOpen})
  }
  
  closeTicketModal() {
    this.props.history.push(`/field-tickets`);
    this.setState({selectedTicket: null})
    this.update()
  }

  filterTickets(term) {
    if (term == null || term == "") {
      this.setState({filteredTickets: this.state.fieldTickets})
      this.forceUpdate()
      return
    }
    const result = this.state.fieldTickets.filter(ticket => 
      ticket.getTicketuuid().toLowerCase().includes(term) || 
      ticket.getPurchaseordernumber().toLowerCase().includes(term) || 
      (ticket.getCompletinggroup()?.getName().toLowerCase() ?? "").includes(term)
    );
    this.setState({filteredTickets: result})
    this.forceUpdate()
  }

  render() {
    const tableData = []
    for (const ticket of this.state.filteredTickets) {
      var userIsPartOfHaulingGroup = ( ticket.getCompletinggroup().getId() === this.props.groupId )
      var userIsPartOfRequestingGroup = ( ticket.getGroup().getId() === this.props.groupId )
      var userIsPartOfBondway = this.props.userAdmin
  
      var totalValue = new BigNumber(0)

      var r = ticket.getBid()?.getSurchargesList().map( function(value) {
        totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(value.getRate()))
      })

      var actionRecords = ticket.getActionrecordsList()
      .sort( function(a, b) { return a.getScheduledaction().getOrder() - b.getScheduledaction().getOrder() } )
      .map( function(val) { return { order: val.getScheduledaction().getOrder(), date: val.getTimestamp().toDate(), type: val.getScheduledaction().getActiontype(), id: val.getId(), siteName: val.getSite().getName() } } )

      if (actionRecords.length > 0) {
        var deadheadToJob = UtilService.convertPbMoneyToBigNumber(ticket.getBid()?.getHourlyrate()).times(UtilService.msToTime(actionRecords[0].date) - UtilService.msToTime(ticket.getStarttime()?.toDate())).decimalPlaces(2)
        var deadheadFromJob = UtilService.convertPbMoneyToBigNumber(ticket.getBid()?.getHourlyrate()).times(UtilService.msToTime(ticket.getEndtime()?.toDate()) - UtilService.msToTime(actionRecords[actionRecords.length - 1].date)).decimalPlaces(2)
    
        totalValue = totalValue.plus(deadheadToJob)
        totalValue = totalValue.plus(deadheadFromJob)  
      }

      if (ticket.getBid()?.getBillingtype() === 0) {
        totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(ticket.getBid()?.getFlatrate()).toFixed(2))
      } else {
          totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(ticket.getBid()?.getHourlyrate()).times(UtilService.msToTime(actionRecords[actionRecords.length - 1].date) - UtilService.msToTime(actionRecords[0].date)).decimalPlaces(2))
      }

      var charges = ticket.getExtrachargesList().map( function(charge) {
        totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(charge.getAmount()).toFixed(2))
      })

      const totalBeforeFees = totalValue
      const totalRequesterFee = totalBeforeFees.times(UtilService.convertPbMoneyToBigNumber(ticket.getShipperfeerate()))
      const totalHaulerFee = totalBeforeFees.times(UtilService.convertPbMoneyToBigNumber(ticket.getHaulerfeerate()))
      var totalValueAfterFees = userIsPartOfRequestingGroup ? totalBeforeFees.plus(totalRequesterFee) : userIsPartOfHaulingGroup ? totalBeforeFees.minus(totalHaulerFee) : userIsPartOfBondway ? totalBeforeFees : new BigNumber(0.0)
  
      let ticketTableData = {
        col1: ticket.getGroup().getName(),
        col2: ticket.getCompletinggroup()?.getName() ?? "",
        col3: <span className="FakeLinkStyle" onClick={() => {this.openTicketModal(ticket)}}>#{ticket.getTicketuuid()}</span>,
        col4: ticket.getStarttime().toDate().toLocaleString(),
        col5: ticket.getAssigneduser().getName(),
        col7: "$"+UtilService.formatMoney(totalValueAfterFees, 2),
        col8: ticket.getPurchaseordernumber(),
      }

      
      tableData.push(ticketTableData)
    }

    const columns = [
                      {
                        Header: 'Requester',
                        accessor: 'col1', // accessor is the "key" in the data
                      },
                      {
                        Header: 'Hauler',
                        accessor: 'col2', // accessor is the "key" in the data
                      },
                      {
                        Header: 'Ticket #',
                        accessor: 'col3',
                      },
                      {
                        Header: 'PO #',
                        accessor: 'col8',
                      },
                      {
                        Header: 'Start Date',
                        accessor: 'col4',
                      },
                      {
                        Header: 'Assigned To',
                        accessor: 'col5',
                      },
                      {
                        Header: 'Total',
                        accessor: 'col7',
                      },
                    ]

    return  <Fragment>
              <NavBar activeRoute={FieldTicketsRoute} />

              <main className="PageContainer">
                <header><SearchBar searchCallback={this.filterTickets} /></header>
                <section className="FieldTicketsContainer"> 
                  <FieldTicketSectionSelector selectedSection={this.state.selectedSection} handleSelectionClick={(section) => {
                    this.setState({selectedSection: section})
                  }}/>
                  <Modal
                    isOpen={this.state.selectedTicket != null}
                    onRequestClose={this.closeTicketModal}
                    contentLabel="My dialog"
                    className="FieldTicketModal"
                    overlayClassName="FieldTicketModalOverlay"
                    closeTimeoutMS={300}
                  >
                    <OriginalOrder fieldTicket={this.state.selectedTicket} />
                    <TicketSection fieldTicket={this.state.selectedTicket} closeSelf={this.closeTicketModal} updateSelf={ ticket => { this.setState( { selectedTicket: ticket } ) } } />
                  </Modal>
                  <div className="FieldTicketsTable">
                    <Table columns={columns} data={tableData} />
                  </div>
                </section>
              </main>
            </Fragment>
  }
}

function mapState(state) {
  const { authentication } = state;
  return {
      token: authentication.token,
      groupType: authentication.groupType,
      groupId: authentication.groupId,
      userAdmin: authentication.userAdmin
  };
}

const connectedApp = connect(mapState)(FieldTickets);

export { connectedApp as FieldTickets };

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table className="FieldTicketsTable" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr className="FieldTicketsTableHeaderBox" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className="FieldTicketsTableHeaders" {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td className="FieldTicketsTableData" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )}
        )}
      </tbody>
    </table>
  )
}