import React, { Fragment, useEffect, useState } from "react";
import { NavBar } from '../../components/navbar/Navbar';
import { AdminReportsRoute } from '../../_types/nav.types'
import { connect } from 'react-redux';

import './Admin.css';

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return  <Fragment>
              <NavBar activeRoute={AdminReportsRoute} />
              <main className="PageContainer">

                <div style={{height: 60}}/>
                <div style={{marginLeft: 24}}>
                <form method="get" action="https://bondway.us:1668/excel/sites.xlsx">
                  <input type="hidden" name="token" value={this.props.token}/>
                  <h3>Get Listing Data <button type="submit">Download</button></h3>
                </form>
                <form method="get" action="https://bondway.us:1668/excel/sites.xlsx">
                  <input type="hidden" name="token" value={this.props.token}/>
                  <h3>Get Site Data <button type="submit">Download</button></h3>
                </form>
                <form method="get" action="https://bondway.us:1668/excel/users.xlsx">
                  <input type="hidden" name="token" value={this.props.token}/>
                  <h3>Get User Data <button type="submit">Download</button></h3>
                </form>
                <form method="get" action="https://bondway.us:1668/excel/groups.xlsx">
                  <input type="hidden" name="token" value={this.props.token}/>
                  <h3>Get Company Data <button type="submit">Download</button></h3>
                </form>
              </div>
              </main>
            </Fragment>
  }
}


function mapState(state) {
  const { authentication } = state;
  return {
      token: authentication.token,
  };
}

const connectedApp = connect(mapState)(AdminPage);

export { connectedApp as AdminPage };