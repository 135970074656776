/* eslint-disable */
//@ts-nocheck
/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as model_pb from './model_pb';


export class RoutingServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetListingDirections = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.RouteResponse,
    (request: model_pb.ListingRoutingRequest) => {
      return request.serializeBinary();
    },
    model_pb.RouteResponse.deserializeBinary
  );

  getListingDirections(
    request: model_pb.ListingRoutingRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.RouteResponse>;

  getListingDirections(
    request: model_pb.ListingRoutingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.RouteResponse) => void): grpcWeb.ClientReadableStream<model_pb.RouteResponse>;

  getListingDirections(
    request: model_pb.ListingRoutingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.RouteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.RoutingService/GetListingDirections',
        request,
        metadata || {},
        this.methodInfoGetListingDirections,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.RoutingService/GetListingDirections',
    request,
    metadata || {},
    this.methodInfoGetListingDirections);
  }

  methodInfoGetDeadheadDirections = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.DeadheadRouteResponse,
    (request: model_pb.DeadheadRoutingRequest) => {
      return request.serializeBinary();
    },
    model_pb.DeadheadRouteResponse.deserializeBinary
  );

  getDeadheadDirections(
    request: model_pb.DeadheadRoutingRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.DeadheadRouteResponse>;

  getDeadheadDirections(
    request: model_pb.DeadheadRoutingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.DeadheadRouteResponse) => void): grpcWeb.ClientReadableStream<model_pb.DeadheadRouteResponse>;

  getDeadheadDirections(
    request: model_pb.DeadheadRoutingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.DeadheadRouteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.RoutingService/GetDeadheadDirections',
        request,
        metadata || {},
        this.methodInfoGetDeadheadDirections,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.RoutingService/GetDeadheadDirections',
    request,
    metadata || {},
    this.methodInfoGetDeadheadDirections);
  }

}

export class StatsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetOperatorDashboardStats = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.StatsOperatorDashboardResponse,
    (request: model_pb.StatsDashboardRequest) => {
      return request.serializeBinary();
    },
    model_pb.StatsOperatorDashboardResponse.deserializeBinary
  );

  getOperatorDashboardStats(
    request: model_pb.StatsDashboardRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.StatsOperatorDashboardResponse>;

  getOperatorDashboardStats(
    request: model_pb.StatsDashboardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.StatsOperatorDashboardResponse) => void): grpcWeb.ClientReadableStream<model_pb.StatsOperatorDashboardResponse>;

  getOperatorDashboardStats(
    request: model_pb.StatsDashboardRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.StatsOperatorDashboardResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.StatsService/GetOperatorDashboardStats',
        request,
        metadata || {},
        this.methodInfoGetOperatorDashboardStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.StatsService/GetOperatorDashboardStats',
    request,
    metadata || {},
    this.methodInfoGetOperatorDashboardStats);
  }

}

export class HaulingListingServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateHaulingListing = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.HaulListingRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  createHaulingListing(
    request: model_pb.HaulListingRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  createHaulingListing(
    request: model_pb.HaulListingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  createHaulingListing(
    request: model_pb.HaulListingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/CreateHaulingListing',
        request,
        metadata || {},
        this.methodInfoCreateHaulingListing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/CreateHaulingListing',
    request,
    metadata || {},
    this.methodInfoCreateHaulingListing);
  }

  methodInfoUpdateHaulingListing = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.HaulListingRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  updateHaulingListing(
    request: model_pb.HaulListingRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  updateHaulingListing(
    request: model_pb.HaulListingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  updateHaulingListing(
    request: model_pb.HaulListingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/UpdateHaulingListing',
        request,
        metadata || {},
        this.methodInfoUpdateHaulingListing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/UpdateHaulingListing',
    request,
    metadata || {},
    this.methodInfoUpdateHaulingListing);
  }

  methodInfoCancelHaulingListing = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  cancelHaulingListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  cancelHaulingListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  cancelHaulingListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/CancelHaulingListing',
        request,
        metadata || {},
        this.methodInfoCancelHaulingListing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/CancelHaulingListing',
    request,
    metadata || {},
    this.methodInfoCancelHaulingListing);
  }

  methodInfoGetHaulingListing = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.HaulingListingResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.HaulingListingResponse.deserializeBinary
  );

  getHaulingListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.HaulingListingResponse>;

  getHaulingListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.HaulingListingResponse) => void): grpcWeb.ClientReadableStream<model_pb.HaulingListingResponse>;

  getHaulingListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.HaulingListingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/GetHaulingListing',
        request,
        metadata || {},
        this.methodInfoGetHaulingListing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/GetHaulingListing',
    request,
    metadata || {},
    this.methodInfoGetHaulingListing);
  }

  methodInfoGetHaulingListings = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.HaulingListingListResponse,
    (request: model_pb.QueryRequest) => {
      return request.serializeBinary();
    },
    model_pb.HaulingListingListResponse.deserializeBinary
  );

  getHaulingListings(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.HaulingListingListResponse>;

  getHaulingListings(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.HaulingListingListResponse) => void): grpcWeb.ClientReadableStream<model_pb.HaulingListingListResponse>;

  getHaulingListings(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.HaulingListingListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/GetHaulingListings',
        request,
        metadata || {},
        this.methodInfoGetHaulingListings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/GetHaulingListings',
    request,
    metadata || {},
    this.methodInfoGetHaulingListings);
  }

  methodInfoGetBidsForListing = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.BidListResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.BidListResponse.deserializeBinary
  );

  getBidsForListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.BidListResponse>;

  getBidsForListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.BidListResponse) => void): grpcWeb.ClientReadableStream<model_pb.BidListResponse>;

  getBidsForListing(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.BidListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/GetBidsForListing',
        request,
        metadata || {},
        this.methodInfoGetBidsForListing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/GetBidsForListing',
    request,
    metadata || {},
    this.methodInfoGetBidsForListing);
  }

  methodInfoGetCompanyBid = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.BidResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.BidResponse.deserializeBinary
  );

  getCompanyBid(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.BidResponse>;

  getCompanyBid(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.BidResponse) => void): grpcWeb.ClientReadableStream<model_pb.BidResponse>;

  getCompanyBid(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.BidResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/GetCompanyBid',
        request,
        metadata || {},
        this.methodInfoGetCompanyBid,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/GetCompanyBid',
    request,
    metadata || {},
    this.methodInfoGetCompanyBid);
  }

  methodInfoBidOnListing = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.BidRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  bidOnListing(
    request: model_pb.BidRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  bidOnListing(
    request: model_pb.BidRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  bidOnListing(
    request: model_pb.BidRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/BidOnListing',
        request,
        metadata || {},
        this.methodInfoBidOnListing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/BidOnListing',
    request,
    metadata || {},
    this.methodInfoBidOnListing);
  }

  methodInfoAcceptBid = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.AcceptBidRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  acceptBid(
    request: model_pb.AcceptBidRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  acceptBid(
    request: model_pb.AcceptBidRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  acceptBid(
    request: model_pb.AcceptBidRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/AcceptBid',
        request,
        metadata || {},
        this.methodInfoAcceptBid,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/AcceptBid',
    request,
    metadata || {},
    this.methodInfoAcceptBid);
  }

  methodInfoUpdateBid = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.BidRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  updateBid(
    request: model_pb.BidRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  updateBid(
    request: model_pb.BidRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  updateBid(
    request: model_pb.BidRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/UpdateBid',
        request,
        metadata || {},
        this.methodInfoUpdateBid,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/UpdateBid',
    request,
    metadata || {},
    this.methodInfoUpdateBid);
  }

  methodInfoWithdrawBid = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  withdrawBid(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  withdrawBid(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  withdrawBid(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/WithdrawBid',
        request,
        metadata || {},
        this.methodInfoWithdrawBid,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/WithdrawBid',
    request,
    metadata || {},
    this.methodInfoWithdrawBid);
  }

  methodInfoPlaceBidSuggestion = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.IdResponse,
    (request: model_pb.BidSuggestionRequest) => {
      return request.serializeBinary();
    },
    model_pb.IdResponse.deserializeBinary
  );

  placeBidSuggestion(
    request: model_pb.BidSuggestionRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.IdResponse>;

  placeBidSuggestion(
    request: model_pb.BidSuggestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.IdResponse) => void): grpcWeb.ClientReadableStream<model_pb.IdResponse>;

  placeBidSuggestion(
    request: model_pb.BidSuggestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.IdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/PlaceBidSuggestion',
        request,
        metadata || {},
        this.methodInfoPlaceBidSuggestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/PlaceBidSuggestion',
    request,
    metadata || {},
    this.methodInfoPlaceBidSuggestion);
  }

  methodInfoDeclineBidSuggestion = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  declineBidSuggestion(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  declineBidSuggestion(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  declineBidSuggestion(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/DeclineBidSuggestion',
        request,
        metadata || {},
        this.methodInfoDeclineBidSuggestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/DeclineBidSuggestion',
    request,
    metadata || {},
    this.methodInfoDeclineBidSuggestion);
  }

  methodInfoGetBillingDefaults = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.BillingDefaultsResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.BillingDefaultsResponse.deserializeBinary
  );

  getBillingDefaults(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.BillingDefaultsResponse>;

  getBillingDefaults(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.BillingDefaultsResponse) => void): grpcWeb.ClientReadableStream<model_pb.BillingDefaultsResponse>;

  getBillingDefaults(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.BillingDefaultsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/GetBillingDefaults',
        request,
        metadata || {},
        this.methodInfoGetBillingDefaults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/GetBillingDefaults',
    request,
    metadata || {},
    this.methodInfoGetBillingDefaults);
  }

  methodInfoUpdateBillingDefaults = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.BillingDefaultsRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  updateBillingDefaults(
    request: model_pb.BillingDefaultsRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  updateBillingDefaults(
    request: model_pb.BillingDefaultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  updateBillingDefaults(
    request: model_pb.BillingDefaultsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/UpdateBillingDefaults',
        request,
        metadata || {},
        this.methodInfoUpdateBillingDefaults,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/UpdateBillingDefaults',
    request,
    metadata || {},
    this.methodInfoUpdateBillingDefaults);
  }

  methodInfoProvisionUploadForAttachment = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ProvisionedURLResponse,
    (request: model_pb.ProvisionAttachmentRequest) => {
      return request.serializeBinary();
    },
    model_pb.ProvisionedURLResponse.deserializeBinary
  );

  provisionUploadForAttachment(
    request: model_pb.ProvisionAttachmentRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ProvisionedURLResponse>;

  provisionUploadForAttachment(
    request: model_pb.ProvisionAttachmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ProvisionedURLResponse) => void): grpcWeb.ClientReadableStream<model_pb.ProvisionedURLResponse>;

  provisionUploadForAttachment(
    request: model_pb.ProvisionAttachmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ProvisionedURLResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.HaulingListingService/ProvisionUploadForAttachment',
        request,
        metadata || {},
        this.methodInfoProvisionUploadForAttachment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.HaulingListingService/ProvisionUploadForAttachment',
    request,
    metadata || {},
    this.methodInfoProvisionUploadForAttachment);
  }

}

export class FieldTicketServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetFieldTicketsPageData = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.FieldTicketsPageResponse,
    (request: model_pb.FieldTicketsPageRequest) => {
      return request.serializeBinary();
    },
    model_pb.FieldTicketsPageResponse.deserializeBinary
  );

  getFieldTicketsPageData(
    request: model_pb.FieldTicketsPageRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.FieldTicketsPageResponse>;

  getFieldTicketsPageData(
    request: model_pb.FieldTicketsPageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.FieldTicketsPageResponse) => void): grpcWeb.ClientReadableStream<model_pb.FieldTicketsPageResponse>;

  getFieldTicketsPageData(
    request: model_pb.FieldTicketsPageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.FieldTicketsPageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/GetFieldTicketsPageData',
        request,
        metadata || {},
        this.methodInfoGetFieldTicketsPageData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/GetFieldTicketsPageData',
    request,
    metadata || {},
    this.methodInfoGetFieldTicketsPageData);
  }

  methodInfoGetFieldTicketData = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.FieldTicketResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.FieldTicketResponse.deserializeBinary
  );

  getFieldTicketData(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.FieldTicketResponse>;

  getFieldTicketData(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.FieldTicketResponse) => void): grpcWeb.ClientReadableStream<model_pb.FieldTicketResponse>;

  getFieldTicketData(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.FieldTicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/GetFieldTicketData',
        request,
        metadata || {},
        this.methodInfoGetFieldTicketData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/GetFieldTicketData',
    request,
    metadata || {},
    this.methodInfoGetFieldTicketData);
  }

  methodInfoCommentOnFieldTicket = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.FieldTicketCommentRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  commentOnFieldTicket(
    request: model_pb.FieldTicketCommentRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  commentOnFieldTicket(
    request: model_pb.FieldTicketCommentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  commentOnFieldTicket(
    request: model_pb.FieldTicketCommentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/CommentOnFieldTicket',
        request,
        metadata || {},
        this.methodInfoCommentOnFieldTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/CommentOnFieldTicket',
    request,
    metadata || {},
    this.methodInfoCommentOnFieldTicket);
  }

  methodInfoUpdateFieldTicket = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.FieldTicketUpdateRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  updateFieldTicket(
    request: model_pb.FieldTicketUpdateRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  updateFieldTicket(
    request: model_pb.FieldTicketUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  updateFieldTicket(
    request: model_pb.FieldTicketUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/UpdateFieldTicket',
        request,
        metadata || {},
        this.methodInfoUpdateFieldTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/UpdateFieldTicket',
    request,
    metadata || {},
    this.methodInfoUpdateFieldTicket);
  }

  methodInfoApproveFieldTicket = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.ApprovalRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  approveFieldTicket(
    request: model_pb.ApprovalRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  approveFieldTicket(
    request: model_pb.ApprovalRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  approveFieldTicket(
    request: model_pb.ApprovalRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/ApproveFieldTicket',
        request,
        metadata || {},
        this.methodInfoApproveFieldTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/ApproveFieldTicket',
    request,
    metadata || {},
    this.methodInfoApproveFieldTicket);
  }

  methodInfoDenyFieldTicket = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.DenyRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  denyFieldTicket(
    request: model_pb.DenyRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  denyFieldTicket(
    request: model_pb.DenyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  denyFieldTicket(
    request: model_pb.DenyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/DenyFieldTicket',
        request,
        metadata || {},
        this.methodInfoDenyFieldTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/DenyFieldTicket',
    request,
    metadata || {},
    this.methodInfoDenyFieldTicket);
  }

  methodInfoSubmitFieldTicketForApproval = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  submitFieldTicketForApproval(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  submitFieldTicketForApproval(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  submitFieldTicketForApproval(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/SubmitFieldTicketForApproval',
        request,
        metadata || {},
        this.methodInfoSubmitFieldTicketForApproval,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/SubmitFieldTicketForApproval',
    request,
    metadata || {},
    this.methodInfoSubmitFieldTicketForApproval);
  }

  methodInfoInvoiceFieldTicket = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.InvoiceRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  invoiceFieldTicket(
    request: model_pb.InvoiceRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  invoiceFieldTicket(
    request: model_pb.InvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  invoiceFieldTicket(
    request: model_pb.InvoiceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/InvoiceFieldTicket',
        request,
        metadata || {},
        this.methodInfoInvoiceFieldTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/InvoiceFieldTicket',
    request,
    metadata || {},
    this.methodInfoInvoiceFieldTicket);
  }

  methodInfoArchiveFieldTicket = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  archiveFieldTicket(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  archiveFieldTicket(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  archiveFieldTicket(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.FieldTicketService/ArchiveFieldTicket',
        request,
        metadata || {},
        this.methodInfoArchiveFieldTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.FieldTicketService/ArchiveFieldTicket',
    request,
    metadata || {},
    this.methodInfoArchiveFieldTicket);
  }

}

export class JobServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoProvisionUploadForAttachment = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ProvisionedURLResponse,
    (request: model_pb.ProvisionAttachmentRequest) => {
      return request.serializeBinary();
    },
    model_pb.ProvisionedURLResponse.deserializeBinary
  );

  provisionUploadForAttachment(
    request: model_pb.ProvisionAttachmentRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ProvisionedURLResponse>;

  provisionUploadForAttachment(
    request: model_pb.ProvisionAttachmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ProvisionedURLResponse) => void): grpcWeb.ClientReadableStream<model_pb.ProvisionedURLResponse>;

  provisionUploadForAttachment(
    request: model_pb.ProvisionAttachmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ProvisionedURLResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.JobService/ProvisionUploadForAttachment',
        request,
        metadata || {},
        this.methodInfoProvisionUploadForAttachment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.JobService/ProvisionUploadForAttachment',
    request,
    metadata || {},
    this.methodInfoProvisionUploadForAttachment);
  }

  methodInfoAssignDriver = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.AssignDriverRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  assignDriver(
    request: model_pb.AssignDriverRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  assignDriver(
    request: model_pb.AssignDriverRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  assignDriver(
    request: model_pb.AssignDriverRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.JobService/AssignDriver',
        request,
        metadata || {},
        this.methodInfoAssignDriver,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.JobService/AssignDriver',
    request,
    metadata || {},
    this.methodInfoAssignDriver);
  }

  methodInfoStartJob = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  startJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  startJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  startJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.JobService/StartJob',
        request,
        metadata || {},
        this.methodInfoStartJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.JobService/StartJob',
    request,
    metadata || {},
    this.methodInfoStartJob);
  }

  methodInfoEndJob = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  endJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  endJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  endJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.JobService/EndJob',
        request,
        metadata || {},
        this.methodInfoEndJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.JobService/EndJob',
    request,
    metadata || {},
    this.methodInfoEndJob);
  }

  methodInfoPerformJobAction = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.JobActionRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  performJobAction(
    request: model_pb.JobActionRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  performJobAction(
    request: model_pb.JobActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  performJobAction(
    request: model_pb.JobActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.JobService/PerformJobAction',
        request,
        metadata || {},
        this.methodInfoPerformJobAction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.JobService/PerformJobAction',
    request,
    metadata || {},
    this.methodInfoPerformJobAction);
  }

  methodInfoCancelJob = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  cancelJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  cancelJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  cancelJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.JobService/CancelJob',
        request,
        metadata || {},
        this.methodInfoCancelJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.JobService/CancelJob',
    request,
    metadata || {},
    this.methodInfoCancelJob);
  }

}

export class WaypointServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoSaveWaypoints = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.SaveWaypointsRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  saveWaypoints(
    request: model_pb.SaveWaypointsRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  saveWaypoints(
    request: model_pb.SaveWaypointsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  saveWaypoints(
    request: model_pb.SaveWaypointsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.WaypointService/SaveWaypoints',
        request,
        metadata || {},
        this.methodInfoSaveWaypoints,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.WaypointService/SaveWaypoints',
    request,
    metadata || {},
    this.methodInfoSaveWaypoints);
  }

  methodInfoGetWaypointStreamForJob = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.Waypoint,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.Waypoint.deserializeBinary
  );

  getWaypointStreamForJob(
    request: model_pb.IdRequest,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/proto.WaypointService/GetWaypointStreamForJob',
      request,
      metadata || {},
      this.methodInfoGetWaypointStreamForJob);
  }

  methodInfoGetWaypointsForJob = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.WaypointResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.WaypointResponse.deserializeBinary
  );

  getWaypointsForJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.WaypointResponse>;

  getWaypointsForJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.WaypointResponse) => void): grpcWeb.ClientReadableStream<model_pb.WaypointResponse>;

  getWaypointsForJob(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.WaypointResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.WaypointService/GetWaypointsForJob',
        request,
        metadata || {},
        this.methodInfoGetWaypointsForJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.WaypointService/GetWaypointsForJob',
    request,
    metadata || {},
    this.methodInfoGetWaypointsForJob);
  }

}

export class AuthServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoLogin = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.LoginResponse,
    (request: model_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    model_pb.LoginResponse.deserializeBinary
  );

  login(
    request: model_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.LoginResponse>;

  login(
    request: model_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.LoginResponse) => void): grpcWeb.ClientReadableStream<model_pb.LoginResponse>;

  login(
    request: model_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.LoginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AuthService/Login',
        request,
        metadata || {},
        this.methodInfoLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AuthService/Login',
    request,
    metadata || {},
    this.methodInfoLogin);
  }

  methodInfoGetOpenIdTokenForDeveloperIdentity = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.GetOpenIdForDeveloperIdentityResponse,
    (request: model_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    model_pb.GetOpenIdForDeveloperIdentityResponse.deserializeBinary
  );

  getOpenIdTokenForDeveloperIdentity(
    request: model_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.GetOpenIdForDeveloperIdentityResponse>;

  getOpenIdTokenForDeveloperIdentity(
    request: model_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.GetOpenIdForDeveloperIdentityResponse) => void): grpcWeb.ClientReadableStream<model_pb.GetOpenIdForDeveloperIdentityResponse>;

  getOpenIdTokenForDeveloperIdentity(
    request: model_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.GetOpenIdForDeveloperIdentityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AuthService/GetOpenIdTokenForDeveloperIdentity',
        request,
        metadata || {},
        this.methodInfoGetOpenIdTokenForDeveloperIdentity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AuthService/GetOpenIdTokenForDeveloperIdentity',
    request,
    metadata || {},
    this.methodInfoGetOpenIdTokenForDeveloperIdentity);
  }

  methodInfoChangePassword = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.ChangePasswordRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  changePassword(
    request: model_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  changePassword(
    request: model_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  changePassword(
    request: model_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AuthService/ChangePassword',
        request,
        metadata || {},
        this.methodInfoChangePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AuthService/ChangePassword',
    request,
    metadata || {},
    this.methodInfoChangePassword);
  }

  methodInfoStartUserRegistration = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  startUserRegistration(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  startUserRegistration(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  startUserRegistration(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AuthService/StartUserRegistration',
        request,
        metadata || {},
        this.methodInfoStartUserRegistration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AuthService/StartUserRegistration',
    request,
    metadata || {},
    this.methodInfoStartUserRegistration);
  }

  methodInfoCompleteUserRegistration = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.UserResponse,
    (request: model_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    model_pb.UserResponse.deserializeBinary
  );

  completeUserRegistration(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.UserResponse>;

  completeUserRegistration(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.UserResponse) => void): grpcWeb.ClientReadableStream<model_pb.UserResponse>;

  completeUserRegistration(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AuthService/CompleteUserRegistration',
        request,
        metadata || {},
        this.methodInfoCompleteUserRegistration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AuthService/CompleteUserRegistration',
    request,
    metadata || {},
    this.methodInfoCompleteUserRegistration);
  }

  methodInfoStartPasswordReset = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.PasswordResetRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  startPasswordReset(
    request: model_pb.PasswordResetRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  startPasswordReset(
    request: model_pb.PasswordResetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  startPasswordReset(
    request: model_pb.PasswordResetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AuthService/StartPasswordReset',
        request,
        metadata || {},
        this.methodInfoStartPasswordReset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AuthService/StartPasswordReset',
    request,
    metadata || {},
    this.methodInfoStartPasswordReset);
  }

  methodInfoCompletePasswordReset = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.ChangePasswordRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  completePasswordReset(
    request: model_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  completePasswordReset(
    request: model_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  completePasswordReset(
    request: model_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AuthService/CompletePasswordReset',
        request,
        metadata || {},
        this.methodInfoCompletePasswordReset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AuthService/CompletePasswordReset',
    request,
    metadata || {},
    this.methodInfoCompletePasswordReset);
  }

}

export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetDriversForGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.UserListResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.UserListResponse.deserializeBinary
  );

  getDriversForGroup(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.UserListResponse>;

  getDriversForGroup(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.UserListResponse) => void): grpcWeb.ClientReadableStream<model_pb.UserListResponse>;

  getDriversForGroup(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.UserListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/GetDriversForGroup',
        request,
        metadata || {},
        this.methodInfoGetDriversForGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/GetDriversForGroup',
    request,
    metadata || {},
    this.methodInfoGetDriversForGroup);
  }

  methodInfoGetUser = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.UserResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.UserResponse.deserializeBinary
  );

  getUser(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.UserResponse>;

  getUser(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.UserResponse) => void): grpcWeb.ClientReadableStream<model_pb.UserResponse>;

  getUser(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/GetUser',
        request,
        metadata || {},
        this.methodInfoGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/GetUser',
    request,
    metadata || {},
    this.methodInfoGetUser);
  }

  methodInfoGetUserList = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.UserListResponse,
    (request: model_pb.QueryRequest) => {
      return request.serializeBinary();
    },
    model_pb.UserListResponse.deserializeBinary
  );

  getUserList(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.UserListResponse>;

  getUserList(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.UserListResponse) => void): grpcWeb.ClientReadableStream<model_pb.UserListResponse>;

  getUserList(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.UserListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/GetUserList',
        request,
        metadata || {},
        this.methodInfoGetUserList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/GetUserList',
    request,
    metadata || {},
    this.methodInfoGetUserList);
  }

  methodInfoCreateUser = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  createUser(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  createUser(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  createUser(
    request: model_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/CreateUser',
        request,
        metadata || {},
        this.methodInfoCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/CreateUser',
    request,
    metadata || {},
    this.methodInfoCreateUser);
  }

  methodInfoUpdateUser = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.UserResponse,
    (request: model_pb.UserRequest) => {
      return request.serializeBinary();
    },
    model_pb.UserResponse.deserializeBinary
  );

  updateUser(
    request: model_pb.UserRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.UserResponse>;

  updateUser(
    request: model_pb.UserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.UserResponse) => void): grpcWeb.ClientReadableStream<model_pb.UserResponse>;

  updateUser(
    request: model_pb.UserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/UpdateUser',
        request,
        metadata || {},
        this.methodInfoUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/UpdateUser',
    request,
    metadata || {},
    this.methodInfoUpdateUser);
  }

  methodInfoDeleteUser = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  deleteUser(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  deleteUser(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  deleteUser(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/DeleteUser',
        request,
        metadata || {},
        this.methodInfoDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/DeleteUser',
    request,
    metadata || {},
    this.methodInfoDeleteUser);
  }

  methodInfoAcceptTermsAndConditions = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  acceptTermsAndConditions(
    request: model_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  acceptTermsAndConditions(
    request: model_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  acceptTermsAndConditions(
    request: model_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/AcceptTermsAndConditions',
        request,
        metadata || {},
        this.methodInfoAcceptTermsAndConditions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/AcceptTermsAndConditions',
    request,
    metadata || {},
    this.methodInfoAcceptTermsAndConditions);
  }

  methodInfoUpdateDeviceID = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.DeviceIdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  updateDeviceID(
    request: model_pb.DeviceIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  updateDeviceID(
    request: model_pb.DeviceIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  updateDeviceID(
    request: model_pb.DeviceIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/UpdateDeviceID',
        request,
        metadata || {},
        this.methodInfoUpdateDeviceID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/UpdateDeviceID',
    request,
    metadata || {},
    this.methodInfoUpdateDeviceID);
  }

  methodInfoGetPendingRegistrations = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.PendingRegistrationsResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.PendingRegistrationsResponse.deserializeBinary
  );

  getPendingRegistrations(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.PendingRegistrationsResponse>;

  getPendingRegistrations(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.PendingRegistrationsResponse) => void): grpcWeb.ClientReadableStream<model_pb.PendingRegistrationsResponse>;

  getPendingRegistrations(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.PendingRegistrationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/GetPendingRegistrations',
        request,
        metadata || {},
        this.methodInfoGetPendingRegistrations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/GetPendingRegistrations',
    request,
    metadata || {},
    this.methodInfoGetPendingRegistrations);
  }

  methodInfoDeletePendingRegistration = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  deletePendingRegistration(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  deletePendingRegistration(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  deletePendingRegistration(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/DeletePendingRegistration',
        request,
        metadata || {},
        this.methodInfoDeletePendingRegistration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/DeletePendingRegistration',
    request,
    metadata || {},
    this.methodInfoDeletePendingRegistration);
  }

  methodInfoShouldResync = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ResyncResponse,
    (request: model_pb.QueryRequest) => {
      return request.serializeBinary();
    },
    model_pb.ResyncResponse.deserializeBinary
  );

  shouldResync(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ResyncResponse>;

  shouldResync(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ResyncResponse) => void): grpcWeb.ClientReadableStream<model_pb.ResyncResponse>;

  shouldResync(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ResyncResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/ShouldResync',
        request,
        metadata || {},
        this.methodInfoShouldResync,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/ShouldResync',
    request,
    metadata || {},
    this.methodInfoShouldResync);
  }

  methodInfoErrorReport = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.ErrorReportRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  errorReport(
    request: model_pb.ErrorReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  errorReport(
    request: model_pb.ErrorReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  errorReport(
    request: model_pb.ErrorReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UserService/ErrorReport',
        request,
        metadata || {},
        this.methodInfoErrorReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UserService/ErrorReport',
    request,
    metadata || {},
    this.methodInfoErrorReport);
  }

}

export class GroupServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.CreateGroupRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  createGroup(
    request: model_pb.CreateGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  createGroup(
    request: model_pb.CreateGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  createGroup(
    request: model_pb.CreateGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.GroupService/CreateGroup',
        request,
        metadata || {},
        this.methodInfoCreateGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.GroupService/CreateGroup',
    request,
    metadata || {},
    this.methodInfoCreateGroup);
  }

  methodInfoDeleteGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  deleteGroup(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  deleteGroup(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  deleteGroup(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.GroupService/DeleteGroup',
        request,
        metadata || {},
        this.methodInfoDeleteGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.GroupService/DeleteGroup',
    request,
    metadata || {},
    this.methodInfoDeleteGroup);
  }

  methodInfoUpdateGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.GroupRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  updateGroup(
    request: model_pb.GroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  updateGroup(
    request: model_pb.GroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  updateGroup(
    request: model_pb.GroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.GroupService/UpdateGroup',
        request,
        metadata || {},
        this.methodInfoUpdateGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.GroupService/UpdateGroup',
    request,
    metadata || {},
    this.methodInfoUpdateGroup);
  }

  methodInfoGetAllAssociatedGroups = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.GroupListResponse,
    (request: model_pb.QueryRequest) => {
      return request.serializeBinary();
    },
    model_pb.GroupListResponse.deserializeBinary
  );

  getAllAssociatedGroups(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.GroupListResponse>;

  getAllAssociatedGroups(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.GroupListResponse) => void): grpcWeb.ClientReadableStream<model_pb.GroupListResponse>;

  getAllAssociatedGroups(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.GroupListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.GroupService/GetAllAssociatedGroups',
        request,
        metadata || {},
        this.methodInfoGetAllAssociatedGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.GroupService/GetAllAssociatedGroups',
    request,
    metadata || {},
    this.methodInfoGetAllAssociatedGroups);
  }

}

export class SiteServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateSite = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.SiteResponse,
    (request: model_pb.SiteRequest) => {
      return request.serializeBinary();
    },
    model_pb.SiteResponse.deserializeBinary
  );

  createSite(
    request: model_pb.SiteRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.SiteResponse>;

  createSite(
    request: model_pb.SiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.SiteResponse) => void): grpcWeb.ClientReadableStream<model_pb.SiteResponse>;

  createSite(
    request: model_pb.SiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.SiteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/CreateSite',
        request,
        metadata || {},
        this.methodInfoCreateSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/CreateSite',
    request,
    metadata || {},
    this.methodInfoCreateSite);
  }

  methodInfoDeleteSite = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  deleteSite(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  deleteSite(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  deleteSite(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/DeleteSite',
        request,
        metadata || {},
        this.methodInfoDeleteSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/DeleteSite',
    request,
    metadata || {},
    this.methodInfoDeleteSite);
  }

  methodInfoUpdateSite = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.SiteRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  updateSite(
    request: model_pb.SiteRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  updateSite(
    request: model_pb.SiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  updateSite(
    request: model_pb.SiteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/UpdateSite',
        request,
        metadata || {},
        this.methodInfoUpdateSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/UpdateSite',
    request,
    metadata || {},
    this.methodInfoUpdateSite);
  }

  methodInfoChangeSiteAccessPeriods = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.SiteAccessChangeRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  changeSiteAccessPeriods(
    request: model_pb.SiteAccessChangeRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  changeSiteAccessPeriods(
    request: model_pb.SiteAccessChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  changeSiteAccessPeriods(
    request: model_pb.SiteAccessChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/ChangeSiteAccessPeriods',
        request,
        metadata || {},
        this.methodInfoChangeSiteAccessPeriods,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/ChangeSiteAccessPeriods',
    request,
    metadata || {},
    this.methodInfoChangeSiteAccessPeriods);
  }

  methodInfoGetSite = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.SiteResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.SiteResponse.deserializeBinary
  );

  getSite(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.SiteResponse>;

  getSite(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.SiteResponse) => void): grpcWeb.ClientReadableStream<model_pb.SiteResponse>;

  getSite(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.SiteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/GetSite',
        request,
        metadata || {},
        this.methodInfoGetSite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/GetSite',
    request,
    metadata || {},
    this.methodInfoGetSite);
  }

  methodInfoGetSiteList = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.SiteListResponse,
    (request: model_pb.QueryRequest) => {
      return request.serializeBinary();
    },
    model_pb.SiteListResponse.deserializeBinary
  );

  getSiteList(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.SiteListResponse>;

  getSiteList(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.SiteListResponse) => void): grpcWeb.ClientReadableStream<model_pb.SiteListResponse>;

  getSiteList(
    request: model_pb.QueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.SiteListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/GetSiteList',
        request,
        metadata || {},
        this.methodInfoGetSiteList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/GetSiteList',
    request,
    metadata || {},
    this.methodInfoGetSiteList);
  }

  methodInfoMissingSiteReport = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.MissingSiteReportRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  missingSiteReport(
    request: model_pb.MissingSiteReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  missingSiteReport(
    request: model_pb.MissingSiteReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  missingSiteReport(
    request: model_pb.MissingSiteReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/MissingSiteReport',
        request,
        metadata || {},
        this.methodInfoMissingSiteReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/MissingSiteReport',
    request,
    metadata || {},
    this.methodInfoMissingSiteReport);
  }

  methodInfoLogSiteActivity = new grpcWeb.AbstractClientBase.MethodInfo(
    model_pb.ErrorResponse,
    (request: model_pb.IdRequest) => {
      return request.serializeBinary();
    },
    model_pb.ErrorResponse.deserializeBinary
  );

  logSiteActivity(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null): Promise<model_pb.ErrorResponse>;

  logSiteActivity(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void): grpcWeb.ClientReadableStream<model_pb.ErrorResponse>;

  logSiteActivity(
    request: model_pb.IdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: model_pb.ErrorResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SiteService/LogSiteActivity',
        request,
        metadata || {},
        this.methodInfoLogSiteActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SiteService/LogSiteActivity',
    request,
    metadata || {},
    this.methodInfoLogSiteActivity);
  }

}

