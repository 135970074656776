import config from '../config';
import { AuthServiceClient, UserServiceClient } from '../proto/ModelServiceClientPb';
import { CreateUserRequest, EmptyRequest, Group, LoginRequest, User } from '../proto/model_pb';
import jwt from 'jsonwebtoken';
import { AuthResponse } from '../_types/user.types';
import UtilService from './util.service';

// Reverse mappings for protobuf enums
// @TODO: Rely on enums from protobuf file
const groupTypes = [
    { value: 0, label: 'Hauler' },
    { value: 1, label: 'Operator' },
    { value: 2, label: 'Service' },
    { value: 3, label: 'Directions' },
    { value: 4, label: 'Comply' }
]

const AuthService = {

    getUser(): User | null {
        let storedUser = localStorage.getItem('user');

        return storedUser ? JSON.parse(storedUser) : null;
    },

    getUserId(): number | null {
        let storedUserId = localStorage.getItem('userId');

        return storedUserId ? JSON.parse(storedUserId) : null;
    },

    getUserAdmin(): boolean | null {
        let storedUserAdmin = localStorage.getItem('userAdmin');

        return storedUserAdmin ? JSON.parse(storedUserAdmin) : null;
    },


    getToken(): string | null {
        let storedToken = localStorage.getItem('token');

        return storedToken ? JSON.parse(storedToken) : null;
    },

    getGroup(): Group | null {
        let storedGroup = localStorage.getItem('group');

        return storedGroup ? JSON.parse(storedGroup) : null;
    },

    getGroupType(): Group | null {
        let storedGroupType = localStorage.getItem('groupType');

        return storedGroupType ? JSON.parse(storedGroupType) : null;
    },

    getGroupId(): number | null {
        let storedGroupId = localStorage.getItem('groupId');

        return storedGroupId ? JSON.parse(storedGroupId) : null;
    },

    getGroupFeeRate(): number | null {
        let storedGroupFee = localStorage.getItem('groupFeeRate');

        return storedGroupFee ? JSON.parse(storedGroupFee) : null;
    },

    parseGroupType(group: Group): string | null {
        const groupType = groupTypes.find(type => {
            return type.value === group.getGrouptype();
        });

        return groupType ? groupType.label : null;
    },

    isUserAuthenticated(): boolean {
        const user = this.getUser();
        const token = this.getToken();
        const group = this.getGroup();
        const groupType = this.getGroupType();

        if (user && token && group && groupType) {
            if (!this.isJwtValid(token) && !window.location.pathname.includes('/r/')) {
                localStorage.setItem('redirectUrl', window.location.href);
                this.logout();
                return false;
            }

            return true;
        }

        if (!window.location.pathname.includes('/r/')) {
            localStorage.setItem('redirectUrl', window.location.pathname);
        }
        this.logout();
        return false;
    },

    isJwtValid(token: string): boolean {
        const decodedToken: any = jwt.decode(token);

        if (decodedToken) {
            if (Date.now() >= decodedToken.exp * 1000) {
                return false;
            }
        }

        return true;
    },

    login(email: string, password: string): Promise<any> {
        const authClient = new AuthServiceClient(config.apiUrl);
        const message = new LoginRequest();

        message.setEmail(email);
        message.setPassword(password);

        return new Promise((resolve, reject) => {
            authClient.login(
                message,
                {},
                (err, resp) => {
                    if (err) {
                        reject(err);
                    }

                    if (resp && resp.getError()) {
                        reject(resp.getError()?.getMessage());
                    }

                    if (resp && resp.getToken()) {
                        const user = resp.getUser();
                        const userId = user?.getId() ?? 0;
                        const userAdmin = user?.getAdmin() ?? false;
                        const token = resp.getToken();
                        const group = resp.getUsergroup();
                        const groupType = this.parseGroupType(group!);
                        const groupId = group?.getId();
                        let groupFeeRate;

                        const feeRate = group?.getBondwayfeerate();
                        if (feeRate) {
                            groupFeeRate = UtilService.convertPbMoneyToBigNumber(feeRate).toString();
                        }

                        if (user && token && group && groupType) {
                            const authResponse: AuthResponse = {
                                acceptedTerms: user.getAcceptedterms(),
                                user,
                                userId,
                                userAdmin,
                                token,
                                group,
                                groupType,
                                groupId,
                                groupFeeRate,
                            }

                            resolve(authResponse);
                        }

                        reject('Login failed: Error when processing user data. Please contact a system administrator.')
                    }
                }
            )

        })

    },

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('userId');
        localStorage.removeItem('userAdmin');
        localStorage.removeItem('token');
        localStorage.removeItem('group');
        localStorage.removeItem('groupType');
        localStorage.removeItem('groupId');
        localStorage.removeItem('groupFeeRate');
    },

    saveAuthToLocalStorage(authResponse: AuthResponse) {
        localStorage.setItem('user', JSON.stringify(authResponse.user));
        localStorage.setItem('userId', JSON.stringify(authResponse.userId));
        localStorage.setItem('userAdmin', JSON.stringify(authResponse.userAdmin));
        localStorage.setItem('token', JSON.stringify(authResponse.token));
        localStorage.setItem('group', JSON.stringify(authResponse.group));
        localStorage.setItem('groupId', JSON.stringify(authResponse.groupId));
        localStorage.setItem('groupType', JSON.stringify(authResponse.groupType));
        localStorage.setItem('groupFeeRate', JSON.stringify(authResponse.groupFeeRate));
    },

    acceptTerms(token: string) {
        const userClient = new UserServiceClient(config.apiUrl);
        const emptyRequest = new EmptyRequest();
        emptyRequest.setToken(token);

        return new Promise((resolve, reject) => {
            userClient.acceptTermsAndConditions(emptyRequest, {}, (err, resp) => {
                if (err) {
                    reject(err);
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    register(token: string, name: string, password: string) {
        const userClient = new AuthServiceClient(config.apiUrl);

        const createUserRequest = new CreateUserRequest();
        createUserRequest.setToken(token);
        createUserRequest.setName(name);
        createUserRequest.setPassword(password);

        return new Promise((resolve, reject) => {
            userClient.completeUserRegistration(createUserRequest, {}, (err, resp) => {
                if (err) {
                    reject(err);
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        })
    }
}

export default AuthService;