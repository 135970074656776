import { AuthResponse, UserTypes } from '../_types/user.types';
import { alertActions } from './alert.actions';
import AuthService from '../services/auth.service';

export const userActions = {
    login,
    logout,
    displayTerms,
    acceptTerms
};

function login(email: string, password: string) {
    return (dispatch: any) => {
        dispatch(request());

        AuthService.login(email, password)
            .then(
                authResponse => {
                    if (authResponse.acceptedTerms) {
                        AuthService.saveAuthToLocalStorage(authResponse);
                        dispatch(success(authResponse));
                    } else {
                        dispatch(displayTerms(authResponse));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: UserTypes.LOGIN_REQUEST } }
}

function acceptTerms(authResponse: AuthResponse) {
    return (dispatch: any) => {
        AuthService.acceptTerms(authResponse.token)
            .then(
                resp => {
                    authResponse.acceptedTerms = true;
                    AuthService.saveAuthToLocalStorage(authResponse);
                    dispatch(success(authResponse))
                },
                err => {
                    dispatch(alertActions.error(err.toString()));
                }
            )
    }
}

function displayTerms(payload: AuthResponse) { return { type: UserTypes.DISPLAY_TERMS, payload } }
function success(payload: AuthResponse) { return { type: UserTypes.LOGIN_SUCCESS, payload } }
function failure(error: any) { return { type: UserTypes.LOGIN_FAILURE, error } }

function logout() {
    AuthService.logout()
    return { type: UserTypes.LOGOUT }
}