import { Group, User } from '../proto/model_pb';

export enum GroupType {
    HAULER = 'Hauler',
    OPERATOR = 'Operator',
    SERVICE = 'Service'
}

export const UserTypes = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    DISPLAY_TERMS: 'USERS_DISPLAY_TERMS',

    LOGOUT: 'USERS_LOGOUT'
}

interface LoginRequestAction {
    type: typeof UserTypes.LOGIN_REQUEST,
    payload: null
}

interface LoginSuccessAction {
    type: typeof UserTypes.LOGIN_SUCCESS,

    // @TODO: Payload should be of type AuthResponse (problem with union types)
    payload: any
}

interface LoginFailureAction {
    type: typeof UserTypes.LOGIN_FAILURE,
    payload: string
}

export type UserActionTypes = LoginRequestAction | LoginSuccessAction | LoginFailureAction;

export type AuthResponse = {
    acceptedTerms: boolean,
    user: User,
    userId: number,
    userAdmin: boolean,
    token: string,
    group: Group,
    groupType: string,
    groupId: number | undefined,
    groupFeeRate: string | undefined
}