import { AlertTypes, AlertActionTypes } from '../_types/alert.types';

export function alert(state = {}, action: AlertActionTypes) {
    switch(action.type) {
        case AlertTypes.SUCCESS:
            return {
                type: AlertTypes.SUCCESS,
                message: action.message
            };
        case AlertTypes.ERROR:
            return {
                type: AlertTypes.ERROR,
                message: action.message
            }
        case AlertTypes.CLEAR:
            return {};
        default: 
            return state;
    }
}