import config from '../config';
import { HaulingListingServiceClient, JobServiceClient, SiteServiceClient, UserServiceClient, GroupServiceClient } from '../proto/ModelServiceClientPb';
import { AcceptBidRequest, AssignDriverRequest, Bid, BidRequest, HaulingListing, HaulListingRequest, IdRequest, ProvisionAttachmentRequest, QueryRequest, UserListResponse } from '../proto/model_pb';
import { grpcInterceptor } from './interceptor.api';

export type ListingRequest = {
    token: string,
    limit: number,
    offset: number,
    searchText?: string,
    sortParam?: QueryRequest.SortParameter
    sortOrder?: QueryRequest.Ordering
}

const ListingsService = {

    getListings: (request: ListingRequest): Promise<any> => {
        const listingsClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const queryRequest = new QueryRequest();
        queryRequest.setToken(request.token);
        queryRequest.setLimit(request.limit);
        queryRequest.setOffset(request.offset);
        queryRequest.setInflate(true);

        if (request.searchText) {
            queryRequest.setLike(request.searchText)
        }

        if ((request.sortParam || request.sortParam === 0) && (request.sortOrder || request.sortOrder === 0)) {
            queryRequest.setSortby(request.sortParam);
            queryRequest.setOrdering(request.sortOrder);
        }

        return new Promise((resolve, reject) => {
            listingsClient.getHaulingListings(queryRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        });
    },

    getListing: (token: string, listingId: number): Promise<any> => {
        const listingsClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const idRequest = new IdRequest();

        idRequest.setToken(token);
        idRequest.setId(listingId);
        idRequest.setInflate(true);

        return new Promise((resolve, reject) => {
            listingsClient.getHaulingListing(idRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            });
        })
    },

    createListing: (token: string, listing: HaulingListing): Promise<any> => {
        const listingsClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const haulListingRequest = new HaulListingRequest();
        haulListingRequest.setToken(token);
        haulListingRequest.setListing(listing);

        return new Promise((resolve, reject) => {
            listingsClient.createHaulingListing(haulListingRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            });
        })
    },

    updateListing: (token: string, listing: HaulingListing): Promise<any> => {
        const listingsClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const haulListingRequest = new HaulListingRequest();
        haulListingRequest.setToken(token);
        haulListingRequest.setListing(listing);

        return new Promise((resolve, reject) => {
            listingsClient.updateHaulingListing(haulListingRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            });
        })
    }, 

    cancelListing: (token: string, listingId: number): Promise<any> => {
        const listingsClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});
        
        const idRequest = new IdRequest();
        idRequest.setToken(token);
        idRequest.setId(listingId);

        return new Promise((resolve, reject) => {
            listingsClient.cancelHaulingListing(idRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            });
        })
    },

    provisionAttachmentForUpload: (token: string, listingId: number, fileType: string): Promise<any> => {
        const listingsClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const provisionAttachmentRequest = new ProvisionAttachmentRequest();
        provisionAttachmentRequest.setToken(token);
        provisionAttachmentRequest.setId(listingId);
        provisionAttachmentRequest.setFiletype(fileType);

        return new Promise((resolve, reject) => {
            listingsClient.provisionUploadForAttachment(provisionAttachmentRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    getSiteList: (token: string): Promise<any> => {
        const siteClient = new SiteServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const queryRequest = new QueryRequest();
        queryRequest.setToken(token);

        return new Promise((resolve, reject) => {
            siteClient.getSiteList(queryRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    getBids: (token: string, listingId: number): Promise<any> => {
        const listingClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const idRequest = new IdRequest();
        idRequest.setId(listingId);
        idRequest.setToken(token);

        return new Promise((resolve, reject) => {
            listingClient.getBidsForListing(idRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    acceptBid: (token: string, listingId: number, bidId: number, quantity: number): Promise<any> => {
        const listingClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const acceptedBid = new AcceptBidRequest.BidAcceptance();

        acceptedBid.setBidid(bidId);
        acceptedBid.setIssuedquantity(quantity);

        const acceptBidRequest = new AcceptBidRequest();

        acceptBidRequest.setToken(token);
        acceptBidRequest.setListingid(listingId);
        acceptBidRequest.setAcceptedbidsList([acceptedBid]);

        return new Promise((resolve, reject) => {
            listingClient.acceptBid(acceptBidRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        })


    },

    submitBid: (token: string, bid: Bid): Promise<any> => {
        const listingClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const bidRequest = new BidRequest();

        bidRequest.setToken(token);
        bidRequest.setBid(bid);

        return new Promise((resolve, reject) => {
            listingClient.bidOnListing(bidRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },    
    
    updateBid: (token: string, bid: Bid): Promise<any> => {
        const listingClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const bidRequest = new BidRequest();

        bidRequest.setToken(token);
        bidRequest.setBid(bid);

        return new Promise((resolve, reject) => {
            listingClient.updateBid(bidRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    withdrawBid: (token: string, id: number): Promise<any> => {
        const listingClient = new HaulingListingServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const idRequest = new IdRequest();

        idRequest.setToken(token);
        idRequest.setId(id);

        return new Promise((resolve, reject) => {
            listingClient.withdrawBid(idRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    getDrivers: (token: string, id: number): Promise<UserListResponse> => {
        const userClient = new UserServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const idRequest = new IdRequest();
        idRequest.setToken(token);
        idRequest.setId(id);

        return new Promise((resolve, reject) => {
            userClient.getDriversForGroup(idRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    cancelJob: (token: string, jobId: number): Promise<any> => {
        const jobClient = new JobServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const request = new IdRequest();
        request.setToken(token);
        request.setId(jobId);

        return new Promise((resolve, reject) => {
            jobClient.cancelJob(request, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },
    
    assignDriver: (token: string, driverId: number, jobId: number): Promise<any> => {
        const jobClient = new JobServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const request = new AssignDriverRequest();
        request.setToken(token);
        request.setDriveruserid(driverId);
        request.setJobid(jobId);

        return new Promise((resolve, reject) => {
            jobClient.assignDriver(request, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        })
    },

    getAssociatedGroups: (token: string): Promise<any> => {
        const groupClient = new GroupServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const request = new QueryRequest();
        request.setToken(token);

        return new Promise((resolve, reject) => {
            groupClient.getAllAssociatedGroups(request, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }
                
                else if (resp) {
                    resolve(resp);
                }
            })
        });
    }
}

export default ListingsService;