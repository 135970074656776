import { Button, Checkbox, MenuItem, TextField } from '@material-ui/core';
import React, { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { NavBar } from '../../components/navbar/Navbar';
import { ListingsRoute } from '../../_types/nav.types';
import ArrowBack from '@material-ui/icons/ArrowBack';
import EditListingNav from './EditListingNav';
import { useHistory, useParams } from 'react-router-dom';
import ListingsService from '../../services/listings.service';
import { alertActions } from '../../_actions/alert.actions';
import { GroupType } from '../../_types/user.types';
import { Group, GroupListResponse, HaulingListing, ScheduledJobAction, Site, SiteListResponse, RouteResponse, S3Asset, ProvisionedURLResponse, HaulingListingResponse } from '../../proto/model_pb';
import ReactMapboxGl, { Layer, Marker, Popup, Source } from 'react-mapbox-gl';
import config from '../../config';
import { Close, Delete } from '@material-ui/icons';
import google_protobuf_timestamp_pb, { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb.js';
import mapboxgl from "mapbox-gl";
import _ from 'underscore';
import RoutingService, { DirectionsRequest } from '../../services/routing.service';
import { Autocomplete } from '@material-ui/lab';


// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type Props = {
    token: string,
    groupType: GroupType,
    displayErrorAlert: any
    displaySuccessAlert: any,
    history: any
}

type ScheduledAction = {
    id?: number,
    order?: number,
    siteAction: ScheduledJobAction.ActionType | '',
    site: Site | null,
    dateAndTime: string,
    notes?: string,
    deleted?: boolean
}

type MapMarker = {
    order: number
    siteName: string,
    siteActionName: string,
    latitude: number,
    longitude: number
}

enum FormField {
    NAME = 'Name',
    QUANTITY = 'Quantity',
    WEIGHT = 'Weight',
    NOTES = 'Notes',
    TYPE = 'Vehicle Type',
    TRAILER = 'Trailer Type',
    LENGTH = 'Trailer Length',
    PERMIT = 'Permit Party',
    EXPIRATION = 'Listing Expiration',
}

type ListingForm = {
    [FormField.NAME]: string,
    [FormField.QUANTITY]: number | '',
    [FormField.WEIGHT]: number | '',
    [FormField.NOTES]: string,
    [FormField.TYPE]: HaulingListing.Vehicle | '',
    [FormField.TRAILER]: HaulingListing.TowLoad | '',
    [FormField.LENGTH]: number | '',
    [FormField.PERMIT]: HaulingListing.PermitParty | '',
    [FormField.EXPIRATION]: string,
}

// Reverse mappings for protobuf enums
// @TODO: Rely on enums from protobuf file
const actionTypes = [
    { value: 0, label: 'Pick Up' },
    { value: 1, label: 'Drop Off' }
]

const trailerTypes = [
    { value: 0, label: "None" },
    { value: 1, label: "Flatbed" },
    { value: 2, label: "Gooseneck" },
    { value: 3, label: "Low Boy" },
    { value: 4, label: "Double Drop" },
    { value: 5, label: "Box Trailer" },
    { value: 6, label: "Low Boy 14 Ft" },
    { value: 7, label: "Hauler Discretion" },
]

const vehicleTypes = [
    { value: 0, label: "Truck" },
    { value: 1, label: "Tractor Trailer" },
    { value: 2, label: "Winch Truck" }
]

const permitTypes = [
    { value: 0, label: "None" },
    { value: 1, label: "Hauler" },
    { value: 2, label: "Shipper" },
]

const Map = ReactMapboxGl({ accessToken: config.mapboxKey });


//@TODO: Move all non ui logic into service
const EditListing = (props: Props) => {
    let listing: MutableRefObject<HaulingListing> = useRef(props.history?.location?.state?.listing);

    const history = useHistory();

    const [schedule, _setSchedule] = useState<ScheduledAction[]>([
        {
            siteAction: '',
            site: null,
            dateAndTime: '',
        }
    ]);

    const [deletedScheduledActions, _setDeletedScheduledActions] = useState<ScheduledAction[]>([]);

    const [activeMarker, setActiveMarker] = useState<MapMarker | undefined>(undefined);
    const [mapMarkers, _setMapMarkers] = useState<MapMarker[]>([]);
    const [route, _setRoute] = useState<RouteResponse>(new RouteResponse());
    const [sites, _setSites] = useState<Site[]>([]);
    const [siteInputs, setSiteInputs] = useState<string[]>([]);
    const [mapBounds, _setMapBounds] = useState<[[number, number], [number, number]]>([[-79.984490, 39.906080], [-79.964490, 39.886080]]);
    const [shouldNeverExpire, setShouldNeverExpire] = useState(false);
    const [attachments, setAttachments] = useState<{ fileUrl: string, file?: File, fileType?: string }[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);


    const [listingFormFields, setListingFormFields] = useState<ListingForm>({
        [FormField.NAME]: '',
        [FormField.QUANTITY]: '',
        [FormField.WEIGHT]: '',
        [FormField.NOTES]: '',
        [FormField.TYPE]: '',
        [FormField.TRAILER]: '',
        [FormField.LENGTH]: '',
        [FormField.PERMIT]: '',
        [FormField.EXPIRATION]: '',
    });

    let [errors, setErrors] = useState<any>({})

    const urlParams: { id: string } = useParams();

    useEffect(() => {
        if (!window.location.pathname.includes('create')) {
            if (!isNaN(+urlParams.id)) {
                if (!listing.current) {
                    getListing(+urlParams.id);
                }
            } else {
                history.push('/listings');
            }
        }
    }, [])

    useEffect(() => {
        if (!listing.current || !listing.current.getListingexpirationtime) {
            if (!window.location.pathname.includes('create')) {
                getListing(+urlParams.id);
            }
        } else {
            let expirationTime: Timestamp | undefined = listing.current.getListingexpirationtime();
            let expiration: string;

            if (!expirationTime || !expirationTime.getSeconds()) {
                expiration = ''
                setShouldNeverExpire(true);
            } else {
                expiration = new Date(expirationTime.toDate().getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);
            }

            setListingFormFields({
                [FormField.NAME]: listing.current.getItem(),
                [FormField.QUANTITY]: listing.current.getMaxlistingquanity(),
                [FormField.WEIGHT]: listing.current.getWeight(),
                [FormField.NOTES]: listing.current.getNote(),
                [FormField.TYPE]: listing.current.getVehicle(),
                [FormField.TRAILER]: listing.current.getTowload(),
                [FormField.LENGTH]: listing.current.getTowlength(),
                [FormField.PERMIT]: listing.current.getPermitter(),
                [FormField.EXPIRATION]: expiration
            });

            const attachments: { fileUrl: string, file?: File, fileType?: string }[] = [];

            listing.current.getAttachmentsList().forEach((attachment: S3Asset) => {
                attachments.push({
                    fileUrl: attachment.getPresignedurl(),
                    file: undefined,
                    fileType: undefined
                });
            })

            setAttachments(attachments);
        }

        ListingsService.getSiteList(props.token)
            .then(
                (response: SiteListResponse) => {
                    const sites = response.getSiteList().sort((site1, site2) => {
                        if (site1.getGroupid() < site2.getGroupid()) {
                            return -1;
                        } else if (site1.getGroupid() > site2.getGroupid()) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })

                    const siteInputs = sites.map(site => {
                        return site.getName();
                    })

                    setSiteInputs(siteInputs);
                    _setSites(sites);
                },
                error => {
                    props.displayErrorAlert(error.toString());
                }
            )

        ListingsService.getAssociatedGroups(props.token)
            .then(
                (response: GroupListResponse) => {
                    setGroups(response.getGroupsList());
                },
                error => {
                    props.displayErrorAlert(error.toString());
                }
            )

    }, [listing.current]);

    useEffect(() => {
        if (listing.current && listing.current.getScheduledjobactionsList) {
            const jobSchedule: ScheduledAction[] = [];

            listing.current.getScheduledjobactionsList().forEach((scheduledAction, index) => {
                let scheduledTime: Timestamp | '' | undefined = scheduledAction.getScheduledactiontime();

                if (!scheduledTime || !scheduledTime.getSeconds()) {
                    scheduledTime = ''
                }

                let site: Site | undefined | null = sites.find((site) => {
                    return site.getId() === scheduledAction.getSiteid()
                });
                if (!site || !site.getId()) {
                    site = null;
                }

                let actionTimestamp: Timestamp | undefined = scheduledAction.getScheduledactiontime();
                let actionTimeString: string;

                if (!actionTimestamp || !actionTimestamp.getSeconds()) {
                    actionTimeString = ''
                } else {
                    actionTimeString = new Date(actionTimestamp.toDate().getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);
                }

                const jobAction: ScheduledAction = {
                    id: scheduledAction.getId(),
                    order: scheduledAction.getOrder(),
                    siteAction: scheduledAction.getActiontype(),
                    notes: scheduledAction.getNote(),
                    site: site,
                    dateAndTime: actionTimeString
                }

                jobSchedule.push(jobAction);
            })

            jobSchedule.forEach((jobAction: ScheduledAction) => {
                if (jobAction.site && (jobAction.siteAction || jobAction.siteAction === 0)) {
                    updateMapMarkers(jobSchedule);
                }
            })
            _setSchedule(jobSchedule);
            updateMapRoute(jobSchedule)
        }
    }, [sites])

    useEffect(() => {
        if (shouldNeverExpire) {
            setListingFormFields({
                ...listingFormFields,
                [FormField.EXPIRATION]: ''
            })

            validateField(FormField.EXPIRATION, '');
        }
    }, [shouldNeverExpire])

    const updateForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;

        if (!value) {
            setListingFormFields({
                ...listingFormFields,
                [name]: ''
            })
        } else {
            switch (name) {
                case FormField.EXPIRATION:
                    let inputDate = new Date(value);
                    const date = new Date(inputDate.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);
                    setListingFormFields({
                        ...listingFormFields,
                        [name]: date
                    })
                    break;
                case FormField.QUANTITY:
                case FormField.WEIGHT:
                case FormField.LENGTH:
                    setListingFormFields({
                        ...listingFormFields,
                        [name]: +value
                    })
                    break;
                case FormField.TYPE:
                    const vehicleType = vehicleTypes.find(type => {
                        return type.label === value;
                    })

                    if (vehicleType) {
                        setListingFormFields({
                            ...listingFormFields,
                            [name]: vehicleType.value
                        })
                    }
                    break;
                case FormField.TRAILER:
                    const trailerType = trailerTypes.find(type => {
                        return type.label === value;
                    })
                    if (trailerType) {
                        setListingFormFields({
                            ...listingFormFields,
                            [name]: trailerType.value
                        })
                    }
                    break;
                case FormField.PERMIT:
                    const permitType = permitTypes.find(type => {
                        return type.label === value;
                    })
                    if (permitType) {
                        setListingFormFields({
                            ...listingFormFields,
                            [name]: permitType.value
                        })
                    }
                    break;
                default:
                    setListingFormFields({
                        ...listingFormFields,
                        [name]: value
                    })

            }
        }

        validateField(name, value);
    }

    const validateField = (name: string, value: any) => {
        const errorMessages = {
            required: name + ' is required',
            positive: name + ' must be a greater than 0'
        }

        switch (name) {
            case FormField.NAME:
                if (!value) {
                    errors[FormField.NAME] = errorMessages.required
                } else {
                    delete errors[FormField.NAME];
                }
                break;
            case FormField.QUANTITY:
                if (!value) {
                    errors[FormField.QUANTITY] = errorMessages.required
                } else if (+value < 1) {
                    errors[FormField.QUANTITY] = errorMessages.positive
                } else {
                    delete errors[FormField.QUANTITY];
                }
                break;
            case FormField.WEIGHT:
                if (!value) {
                    errors[FormField.WEIGHT] = errorMessages.required
                } else if (+value < 1) {
                    errors[FormField.WEIGHT] = errorMessages.positive
                } else {
                    delete errors[FormField.WEIGHT];
                }
                break;
            case FormField.TYPE:
                if (value || value === 0) {
                    delete errors[FormField.TYPE];
                } else {
                    errors[FormField.TYPE] = errorMessages.required
                }
                break;
            case FormField.TRAILER:
                if (value || value === 0) {
                    delete errors[FormField.TRAILER];
                } else {
                    errors[FormField.TRAILER] = errorMessages.required
                }
                break;
            case FormField.LENGTH:
                if (!value && value !== 0) {
                    errors[FormField.LENGTH] = errorMessages.required
                } else if (+value < 0) {
                    errors[FormField.LENGTH] = errorMessages.positive
                } else {
                    delete errors[FormField.LENGTH];
                }
                break;
            case FormField.PERMIT:
                if (value || value === 0) {
                    delete errors[FormField.PERMIT];
                } else {
                    errors[FormField.PERMIT] = errorMessages.required
                }
                break;
            case FormField.EXPIRATION:
                if (!value && shouldNeverExpire === false) {
                    errors[FormField.EXPIRATION] = errorMessages.required
                } else {
                    delete errors[FormField.EXPIRATION];
                }
                break;
        }

        setErrors(errors);
    }

    const getLabel = (value: number | '', valueList: Array<{ value: number, label: string }>): string => {
        if (value === '') return value;

        const listItem = valueList.find(type => {
            return value === type.value
        });

        if (!listItem) return '';

        return listItem.label;
    }

    const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;

        if (!fileList) return;

        const attachmentsCopy = [...attachments];

        Array.from(fileList).forEach(file => {
            attachmentsCopy.push({
                fileUrl: URL.createObjectURL(file),
                file: file,
                fileType: file.type
            });
        });

        setAttachments(attachmentsCopy);
    }

    const deleteFile = (index: number) => {
        const attachmentsCopy = [...attachments];

        attachmentsCopy.splice(index, 1);

        setAttachments(attachmentsCopy)
    }

    const validateForm = () => {
        for (const [key, value] of Object.entries(listingFormFields)) {
            validateField(key, value);

            // Forces rerender to display fields
            setListingFormFields({
                ...listingFormFields,
                [key]: value
            })
        }
    }

    const cancelListing = () => {
        if (window.confirm('Are you sure you want to cancel this listing?')) {
            ListingsService.cancelListing(props.token, listing.current.getId())
                .then(() => {
                    props.displaySuccessAlert('Listing successfully cancelled');

                    const path = '/listings/'
                    history.push(path);
                }, () => {
                    props.displaySuccessAlert('Unable to cancel listing. Please contact an administrator.');
                })
        };
    }

    const getListing = (id: number) => {
        ListingsService.getListing(props.token, id)
            .then((response: HaulingListingResponse) => {
                const haulingListing = response.getListing();

                if (haulingListing) {
                    listing.current = haulingListing;
                } else {
                    props.displayErrorAlert('Encountered error when loading listing');
                    history.push('/listings');
                }
            }, (err) => {
                props.displayErrorAlert('Encountered error when loading listing');
                history.push('/listings');
            })
    }

    const submitListing = () => {
        let error = false;

        validateForm();

        if (Object.keys(errors).length > 0) {
            return;
        }

        const haulListing = new HaulingListing();

        haulListing.setItem(listingFormFields[FormField.NAME]);
        haulListing.setMaxlistingquanity(listingFormFields[FormField.QUANTITY] as number);
        haulListing.setWeight(listingFormFields[FormField.WEIGHT] as number);
        haulListing.setNote(listingFormFields[FormField.NOTES]);
        haulListing.setVehicle(listingFormFields[FormField.TYPE] as HaulingListing.Vehicle);
        haulListing.setTowload(listingFormFields[FormField.TRAILER] as HaulingListing.TowLoad);
        haulListing.setTowlength(listingFormFields[FormField.LENGTH] as number);
        haulListing.setPermitter(listingFormFields[FormField.PERMIT] as HaulingListing.PermitParty);

        let expirationTime: Date | string | undefined;
        let pbTimestamp: Timestamp = new google_protobuf_timestamp_pb.Timestamp();
        if (!listingFormFields[FormField.EXPIRATION] || listingFormFields[FormField.EXPIRATION] === '' || shouldNeverExpire) {
            expirationTime = undefined;
        } else {
            expirationTime = new Date(listingFormFields[FormField.EXPIRATION]);
            pbTimestamp.fromDate(expirationTime)
        }
        haulListing.setListingexpirationtime(pbTimestamp);

        const jobSchedule: ScheduledJobAction[] = [];

        [...schedule, ...deletedScheduledActions].forEach((scheduledAction, index) => {
            if ((!scheduledAction.siteAction && scheduledAction.siteAction !== 0) || !scheduledAction.dateAndTime || !scheduledAction.site) {
                props.displayErrorAlert('Error parsing job schedule. Please ensure all fields are filled in.');
                error = true;
            }

            const action = new ScheduledJobAction();
            const site = scheduledAction.site as Site;
            let pbTimestamp: Timestamp = new google_protobuf_timestamp_pb.Timestamp();
            let expirationTime: Date | string | undefined = new Date(scheduledAction.dateAndTime);
            pbTimestamp.fromDate(expirationTime)

            if (scheduledAction.id) {
                action.setId(scheduledAction.id);

                if (scheduledAction.deleted) {
                    let pbTimestamp: Timestamp = new google_protobuf_timestamp_pb.Timestamp();
                    pbTimestamp.fromDate(new Date());
                    action.setDeleted(pbTimestamp);
                }
            }

            action.setSiteid(site.getId())
            action.setOrder(index);
            action.setScheduledactiontime(pbTimestamp);
            action.setActiontype(scheduledAction.siteAction as ScheduledJobAction.ActionType);

            if (scheduledAction.notes) {
                action.setNote(scheduledAction.notes);
            }

            jobSchedule.push(action);
        })

        if (error) return;

        haulListing.setScheduledjobactionsList(jobSchedule);

        var metersTraveled = 0
        route.getLegsList().forEach((leg, index) => {
            metersTraveled += leg.getDistance()
        })

        if (metersTraveled == 0 && listing.current.getEstimatedtraveldistance() != 0) {
            haulListing.setEstimatedtraveldistance(listing.current.getEstimatedtraveldistance())
        } else {
            haulListing.setEstimatedtraveldistance(Math.trunc(metersTraveled))
        }

        if (listing && listing.current.getId()) {
            haulListing.setId(listing.current.getId());
            ListingsService.updateListing(props.token, haulListing)
                .then((resp) => {
                    if (!attachments || attachments.length === 0) {
                        props.displaySuccessAlert('Listing successfully updated');
                        const path = '/listings';
                        history.push(path);
                    }

                    let uploaded = 0;

                    attachments.forEach(attachment => {
                        if (attachment.file && attachment.fileType) {
                            ListingsService.provisionAttachmentForUpload(props.token, listing.current.getId(), attachment.fileType!)
                                .then(
                                    async (resp: ProvisionedURLResponse) => {
                                        uploaded += 1;
                                        await fetch(resp.getUrl(), {
                                            method: 'PUT',
                                            headers: {
                                                'Content-Type': attachment.fileType!
                                            },
                                            body: attachment.file
                                        })

                                        if (uploaded === attachments.length) {
                                            props.displaySuccessAlert('Listing successfully updated');
                                            const path = '/listings';
                                            history.push(path);
                                        }
                                    },
                                    err => {
                                        uploaded += 1;

                                        if (uploaded === attachments.length) {
                                            props.displaySuccessAlert('Listing successfully updated');
                                            const path = '/listings';
                                            history.push(path);
                                        }
                                    })
                        }
                    })
                }, (err) => {
                    props.displayErrorAlert(err.toString());
                });
        } else {
            ListingsService.createListing(props.token, haulListing)
                .then((resp) => {
                    if (!attachments || attachments.length === 0) {
                        props.displaySuccessAlert('Listing successfully updated');
                        const path = '/listings';
                        history.push(path);
                    }

                    let uploaded = 0;

                    attachments.forEach(attachment => {
                        if (attachment.file && attachment.fileType) {
                            ListingsService.provisionAttachmentForUpload(props.token, listing.current.getId(), attachment.fileType!)
                                .then(
                                    async (resp: ProvisionedURLResponse) => {
                                        uploaded += 1;
                                        await fetch(resp.getUrl(), {
                                            method: 'PUT',
                                            headers: {
                                                'Content-Type': attachment.fileType!
                                            },
                                            body: attachment.file
                                        })

                                        if (uploaded === attachments.length) {
                                            props.displaySuccessAlert('Listing successfully created');
                                            const path = '/listings';
                                            history.push(path);
                                        }
                                    },
                                    err => {
                                        uploaded += 1;

                                        if (uploaded === attachments.length) {
                                            props.displaySuccessAlert('Listing successfully created');
                                            const path = '/listings';
                                            history.push(path);
                                        }
                                    })
                        }
                    })
                }, (err) => {
                    props.displayErrorAlert(err.toString());
                });
        }
    }

    const autosetSchedule = () => {
        route.getLegsList().forEach((leg, index) => {
            if (leg.getDuration() != undefined) {
                const dur = leg.getDuration()!.toDate()

                const lhs = schedule[index]
                const rhs = schedule[index + 1]
                updateSchedule(rhs, 'datetime', new Date((new Date(lhs.dateAndTime)).getTime() + dur.getTime()).toISOString(), 0)
            }
        })
    }

    const reverseSchedule = () => {
        const reversedSchedule = [...schedule.reverse()];

        updateMapMarkers(reversedSchedule);
        updateMapRoute(reversedSchedule);
        _setSchedule(reversedSchedule);
    }

    const updateSchedule = (scheduledItem: ScheduledAction, field: string, event: any, index: number) => {
        switch (field) {
            case 'action':
                const action = actionTypes.find(actionType => {
                    if (typeof event !== 'string') {
                        return actionType.label === event.target.value;
                    } else {
                        return actionType.label === event;
                    }
                })!.value;
                scheduledItem.siteAction = action;

                if (scheduledItem.site && (scheduledItem.siteAction || scheduledItem.siteAction === 0)) {
                    updateMapMarkers(schedule);
                }

                _setSchedule([...schedule]);
                break;
            case 'site':
                scheduledItem.site = event;

                if (scheduledItem.site && (scheduledItem.siteAction || scheduledItem.siteAction === 0)) {
                    updateMapMarkers(schedule);
                }

                _setSchedule([...schedule]);
                updateMapRoute(schedule)
                break;
            case 'datetime':
                var inputDate = new Date();
                if (typeof event !== 'string') {
                    inputDate = new Date(event.target.value);
                } else {
                    inputDate = new Date(event);
                }
                const date = new Date(inputDate.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);

                scheduledItem.dateAndTime = date;
                _setSchedule([...schedule]);
                break;

            case 'notes':
                var notes = "";
                if (typeof event !== 'string') {
                    notes = event.target.value;
                } else {
                    notes = event;
                }
                scheduledItem.notes = notes;

                _setSchedule([...schedule]);
                break;
        }
    }

    const updateMapRoute = (scheduledActions: ScheduledAction[]) => {
        const jobSchedule: ScheduledJobAction[] = [];

        if (scheduledActions.length < 2) {
            _setRoute(new RouteResponse());
            return;
        }

        scheduledActions.forEach((scheduledAction, index) => {
            if (scheduledAction.site) {
                const action = new ScheduledJobAction();
                const site = scheduledAction.site as Site;

                action.setSite(site)
                action.setOrder(index);

                if (scheduledAction.notes) {
                    action.setNote(scheduledAction.notes);
                }

                jobSchedule.push(action);
            }
        })

        if (jobSchedule.length > 1) {
            const request: DirectionsRequest = {
                token: props.token,
                jobActions: jobSchedule
            }

            RoutingService.getEstimate(request).then(
                (response: RouteResponse) => {
                    _setRoute(response);
                },
                (error: any) => {
                    props.displayErrorAlert(error.toString());
                }
            )
        }
    }

    const updateMapMarkers = (schedule: ScheduledAction[]) => {
        const mapMarkers = schedule

            .filter((scheduledAction) => {
                return !scheduledAction.deleted && scheduledAction.site && (scheduledAction.siteAction || scheduledAction.siteAction === 0);
            })

            .map((scheduledAction, index) => {
                const actionTypeName = actionTypes.find(type => {
                    return type.value === scheduledAction.siteAction;
                })?.label;

                const site = scheduledAction.site as Site;

                return {
                    order: index + 1,
                    siteName: site.getName(),
                    siteActionName: actionTypeName!,
                    latitude: site.getLatitude(),
                    longitude: site.getLongitude()
                }
            })

        const bounds = getMapBounds(mapMarkers);

        if (bounds && mapMarkers) {
            _setMapMarkers([...mapMarkers]);
            _setMapBounds(bounds);
        }
    }

    const getMapBounds = (coordinates: MapMarker[]): [[number, number], [number, number]] | undefined => {
        if (coordinates.length < 1) {
            return;
        }

        if (coordinates.length === 1) {
            return [
                [coordinates[0].longitude + .01, coordinates[0].latitude + .01],
                [coordinates[0].longitude - .01, coordinates[0].latitude - .01]
            ];
        }

        if (coordinates.length > 1) {
            const lats: number[] = [];
            const lngs: number[] = [];
            coordinates.forEach(coord => {
                lats.push(coord.latitude);
                lngs.push(coord.longitude);
            })

            const maxLat = Math.min.apply(null, lats);
            const maxLng = Math.min.apply(null, lngs);
            const minLat = Math.max.apply(null, lats);
            const minLng = Math.max.apply(null, lngs);

            return [
                [minLng, minLat],
                [maxLng, maxLat]
            ]
        }
    }

    // const getScheduleForDisplay = () => {
    //     return schedule.filter(scheduledItem => {
    //         return scheduledItem.deleted !== true;
    //     })
    // }

    const addToSchedule = () => {
        schedule.push({
            siteAction: '',
            site: null,
            dateAndTime: ''
        })

        _setSchedule([...schedule]);
    }

    const deleteFromSchedule = (index: number) => {
        const removed = schedule.splice(index, 1);

        if (removed[0].id) {
            removed[0].deleted = true;
            _setDeletedScheduledActions([...deletedScheduledActions, removed[0]]);
        }

        schedule.forEach((jobAction: ScheduledAction) => {
            if (jobAction.site && (jobAction.siteAction || jobAction.siteAction === 0)) {
                updateMapMarkers(schedule);
            }
        })

        _setSchedule([...schedule]);
        updateMapRoute(schedule)
    }

    const goToListings = () => {
        const path = '/listings/'

        history.push(path);
    }

    const getLayerColor = (marker: MapMarker): string => {
        switch (marker.siteActionName) {
            case 'Pick Up':
                return '#165BC1'
            case 'Drop Off':
                return '#00AE46'
            default:
                return '#000000'
        }
    }

    const getGroupName = (groupId: number): string => {
        const group = groups.find(group => group.getId() === groupId);

        return group ? group.getName() : '';
    }

    const updateSiteInputs = (input: string, index: number) => {
        const inputs = siteInputs;

        inputs[index] = input;
        setSiteInputs([...inputs]);
    }

    const isNewListing = () => {
        return !!(!listing.current || !listing.current.getId || !listing.current.getId());
    }

    const getScheduledCount = (schedule: ScheduledAction[]) => {
        return schedule.filter(scheduledItem => {
            return scheduledItem.deleted !== true
        }).length
    }

    return <Fragment>
        <NavBar activeRoute={ListingsRoute} />

        <main className="PageContainer EditListingsContainer">

            <header className="EditListingsHeader">

                <div className="EditListingsHeaderReturn" onClick={goToListings}>
                    <ArrowBack />
                    View Listings
                </div>

                {(props.groupType !== GroupType.HAULER) &&
                    <div className="EditListingsHeaderButton">
                        {!isNewListing() &&
                            <div className="EditListingCancelButton" onClick={cancelListing}>
                                <Button variant="contained" color="secondary">
                                    Cancel Listing
                                </Button>
                            </div>
                        }

                        <div>
                            <Button variant="contained" color="primary" onClick={submitListing}>
                                {isNewListing() && <span>Post Listing</span>}
                                {!isNewListing() && <span>Update Listing</span>}
                            </Button>
                        </div>
                    </div>}
            </header>

            <EditListingNav navState='listing' listing={listing.current} groupType={props.groupType} />

            <section className="EditListingFormContainer">
                <div className="EditListingForm">
                    <div>
                        <section className="EditListingSection">
                            <header className="EditListingSectionHeader">Listing Details</header>

                            <div className="EditListingFormInput">
                                <TextField
                                    name={FormField.NAME}
                                    error={!!errors[FormField.NAME]}
                                    value={listingFormFields[FormField.NAME]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    onChange={updateForm}
                                    label="Listing Name"
                                    variant="outlined"
                                    size="small"
                                    helperText={errors[FormField.NAME] ? errors[FormField.NAME] : ''}
                                    fullWidth />
                            </div>

                            <div className="EditListingFormInput DualInput">
                                <TextField
                                    name={FormField.QUANTITY}
                                    error={!!errors[FormField.QUANTITY]}
                                    value={listingFormFields[FormField.QUANTITY]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    onChange={updateForm}
                                    type="number"
                                    InputProps={{ inputProps: { min: "0" } }}
                                    label="Quantity"
                                    size="small"
                                    helperText={errors[FormField.QUANTITY] ? errors[FormField.QUANTITY] : ''}
                                    variant="outlined" />
                                <TextField
                                    name={FormField.WEIGHT}
                                    value={listingFormFields[FormField.WEIGHT]}
                                    error={!!errors[FormField.WEIGHT]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    onChange={updateForm}
                                    type="number"
                                    InputProps={{ inputProps: { min: "0" } }}
                                    label="Weight (lbs)"
                                    size="small"
                                    helperText={errors[FormField.WEIGHT] ? errors[FormField.WEIGHT] : ''}
                                    variant="outlined" />
                            </div>

                            <div className="EditListingFormInput">
                                <TextField
                                    name={FormField.NOTES}
                                    value={listingFormFields[FormField.NOTES]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    onChange={updateForm}
                                    label="Notes"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    fullWidth />
                            </div>

                        </section>

                        <section className="EditListingSection">
                            <header className="EditListingSectionHeader">Vehicle and Tow</header>

                            <div className="EditListingFormInput DualInput">
                                <TextField
                                    name={FormField.TYPE}
                                    value={getLabel(listingFormFields[FormField.TYPE], vehicleTypes)}
                                    error={!!errors[FormField.TYPE]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    onChange={updateForm}
                                    helperText={errors[FormField.TYPE] ? errors[FormField.TYPE] : ''}
                                    select
                                    label="Required Vehicle Type"
                                    variant="outlined"
                                    size="small">
                                    {vehicleTypes.map((vehicleType) => (
                                        <MenuItem key={vehicleType.value} value={vehicleType.label}>
                                            {vehicleType.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    name={FormField.TRAILER}
                                    value={getLabel(listingFormFields[FormField.TRAILER], trailerTypes)}
                                    error={!!errors[FormField.TRAILER]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    onChange={updateForm}
                                    helperText={errors[FormField.TRAILER] ? errors[FormField.TRAILER] : ''}
                                    select
                                    label="Trailer Type"
                                    variant="outlined"
                                    size="small">
                                    {trailerTypes.map((vehicleType) => (
                                        <MenuItem key={vehicleType.value} value={vehicleType.label}>
                                            {vehicleType.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="EditListingFormInput DualInput">
                                <div></div>
                                <TextField
                                    name={FormField.LENGTH}
                                    value={listingFormFields[FormField.LENGTH]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    error={!!errors[FormField.LENGTH]}
                                    onChange={updateForm}
                                    helperText={errors[FormField.LENGTH] ? errors[FormField.LENGTH] : ''}
                                    type="number"
                                    label="Trailer Length (ft)"
                                    size="small"
                                    variant="outlined" />
                            </div>
                        </section>

                        <section className="EditListingSection">
                            <header className="EditListingSectionHeader">Permit Information</header>

                            <div className="EditListingFormInput">
                                <TextField
                                    name={FormField.PERMIT}
                                    value={getLabel(listingFormFields[FormField.PERMIT], permitTypes)}
                                    error={!!errors[FormField.PERMIT]}
                                    disabled={props.groupType === GroupType.HAULER}
                                    onChange={updateForm}
                                    helperText={errors[FormField.PERMIT] ? errors[FormField.PERMIT] : ''}
                                    select
                                    label="Responsible Permit Party"
                                    variant="outlined"
                                    size="small"
                                    fullWidth>
                                    {permitTypes.map((permit) => (
                                        <MenuItem key={permit.value} value={permit.label}>
                                            {permit.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </section>

                        <section className="EditListingSection">
                            <div className="EditListingAddAttachmentContainer">
                                <header className="EditListingSectionSubheader">Attachments</header>
                                {(props.groupType !== GroupType.HAULER) && <span className="EditListingAddAttachmentPrompt">
                                    <Button component="label">
                                        <input type="file" multiple hidden onChange={handleFile} accept="image/png, image/jpeg, image/png"></input>
                                        + Add Attachment
                                    </Button>
                                </span>
                                }
                            </div>

                            {attachments.map((attachment, index) => (
                                <div className="EditListingAttachmentImageContainer">
                                    <img className="EditListingAttachmentImage" alt="" src={attachment.fileUrl} />
                                    {(props.groupType !== GroupType.HAULER) && <Delete
                                        onClick={() => deleteFile(index)}
                                        className="EditListingAttachmentImageDelete" style={{
                                            color: '#C2CFE0',
                                            cursor: 'pointer'
                                        }}></Delete>
                                    }
                                </div>
                            ))}
                        </section>
                    </div>
                    <div className="EditListingFormDivider">
                        <div className="FormDividerInner" />
                    </div>
                    <div>
                        <section className="EditListingSection">
                            <header className="EditListingSectionHeader">Listing Expiration</header>

                            <div className="EditListingFormInput">
                                <TextField
                                    name={FormField.EXPIRATION}
                                    error={!!errors[FormField.EXPIRATION]}
                                    value={listingFormFields[FormField.EXPIRATION]}
                                    disabled={props.groupType === GroupType.HAULER || shouldNeverExpire}
                                    onChange={updateForm}
                                    helperText={errors[FormField.EXPIRATION] ? errors[FormField.EXPIRATION] : ''}
                                    id="date"
                                    label="Expiration Date and Time"
                                    type="datetime-local"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />

                                <div className="EditListingCheckboxContainer">
                                    <Checkbox
                                        checked={shouldNeverExpire}
                                        onChange={() => setShouldNeverExpire(!shouldNeverExpire)}
                                        color="primary"
                                    />

                                    Listing Never Expires
                                </div>

                            </div>
                        </section>
                        <section className="EditListingSection">
                            <header className="EditListingSectionHeader">Scheduling</header>

                            {schedule.map((scheduledItem, index) => (
                                <div className="EditListingSchedulingContainer">

                                    {(index > 0) &&
                                        <div className="EditListingScheduleDivider">
                                            <div className="FormDividerHorizontal" />
                                        </div>
                                    }


                                    <div className="EditListingScheduleCancel">
                                        <span className="EditListingScheduleCancelText" onClick={() => { deleteFromSchedule(index) }}>Delete</span>
                                    </div>

                                    <div className="EditListingFormInput DualInput SchedulingInput">
                                        <TextField
                                            select
                                            label="Site Action"
                                            disabled={props.groupType === GroupType.HAULER}
                                            variant="outlined"
                                            onChange={(action: React.ChangeEvent<HTMLInputElement>) => { updateSchedule(scheduledItem, 'action', action, index) }}
                                            value={getLabel(schedule[index].siteAction, actionTypes)}
                                            size="small"
                                            fullWidth
                                        >
                                            {actionTypes.map((siteAction, actionIndex) => (
                                                <MenuItem key={actionIndex} value={siteAction.label}>
                                                    {siteAction.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <Autocomplete
                                            disabled={props.groupType === GroupType.HAULER}
                                            value={schedule[index].site}
                                            onChange={(event, val) => {
                                                updateSchedule(scheduledItem, 'site', val, index)
                                            }}
                                            inputValue={siteInputs[index]}
                                            onInputChange={(event, val) => {
                                                updateSiteInputs(val, index);
                                            }}
                                            options={sites}
                                            groupBy={(site) => getGroupName(site.getGroupid())}
                                            getOptionLabel={(site) => site ? site.getName() : ''}
                                            renderInput={(params) => <TextField {...params} label="Site" variant="outlined" size="small" />}
                                        />

                                        {/* <TextField
                                            select
                                            label="Site"
                                            variant="outlined"
                                            disabled={props.groupType === GroupType.HAULER}
                                            onChange={(action: React.ChangeEvent<HTMLInputElement>) => { updateSchedule(scheduledItem, 'site', action, index) }}
                                            value={getSiteName(schedule[index].site)}
                                            size="small"
                                            fullWidth >

                                                <Fragment>
                                                    {sites.map((site) => (
                                                           <MenuItem
                                                                key={site.getId()}
                                                                value={site.getName()}
                                                            >
                                                                {site.getName()}
                                                            </MenuItem>
                                                    ))}
                                                </Fragment>
                                        </TextField> */}
                                    </div>

                                    <div className="EditListingFormInput SchedulingInput">
                                        <TextField
                                            id="date"
                                            label="Date and Time"
                                            type="datetime-local"
                                            variant="outlined"
                                            disabled={props.groupType === GroupType.HAULER}
                                            onChange={(action: React.ChangeEvent<HTMLInputElement>) => { updateSchedule(scheduledItem, 'datetime', action, index) }}
                                            value={schedule[index].dateAndTime}
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="EditListingFormInput SchedulingInput">
                                        <TextField
                                            label="Notes"
                                            variant="outlined"
                                            disabled={props.groupType === GroupType.HAULER}
                                            onChange={(action: React.ChangeEvent<HTMLInputElement>) => { updateSchedule(scheduledItem, 'notes', action, index) }}
                                            value={schedule[index].notes}
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            ))}
                            {(props.groupType !== GroupType.HAULER) &&
                                <div className="EditListingScheduleActionContainer">
                                    {(getScheduledCount(schedule) > 0)
                                        ? <div className="EditListingScheduleActionButtons">
                                            <Button title="Click to automatically set action times based off of the provided route." variant="outlined" color="default" size="small" onClick={autosetSchedule}>Autoset Time</Button>
                                            <Button title="Click to reverse scheduled job actions" variant="outlined" color="default" size="small" onClick={reverseSchedule}>Reverse Schedule</Button>
                                        </div>
                                        : <div></div>
                                    }

                                    <div onClick={addToSchedule} className="EditListingActionTextContainer">+ Add Additional Site Action</div>
                                </div>}

                            <div className="EditListingMapContainer">
                                <Map
                                    // eslint-disable-next-line react/style-prop-object
                                    style="mapbox://styles/mapbox/streets-v9"
                                    containerStyle={{
                                        height: '350px',
                                        width: '100%',
                                        borderRadius: '5px',
                                    }}
                                    fitBounds={mapBounds}
                                    fitBoundsOptions={{ padding: 40 }}
                                >
                                    <Source id="routeSource" geoJsonSource={
                                        {
                                            "type": "geojson",
                                            "data": {
                                                "type": "FeatureCollection",
                                                "features": route.getLegsList().map(leg => {
                                                    return {
                                                        "type": "Feature",
                                                        "geometry": JSON.parse(leg.getGeojsonline())
                                                    }
                                                }
                                                ),
                                            }
                                        }
                                    } />
                                    <Layer type="line" id="routeLayer" sourceId="routeSource" paint={{ 'line-color': "#69a8f4", 'line-width': 4.5 }} />

                                    <Fragment>
                                        {mapMarkers.map((marker) => (
                                            <Marker
                                                onClick={() => setActiveMarker(marker)}
                                                coordinates={[marker.longitude, marker.latitude]}
                                                anchor="center"
                                            >
                                                <div
                                                    className="EditListingMapMarker"
                                                    style={{ backgroundColor: getLayerColor(marker) }}>
                                                    {marker.order}
                                                </div>
                                            </Marker>
                                        ))}

                                        {(activeMarker) &&
                                            <Popup
                                                className="EditListingMapPopup"
                                                offset={8}
                                                onClick={() => { setActiveMarker(undefined) }}
                                                coordinates={[
                                                    activeMarker.longitude,
                                                    activeMarker.latitude
                                                ]}>
                                                <Close />
                                                {activeMarker.siteName}
                                            </Popup>
                                        }
                                    </Fragment>
                                </Map>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </main>
    </Fragment >
}

function mapState(state: any) {
    const { authentication } = state;
    return {
        token: authentication.token,
        groupType: authentication.groupType
    };
}

const actionCreators = {
    displayErrorAlert: alertActions.error,
    displaySuccessAlert: alertActions.success
};

const connectedApp = connect(mapState, actionCreators)(EditListing);

export { connectedApp as EditListing };