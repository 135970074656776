import config from '../config';
import { RoutingServiceClient } from '../proto/ModelServiceClientPb';
import { ListingRoutingRequest, HaulingListing, ScheduledJobAction, DeadheadRoutingRequest, Bid } from '../proto/model_pb';

export type DirectionsRequest = {
    token: string,
    jobActions: ScheduledJobAction[],
}

const RoutingService = {

    getEstimate: (request: DirectionsRequest): Promise<any> => {
        const routingClient = new RoutingServiceClient(config.apiUrl);

        const listing = new HaulingListing();
        listing.setScheduledjobactionsList(request.jobActions);

        const listingRequest = new ListingRoutingRequest();
        listingRequest.setToken(request.token);
        listingRequest.setListing(listing);
        return new Promise((resolve, reject) => {
            routingClient.getListingDirections(listingRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        });
    },

    getDeadheadDirections: (token: string, bid: Bid, listing: HaulingListing): Promise<any> => {
        const routingClient = new RoutingServiceClient(config.apiUrl);

        const deadheadRoutingRequest = new DeadheadRoutingRequest();
        deadheadRoutingRequest.setToken(token);
        deadheadRoutingRequest.setBid(bid);
        deadheadRoutingRequest.setListing(listing);

        return new Promise((resolve, reject) => {
            routingClient.getDeadheadDirections(deadheadRoutingRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        })
    }
}

export default RoutingService;