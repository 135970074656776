import React from 'react';
import './Search.css';
import glass from '../../assets/magnifying_glass.svg';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {search: ''};

    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  handleChangeSearch(event) {
    this.setState({search: event.target.value.toLowerCase()});
    this.props.searchCallback(event.target.value.toLowerCase());
  }

  render() {
    return  <div className="SearchBox">
              <img src={glass} className="Search-magifying-glass" alt="glass" />
              <input type="text" className="Search-text-input" placeholder="Search Ticket Number" value={this.state.search} onChange={this.handleChangeSearch}/>
            </div>
  }
}

export default SearchBar