import { Button, MenuItem, Slider, TextField } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { ArrowBack, Delete } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NavBar } from '../../components/navbar/Navbar';
import { Bid, BillingSurcharge, DeadheadRouteResponse, Group, GroupListResponse, HaulingListing, HaulingListingResponse, Job, Money, ScheduledJobAction, Site, SiteListResponse } from '../../proto/model_pb';
import { alertActions } from '../../_actions/alert.actions';
import { ListingsRoute } from '../../_types/nav.types';
import { GroupType } from '../../_types/user.types';
import EditListingNav from './EditListingNav';
import BigNumber from "bignumber.js";
import ListingsService from '../../services/listings.service';
import { Autocomplete } from '@material-ui/lab';
import RoutingService from '../../services/routing.service';
import google_protobuf_timestamp_pb, { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb.js';


type Props = {
    token: string,
    groupType: GroupType,
    groupFeeRate: string,
    groupId: string,
    displaySuccessAlert: any,
    displayErrorAlert: any,
    history: any
}

type DisplayedBid = {
    id?: number,
    haulerName?: string,
    haulerImage?: string,
    billingType?: Bid.BillingType,
    hourlyRate?: string,
    flatRate?: string,
    onSiteCost?: string,
    deadheadToCost?: string,
    deadheadFromCost?: string,
    haulerFee?: string,
    subTotal?: string,
    fee?: string,
    total?: string,
    availableDrivers?: number
}

type DisplayedCharge = {
    amount?: string,
    name: string
}

const Bids = (props: Props) => {
    const history = useHistory();

    const haulerChargeTypes = [
        { value: 'flat', label: 'Flat' },
        { value: 'hourly', label: 'Hourly' }
    ]

    const [listing, setListing] = useState<HaulingListing>(props.history?.location?.state?.listing);

    const [remainingQuantity, setRemainingQuantity] = useState<number | undefined>(undefined);
    const [bids, setBids] = useState<DisplayedBid[]>([]);
    const [availableDrivers, setAvailableDrivers] = useState<number[]>([]);
    const [drivers, _setDrivers] = useState<number[]>([]);

    const [haulerBid, setHaulerBid] = useState<Bid>();
    const [haulerRate, _setHaulerRate] = useState<string | undefined>(undefined);
    const [haulerChargeType, _setHaulerChargeType] = useState<string>('hourly');
    const [haulerCharges, setHaulerCharges] = useState<DisplayedCharge[]>([{ name: '', amount: undefined }]);
    const [haulerSubtotal, setHaulerSubtotal] = useState<BigNumber | undefined>();
    const [haulerFee, setHaulerFee] = useState<BigNumber | undefined>();
    const [haulerTotal, setHaulerTotal] = useState<BigNumber | undefined>();
    const [haulerAssignedDrivers, setHaulerAssignedDrivers] = useState<number>(1);
    const [haulerOnSiteCost, setHaulerOnSiteCost] = useState<BigNumber | undefined>();
    const [haulerDeadheadSite, _setHaulerDeadheadSite] = useState<Site | null>(null);
    const [haulerDeadheadSiteInput, setHaulerDeadheadSiteInput] = useState<string>('');
    const [haulerDeadheadCost, setHaulerDeadheadCost] = useState<BigNumber | undefined>();
    const [haulerDeadheadDuration, setHaulerDeadheadDuration] = useState<number | undefined>();
    const [haulerDeadheadToJobDuration, setHaulerDeadheadToJobDuration] = useState<number | undefined>();
    const [haulerDeadheadFromJobDuration, setHaulerDeadheadFromJobDuration] = useState<number | undefined>();

    const [sites, _setSites] = useState<Site[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);

    const urlParams: { id: string } = useParams();

    useEffect(() => {
        if (!isNaN(+urlParams.id)) {
            getListing(+urlParams.id);
        } else {
            history.push('/listings');
        }
    }, [])

    useEffect(() => {
        if (!listing || !listing.getId) {
            return;
        } else {
            if (props.groupType === GroupType.HAULER) {
                const bids = listing.getBidsList();

                ListingsService.getSiteList(props.token)
                    .then(
                        (response: SiteListResponse) => {
                            const sites = response.getSiteList().sort((site1, site2) => {
                                if (site1.getGroupid() < site2.getGroupid()) {
                                    return -1;
                                } else if (site1.getGroupid() > site2.getGroupid()) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            })

                            _setSites(sites);
                        },
                        error => {
                            props.displayErrorAlert(error.toString());
                        }
                    )

                ListingsService.getAssociatedGroups(props.token)
                    .then(
                        (response: GroupListResponse) => {
                            setGroups(response.getGroupsList());
                        },
                        error => {
                            props.displayErrorAlert(error.toString());
                        }
                    )

                if (bids && bids[0]) {

                    const bid = bids[0];
                    setHaulerBid(bid);

                    if (bid.getDeadheadyard()) {
                        _setHaulerDeadheadSite(bid.getDeadheadyard()!);
                        setHaulerDeadheadSiteInput(bid.getDeadheadyard()!.getName())
                    }

                    let bnRate = new BigNumber(0);
                    let bnDeadheadCost;
                    let bnOnSiteCost;
                    let billingType = 'hourly';

                    if (bid.getBillingtype() === Bid.BillingType.FLAT_RATE) {
                        const flatRate = bid.getFlatrate();

                        if (flatRate) {
                            bnRate = convertPbMoneyToBigNumber(flatRate);

                            billingType = 'flat';
                            _setHaulerChargeType('flat');
                            setHaulerRate(bnRate.toString());
                        }

                    } else if (bid.getBillingtype() === Bid.BillingType.HOURLY) {
                        const hourlyRate = bid.getHourlyrate();

                        if (hourlyRate) {
                            bnRate = convertPbMoneyToBigNumber(hourlyRate);

                            billingType = 'hourly';
                            _setHaulerChargeType('hourly');
                            setHaulerRate(bnRate.toString());

                            const numberOfActions = listing.getScheduledjobactionsList().length;
                            bnOnSiteCost = bnRate.times(numberOfActions);

                            setHaulerOnSiteCost(bnOnSiteCost);

                            if (bid.getDeadheadyard() && bid.getDeadheadstartduration() && bid.getDeadheadendduration()) {
                                const deadheadDuration = msToHours(bid.getDeadheadstartduration()!.toDate().getTime() + bid.getDeadheadendduration()!.toDate().getTime());
                                setHaulerDeadheadDuration(deadheadDuration);
                                setHaulerDeadheadToJobDuration(bid.getDeadheadstartduration()!.toDate().getTime());
                                setHaulerDeadheadFromJobDuration(bid.getDeadheadendduration()!.toDate().getTime());
                                bnDeadheadCost = bnRate.times(deadheadDuration);
                                setHaulerDeadheadCost(bnDeadheadCost);
                            }
                        }
                    }

                    const charges = bid.getSurchargesList();

                    let displayedCharges: DisplayedCharge[] = [];

                    if (charges && charges.length && charges.length > 0) {

                        charges.forEach(charge => {
                            const displayedCharge = {
                                name: charge.getChargename(),
                                amount: convertPbMoneyToBigNumber(charge.getRate()!).toString()
                            }
                            displayedCharges.push(displayedCharge);
                        })

                        setHaulerCharges(displayedCharges);
                    }

                    let bnHaulerCharges: BigNumber[] = [];

                    displayedCharges.forEach(charge => {
                        if (charge.amount) {
                            bnHaulerCharges.push(new BigNumber(charge.amount));
                        }
                    })

                    const subTotal = getHaulerSubtotal(listing.getScheduledjobactionsList(), bnRate, bnHaulerCharges, billingType, bnDeadheadCost, bnOnSiteCost);
                    setHaulerSubtotal(subTotal);

                    setHaulerAssignedDrivers(bid.getServicequantity());

                } else {
                    setHaulerBid(new Bid());
                }
            }

            if (props.groupType !== GroupType.HAULER) {
                setRemainingQuantity(listing.getMaxlistingquanity() - listing.getAssignedjobsList().length);

                const bids = listing.getBidsList();
                const displayedBids: DisplayedBid[] = [];
                const assignedDrivers: number[] = [];
                const availableDrivers: number[] = [];

                bids.forEach((bid: Bid) => {
                    assignedDrivers.push(0);
                    const displayedBid: DisplayedBid = {};
                    const bidType = bid.getBillingtype();

                    const availableDriversForBid = getAvailableDrivers(bid.getGroupid(), listing.getAssignedjobsList(), bid.getServicequantity());
                    availableDrivers.push(availableDriversForBid);

                    displayedBid.id = bid.getId();
                    displayedBid.haulerName = bid.getInflatedgroup()?.getName();
                    displayedBid.haulerImage = bid.getInflatedgroup()?.getPresignedsvglogourl();
                    displayedBid.billingType = bidType;

                    if (bidType === Bid.BillingType.FLAT_RATE) {
                        const pbFlatRate = bid.getFlatrate();

                        let bnFlatRate, bnSubTotal;

                        if (pbFlatRate) {
                            bnFlatRate = convertPbMoneyToBigNumber(pbFlatRate);

                            const haulerFees = bid.getSurchargesList();

                            if (haulerFees && haulerFees.length > 0) {
                                let bnHaulerFee: BigNumber = new BigNumber(0);

                                haulerFees.forEach(fee => {
                                    if (fee.getRate()) {
                                        const bnFee = convertPbMoneyToBigNumber(fee.getRate()!);
                                        bnHaulerFee = bnFee.plus(bnHaulerFee);
                                    }
                                })

                                displayedBid.haulerFee = '$' + bnHaulerFee.toFormat(2);

                                bnSubTotal = bnFlatRate.plus(bnHaulerFee);
                            } else {
                                bnSubTotal = bnFlatRate;
                            }

                            displayedBid.flatRate = '$' + bnFlatRate.toFormat(2);
                            displayedBid.subTotal = '$' + bnSubTotal.toFormat(2);

                            const pbListerFee = bid.getListerfee();

                            let bnListerFee: BigNumber | 0 = 0;

                            if (pbListerFee) {
                                bnListerFee = convertPbMoneyToBigNumber(pbListerFee);

                                const bnFee = bnSubTotal.times(bnListerFee);
                                displayedBid.fee = '$' + bnFee.toFormat(2);

                                const bnTotal = bnSubTotal.plus(bnFee);
                                displayedBid.total = '$' + bnTotal.toFormat(2);
                            } else {
                                displayedBid.fee = '$0.00';
                                displayedBid.total = '$' + bnSubTotal.toFormat(2);
                            }

                        }
                    } else if (bidType === Bid.BillingType.HOURLY) {

                        const pbHourlyRate = bid.getHourlyrate();

                        let bnHourlyRate;
                        let bnOnSiteCost: BigNumber = new BigNumber(0);
                        let bnDeadheadToCost: BigNumber = new BigNumber(0);
                        let bnDeadheadFromCost: BigNumber = new BigNumber(0);

                        if (pbHourlyRate) {
                            bnHourlyRate = convertPbMoneyToBigNumber(pbHourlyRate);
                            displayedBid.hourlyRate = '$' + bnHourlyRate.toFormat(2);

                            const pbDeadheadStartDuration = bid.getDeadheadstartduration();
                            const pbDeadheadEndDuration = bid.getDeadheadendduration();

                            let deadheadStartHours = 0;
                            let deadheadEndHours = 0;

                            if (pbDeadheadStartDuration) {
                                deadheadStartHours = msToHours(pbDeadheadStartDuration.toDate().getTime());
                                bnDeadheadToCost = bnHourlyRate.times(deadheadStartHours);
                                displayedBid.deadheadToCost = '$' + bnDeadheadToCost.toFormat(2);
                            }

                            if (pbDeadheadEndDuration) {
                                deadheadEndHours = msToHours(pbDeadheadEndDuration.toDate().getTime());
                                bnDeadheadFromCost = bnHourlyRate.times(deadheadEndHours);
                                displayedBid.deadheadFromCost = '$' + bnDeadheadFromCost.toFormat(2);
                            }

                            const haulerFees = bid.getSurchargesList();

                            let bnHaulerFee: BigNumber = new BigNumber(0);

                            if (haulerFees && haulerFees.length > 0) {
                                haulerFees.forEach(fee => {
                                    if (fee.getRate()) {
                                        const bnFee = convertPbMoneyToBigNumber(fee.getRate()!);
                                        bnHaulerFee = bnFee.plus(bnHaulerFee);
                                    }
                                })

                                displayedBid.haulerFee = '$' + bnHaulerFee.toFormat(2);
                            }

                            const numberOfActions = listing.getScheduledjobactionsList().length;
                            bnOnSiteCost = bnHourlyRate.times(numberOfActions);
                            displayedBid.onSiteCost = '$' + bnOnSiteCost.toFormat(2);

                            const jobSchedule = listing.getScheduledjobactionsList();
                            const bnDeadheadCost = bnDeadheadToCost.plus(bnDeadheadFromCost);

                            let bnSubTotal;

                            if (jobSchedule.length > 1) {
                                const duration = getTotalDuration(listing.getScheduledjobactionsList());
                                const jobCost = bnHourlyRate.times(duration);

                                bnSubTotal = jobCost.plus(bnDeadheadCost).plus(bnOnSiteCost).plus(bnHaulerFee);
                            } else {
                                bnSubTotal = bnDeadheadCost.plus(bnOnSiteCost).plus(bnHaulerFee);
                            }

                            displayedBid.subTotal = '$' + bnSubTotal.toFormat(2);

                            const pbListerFee = listing.getCreatorgroup()?.getBondwayfeerate();

                            let bnListerFee: BigNumber | 0 = 0;

                            if (pbListerFee) {
                                bnListerFee = convertPbMoneyToBigNumber(pbListerFee);

                                const bnFee = bnSubTotal.times(bnListerFee);
                                displayedBid.fee = '$' + bnFee.toFormat(2);

                                const bnTotal = bnSubTotal.plus(bnFee);
                                displayedBid.total = '$' + bnTotal.toFormat(2);
                            } else {
                                displayedBid.fee = '$0.00';
                                displayedBid.total = '$' + bnSubTotal.toFormat(2);
                            }
                        }
                    }

                    displayedBids.push(displayedBid);
                })

                _setDrivers(assignedDrivers);
                setAvailableDrivers(availableDrivers);
                setBids(displayedBids);
            }
        }

    }, [listing])

    useEffect(() => {
        if (props.groupFeeRate && haulerSubtotal) {
            const bnFeeRate = new BigNumber(props.groupFeeRate);
            const feeTotal = haulerSubtotal.times(bnFeeRate);

            setHaulerFee(feeTotal);
            setHaulerTotal(haulerSubtotal.minus(feeTotal));
        }
    }, [haulerSubtotal])

    const getListing = (id: number) => {
        ListingsService.getListing(props.token, id)
            .then((response: HaulingListingResponse) => {
                const haulingListing = response.getListing();

                if (haulingListing) {
                    setListing(haulingListing);
                } else {
                    props.displayErrorAlert('Encountered error when loading listing');
                    history.push('/listings');
                }
            }, (err) => {
                props.displayErrorAlert('Encountered error when loading listing');
                history.push('/listings');
            })
    }

    const getGroupName = (groupId: number): string => {
        const group = groups.find(group => group.getId() === groupId);

        return group ? group.getName() : '';
    }

    const isBidAccepted = () => {
        const jobs = listing.getAssignedjobsList().filter(job => {
            return job.getHaulergroupid() === +props.groupId;
        })

        return jobs.length > 0;
    }

    const setHaulerChargeType = (type: string) => {
        let bnRate = haulerRate ? new BigNumber(haulerRate) : undefined;
        let bnHaulerCharges: BigNumber[] = [];
        let onSiteCost: BigNumber = new BigNumber(0);

        haulerCharges.forEach(charge => {
            if (charge.amount) {
                bnHaulerCharges.push(new BigNumber(charge.amount));
            }
        })

        if (type === 'flat') {
            setHaulerDeadheadSite(null);
            setHaulerDeadheadDuration(0);
            setHaulerDeadheadFromJobDuration(0);
            setHaulerDeadheadToJobDuration(0);
            setHaulerDeadheadCost(new BigNumber(0));
        } else if (type === 'hourly' && bnRate) {
            const numberOfActions = listing.getScheduledjobactionsList().length;
            const bnOnSiteCost = bnRate.times(numberOfActions);

            setHaulerOnSiteCost(bnOnSiteCost);
            onSiteCost = bnOnSiteCost;
        }

        const subTotal = getHaulerSubtotal(listing.getScheduledjobactionsList(), bnRate, bnHaulerCharges, type, new BigNumber(0), onSiteCost);
        setHaulerSubtotal(subTotal);
        _setHaulerChargeType(type);
    }

    const addHaulerCharge = () => {
        haulerCharges.push({
            name: '',
            amount: undefined
        });

        setHaulerCharges([...haulerCharges]);
    }

    const onChargeNameUpdated = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        haulerCharges[index].name = event.target.value;

        setHaulerCharges([...haulerCharges]);
    }

    const onChargeAmountUpdated = (amount: string, index: number) => {
        haulerCharges[index].amount = amount;

        let bnHaulerCharges: BigNumber[] = [];

        haulerCharges.forEach(charge => {
            if (charge.amount) {
                bnHaulerCharges.push(new BigNumber(charge.amount));
            }
        })

        let bnHaulerRate;

        if (haulerRate) {
            bnHaulerRate = new BigNumber(haulerRate);
        }

        const subTotal = getHaulerSubtotal(listing.getScheduledjobactionsList(), bnHaulerRate, bnHaulerCharges, haulerChargeType, haulerDeadheadCost, haulerOnSiteCost)

        setHaulerCharges([...haulerCharges]);
        setHaulerSubtotal(subTotal);
    }

    const setHaulerDeadheadSite = (site: Site | null) => {
        _setHaulerDeadheadSite(site);
        let bnRate = haulerRate ? new BigNumber(haulerRate) : undefined;
        let bnHaulerCharges: BigNumber[] = [];
        let deadheadCost = new BigNumber(0);

        haulerCharges.forEach(charge => {
            if (charge.amount) {
                bnHaulerCharges.push(new BigNumber(charge.amount));
            }
        })

        if (!site) {
            setHaulerDeadheadDuration(0);
            setHaulerDeadheadFromJobDuration(0);
            setHaulerDeadheadToJobDuration(0);
            setHaulerDeadheadCost(new BigNumber(0));

            const subTotal = getHaulerSubtotal(listing.getScheduledjobactionsList(), bnRate, bnHaulerCharges, haulerChargeType, deadheadCost);
            setHaulerSubtotal(subTotal);
            return;
        };

        if (haulerBid && haulerBid.getId() > 0) {
        } else {
            haulerBid?.setListingid(listing.getId())
            haulerBid?.setGroupid(+props.groupId);
        }
        haulerBid!.setDeadheadyard(site);

        RoutingService.getDeadheadDirections(props.token, haulerBid!, listing)
            .then(
                (resp: DeadheadRouteResponse) => {
                    const deadheadToLeg = resp.getDeadheadtojob();
                    const deadheadFromLeg = resp.getDeadheadfromjob();


                    if (!deadheadToLeg || !deadheadToLeg.getDuration() || !deadheadFromLeg || !deadheadFromLeg.getDuration()) return;

                    const totalDuration = msToHours(deadheadToLeg.getDuration()!.toDate().getTime() + deadheadFromLeg.getDuration()!.toDate().getTime());
                    setHaulerDeadheadDuration(totalDuration);
                    setHaulerDeadheadToJobDuration(deadheadToLeg.getDuration()!.toDate().getTime());
                    setHaulerDeadheadFromJobDuration(deadheadFromLeg.getDuration()!.toDate().getTime());

                    if (haulerChargeType === 'hourly' && haulerRate) {
                        deadheadCost = new BigNumber(haulerRate).times(totalDuration);
                        setHaulerDeadheadCost(deadheadCost);
                    }

                    const subTotal = getHaulerSubtotal(listing.getScheduledjobactionsList(), bnRate, bnHaulerCharges, haulerChargeType, deadheadCost, haulerOnSiteCost)
                    setHaulerSubtotal(subTotal);
                },
                err => {
                    console.error('Error obtaining deadhead duration');
                })
    }

    const setHaulerRate = (rate: string) => {
        let bnRate, bnDeadheadCost, bnOnSiteCost;

        if (rate && rate !== '') {
            bnRate = new BigNumber(rate);

            if (haulerDeadheadDuration) {
                bnDeadheadCost = bnRate.times(haulerDeadheadDuration);
                setHaulerDeadheadCost(bnDeadheadCost);
            }

            const numberOfActions = listing.getScheduledjobactionsList().length;
            bnOnSiteCost = bnRate.times(numberOfActions);

            setHaulerOnSiteCost(bnOnSiteCost);
        }

        let bnHaulerCharges: BigNumber[] = [];

        haulerCharges.forEach(charge => {
            if (charge.amount) {
                bnHaulerCharges.push(new BigNumber(charge.amount));
            }
        })

        const subTotal = getHaulerSubtotal(listing.getScheduledjobactionsList(), bnRate, bnHaulerCharges, haulerChargeType, bnDeadheadCost, bnOnSiteCost);
        _setHaulerRate(rate);
        setHaulerSubtotal(subTotal);
    }

    const getHaulerSubtotal = (jobActions: ScheduledJobAction[], rate: BigNumber | undefined, charges: BigNumber[], billingType: string, deadheadCost?: BigNumber, onSiteCost?: BigNumber): BigNumber => {
        let totalCost: BigNumber = new BigNumber(0);

        if (rate && (rate.s || rate.e || rate.c)) {
            if (billingType === 'hourly') {
                const duration = getTotalDuration(jobActions);
                totalCost = rate.times(duration);

                if (deadheadCost) {
                    totalCost = totalCost.plus(deadheadCost);
                }

                if (onSiteCost) {
                    totalCost = totalCost.plus(onSiteCost);
                }
            } else if (billingType === 'flat') {
                totalCost = totalCost.plus(rate);
            }
        }

        charges.forEach(charge => {
            totalCost = totalCost.plus(charge);
        })

        return totalCost;
    }

    const numberStringToPbMoney = (value: string): Money => {
        let units = ""
        let nanos = ""
        const split = value.toString().split('.')

        if (split.length > 0) {
            units = split[0]
        }

        if (split.length > 1) {
            nanos = split[1]
        }

        if (nanos.length > 3) {
            nanos = "".concat(nanos.charAt(0)).concat(nanos.charAt(1)).concat(nanos.charAt(2))
        }

        while (nanos.length < 9) {
            nanos = nanos.concat('0')
        }

        const money = new Money()
        money.setUnits(parseInt(units))
        money.setNanos(parseInt(nanos))
        money.setCurrencyCode("USD")

        return money;
    }

    const deleteCharge = (index: number) => {
        haulerCharges.splice(index, 1);

        let bnRate = haulerRate ? new BigNumber(haulerRate) : undefined;
        let bnHaulerCharges: BigNumber[] = [];

        haulerCharges.forEach(charge => {
            if (charge.amount) {
                bnHaulerCharges.push(new BigNumber(charge.amount));
            }
        })

        const subtotal = getHaulerSubtotal(listing.getScheduledjobactionsList(), bnRate, bnHaulerCharges, haulerChargeType, haulerDeadheadCost, haulerOnSiteCost);
        setHaulerCharges([...haulerCharges]);
        setHaulerSubtotal(subtotal);
    }

    const saveBid = () => {
        if (!haulerRate) {
            props.displayErrorAlert('Rate must be set');
            return;
        }

        if (haulerAssignedDrivers === 0) {
            props.displayErrorAlert('At least one driver must be assigned');
            return;
        }

        if (haulerChargeType === 'flat') {
            const pbFlatRate = numberStringToPbMoney(haulerRate);
            haulerBid?.setFlatrate(pbFlatRate);
            haulerBid?.setHourlyrate(numberStringToPbMoney('0'));
            haulerBid?.setBillingtype(Bid.BillingType.FLAT_RATE);
        } else if (haulerChargeType === 'hourly') {
            const pbHourlyRate = numberStringToPbMoney(haulerRate);
            haulerBid?.setHourlyrate(pbHourlyRate);
            haulerBid?.setFlatrate(numberStringToPbMoney('0'));
            haulerBid?.setBillingtype(Bid.BillingType.HOURLY);
        }

        const pbSurcharges: BillingSurcharge[] = [];

        haulerCharges.forEach(charge => {
            if (charge.name && charge.amount) {
                const surcharge = new BillingSurcharge();
                surcharge.setChargename(charge.name);
                surcharge.setRate(numberStringToPbMoney(charge.amount));

                pbSurcharges.push(surcharge);
            }
        })

        let pbToJob: Timestamp = new google_protobuf_timestamp_pb.Timestamp();
        let pbFromJob: Timestamp = new google_protobuf_timestamp_pb.Timestamp();

        pbToJob.fromDate(new Date(haulerDeadheadToJobDuration ?? 0))
        pbFromJob.fromDate(new Date(haulerDeadheadFromJobDuration ?? 0))

        haulerBid?.setListingid(listing.getId());
        haulerBid?.setDeadheadyard(haulerDeadheadSite ?? undefined);
        haulerBid?.setSurchargesList(pbSurcharges);
        haulerBid?.setServicequantity(haulerAssignedDrivers);
        haulerBid?.setDeadheadstartduration(pbToJob);
        haulerBid?.setDeadheadendduration(pbFromJob);
        if (haulerBid!.getId() > 0) {
            ListingsService.updateBid(props.token, haulerBid!)
                .then(() => {
                    props.displaySuccessAlert('Bid successfully updated');

                    ListingsService.getListing(props.token, listing.getId())
                        .then((listingResponse: HaulingListingResponse) => {
                            setListing(listingResponse.getListing()!);
                            setHaulerBid(listingResponse.getListing()?.getBidsList()[0]);
                        })

                })
                .catch(err => {
                    props.displayErrorAlert(err.toString());
                });
        } else {
            ListingsService.submitBid(props.token, haulerBid!)
                .then(() => {
                    props.displaySuccessAlert('Bid successfully submitted');

                    ListingsService.getListing(props.token, listing.getId())
                        .then((listingResponse: HaulingListingResponse) => {
                            setListing(listingResponse.getListing()!);
                            setHaulerBid(listingResponse.getListing()?.getBidsList()[0]);
                        })
                })
                .catch(err => {
                    props.displayErrorAlert(err.toString());
                });
        }

    }

    const withdrawBid = () => {
        if (haulerBid && haulerBid.getId() > 0) {
            ListingsService.withdrawBid(props.token, haulerBid.getId())
                .then(() => {
                    props.displaySuccessAlert('Bid successfully withdrawn');

                    const path = '/listings';
                    history.push(path, { listing })
                })
                .catch(err => {
                    props.displayErrorAlert(err.toString());
                });
        } else {
            props.displayErrorAlert('Could not locate bid in the system');
        }
    }

    const getAvailableDrivers = (groupId: number, scheduledJobs: Job[], serviceQuantity: number): number => {
        const filteredJobs = scheduledJobs.filter(job => (job.getHaulergroupid() === groupId));

        return serviceQuantity - filteredJobs.length;
    }

    const setDrivers = (index: number, event: any, value?: any) => {
        let updatedValue: number = 0;

        if (value) {
            updatedValue = value;
        } else {
            updatedValue = +event.target.value
        }

        const driversCopy = [...drivers];
        driversCopy[index] = updatedValue;

        _setDrivers(driversCopy);
    }

    const acceptBid = (index: number) => {
        ListingsService.acceptBid(props.token, listing.getId(), bids[index].id!, drivers[index])
            .then(() => {
                props.displaySuccessAlert(`${drivers[index]} drivers successfully assigned`);
                setRemainingQuantity(remainingQuantity! - drivers[index]);

                const driversCopy = [...availableDrivers];
                driversCopy[index] = availableDrivers[index] - drivers[index];

                setAvailableDrivers(driversCopy);
            })
            .catch(err => {
                props.displayErrorAlert(err.toString());
            });
    }

    const getTotalDuration = (scheduledActions: ScheduledJobAction[]): number => {
        scheduledActions.sort((a, b) => {
            const aScheduledActionTime = a.getScheduledactiontime()?.getSeconds();
            const bScheduledActionTime = b.getScheduledactiontime()?.getSeconds();

            let precedence = (aScheduledActionTime! > bScheduledActionTime!) ? 1 : -1;

            return precedence;
        })

        const firstActionTimestamp = scheduledActions[0].getScheduledactiontime()?.toDate().getTime()
        const lastActionTimestamp = scheduledActions[scheduledActions.length - 1].getScheduledactiontime()?.toDate().getTime();

        return msToHours(lastActionTimestamp! - firstActionTimestamp!);
    }

    const viewListings = () => {
        const path = '/listings';

        history.push(path, { listing })
    }

    const msToHours = (duration: number): number => {
        // Round to nearest second
        const minutes = Math.floor((duration / (1000 * 60)) % 60)
        const hours = Math.floor(duration / (1000 * 60 * 60))

        return hours + (minutes / 60);
    }

    const convertPbMoneyToBigNumber = (pbMoney: Money): BigNumber => {
        return new BigNumber(pbMoney.getUnits() + '.' + pad(Math.abs(pbMoney.getNanos()), 9))
    }

    const pad = (num: number, size: number) => {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    return <Fragment>
        <NavBar activeRoute={ListingsRoute} />

        <main className="PageContainer EditListingsContainer">
            <header className="EditListingsHeader">

                <div className="EditListingsHeaderReturn" onClick={viewListings}>
                    <ArrowBack />
                    View Listings
                </div>
            </header>

            {(props.groupType === GroupType.HAULER) && <div>
                <EditListingNav navState='bids' listing={listing} groupType={props.groupType} />

                <div className="BidsContainer BidsHaulerContainer">
                    {/* <div className="BidsHaulerSection">
                        <div className="BidsHaulerHeader">
                            Price Suggestion
                        </div>

                        <div className="BidsHaulerSectionBar Header">
                            <div>Base Charges</div>
                            <div>Breakdown</div>
                        </div>

                        <div className="BidsHaulerSectionBar Content">
                            <div>Hourly Cost</div>
                            <div></div>
                        </div>

                        <div className="BidsHaulerSectionBar Footer">
                            <div>Subtotal</div>
                            <div></div>
                        </div>
                    </div>

                    <div className="EditListingFormDivider">
                        <div className="FormDividerInner" />
                    </div> */}

                    <div className="BidsHaulerSection">
                        <div className="BidsHaulerHeader">
                            Place Bid
                        </div>

                        <div className="BidsHaulerSectionBar Header">
                            <div>Base Charges</div>
                            <div>Breakdown</div>
                        </div>

                        <div className="BidsHaulerSectionBar Input">
                            <TextField
                                select
                                value={haulerChargeType}
                                onChange={(event) => { setHaulerChargeType(event.target.value) }}
                                disabled={isBidAccepted()}
                                label="Charge Type"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    style: {
                                        width: '360px',
                                    }
                                }}
                            >
                                {haulerChargeTypes.map((chargeType) => (
                                    <MenuItem key={chargeType.value} value={chargeType.value}>
                                        {chargeType.label}
                                    </MenuItem>
                                ))}

                            </TextField>

                            <CurrencyTextField
                                className="BidsHaulerCurrencyInput"
                                value={haulerRate}
                                onChange={(event, value) => setHaulerRate(value)}
                                disabled={isBidAccepted()}
                                outputFormat="string"
                                variant="outlined" />
                        </div>

                        {(haulerChargeType === 'hourly') && <div className="BidsHaulerSectionBar Footer">
                            <div>Deadhead Cost*</div>
                            <div className="BidsHaulerSiteContainer">
                                <Autocomplete
                                    disabled={isBidAccepted()}
                                    value={haulerDeadheadSite}
                                    onChange={(event, val) => {
                                        setHaulerDeadheadSite(val)
                                    }}
                                    inputValue={haulerDeadheadSiteInput}
                                    onInputChange={(event, val) => {
                                        setHaulerDeadheadSiteInput(val);
                                    }}
                                    options={sites}
                                    groupBy={(site) => getGroupName(site.getGroupid())}
                                    getOptionLabel={(site) => site ? site.getName() : ''}
                                    renderInput={(params) => <TextField {...params} label="Deadhead Site" variant="outlined" size="small" fullWidth />}
                                />
                            </div>
                        </div>}

                        {(haulerChargeType === 'hourly') && <div className="BidsHaulerSectionBar Footer">
                            <div className="BidsHaulerSectionFootnote">*we automatically calculate this value</div>
                            <div>{haulerDeadheadCost ? '$' + haulerDeadheadCost.toFormat(2) : 'N/A'}</div>
                        </div>}

                        {(haulerChargeType === 'hourly') && <div className="BidsHaulerSectionBar">
                            <div>On Site Cost</div>
                            <div>{haulerOnSiteCost ? '$' + haulerOnSiteCost.toFormat(2) : 'N/A'}</div>
                        </div>}

                        <div className="BidsHaulerSectionBar Header">
                            <div>Extra Charges</div>
                            {!isBidAccepted() && (
                                <div className="BidsHaulerSectionBarAction" onClick={addHaulerCharge}>+ Add Extra Charge</div>
                            )}
                        </div>

                        {haulerCharges.map((charge, index) => (
                            <div className="BidsHaulerSectionBar Input">
                                <TextField
                                    className="BidsHaulerChargeNameInput"
                                    value={charge.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChargeNameUpdated(event, index) }}
                                    disabled={isBidAccepted()}
                                    label="Charge Name"
                                    size="small"
                                    variant="outlined" />
                                <div className="BidsHaulerChargeInputContainer">
                                    <CurrencyTextField
                                        value={charge.amount ? charge.amount : ''}
                                        onChange={(event, value) => onChargeAmountUpdated(value, index)}
                                        disabled={isBidAccepted()}
                                        className="BidsHaulerCurrencyInput"
                                        variant="outlined" />
                                    {!isBidAccepted() && (<Delete className="BidsHaulerDeleteCharge" onClick={() => { deleteCharge(index) }} />)}
                                </div>
                            </div>
                        ))}

                        <div className="BidsHaulerSectionBar Footer">
                            <div>Subtotal</div>
                            <div>{haulerSubtotal ? '$' + haulerSubtotal.toFormat(2) : 'N/A'}</div>
                        </div>

                        <div className="BidsHaulerSectionBar">
                            <div>BondWay Fee</div>
                            <div>{haulerFee ? '$' + haulerFee.toFormat(2) : 'N/A'}</div>
                        </div>

                        <div className="BidsHaulerSectionBar Footer">
                            <div>Total Charge Per Driver</div>
                            <div>{haulerTotal ? '$' + haulerTotal.toFormat(2) : 'N/A'}</div>
                        </div>

                        <div className="BidsHaulerSectionAssignment">
                            <div>Available Drivers <span className="BidCardAvailableText">({listing.getMaxlistingquanity()} assignable)</span></div>

                            <div className="BidsHaulerSectionAssignmentInput">
                                <Slider
                                    className="BidsHaulerSectionSlider"
                                    value={haulerAssignedDrivers}
                                    onChange={(event, value) => { setHaulerAssignedDrivers(+value) }}
                                    disabled={isBidAccepted()}
                                    step={1}
                                    marks={true}
                                    min={1}
                                    max={listing.getMaxlistingquanity()} />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    value={haulerAssignedDrivers}
                                    disabled={isBidAccepted()}
                                    onChange={(event) => { setHaulerAssignedDrivers(+event.target.value) }}
                                    error={haulerAssignedDrivers > listing.getMaxlistingquanity()}
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: listing.getMaxlistingquanity()
                                        },
                                        style: {
                                            width: '90px',
                                            height: '32px',
                                        }
                                    }} />
                            </div>
                        </div>

                        <div className="BidsHaulerSectionActionRow">
                            <div className="BidsHaulerCannotChangePrompt">
                                {(isBidAccepted()) && <div>Your bid has been accepted. It can no longer be changed.</div>}
                            </div>

                            <div className="BidsHaulerSectionActions">
                                {(haulerBid && haulerBid.getId() > 0) && <div className="BidsHaulerRemoveBid">
                                    <Button disabled={isBidAccepted()} variant="contained" color="secondary" onClick={() => withdrawBid()}>Withdraw Bid</Button>
                                </div>
                                }
                                <div className="BidsHaulerSave">
                                    <Button disabled={isBidAccepted()} variant="contained" color="primary" onClick={() => saveBid()}>Save</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            {(props.groupType !== GroupType.HAULER) && <div>
                <EditListingNav navState='bids' listing={listing} groupType={props.groupType} />

                <div className="BidsPrompt">
                    You have {remainingQuantity} order(s) left to assign.
                </div>

                <div className="BidsContainer">
                    {(!bids || bids.length === 0) &&
                        <div className="BidsEmpty">No Bids</div>
                    }

                    {(bids.length > 0) &&
                        <Fragment>
                            <header className="BidsHeader">Submitted Bids</header>
                            <div className="BidsCardContainer">
                                {bids.map((bid, index) => (
                                    <div className="BidContainer">
                                        <div className="BidCardName">
                                            <img className="BidCardGroupIcon" src={bid.haulerImage} alt="" />
                                            {bid.haulerName}
                                        </div>
                                        <div className="BidCardDetails">

                                            {bid.billingType === Bid.BillingType.HOURLY &&
                                                <div className="BidCardItem">
                                                    <span>Hourly Rate</span>
                                                    <span>{bid.hourlyRate}</span>
                                                </div>
                                            }

                                            {bid.billingType === Bid.BillingType.FLAT_RATE &&
                                                <div className="BidCardItem">
                                                    <span>Flat Rate</span>
                                                    <span>{bid.flatRate}</span>
                                                </div>
                                            }

                                            <div className="BidCardItem">
                                                <span>Deadhead To Site</span>
                                                <span>{bid.deadheadToCost || 'N/A'}</span>
                                            </div>
                                            <div className="BidCardItem">
                                                <span>Deadhead From Site</span>
                                                <span>{bid.deadheadFromCost || 'N/A'}</span>
                                            </div>
                                            <div className="BidCardItem">
                                                <span>On Site Cost</span>
                                                <span>{bid.onSiteCost || 'N/A'}</span>
                                            </div>
                                            <div className="BidCardItem">
                                                <span>Hauler Charges</span>
                                                <span>{bid.haulerFee || 'N/A'}</span>
                                            </div>
                                            <div className="BidCardItem BidCardTotal">
                                                <span>Subtotal</span>
                                                <span className="BidCardTotalValue">{bid.subTotal}</span>
                                            </div>
                                            <div className="BidCardItem">
                                                <span>BondWay Fee</span>
                                                <span>{bid.fee}</span>
                                            </div>
                                            <div className="BidCardItem BidCardTotal">
                                                <span>Total Charge Per Driver</span>
                                                <span className="BidCardTotalValue">{bid.total}</span>
                                            </div>

                                            <div className="BidCardInput">
                                                <div className="BidCardInputHeader">Available Drivers <span className="BidCardAvailableText">{`(${availableDrivers[index]} available)`}</span></div>
                                                <div className="BidCardInputContent">
                                                    <Slider
                                                        value={drivers[index] || 0}
                                                        onChange={(event, value) => setDrivers(index, event, value)}
                                                        marks={true}
                                                        min={0}
                                                        max={availableDrivers[index]}
                                                        step={1}>
                                                    </Slider>
                                                    <TextField
                                                        value={drivers[index] || 0}
                                                        error={drivers[index] > availableDrivers[index]}
                                                        onChange={(event) => setDrivers(index, event)}
                                                        hiddenLabel
                                                        type="number"
                                                        variant="outlined"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: availableDrivers[index]
                                                            },
                                                            style: {
                                                                width: '90px',
                                                                height: '32px',
                                                                borderRadius: 0
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="BidCardActions" onClick={() => acceptBid(index)}>
                                            Accept Bid
                                    </div>
                                    </div>

                                ))}
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
            }
        </main>


    </Fragment >
}

function mapState(state: any) {
    const { authentication } = state;
    return {
        token: authentication.token,
        groupType: authentication.groupType,
        groupFeeRate: authentication.groupFeeRate,
        groupId: authentication.groupId
    };
}

const actionCreators = {
    displaySuccessAlert: alertActions.success,
    displayErrorAlert: alertActions.error
};

const connectedApp = connect(mapState, actionCreators)(Bids);

export { connectedApp as Bids };