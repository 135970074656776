import config from '../config';
import { UserServiceClient } from '../proto/model_grpc_web_pb';
import { IdRequest, User, UserRequest, UserResponse } from '../proto/model_pb';
import { store } from '../_helpers/store';
import { grpcInterceptor } from './interceptor.api';

const AccountService = {

    getUser: (): Promise<UserResponse> => {
        const reduxStore = store.getState();

        const token = reduxStore.authentication.token;
        const userId = reduxStore.authentication.userId;

        const userClient = new UserServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const idRequest = new IdRequest();
        idRequest.setId(+userId);
        idRequest.setToken(token);

        return new Promise((resolve, reject) => {
            userClient.getUser(idRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        });
    },

    updateUser: (user: User): Promise<UserResponse> => {
        const reduxStore = store.getState();
        const token = reduxStore.authentication.token;

        const userClient = new UserServiceClient(config.apiUrl, null, {'unaryInterceptors': [grpcInterceptor]});

        const userRequest = new UserRequest();
        userRequest.setUser(user);
        userRequest.setToken(token);
        
        return new Promise((resolve, reject) => {
            userClient.updateUser(userRequest, {}, (err, resp) => {
                if (err) {
                    reject('System Error: Please contact an administrator');
                }

                else if (resp && resp.getError()) {
                    reject(resp.getError()?.getMessage());
                }

                else if (resp) {
                    resolve(resp);
                }
            })
        });
    }

}

export default AccountService;