import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LoginRoute = ({component, ...rest}: any) => {
    const userIsAuthed = useSelector((state: any) => state.authentication.loggedIn);

    const routeComponent = (props: any) => {
        if (userIsAuthed) {
            const redirectUrl = localStorage.getItem('redirectUrl');

            if (redirectUrl) {
                localStorage.removeItem('redirectUrl');
                return <Redirect to={{pathname: redirectUrl}}/>
            } else {
                return <Redirect to={{pathname: '/listings'}}/>
            }
        } else {
            return React.createElement(component, props)
        }

    }

    return <Route {...rest} render={routeComponent}/>;
};

export default LoginRoute;