import React from 'react';
import { Money, FieldTicketUpdateRequest, FieldTicketCharge, IdRequest, FieldTicketActionRecord, FieldTicketComment, FieldTicketCommentRequest, ApprovalRequest, FieldTicket, User } from '../../proto/model_pb.js'
import { FieldTicketServiceClient, UserServiceClient, WaypointServiceClient } from '../../proto/model_grpc_web_pb.js'
import DateTimePicker from 'react-datetime-picker';
import Dropdown from 'react-dropdown';
import ImageGallery from './ImageGallery'
import accountPlaceholder from '../../assets/account_placeholder.svg';
import addButtonIcon from '../../assets/add_circle.svg';
import removeButtonIcon from '../../assets/remove_circle.svg';
import ReactMapboxGl, { Popup, GeoJSONLayer, Marker, LngLatBounds } from 'react-mapbox-gl';
import mapboxgl from 'mapbox-gl'
import google_protobuf_timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb.js';
import config from '../../config'
import BigNumber from 'bignumber.js';
import UtilService from '../../services/util.service'
import { connect } from 'react-redux';

import 'react-dropdown/style.css';

import './OriginalOrder.css';
import './TicketSection.css';
import './Sidebar.css'

const Map = ReactMapboxGl({ accessToken: config.mapboxKey });

const linePaint = {
  'line-color': 'blue',
  'line-width': 4
};

class TicketSection extends React.Component {
  trailerOptions = [
    { value: 0, label: "None" },
    { value: 1, label: "Flatbed" },
    { value: 2, label: "Gooseneck" },
    { value: 3, label: "Low Boy" },
    { value: 4, label: "Double Drop" },
    { value: 5, label: "Box Trailer" },
    { value: 6, label: "Low Boy 14 ft" },
    { value: 7, label: "Hauler Discretion" },
  ]

  vehicleOptions = [
    { value: 0, label: "Truck" },
    { value: 1, label: "Tractor Trailer" },
  ]

  permitOptions = [
    { value: 0, label: "None" },
    { value: 1, label: "Hauler" },
    { value: 2, label: "Shipper" },
  ]

  actionTypes = [
    { value: 0, label: "Pickup" },
    { value: 1, label: "Dropoff" },
  ]

  constructor(props) {
    super(props);

    const assignedUser = this.props.fieldTicket.getAssigneduser()
    this.submitComment = this.submitComment.bind(this)
    this.submitTicketForReview = this.submitTicketForReview.bind(this)
    this.submitTicketApproval = this.submitTicketApproval.bind(this)
    this.submitTicketDispute = this.submitTicketDispute.bind(this)
    this.submitTicketInvoicing = this.submitTicketInvoicing.bind(this)
    this.submitTicketUpdates = this.submitTicketUpdates.bind(this)
    this.submitTicketPaid = this.submitTicketPaid.bind(this)
    this.addNewCharge = this.addNewCharge.bind(this)
    this.removeCharge = this.removeCharge.bind(this)
    this.print = this.print.bind(this);

    var charges = this.props.fieldTicket.getExtrachargesList().map(function (charge) {
      return {
        amount: UtilService.convertPbMoneyToBigNumber(charge.getAmount()),
        chargeName: charge.getChargename(),
      }
    })

    var coords = []
    this.props.fieldTicket.getActionrecordsList().map(function (value) {
      coords.push([value.getSite().getLongitude(), value.getSite().getLatitude()])
    })

    if (this.props.fieldTicket.getBid().getDeadheadyard().getId() != 0) {
      coords.push([this.props.fieldTicket.getBid().getDeadheadyard().getLongitude(), this.props.fieldTicket.getBid().getDeadheadyard().getLatitude()])
    }

    var bounds = coords.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

    var fitbounds = null
    if (bounds._ne !== null && bounds._sw !== null) {
      fitbounds = [[bounds._ne.lng, bounds._ne.lat], [bounds._sw.lng, bounds._sw.lat]]
    }

    var actionRecords = this.props.fieldTicket.getActionrecordsList()
      .sort(function (a, b) { return a.getScheduledaction().getOrder() - b.getScheduledaction().getOrder() })
      .map(function (val) { return { order: val.getScheduledaction().getOrder(), date: val.getTimestamp().toDate(), type: val.getScheduledaction().getActiontype(), id: val.getId(), siteName: val.getSite().getName() } })

    this.state = {
      startTime: this.props.fieldTicket.getStarttime().toDate(),
      endTime: this.props.fieldTicket.getEndtime().toDate(),
      actionRecords: actionRecords,
      trailerType: this.trailerOptions[this.props.fieldTicket.getTrailer()],
      vehicleType: this.vehicleOptions[this.props.fieldTicket.getVehicle()],
      permit: this.permitOptions[this.props.fieldTicket.getPermit()],
      assignedDriver: { value: assignedUser, label: assignedUser.getName() },
      userOptions: [{ value: assignedUser, label: assignedUser.getName() }],
      weight: this.props.fieldTicket.getWeight(),
      length: this.props.fieldTicket.getTrailerlength(),
      milesTraveled: this.props.fieldTicket.getMeasuredtraveldistancekm(),
      attachedImages: this.props.fieldTicket.getJob().getAttachmentsList().map(function (element) { return { src: element.getPresignedurl() } }),
      commentInProgress: "",
      comments: [],
      routeData: {},
      ticketCharges: charges,
      needsSaved: false,
      newChargeName: null,
      newChargeValue: null,
      purchaseOrderNumber: this.props.fieldTicket.getPurchaseordernumber(),
      selectedSiteMarker: null,
      fitBounds: fitbounds,
      shipperFeeRate: UtilService.formatMoney(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getShipperfeerate()), 3),
      haulerFeeRate: UtilService.formatMoney(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getHaulerfeerate()), 3),
    }
  }

  changeStart = date => { this.setState({ startTime: date, needsSaved: true }) }
  changeEnd = date => { this.setState({ endTime: date, needsSaved: true }) }
  changeAction = (date, action) => {
    var records = this.state.actionRecords
    for (var i = 0; i < records.length; i++) {
      if (records[i].id === action.id) {
        records[i].date = date
      }
    }
    this.setState({ actionRecords: records, needsSaved: true })
  }
  changeTrailerType = type => this.setState({ trailerType: type, needsSaved: true })
  changeVehicleType = type => this.setState({ vehicleType: type, needsSaved: true })
  changeAssignedDriver = user => this.setState({ assignedDriver: user, needsSaved: true })
  changeWeight = event => { this.setState({ weight: event.target.value, needsSaved: true }) }
  changeLength = event => { this.setState({ length: event.target.value, needsSaved: true }) }
  changeMiles = event => { this.setState({ milesTraveled: event.target.value, needsSaved: true }) }
  changePermit = permit => { this.setState({ permit: permit, needsSaved: true }) }
  changePurchaseOrderNumber = event => { this.setState({ purchaseOrderNumber: event.target.value, needsSaved: true }) }
  changeCommentInProgress = event => { this.setState({ commentInProgress: event.target.value }) }
  changeChargeName = event => { this.setState({ newChargeName: event.target.value }) }
  changeChargeValue = event => { this.setState({ newChargeValue: event.target.value }) }
  changeShipperFeeRate = event => { this.setState({ shipperFeeRate: event.target.value, needsSaved: true }) }
  changeHaulerFeeRate = event => { this.setState({ haulerFeeRate: event.target.value, needsSaved: true }) }
  changeSelectedMarker = site => { this.setState({ selectedSiteMarker: site }) }
  closePopup = () => { this.setState({ selectedSiteMarker: null }) }

  componentWillMount() {
    var userService = new UserServiceClient(config.apiUrl);
    var waypointService = new WaypointServiceClient(config.apiUrl);

    var message = new IdRequest()
    message.setToken(this.props.token)
    message.setId(this.props.fieldTicket.getJob().getHaulergroupid())

    userService.getDriversForGroup(
      message,
      {},
      (err, response) => {
        if (err) {
          console.log(err)
          return
        }
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        if (response.getUserList()) {
          const users = response.getUserList()
            .filter(user => user.getUsertype() == 0)
            .map(function (user) { return { value: user, label: user.getName() } })
          this.setState({ userOptions: users })
        }
      }
    )

    var waypointRequest = new IdRequest()
    waypointRequest.setToken(this.props.token)
    waypointRequest.setId(this.props.fieldTicket.getJob().getId())

    waypointService.getWaypointsForJob(
      waypointRequest,
      {},
      (err, response) => {
        if (err) {
          console.log(err)
          return
        }
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        if (response.getWaypointsList()) {
          const waypoints = response.getWaypointsList().sort(function (a, b) { return b.getTimestamp().toDate() - a.getTimestamp().toDate() }).map(function (point) { return [point.getLongitude(), point.getLatitude()] })

          var geojson = {
            'type': 'FeatureCollection',
            'features': [
              {
                'type': 'Feature',
                'geometry': {
                  'type': 'LineString',
                  'coordinates': waypoints
                }
              }
            ]
          }

          this.setState({ routeData: geojson })
        }
      }
    )
  }

  updateTicket() {
    var ticketService = new FieldTicketServiceClient(config.apiUrl);
    var fieldTicketUpdate = new IdRequest()
    fieldTicketUpdate.setToken(this.props.token)
    fieldTicketUpdate.setId(this.props.fieldTicket.getId())

    ticketService.getFieldTicketData(
      fieldTicketUpdate,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        if (response.getTicket()) {
          const ticket = response.getTicket()
          this.props.updateSelf(ticket)
        }
      }
    )
  }

  submitComment() {
    if (this.state.commentInProgress === "" || this.state.commentInProgress === null) {
      return
    }
    var ticketService = new FieldTicketServiceClient(config.apiUrl);

    var comment = new FieldTicketComment()
    comment.setFieldticketid(this.props.fieldTicket.getId())
    comment.setMessage(this.state.commentInProgress)

    var commentRequest = new FieldTicketCommentRequest()
    commentRequest.setToken(this.props.token)
    commentRequest.setComment(comment)

    ticketService.commentOnFieldTicket(
      commentRequest,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        // Comment success. Remove the comment text and reload the ticket.
        this.setState({ commentInProgress: "" })
        this.updateTicket()
      }
    )
  }

  submitTicketForReview() {
    var request = new IdRequest()
    request.setToken(this.props.token)
    request.setId(this.props.fieldTicket.getId())

    var ticketService = new FieldTicketServiceClient(config.apiUrl);
    ticketService.submitFieldTicketForApproval(
      request,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        this.props.closeSelf()
      }
    )
  }

  submitTicketApproval() {
    var ticket = new FieldTicket()
    ticket.setId(this.props.fieldTicket.getId())

    var request = new ApprovalRequest()
    request.setToken(this.props.token)
    request.setTicket(ticket)

    var ticketService = new FieldTicketServiceClient(config.apiUrl);
    ticketService.approveFieldTicket(
      request,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        this.props.closeSelf()
      }
    )
  }

  submitTicketDispute() {
    var ticket = new FieldTicket()
    ticket.setId(this.props.fieldTicket.getId())

    var request = new ApprovalRequest()
    request.setToken(this.props.token)
    request.setTicket(ticket)

    var ticketService = new FieldTicketServiceClient(config.apiUrl);
    ticketService.denyFieldTicket(
      request,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        this.props.closeSelf()
      }
    )
  }

  submitTicketInvoicing() {
    var ticket = new FieldTicket()
    ticket.setId(this.props.fieldTicket.getId())

    var request = new ApprovalRequest()
    request.setToken(this.props.token)
    request.setTicket(ticket)

    var ticketService = new FieldTicketServiceClient(config.apiUrl);
    ticketService.invoiceFieldTicket(
      request,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        this.props.closeSelf()
      }
    )
  }

  submitTicketPaid() {
    var request = new IdRequest()
    request.setToken(this.props.token)
    request.setId(this.props.fieldTicket.getId())

    var ticketService = new FieldTicketServiceClient(config.apiUrl);
    ticketService.archiveFieldTicket(
      request,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        this.props.closeSelf()
      }
    )
  }

  submitTicketUpdates() {
    if (this.state.dropoffTime < this.state.startTime) {
      alert("Invalid Ticket: Dropoff time is set before the start time!")
      return
    }

    if (this.state.dropoffTime < this.state.pickupTime) {
      alert("Invalid Ticket: Dropoff time is set before the pickup time!")
      return
    }

    if (this.state.pickupTime < this.state.startTime) {
      alert("Invalid Ticket: Pickup time is set before the start time!")
      return
    }

    var ticket = this.props.fieldTicket
    var user = new User()
    user.setId(this.state.assignedDriver.value.getId())

    var newTicket = new FieldTicket()
    newTicket.setId(ticket.getId())
    newTicket.setVehicle(this.state.vehicleType.value)
    newTicket.setTrailer(this.state.trailerType.value)
    newTicket.setPermit(this.state.permit.value)
    newTicket.setAssigneduser(user)
    newTicket.setWeight(this.state.weight)
    newTicket.setTrailerlength(this.state.length)
    newTicket.setPurchaseordernumber(this.state.purchaseOrderNumber)

    var start = new google_protobuf_timestamp_pb.Timestamp;
    start.fromDate(this.state.startTime)
    var end = new google_protobuf_timestamp_pb.Timestamp;
    end.fromDate(this.state.endTime)

    newTicket.setStarttime(start)
    newTicket.setEndtime(end)

    var records = []
    this.state.actionRecords.forEach(
      (record) => {
        var newRecord = new FieldTicketActionRecord()
        newRecord.setId(record.id)
        newRecord.setTimestamp(new google_protobuf_timestamp_pb.Timestamp.fromDate(record.date))
        records.push(newRecord)
      }
    )
    console.log(records)
    newTicket.setActionrecordsList(records)

    for (const c of this.state.ticketCharges) {
      var money = this.moneyStringToPB(c.amount.toString())

      var newCharge = new FieldTicketCharge()
      newCharge.setChargename(c.chargeName)
      newCharge.setAmount(money)

      newTicket.addExtracharges(newCharge)
    }

    newTicket.setShipperfeerate(this.moneyStringToPB(this.state.shipperFeeRate.toString()))
    newTicket.setHaulerfeerate(this.moneyStringToPB(this.state.haulerFeeRate.toString()))


    var updateTicketRequest = new FieldTicketUpdateRequest()
    updateTicketRequest.setToken(this.props.token)
    updateTicketRequest.setTicket(newTicket)

    var ticketService = new FieldTicketServiceClient(config.apiUrl);

    ticketService.updateFieldTicket(
      updateTicketRequest,
      {},
      (err, response) => {
        if (response.getError()) {
          alert(response.getError().getMessage())
          return
        }

        this.setState({ needsSaved: false })
        this.updateTicket()
      }
    )
  }

  moneyStringToPB(moneyString) {
    var units = ""
    var nanos = ""
    var split = moneyString.split('.')

    if (split.length > 0) {
      units = split[0]
    }

    if (split.length > 1) {
      nanos = split[1]
    }

    if (nanos.length > 3) {
      nanos = "".concat(nanos.charAt(0)).concat(nanos.charAt(1)).concat(nanos.charAt(2))
    }

    while (nanos.length < 9) {
      nanos = nanos.concat('0')
    }

    var money = new Money()
    money.setUnits(parseInt(units))
    money.setNanos(parseInt(nanos))
    money.setCurrencyCode("USD")
    return money
  }

  addNewCharge() {
    var charge = {
      amount: parseFloat(this.state.newChargeValue),
      chargeName: this.state.newChargeName,
    }

    var charges = this.state.ticketCharges
    charges.push(charge)

    this.setState({ ticketCharges: charges, newChargeName: "", newChargeValue: 0.0, needsSaved: true })
  }

  removeCharge(index) {
    var charges = this.state.ticketCharges
    charges.splice(index, 1)

    this.setState({ ticketCharges: charges, needsSaved: true })
  }

  print() {
    window.print();
  }

  render() {
    if (this.props.fieldTicket === null) {
      return <div />
    }

    var comments = []
    for (const c of this.props.fieldTicket.getCommentsList()) {
      console.log(this.props.userId)
      comments.push(<TicketComment key={c.getId()} loggedInUser={c.getUser().getId() === this.props.userId} message={c.getMessage()} user={c.getUser()} />)
    }

    var userIsPartOfHaulingGroup = (this.props.fieldTicket.getCompletinggroup().getId() === this.props.groupId)
    var userIsPartOfRequestingGroup = (this.props.fieldTicket.getGroup().getId() === this.props.groupId)
    var userIsPartOfBondway = this.props.userAdmin
    var isDisabled = !userIsPartOfHaulingGroup || (this.props.fieldTicket.getStatus() !== 0 && this.props.fieldTicket.getStatus() !== 3)
    var canEdit = !isDisabled
    var actionButtons

    // Unsubmitted = 0 or Disputed = 3;
    if ((userIsPartOfBondway || userIsPartOfHaulingGroup) && (this.props.fieldTicket.getStatus() === 0 || this.props.fieldTicket.getStatus() === 3)) {
      actionButtons = <div className="DualBox">
        <a className={this.state.needsSaved ? "ActiveButton" : "InactiveButton"} onClick={this.state.needsSaved ? this.submitTicketUpdates : null}>Save Draft</a>
        <a className={!this.state.needsSaved ? "ActiveButton" : "InactiveButton"} onClick={this.state.needsSaved ? null : this.submitTicketForReview}>Submit for Review</a>
      </div>
    }
    // InReview = 1;
    else if ((userIsPartOfBondway || userIsPartOfRequestingGroup) && this.props.fieldTicket.getStatus() === 1) {
      actionButtons = <div className="DualBox">
        <a className="DisputeButton" onClick={this.submitTicketDispute}>Dispute</a>
        <a className="ApproveButton" onClick={this.submitTicketApproval}>Approve</a>
      </div>
    }
    // Approved = 2;
    else if (userIsPartOfBondway && this.props.fieldTicket.getStatus() === 2) {
      actionButtons = <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <a className={this.state.needsSaved ? "ActiveButton" : "InactiveButton"} onClick={this.state.needsSaved ? this.submitTicketUpdates : null}>Save</a>
        <a className="ActiveButton" onClick={this.submitTicketInvoicing}>Move to Invoiced</a>
      </div>
    }
    // Invoiced = 4;
    else if (userIsPartOfBondway && this.props.fieldTicket.getStatus() === 4) {
      actionButtons = <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <a className={this.state.needsSaved ? "ActiveButton" : "InactiveButton"} onClick={this.state.needsSaved ? this.submitTicketUpdates : null}>Save</a>
        <a className="ActiveButton" onClick={this.submitTicketPaid}>Move To Paid</a>
      </div>
    }

    var totalValue = new BigNumber(0)
    var hourlyTotal = UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(this.state.actionRecords[this.state.actionRecords.length - 1].date) - UtilService.msToTime(this.state.actionRecords[0].date)).decimalPlaces(2)
    var deadheadToJob = UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(this.state.actionRecords[0].date) - UtilService.msToTime(this.state.startTime)).decimalPlaces(2)
    var deadheadFromJob = UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(this.state.endTime) - UtilService.msToTime(this.state.actionRecords[this.state.actionRecords.length - 1].date)).decimalPlaces(2)

    totalValue = totalValue.plus(deadheadToJob)
    totalValue = totalValue.plus(deadheadFromJob)

    var billingSurcharges = this.props.fieldTicket.getBid()?.getSurchargesList().map(function (value) {
      var rate = UtilService.convertPbMoneyToBigNumber(value.getRate()).decimalPlaces(2)
      totalValue = totalValue.plus(rate)
      return <BillingItem title={value.getChargename()} value={(rate > 0 ? "+" : "-") + ' $' + (rate)} />
    }) ?? []

    if (this.props.fieldTicket.getBid()?.getBillingtype() === 0) {
      totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getFlatrate()).decimalPlaces(2))
    } else if (this.props.fieldTicket.getBid()?.getBillingtype() === 1) {
      totalValue = totalValue.plus(hourlyTotal)
    }

    var obj = this
    var index = 0
    // For each ticket charge on the ticket
    var ticketCharges = this.state.ticketCharges.map(function (value) {
      totalValue = totalValue.plus(value.amount)
      var i = index
      index++
      return <div style={{ display: 'flex' }}>
        <BillingItem title={value.chargeName} value={(value.amount > 0 ? "+" : "-") + ' $' + Math.abs(value.amount)} />
        {canEdit ? <RemoveButtonComponent removeCharge={obj.removeCharge} i={i} /> : null}
      </div>
    }) ?? []

    const totalBeforeFees = totalValue
    const totalRequesterFee = new BigNumber(this.state.shipperFeeRate).times(totalBeforeFees).decimalPlaces(2)
    const totalHaulerFee = new BigNumber(this.state.haulerFeeRate).times(totalBeforeFees).decimalPlaces(2)
    var totalValueAfterFees = userIsPartOfRequestingGroup ? totalBeforeFees.plus(totalRequesterFee) : userIsPartOfHaulingGroup ? totalBeforeFees.minus(totalHaulerFee) : userIsPartOfBondway ? totalBeforeFees : new BigNumber(0)

    var beforeFeeSection = <BillingItem title={"Total Before Fees"} value={'= $' + totalBeforeFees.decimalPlaces(2)} />
    var feeSection = <BillingItem title={"Bondway Fees"} value={(userIsPartOfRequestingGroup ? "+" : "-") + ' $' + (userIsPartOfRequestingGroup ? totalRequesterFee : totalHaulerFee).toFixed(2, 1)} />
    var totalSection = <BillingItem title={userIsPartOfRequestingGroup ? "Accounts Payable" : "Accounts Receivable"} value={'= $' + totalValueAfterFees.decimalPlaces(2)} />
    var accountsPayableSection = <BillingItem title={"Accounts Payable"} value={'$' + totalBeforeFees.minus(totalHaulerFee).decimalPlaces(2)} />
    var accountsReceiveableSection = <BillingItem title={"Accounts Receivable"} value={'$' + totalBeforeFees.plus(totalRequesterFee).decimalPlaces(2)} />

    var haulerFeeAmount = <BillingItem title={"Hauler Fee Amount"} value={'$' + totalHaulerFee || 0} />
    var shipperFeeAmount = <BillingItem title={"Shipper Fee Amount"} value={'$' + totalRequesterFee || 0} />

    var bondwayTotalRevenueSection = <BillingItem title={"Bondway Revenue"} value={'= $' + (totalBeforeFees.plus(totalRequesterFee)).minus(totalBeforeFees.minus(totalHaulerFee).decimalPlaces(2))} />

    var siteActionLayers = this.props.fieldTicket.getActionrecordsList().map(function (value) {
      var color = value.getScheduledaction().getActiontype() === 0 ? "#165BC1" : "#00AE46"
      var coords = [value.getSite().getLongitude(), value.getSite().getLatitude()]
      return <Marker
        onClick={() => obj.changeSelectedMarker(value.getSite())}
        anchor="center"
        coordinates={coords}>
        <div style={{ height: '18px', width: '18px', userSelect: "none", display: "flex", alignItems: "center", justifyContent: "center", color: "white", fontWeight: "bold", backgroundColor: color, borderRadius: '50%', borderColor: '#FFFFFF', borderWidth: '3px', borderStyle: 'solid' }}>
          {value.getScheduledaction().getOrder() + 1}
        </div>
      </Marker>
    }) ?? []

    if (this.props.fieldTicket.getBid().getDeadheadyard().getId() != 0) {
      siteActionLayers.push(
        <Marker
          anchor="center"
          onClick={() => obj.changeSelectedMarker(this.props.fieldTicket.getBid().getDeadheadyard())}
          coordinates={[this.props.fieldTicket.getBid().getDeadheadyard().getLongitude(), this.props.fieldTicket.getBid().getDeadheadyard().getLatitude()]}>
          <div style={{ height: '14px', width: '14px', backgroundColor: 'red', borderRadius: '50%', borderColor: '#FFFFFF', borderWidth: '3px', borderStyle: 'solid' }} />
        </Marker>
      )
    }

    var billingSection = <div className="InnerFlex">
      {
        this.props.fieldTicket.getBid()?.getBillingtype() == 1
          ?
          <BillingItem title={"Hourly Rate ($" + UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()) + '/hr)'} value={'$' + hourlyTotal} />
          :
          <BillingItem title={"Flat Rate"} value={'$' + (UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getFlatrate())).toFixed(2)} />
      }
      {
        this.state.actionRecords.length > 0
          ?
          <BillingItem title={"Deadhead To Job"} value={'+ $' + deadheadToJob} />
          :
          null
      }

      {
        this.state.actionRecords.length > 0
          ?
          <BillingItem title={"Deadhead From Job"} value={'+ $' + deadheadFromJob} />
          :
          null
      }

      {billingSurcharges}

      <div className="Sidebar-divider" style={{ marginBottom: "16px" }} />
      {ticketCharges}
      {canEdit ? <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <input value={this.state.newChargeName} onChange={this.changeChargeName} placeholder={"Charge Name"} style={{ marginRight: "16px" }} />
          <input value={this.state.newChargeValue} onChange={this.changeChargeValue} type="number" placeholder={"0.0"} />
        </div>
        <img onClick={this.addNewCharge} src={addButtonIcon} style={{ marginBottom: "8px", marginTop: "8px", width: "20px", height: "20px" }} />
      </div>
        : null}

      <div className="Sidebar-divider" style={{ marginBottom: "16px" }} />

      {beforeFeeSection}
      <div className="Sidebar-divider" style={{ marginBottom: "16px" }} />
      {!userIsPartOfBondway ? feeSection : null}
      {!userIsPartOfBondway ? totalSection : null}
      {userIsPartOfBondway ? accountsPayableSection : null}
      {userIsPartOfBondway ?
        <div className="BillingItemFlex">
          <span className="BillingItemTitle">Hauler Fee Rate</span>
          <span className="BillingItemValue"><input value={this.state.haulerFeeRate} onChange={this.changeHaulerFeeRate} type="number" class="noscroll" placeholder={"0.0"} /></span>
        </div>
        : null
      }
      {userIsPartOfBondway ? haulerFeeAmount : null}
      {userIsPartOfBondway ? accountsReceiveableSection : null}
      {userIsPartOfBondway ?
        <div className="BillingItemFlex">
          <span className="BillingItemTitle">Shipper Fee Rate</span>
          <span className="BillingItemValue"><input value={this.state.shipperFeeRate} onChange={this.changeShipperFeeRate} type="number" class="noscroll" placeholder={"0.0"} /></span>
        </div>
        : null
      }
      {userIsPartOfBondway ? shipperFeeAmount : null}
      {userIsPartOfBondway ? <div className="Sidebar-divider" style={{ marginBottom: "16px" }} /> : null}
      {userIsPartOfBondway ? bondwayTotalRevenueSection : null}
    </div>

    var purchaseOrderSection = null
    if (userIsPartOfRequestingGroup) {
      purchaseOrderSection = <div className="DualBox">
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle">Puchase Order Number</span>
          <input onChange={this.changePurchaseOrderNumber} value={this.state.purchaseOrderNumber} onBlur={(e) => {
            this.submitTicketUpdates()
          }} />
        </div>
        <div className="InnerFlex" />
      </div>
    }

    var recordBoxes = this.state.actionRecords.map(
      function (val) {
        var color = val.type === 0 ? "#165BC1" : "#00AE46"
        return <div className="InnerFlex">
          <span className="OrderPanelItemTitle">{val.order + 1}. <span style={{ color: color }}>{obj.actionTypes[val.type].label}</span> at {val.siteName}</span>
          <DateTimePicker disabled={isDisabled} className="DateTimeStyle" onChange={(date) => obj.changeAction(date, val)} value={val.date} />
        </div>
      }
    )

    // Every other box, we insert two boxes into a dualbox element. we have to test if the second box exists.
    var recordsSection = []
    recordBoxes.forEach(
      function (box, idx) {
        if (idx % 2 == 0) {
          recordsSection.push(
            <div className="DualBox">
              {box}
              {(idx + 1 < recordBoxes.length) ? recordBoxes[idx + 1] : <div className="InnerFlex" />}
            </div>
          )
        }
      }
    )
    return <div className="TicketSection">
      <div className="OrderPanelHeader">Ticket Number: #{this.props.fieldTicket.getTicketuuid()}
        <span className="OrderPanelPrint" onClick={() => this.print()}>Print</span>
      </div>

      {purchaseOrderSection}

      <span className="OrderPanelSubheader">Submitted Times</span>
      <div className="DualBox">
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle" style={this.state.startTime > this.state.endTime ? { color: "red" } : { color: "#FF8484" }}>Start Time</span>
          <DateTimePicker disabled={isDisabled} className="DateTimeStyle" onChange={this.changeStart} value={this.state.startTime} />
        </div>
        <div className="InnerFlex" />
      </div>

      {recordsSection}


      <div className="DualBox">
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle" style={this.state.endTime < this.state.startTime ? { color: "red" } : { color: "#FF8484" }}>End Time</span>
          <DateTimePicker disabled={isDisabled} className="DateTimeStyle" onChange={this.changeEnd} value={this.state.endTime} />
        </div>
        <div className="InnerFlex" />
      </div>


      <span className="OrderPanelSubheader">Vehicle &amp; Driver Information</span>
      <div className="DualBox">
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle">Vehicle Type</span>
          <Dropdown disabled={isDisabled} className="DropdownStyle" options={this.vehicleOptions} onChange={this.changeVehicleType} value={this.state.vehicleType} />
        </div>
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle">Assigned Driver</span>
          <Dropdown disabled={isDisabled} className="DropdownStyle" options={this.state.userOptions} onChange={this.changeAssignedDriver} value={this.state.assignedDriver} />
        </div>
      </div>

      <span className="OrderPanelSubheader">Load Information</span>
      <div className="DualBox">
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle">Weight (lbs)</span>
          <input disabled={isDisabled} className="TextInputStyle" type="number" onChange={this.changeWeight} value={this.state.weight} />
        </div>
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle">Trailer Type</span>
          <Dropdown disabled={isDisabled} className="DropdownStyle" options={this.trailerOptions} onChange={this.changeTrailerType} value={this.state.trailerType} />
        </div>
      </div>

      <div className="DualBox">
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle">Trailer Length (ft)</span>
          <input disabled={isDisabled} className="TextInputStyle" type="number" onChange={this.changeLength} value={this.state.length} />
        </div>
        <div className="InnerFlex">
          <span className="OrderPanelItemTitle">Permit Holder</span>
          <Dropdown disabled={isDisabled} className="DropdownStyle" options={this.permitOptions} onChange={this.changePermit} value={this.state.permit} />
        </div>
      </div>

      <span className="OrderPanelSubheader">Billing</span>
      <div className="DualBox">
        {billingSection}

        <div className="InnerFlex">
          <Map
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: '350px',
              width: '100%',
              borderRadius: '5px',
            }}
            fitBounds={this.state.fitBounds}
            fitBoundsOptions={{ padding: 40 }}
          >
            <GeoJSONLayer
              data={this.state.routeData}
              linePaint={linePaint}
            />
            {siteActionLayers}

            {this.state.selectedSiteMarker !== null ? (
              <Popup
                onClick={this.closePopup}
                coordinates={[this.state.selectedSiteMarker.getLongitude(), this.state.selectedSiteMarker.getLatitude()]}
              >
                {this.state.selectedSiteMarker.getName()}
              </Popup>
            )
              : null}
          </Map>
        </div>
      </div>

      <span className="OrderPanelSubheader">Attachments</span>
      <ImageGallery imgs={this.state.attachedImages} />

      <div className="Spacer" />
      <span className="OrderPanelSubheader">Comments</span>
      {comments}

      <div className="CommentBox">
        <textarea placeholder="Enter a comment" className="CommentTextArea" onChange={this.changeCommentInProgress} value={this.state.commentInProgress} />
        <a className="AddCommentButton" onClick={this.submitComment}>Add Comment</a>
      </div>
      {actionButtons}
    </div>
  }
}

// inputs:
// props.title = "Title"
// props.value = "Value"
function BillingItem(props) {
  return <div className="BillingItemFlex">
    <span className="BillingItemTitle">{props.title}</span>
    <span className="BillingItemValue">{props.value}</span>
  </div>
}

class RemoveButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.action = this.action.bind(this)
  }

  action() {
    this.props.removeCharge(this.props.i)
  }

  render() {
    return <img onClick={this.action} src={removeButtonIcon} style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
  }
}


function mapState(state) {
  const { authentication } = state;
  return {
    userId: authentication.userId,
    token: authentication.token,
    groupType: authentication.groupType,
    groupId: authentication.groupId,
    userAdmin: authentication.userAdmin
  };
}

const connectedApp = connect(mapState)(TicketSection);

export { connectedApp as TicketSection };

class TicketComment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className="TicketCommentContainer" style={this.props.loggedInUser ? { flexDirection: "row-reverse" } : { flexDirection: "row" }}>
      <div style={{ width: "96px" }}>
        <img src={accountPlaceholder} className="TicketCommentImage" />
        <div style={{ width: "100%", textAlign: "center" }}>{this.props.user.getName()}</div>
      </div>
      <div className="TicketCommentBox" style={this.props.loggedInUser ? { backgroundColor: "#CEDCF070" } : { backgroundColor: "#E2F0CE" }} >
        {this.props.message}
      </div>
    </div>
  }
}