import React from 'react';

import './OriginalOrder.css';
import './TicketSection.css';
import './FieldTickets.scss';
import UtilService  from '../../services/util.service'
import BigNumber from 'bignumber.js'
import { connect } from 'react-redux';

class OriginalOrder extends React.Component {
  constructor(props) {
    super(props);
  }

  render() { 
    if (this.props.fieldTicket === null) {
      return <div style={{height: "90vw"}}/>
    }

    var note = this.props.fieldTicket.getListing().getNote()
    var vehicle = ""
    switch (this.props.fieldTicket.getListing().getVehicle()) {
      case 0:
        vehicle = "Truck"
        break;
      case 1:
        vehicle = "Tractor Trailer"
        break;
    }

    var trailer = ""
    switch (this.props.fieldTicket.getListing().getTowload()) {
      case 0:
        trailer = "None"
        break;
      case 1:
        trailer = "Flatbed"
        break;
      case 2:
        trailer = "Gooseneck"
        break;
      case 3:
        trailer = "Low Boy"
        break;
      case 4:
        trailer = "Drop Deck"
        break;  
      case 5:
        trailer = "Box Trailer"
        break;    
    }

    var permit = ""
    switch (this.props.fieldTicket.getListing().getPermitter()) {
      case 0:
        permit = "No Permit Required"
        break;
      case 1:
        permit = "Hauler"
        break;
      case 2:
        permit = "Shipper"
        break;
    }

    var userIsPartOfHaulingGroup = ( this.props.fieldTicket.getCompletinggroup().getId() === this.props.groupId )
    var userIsPartOfRequestingGroup = ( this.props.fieldTicket.getGroup().getId() === this.props.groupId )
    var userIsPartOfBondway = this.props.userAdmin

    var totalValue = new BigNumber(0)

    var billingSurcharges = this.props.fieldTicket.getBid()?.getSurchargesList().map( function(value) {
      totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(value.getRate()))
      
      return <BillingItem title={value.getChargename()} value={('+ $'+UtilService.convertPbMoneyToBigNumber(value.getRate()))}/>
    }) ?? []
    
    var first = Date(0)
    var last = Date(0)
    var actions = this.props.fieldTicket.getActionrecordsList()
                    .sort( function(a, b) { return a.getScheduledaction().getOrder() - b.getScheduledaction().getOrder() } )
                    .map( function(val) { return val.getScheduledaction() } )
    
    if (actions.length > 0) {
      first = actions[0].getScheduledactiontime().toDate()
      last = actions[actions.length - 1].getScheduledactiontime().toDate()
    }

    // Hourly or flat rate
    if (this.props.fieldTicket.getBid()?.getBillingtype() === 0) {
      totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getFlatrate()))
    } else {
      totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(last - first)))
    }

    // estimated deadhead charge
    totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(this.props.fieldTicket.getBid()?.getDeadheadstartduration().toDate() ?? 0)))
    totalValue = totalValue.plus(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(this.props.fieldTicket.getBid()?.getDeadheadendduration().toDate() ?? 0)))

    const totalBeforeFees = totalValue
    const totalRequesterFee = totalBeforeFees.times(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getShipperfeerate()))
    const totalHaulerFee = totalBeforeFees.times(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getHaulerfeerate()))
    var totalValueAfterFees = userIsPartOfRequestingGroup ? totalBeforeFees.plus(totalRequesterFee) : userIsPartOfHaulingGroup ? totalBeforeFees.minus(totalHaulerFee) : userIsPartOfBondway ? totalBeforeFees : new BigNumber(0)

    var feeSection = <BillingItem title={'Bondway Fees'} value={(userIsPartOfRequestingGroup ? "+" : "-")+' $'+(userIsPartOfRequestingGroup ? totalRequesterFee : totalHaulerFee).decimalPlaces(2)}/>
    var beforeFeeSection = <BillingItem title={'Estimate Before Fees'} value={'$'+totalBeforeFees.decimalPlaces(2)}/>
    var afterFeeSection = <BillingItem title={'Estimate After Fees'} value={'$'+totalValueAfterFees.decimalPlaces(2)}/>

    var bondwayRequesterSplitSection = <BillingItem title={"Requester Split"} value={'$'+totalRequesterFee.toFixed(2)}/>
    var bondwayHaulerSplitSection = <BillingItem title={"Hauler Split"} value={'$'+totalHaulerFee.toFixed(2)}/>
    var bondwayTotalRevenueSection = <BillingItem title={"Bondway Revenue"} value={'$'+(totalHaulerFee.plus(totalRequesterFee).toFixed(2))}/>

    var billingSection = <div className="OuterFlex">
      { 
        this.props.fieldTicket.getBid()?.getBillingtype() == 1 
        ?
        <BillingItem title={"Hourly Rate ($" + UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()) +'/hr)'} value={'$'+(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(last - first)).toFixed(2))}/>
        : 
        <BillingItem title={"Flat Rate"} value={'$'+(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getFlatrate()).toFixed(2))}/>
      }

      {
        this.props.fieldTicket.getBid()?.getDeadheadstartduration().toDate() > 0
        ? 
        <BillingItem title={"Deadhead To Job"} value={'+ $'+(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(this.props.fieldTicket.getBid()?.getDeadheadstartduration().toDate() ?? 0)).decimalPlaces(2))}/>
        :
        <BillingItem title={"Deadhead To Job"} value={'No Estimate'}/>
      }

{
        this.props.fieldTicket.getBid()?.getDeadheadendduration().toDate() > 0
        ? 
        <BillingItem title={"Deadhead From Job"} value={'+ $'+(UtilService.convertPbMoneyToBigNumber(this.props.fieldTicket.getBid()?.getHourlyrate()).times(UtilService.msToTime(this.props.fieldTicket.getBid().getDeadheadendduration().toDate())).decimalPlaces(2))}/>
        :
        <BillingItem title={"Deadhead From Job"} value={'No Estimate'}/>
      }


      {billingSurcharges}

      <div className="Sidebar-divider" style={{marginBottom: "16px"}}/>
      {beforeFeeSection}
      <div className="Sidebar-divider" style={{marginBottom: "16px"}}/>
      {!userIsPartOfBondway ? feeSection : null}
      {afterFeeSection}
      {userIsPartOfBondway ? <div className="Sidebar-divider" style={{marginBottom: "16px"}}/> : null}
      {userIsPartOfBondway ? bondwayRequesterSplitSection : null}
      {userIsPartOfBondway ? bondwayHaulerSplitSection : null}
      {userIsPartOfBondway ? <div className="Sidebar-divider" style={{marginBottom: "16px"}}/> : null}
      {userIsPartOfBondway ? bondwayTotalRevenueSection : null}
    </div>

    return  <div className="OriginalOrderPanel">
              <span className="OrderPanelHeader">{this.props.fieldTicket.getListing().getItem()}</span>
              <span className="OrderPanelSubheader">Original Order Information</span>

              <div  className="Spacer"/>
              <span className="OrderPanelItemTitle">Company</span>
              <span className="OrderPanelItemValue">{this.props.fieldTicket.getGroup().getName()}</span>
              <div  className="Spacer"/>
              <span className="OrderPanelItemTitle">Vehicle Type</span>
              <span className="OrderPanelItemValue">{vehicle}</span>
              <div  className="Spacer"/>
              <span className="OrderPanelItemTitle">Assigned Driver</span>
              <span className="OrderPanelItemValue">{this.props.fieldTicket.getOriginalassigneduser()?.getName()}</span>
              <div className="Order-divider"/>

              <span className="OrderPanelItemTitle">Weight</span>
              <span className="OrderPanelItemValue">{this.props.fieldTicket.getListing().getWeight()}</span>
              <div  className="Spacer"/>
              <span className="OrderPanelItemTitle">Trailer Type</span>
              <span className="OrderPanelItemValue">{trailer}</span>
              <div  className="Spacer"/>
              <span className="OrderPanelItemTitle">Trailer Length</span>
              <span className="OrderPanelItemValue">{this.props.fieldTicket.getListing().getTowlength()}</span>
              <div className="Order-divider"/>

              <span className="OrderPanelItemTitle">Dispatcher Notes</span>
              <span className="OrderPanelItemValue">{note === null || note === "" ? "None" : this.props.fieldTicket.getListing().getNote()}</span>
              <div className="Order-divider"/>

              <span className="OrderPanelItemTitle">Permit Holder</span>
              <span className="OrderPanelItemValue">{permit}</span>
              <div className="Order-divider"/>

              {billingSection}
            </div>
  }
}



function mapState(state) {
  const { authentication } = state;
  return {
      token: authentication.token,
      groupType: authentication.groupType,
      groupId: authentication.groupId,
      userAdmin: authentication.userAdmin
  };
}

const connectedApp = connect(mapState)(OriginalOrder);

export { connectedApp as OriginalOrder };

// inputs:
// props.title = "Title"
// props.value = "Value"
function BillingItem(props) {
  return  <div className = "BillingItemFlex">
            <span className="BillingItemTitle">{props.title}</span>
            <span className="BillingItemValue">{props.value}</span>
          </div>
}