import React, { Fragment, useEffect, useState } from "react";
import { NavBar } from '../../components/navbar/Navbar';
import './Listings.scss';
import { ListingsRoute } from '../../_types/nav.types';
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import ListingsService, { ListingRequest } from '../../services/listings.service';
import { alertActions } from '../../_actions/alert.actions';
import { useHistory } from 'react-router-dom';
import { HaulingListing, HaulingListingListResponse, QueryRequest, ScheduledJobAction } from '../../proto/model_pb';
import Pill from '../../components/ui/Pill';
import { GroupType } from '../../_types/user.types';
import _ from 'underscore';

type Props = {
    token: string,
    groupType: GroupType,
    userAdmin: boolean,
    displayErrorAlert: any
}

type Sort = {
    active: QueryRequest.SortParameter | undefined,
    direction: 'asc' | 'desc',
}

const Listings = (props: Props) => {

    const history = useHistory();
    const [listings, setListings] = useState<HaulingListing[]>([]);
    const [listingCount, setListingCount] = useState<number | undefined>(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sort, setSort] = useState<Sort>({ active: QueryRequest.SortParameter.DATE, direction: 'desc' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getListings(rowsPerPage, page, undefined, sort);
    }, []);

    const getListings = (limit: number, offset: number, searchText: string | undefined, sort: Sort | undefined) => {
        let sortParam = undefined;
        let sortOrder = undefined;

        if (sort && (sort.active || sort.active === 0)) {
            if (sort.direction === 'asc') {
                sortParam = sort.active;
                sortOrder = QueryRequest.Ordering.ASCENDING
            } else if (sort.direction === 'desc') {
                sortParam = sort.active;
                sortOrder = QueryRequest.Ordering.DESCENDING
            }
        }

        const request: ListingRequest = {
            token: props.token,
            limit,
            offset,
            searchText,
            sortParam,
            sortOrder
        }

        setLoading(true);

        ListingsService.getListings(request)
            .then(
                (response: HaulingListingListResponse) => {
                    const listings: HaulingListing[] = response.getListingsList();

                    if (!listingCount) {
                        const count: number = response.getCount();
                        setListingCount(count);
                    }

                    setLoading(false);
                    setListings(listings);
                },
                error => {
                    setLoading(false);
                    props.displayErrorAlert(error.toString());
                }
            )
    }

    const onSearch = _.debounce((searchText: string) => {
        setPage(0);
        getListings(rowsPerPage, 0, searchText, sort)
    }, 1000)

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        getListings(rowsPerPage, newPage * rowsPerPage, undefined, sort);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const rowsPerPage = parseInt(event.target.value, 10);

        if (rowsPerPage) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
            getListings(rowsPerPage, 0, undefined, sort);
        }

    }

    const handleSort = (sortBy: QueryRequest.SortParameter) => {
        const sortCopy = JSON.parse(JSON.stringify(sort));

        if (sortCopy.active !== sortBy) {
            sortCopy.active = sortBy;
            sortCopy.direction = 'asc'
        } else {
            if (sortCopy.direction === 'asc') {
                sortCopy.direction = 'desc'
            } else if (sortCopy.direction === 'desc') {
                sortCopy.active = undefined;
                sortCopy.direction = 'asc';
            }
        }

        setPage(0);
        setSort(sortCopy);

        getListings(rowsPerPage, 0, undefined, sortCopy);
    }

    const getActionColors = (actionType: ScheduledJobAction.ActionType) => {
        switch (actionType) {
            case ScheduledJobAction.ActionType.PICKUP: return {
                backgroundColor: '#CEDCF0',
                color: '#143D7A'
            }
            case ScheduledJobAction.ActionType.DROPOFF: return {
                backgroundColor: '#d9f7be',
                color: '#135200'
            }
            default: return {
                backgroundColor: '#CEDCF0',
                color: '#143D7A'
            }
        }
    }

    const getListingStatus = (status: HaulingListing.ListingStatus): string => {
        switch (status) {
            case HaulingListing.ListingStatus.COMPLETE: 
                return 'Complete'
            case HaulingListing.ListingStatus.POSTED:
                return 'Posted'
            default:
                return 'Posted'
        }
    }

    const getDateFromSchedule = (schedule: ScheduledJobAction[]): string => {
        const firstAction = schedule.find((scheduledAction) => {
            return scheduledAction.getOrder() === 0;
        })

        if (!firstAction) {
            return 'Unknown'
        }

        const date = firstAction.getScheduledactiontime()!.toDate();

        return date.toLocaleDateString('en-US');
    }

    const createListing = (listing?: HaulingListing) => {
        const path = '/listings/create';

        history.push(path);;
    }

    const viewListing = (listing: HaulingListing) => {
        const path = `/listings/${listing.getId()}` 

        history.push(path, {listing});
    }

    const duplicateListing = (listing: HaulingListing) => {
        const path = '/listings/create';

        listing.setId(0);
        listing.setAttachmentsList([]);
        history.push(path, { listing });
    }

    const viewBids = (listing: HaulingListing) => {
        const path = `/listings/${listing.getId()}/bids`;

        history.push(path, { listing });
    }

    return <Fragment>
        <NavBar activeRoute={ListingsRoute} />

        <section className="PageContainer">
            <header className="ListingsHeader">
                <div className="ListingsHeaderSearch">
                    <TextField onChange={(event) => {
                        onSearch(event.target.value);
                    }} label="Search Listings" variant="outlined" size="small" fullWidth />
                </div>

                {(props.groupType === GroupType.OPERATOR || props.groupType === GroupType.SERVICE) && <div className="ListingsHeaderButton">
                    <Button variant="contained" color="primary" onClick={() => createListing(undefined)}>
                        Create Listing
                    </Button>
                </div>}
            </header>

            <main className="ListingsContainer">
                {(loading) &&
                    <div className="ActiveJobsLoading">
                        <CircularProgress></CircularProgress>
                    </div>
                }

                {(!loading) && <TableContainer className="ListingsTable" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel active={sort.active === QueryRequest.SortParameter.NAME} direction={sort.direction} onClick={() => { handleSort(QueryRequest.SortParameter.NAME) }}>
                                        Listing Name
                                    </TableSortLabel>
                                </TableCell>
                                {(props.groupType === GroupType.HAULER || props.userAdmin === true) && <TableCell>Company</TableCell>}
                                <TableCell>Locations</TableCell>
                                <TableCell>
                                    <TableSortLabel active={sort.active === QueryRequest.SortParameter.DATE} direction={sort.direction} onClick={() => { handleSort(QueryRequest.SortParameter.DATE) }}>
                                        Scheduled Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Listing Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listings.map((listing: HaulingListing) => (
                                <TableRow key={listing.getId()}>
                                    <TableCell>{listing.getItem()}</TableCell>
                                    {(props.groupType === GroupType.HAULER || props.userAdmin === true) &&
                                        <TableCell>
                                            <div className="ListingsRowCompany">
                                                <img className="ListingsRowCompanyImage" src={listing.getCreatorgroup()?.getPresignedsvglogourl()} />{listing.getCreatorgroup()?.getName()}
                                            </div>
                                        </TableCell>}
                                    <TableCell>
                                        <div className="ListingsPillsContainer">
                                            {listing.getScheduledjobactionsList().map(((action: ScheduledJobAction) => (
                                                <Pill
                                                    key={action.getId()}
                                                    name={action.getSite()?.getName() || 'N/A'}
                                                    color={getActionColors(action.getActiontype()).color}
                                                    backgroundColor={getActionColors(action.getActiontype()).backgroundColor} />
                                            )))}
                                        </div>
                                    </TableCell>
                                    <TableCell>{getDateFromSchedule(listing.getScheduledjobactionsList())}</TableCell>
                                    <TableCell>{getListingStatus(listing.getStatus())}</TableCell>
                                    <TableCell>
                                        {(props.groupType !== GroupType.HAULER) &&
                                            <div className="ListingsActionContainer">
                                                <span className="ListingsActionLink" onClick={() => duplicateListing(listing)}>Duplicate</span>
                                                <span className="ListingsActionLink" onClick={() => viewBids(listing)}>Review Bids</span>
                                                <span className="ListingsActionLink" onClick={() => viewListing(listing)}>Details</span>
                                            </div>
                                        }
                                        {(props.groupType === GroupType.HAULER) &&
                                            <div className="ListingsActionContainer">
                                                <span className="ListingsActionLink" onClick={() => viewBids(listing)}>Place Bid</span>
                                                <span className="ListingsActionLink" onClick={() => viewListing(listing)}>Details</span>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={listingCount || 10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage} />
                            </TableRow>
                            </TableFooter>
                    </Table>
                </TableContainer>
                }
            </main>
        </section>
    </Fragment >
}

function mapState(state: any) {
    const { authentication } = state;
    return {
        token: authentication.token,
        groupType: authentication.groupType,
        userAdmin: authentication.userAdmin
    };
}

const actionCreators = {
    displayErrorAlert: alertActions.error
};

const connectedApp = connect(mapState, actionCreators)(Listings);

export { connectedApp as Listings };