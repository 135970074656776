import AuthService from '../services/auth.service';
import { UserActionTypes, UserTypes } from '../_types/user.types';

let userIsAuthenticated = AuthService.isUserAuthenticated();
const user = AuthService.getUser();
const userId = AuthService.getUserId();
const userAdmin = AuthService.getUserAdmin();
const token = AuthService.getToken();
const group = AuthService.getGroup();
const groupType = AuthService.getGroupType();
const groupId = AuthService.getGroupId();
const groupFeeRate = AuthService.getGroupFeeRate();

const initialState = userIsAuthenticated ? { loggedIn: true, user, userId, userAdmin, token, group, groupType, groupId, groupFeeRate} : {};

export function authentication(state = initialState, action: UserActionTypes) {
    switch(action.type) {
        case UserTypes.LOGIN_REQUEST:
            return {
                loggingIn: true,
            };
        case UserTypes.LOGIN_SUCCESS:
            return {
                loggingIn: false,
                loggedIn: true,
                user: action.payload.user,
                userId: action.payload.userId,
                userAdmin: action.payload.userAdmin,
                token: action.payload.token,
                group: action.payload.group,
                groupType: action.payload.groupType,
                groupId: action.payload.groupId,
                groupFeeRate: action.payload.groupFeeRate
            };
        case UserTypes.LOGIN_FAILURE:
            return {};
        case UserTypes.DISPLAY_TERMS:
            return {
                tempUser: action.payload,
                displayingTerms: true
            }
        case UserTypes.LOGOUT:
            return {};
        default: 
            return state;
    }
}