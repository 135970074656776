import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Switch, BrowserRouter, Route } from 'react-router-dom';
import { Login } from './pages/login/Login';
import { Listings } from './pages/listings/Listings';
import { EditListing } from './pages/listings/EditListing';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { alertActions } from './_actions/alert.actions';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { AlertActionTypes, AlertTypes } from './_types/alert.types';

import PrivateRoute from './components/routes/PrivateRoute';
import LoginRoute from './components/routes/LoginRoute';
import { FieldTickets } from './pages/field-tickets/FieldTickets.js';
import { AdminPage } from './pages/admin/Admin.js';
import Dashboard from './pages/dashboard/Dashboard';
import { Jobs } from './pages/listings/Jobs';
import { Bids } from './pages/listings/Bids';
import { ActiveJobs } from './pages/jobs/ActiveJobs';
import { Profile } from './pages/profile/Profile';
import { Registration } from './pages/login/Registration';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type Props = {
  alert: AlertActionTypes,
  userAdmin: Boolean,
}

type AlertSeverityTypes = "success" | "info" | "warning" | "error" | undefined;

const App = (props: Props) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.alert.message) {
      setOpen(true);
    }
  }, [props.alert])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const getAlertType = (alert: AlertActionTypes): AlertSeverityTypes => {
    if (alert.type === AlertTypes.SUCCESS) {
      return "success";
    } else if (alert.type === AlertTypes.ERROR) {
      return "error";
    }
  }

  return (

    <Fragment>
      {
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={getAlertType(props.alert)}>
            {props.alert.message}
          </Alert>
        </Snackbar>}

      <BrowserRouter>
        <Switch>
          <LoginRoute path='/login' component={Login}></LoginRoute>
          <Route path='/r/:token'>
            <Registration />
          </Route>
          <PrivateRoute exact path='/listings' component={Listings} />
          <PrivateRoute exact path='/listings/create' component={EditListing} />
          <PrivateRoute exact path='/listings/:id' component={EditListing} />
          <PrivateRoute exact path='/listings/:id/bids' component={Bids} />
          <PrivateRoute exact path='/listings/:id/jobs' component={Jobs} />
          <PrivateRoute exact path='/jobs' component={ActiveJobs} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/field-tickets' component={FieldTickets} />
          <PrivateRoute exact path='/field-tickets/:id' component={FieldTickets} />
          {props.userAdmin ? <PrivateRoute exact path='/admin' component={AdminPage} /> : null }
          <PrivateRoute exact path='/profile' component={Profile}/>
          <PrivateRoute exact path='/unsubscribe' component={Profile}/>
          <Redirect from='*' to='/login' />
        </Switch>
      </BrowserRouter>
    </Fragment>

  );
}

function mapState(state: any) {
  const { authentication, alert } = state;
  return { alert: alert, userAdmin: authentication.userAdmin };
}

const actionCreators = {
  clearAlerts: alertActions.clear
}

const connectedApp = connect(mapState, actionCreators)(App);

export default connectedApp;
