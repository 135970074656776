import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import logo from '../../assets/bondway-primary.svg';
import AuthService from '../../services/auth.service';
import { alertActions } from '../../_actions/alert.actions';

type Props = {
    displayErrorAlert: any,
    displaySuccessAlert: any
}

const Registration = (props: Props) => {
    const history = useHistory();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    const urlParams: { token: string } = useParams();

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFirstName(event.target.value);
    }

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLastName(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setConfirmPassword(event.target.value);
    }

    const toggleShowPassword = (): void => {
        setShowPassword(!showPassword);
    }

    const toggleShowPasswordConfirmation = (): void => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
    }

    const register = (event: React.FormEvent): void => {
        event.preventDefault();

        if (!firstName || !lastName || !password || !confirmPassword) {
            props.displayErrorAlert('Form must be filled out before it can be submitted');
            return;
        }

        if (password !== confirmPassword) {
            props.displayErrorAlert('Password confirmation does not match the original password');
            return;
        }

        console.log(urlParams.token);

        const name = firstName + ' ' + lastName;

        AuthService.register(urlParams.token, name, password)
            .then((success) => {
                props.displaySuccessAlert('User successfully registered');
                history.push('/login');
            }, (err) => {
                console.log(err);
                props.displayErrorAlert('Error registering user, please contact an administrator');
            })
    }

    return <Fragment>
        <header className="LoginHeader">
            <img className="LoginLogo" src={logo} alt='Bondway Logo'></img>
        </header>

        <main className="LoginPageContainer">
            <section className="RegisterContainer">
                <form noValidate onSubmit={register}>
                    <h1 className="LoginHeading">Register For BondWay</h1>

                    <div className="LoginFormInput">
                        <TextField label="First Name" variant="outlined" size="small" fullWidth
                            value={firstName}
                            onChange={handleFirstNameChange}
                        />
                    </div>

                    <div className="LoginFormInput">
                        <TextField label="Last Name" variant="outlined" size="small" fullWidth
                            value={lastName}
                            onChange={handleLastNameChange}
                        />
                    </div>

                    <div className="LoginFormInput">
                        <TextField label="Password" variant="outlined" size="small" type={showPassword ? 'text' : 'password'} fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </div>

                    <div className="LoginFormInput">
                        <TextField label="Confirm Password" variant="outlined" type={showPasswordConfirmation ? 'text' : 'password'} size="small" fullWidth
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPasswordConfirmation}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        size="small"
                                    >
                                        {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </div>

                    <div className="LoginFormActions">
                        <Button variant="contained" color="primary" type="submit">Register</Button>
                    </div>
                </form>

            </section>
        </main>
    </Fragment>
}

function mapState(state: any) {
    return {}
}

const actionCreators = {
    displayErrorAlert: alertActions.error,
    displaySuccessAlert: alertActions.success
};

const connectedRegistration = connect(mapState, actionCreators)(Registration);

export { connectedRegistration as Registration };
