const actionTypes = [
    { value: 0, label: 'Pick Up' },
    { value: 1, label: 'Drop Off' }
]

const jobStatusTypes = [
    {value: 0, label: 'Unassigned'},
    {value: 1, label: 'Not Started'},
    {value: 2, label: 'In Progress'},
    {value: 3, label: 'Complete'}
]

const JobMappings = {
    getActionType: (val: number): string | undefined => {
        return actionTypes.find(actionType => actionType.value === val)?.label;
    },
    
    getStatusType: (val: number): string | undefined => {
        return jobStatusTypes.find(statusType => statusType.value === val)?.label;
    },
}

export default JobMappings;