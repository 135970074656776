import { AlertTypes } from '../_types/alert.types';

export const alertActions = {
    success,
    error,
    clear
};

function success(message: string) {
    return { type: AlertTypes.SUCCESS, message };
}

function error(message: string) {
    return { type: AlertTypes.ERROR, message };
}

function clear() {
    return { type: AlertTypes.CLEAR };
}