import React from 'react';
import './ImageGallery.css';
import ImgsViewer from 'react-images-viewer'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currImg: 0,
    }

    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrev = this.gotoPrev.bind(this)
    this.gotoImg = this.gotoImg.bind(this)
    this.handleClickImg = this.handleClickImg.bind(this)
    this.closeImgsViewer = this.closeImgsViewer.bind(this)
    this.openImgsViewer = this.openImgsViewer.bind(this)
    this.renderGallery = this.renderGallery.bind(this)
    this.imageView = this.imageView.bind(this)
  }

  openImgsViewer (index, event) {
    event.preventDefault()
    this.setState({
      currImg: 0,
      isOpen: true,
    })
  }
  closeImgsViewer () {
    this.setState({
      currImg: 0,
      isOpen: false,
    })
  }
  gotoPrev () {
    this.setState({
      currImg: this.state.currImg - 1
    })
  }
  gotoNext () {
    this.setState({
      currImg: this.state.currImg + 1
    })
  }
  gotoImg (index) {
    this.setState({
      currImg: index
    })
  }
  handleClickImg () {
    if (this.state.currImg === this.props.imgs.length - 1) return
    this.gotoNext()
  }
  
  imageView(image) {
    return (
      <div key={image.src} onClick={(e) => this.openImgsViewer(image, e)}>
        <img src={image.src} className={css(classes.source)} />
      </div>
    )
  }

  renderGallery () {
    if (!this.props.imgs) return

    const gallery = this.props.imgs.map(this.imageView)

    return (
      <div className="FillBox">
        {gallery}
      </div>
    )
  }
  render () {
    return (
      <div className="Box">
        {this.renderGallery()}
        <ImgsViewer
          backdropCloseable
          currImg={this.state.currImg}
          imgs={this.props.imgs}
          isOpen={this.state.isOpen}
          onClickImg={this.handleClickImg}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrev}
          onClickThumbnail={this.gotoImg}
          onClose={this.closeImgsViewer}
          preventScroll={this.props.preventScroll}
          showThumbnails={this.props.showThumbnails}
          spinner={this.props.spinner}
          spinnerColor={this.props.spinnerColor}
          spinnerSize={this.props.spinnerSize}
          theme={this.props.theme}
        />
      </div>
    )
  }
}

ImageGallery.displayName = 'Image Gallery'
ImageGallery.propTypes = {
  preventScroll: PropTypes.bool,
  spinner: PropTypes.func,
  spinnerColor: PropTypes.string,
  spinnerSize: PropTypes.number,
  theme: PropTypes.object,
  heading: PropTypes.string,
  imgs: PropTypes.array,
  showThumbnails: PropTypes.bool,
  subheading: PropTypes.string,
}

const gutter = {
  small: 2,
  large: 4,
}

const classes = StyleSheet.create({
  // actual <img />
  source: {
    border: 0,
    height: '100px',
    width: 'auto',
    marginLeft: '8px',
    marginRight: '8px',
    cursor: 'pointer',
  },
})


export default ImageGallery