export const AlertTypes = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
}

interface AlertSuccessAction {
    type: typeof AlertTypes.SUCCESS,
    message: string
}

interface AlertErrorAction {
    type: typeof AlertTypes.ERROR,
    message: string
}

interface AlertClearAction {
    type: typeof AlertTypes.CLEAR,
    message: null
}

export type AlertActionTypes = AlertSuccessAction | AlertErrorAction | AlertClearAction;