import './Pill.scss';

type Props = {
    name: string,
    color: string,
    backgroundColor: string
}

const Pill = (props: Props) => {

    return <div className="Pill" style={{
        'borderColor': props.color,
        'backgroundColor': props.backgroundColor,
        'color': props.color
    }}>
        {props.name}
    </div>
}

export default Pill;