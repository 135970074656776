import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import { ArrowBack, LocalShipping, Person } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NavBar } from '../../components/navbar/Navbar';
import { HaulingListing, HaulingListingResponse, Job, JobActionRecord, ScheduledJobAction, User, UserListResponse } from '../../proto/model_pb';
import ListingsService from '../../services/listings.service';
import { alertActions } from '../../_actions/alert.actions';
import JobMappings from '../../_mappings/job.mappings';
import ListingMappings from '../../_mappings/listing.mappings';
import { ListingsRoute } from '../../_types/nav.types';
import { GroupType } from '../../_types/user.types';
import EditListingNav from './EditListingNav';

type Props = {
    token: string,
    groupType: GroupType,
    groupId: number,
    displayErrorAlert: any,
    displaySuccessAlert: any,
    history: any
}

const Jobs = (props: Props) => {
    const history = useHistory();

    const [listing, setListing] = useState<HaulingListing>(props.history?.location?.state?.listing);
    const [jobs, setJobs] = useState<Job[][]>([]);
    const [activeHauler, setActiveHauler] = useState<number>(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [drivers, setDrivers] = useState<User[]>([]);
    const [jobForDialog, setJobForDialog] = useState<Job | undefined>(undefined);
    const [selectedDriver, setSelectedDriver] = useState<User | undefined>(undefined);

    const urlParams: { id: string } = useParams();

    useEffect(() => {
        if (!isNaN(+urlParams.id)) {
            getListing(+urlParams.id);
        } else {
            history.push('/listings');
        }
    }, [])

    useEffect(() => {
        if (!listing || !listing.getId) {
            return;
        } else {
            const sortedJobs = listing.getAssignedjobsList().sort((a, b) => {
                if (a.getHaulergroupid() < b.getHaulergroupid()) {
                    return -1;
                }

                if (a.getHaulergroupid() > b.getHaulergroupid()) {
                    return 1;
                }

                return 0;
            })

            const jobsByHauler = sortedJobs.reduce(function (r: any, a: Job, i: any) {
                if (!i || r[r.length - 1][0].getHaulergroupid() !== a.getHaulergroupid()) {
                    return r.concat([[a]]);
                }
                r[r.length - 1].push(a);
                return r;
            }, []);

            setJobs(jobsByHauler);

            if (props.groupType === GroupType.HAULER) {
                ListingsService.getDrivers(props.token, props.groupId)
                    .then(
                        (response: UserListResponse) => {
                            setDrivers(response.getUserList());
                        },
                        err => {
                            console.error('Could not load drivers');
                        }
                    )
            }
        }

    }, [listing])

    const getListing = (id: number) => {
        ListingsService.getListing(props.token, id)
            .then((response: HaulingListingResponse) => {
                const haulingListing = response.getListing();

                if (haulingListing) {
                    setListing(haulingListing);
                } else {
                    props.displayErrorAlert('Encountered error when loading listing');
                    history.push('/listings');
                }
            }, (err) => {
                props.displayErrorAlert('Encountered error when loading listing');
                history.push('/listings');
            })
    }

    const viewListings = () => {
        const path = '/listings';

        history.push(path, { listing })
    }

    const cancelJob = (activeJob: Job, jobIndex: number) => {
        if (window.confirm('Are you sure you want to cancel this job?')) {
            ListingsService.cancelJob(props.token, activeJob.getId())
                .then(
                    () => {
                        let jobsList = listing?.getAssignedjobsList();

                        jobsList = jobsList!.filter(job => {
                            return job.getId() !== activeJob.getId()
                        });

                        listing?.setAssignedjobsList(jobsList);
                        setListing(listing);

                        jobs[activeHauler].splice(jobIndex, 1);

                        props.displaySuccessAlert('Job successfully cancelled');
                    },
                    () => {
                        props.displayErrorAlert('Unable to cancel job. Please contact an administrator.')
                    }
                )
        }
    }

    const onDialogOpen = (activeJob: Job) => {
        setJobForDialog(activeJob);
        setDialogOpen(true);
    }

    const onDialogClose = () => {
        setJobForDialog(undefined);
        setDialogOpen(false);
    }

    const onDriverSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const driver = drivers.find(driver => {
            return driver.getId() === +event.target.value;
        })
        setSelectedDriver(driver);
    }

    const assignDriver = () => {
        if (selectedDriver && jobForDialog) {
            ListingsService.assignDriver(props.token, selectedDriver.getId(), jobForDialog.getId())
                .then(
                    (response: any) => {
                        jobForDialog.setInflatedriver(selectedDriver);
                        setSelectedDriver(undefined);
                        props.displaySuccessAlert('Driver successfully assigned');
                        setJobForDialog(undefined);
                        setDialogOpen(false);
                    },
                    err => {
                        props.displayErrorAlert('Could not assign driver to job');
                        setJobForDialog(undefined);
                        setDialogOpen(false);
                    }
                )
        }
    }

    const getVehicleType = (listing?: HaulingListing): string | undefined => {
        if (!listing) { return undefined }

        return ListingMappings.getVehicleType(listing.getVehicle());
    }

    const getTrailerType = (listing?: HaulingListing): string | undefined => {
        if (!listing) { return undefined }

        return ListingMappings.getTrailerType(listing.getTowload());
    }

    const getJobStatus = (job: Job): string | undefined => {
        if (!job) { return undefined }

        return JobMappings.getStatusType(job.getStatus());
    }

    const getJobCurrentDuration = (job: Job): string => {
        if (!job || !job.getStartedat()) { return 'Not Started' };

        const startedAt = +job.getStartedat()!.toDate();
        const currentTime = +new Date();
        let timeDiff: number;

        if (job && job.getEndedat()) {
            const endedAt = +job.getEndedat()!.toDate();
            timeDiff = (endedAt - startedAt)
        } else {
            timeDiff = (currentTime - startedAt);
        }

        const diffHours = Math.floor(timeDiff / 86400000 / 3600000);
        const diffMins = Math.floor(((timeDiff % 86400000) % 3600000) / 60000);

        if (diffHours === 0) {
            return `${diffMins} mins`
        } else {
            return `${diffHours} hrs ${diffMins} mins`;
        }
    }

    const getJobNextStep = (job: Job, listing: HaulingListing): string | undefined => {
        if (!job || !job.getStartedat()) { return 'Job Not Started' }

        const actionRecord: JobActionRecord[] = job.getActionrecordsList();
        const scheduledJobActions: ScheduledJobAction[] = listing.getScheduledjobactionsList();
        let nextAction: ScheduledJobAction | undefined;

        scheduledJobActions.every((scheduledAction, index) => {
            if (!actionRecord[index]) {
                nextAction = scheduledAction;
                return false;
            }

            return true;
        })

        if (!nextAction) {
            return 'Job Completed'
        }

        const actionType = nextAction.getActiontype();
        const siteName = nextAction.getSite()?.getName();

        switch (actionType) {
            case ScheduledJobAction.ActionType.PICKUP:
                return `Picking Up From ${siteName}`
            case ScheduledJobAction.ActionType.DROPOFF:
                return `Dropping Off At ${siteName}`
            default:
                return `Job Status Unknown`
        }
    }

    const viewTicket = (job: Job) => {
        if (job && job.getFieldticketid()) {
            history.push(`/field-tickets/${job.getFieldticketid()}`);
        }
    }

    return <Fragment>
        <NavBar activeRoute={ListingsRoute} />

        <main className="PageContainer EditListingsContainer">
            <header className="EditListingsHeader">
                <div className="EditListingsHeaderReturn" onClick={viewListings}>
                    <ArrowBack />
                    View Listings
                </div>
            </header>

            <EditListingNav navState="jobs" listing={listing} groupType={props.groupType} />

            <div className="ListingJobsContainer">

                {(jobs.length > 0) &&
                    <div>
                        {(props.groupType !== GroupType.HAULER) && (
                            <div>
                                {jobs.map((job, index) => (
                                    <Fragment>
                                        <div className="ListingJobsNav">
                                            <div className={`ListingJobsNavCell ${activeHauler === index ? "ListingJobsNavCellActive" : ""}`} onClick={() => setActiveHauler(index)}>
                                                <img className="ListingJobGroupIcon" src={job[0]?.getInflatehaulergroup()?.getPresignedsvglogourl()} alt="" />
                                                {job[0]?.getInflatehaulergroup()?.getName()}
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                        )}

                        <div className="ListingJobsCardContainer">
                            {jobs[activeHauler].map((activeJob, index) => (
                                <div className="ListingJobsCard">
                                    <div className="ListingJobsCardHeader">
                                        {listing?.getItem()}
                                        {(!!activeJob.getFieldticketid() && activeJob.getStatus() === Job.Status.ENDED) && <span className="ListingJobsCardHeaderLink" onClick={() => {viewTicket(activeJob)}}>View Ticket</span>}
                                    </div>

                                    <div className="ListingJobsCardSection">
                                        <div className="ListingJobsCardTopSection">
                                            <div className="ListingJobsCardTopSectionHeader">Truck Overview</div>
                                            <div className="ListingJobsCardTopSectionContent">
                                                <div className="ListingJobsCardTopSectionPill">{getVehicleType(listing) || 'N/A'}</div>
                                                <div className="ListingJobsCardTopSectionPill">{getTrailerType(listing) || 'N/A'}</div>
                                            </div>
                                        </div>

                                        <div className="ListingJobsCardTopSection">
                                            <div className="ListingJobsCardTopSectionHeader">Route Overview</div>
                                            <div className="ListingJobsCardTopSectionContent">
                                                <div className="ListingJobsCardTopSectionPill">{getJobCurrentDuration(activeJob)}</div>
                                                <div className="ListingJobsCardTopSectionPill">{getJobStatus(activeJob)}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ListingJobsCardSection">

                                        <div className="ListingJobsCardBottomSection">
                                            <LocalShipping color="primary" />

                                            <div className="ListingJobsCardBottomSectionDetails">
                                                <div className="ListingJobsCardBottomSectionDetailsPrimary">
                                                    {getJobNextStep(activeJob, listing)}
                                                </div>
                                                {/* <div className="ListingJobsCardBottomSectionDetailsSecondary">
                                                    Estimated Arrival <span className="bold">{ }</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ListingJobsCardSection">
                                        <div className="ListingJobsCardBottomSection">
                                            <Person color="primary" />

                                            <div className="ListingJobsCardBottomSectionDetails">
                                                <div className="ListingJobsCardBottomSectionDetailsPrimary">
                                                    {activeJob.getInflatedriver()?.getName() || 'Not Assigned'}
                                                </div>
                                                <div className="ListingJobsCardBottomSectionDetailsSecondary">
                                                    {activeJob.getInflatedriver()?.getPhonenumber() || 'Driver needs assigned'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ListingJobsCardActions">
                                        {(activeJob.getStatus() !== Job.Status.ENDED) && <div className="ListingJobsCardAction" onClick={() => cancelJob(activeJob, index)}>
                                            <span>Cancel Job</span>
                                        </div>
                                        }
                                        {(!!(activeJob.getDriveruserid() && props.groupType === GroupType.HAULER)) && <div className="ListingJobsCardAction">
                                            <span>Call Driver</span>
                                        </div>
                                        }
                                        {(!!(!activeJob.getDriveruserid() && props.groupType === GroupType.HAULER)) && <div onClick={() => onDialogOpen(activeJob)} className="ListingJobsCardAction">
                                            <span>Assign Driver</span>
                                        </div>
                                        }
                                        {/* <div className="ListingJobsCardAction">Detail View</div> */}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Dialog fullWidth maxWidth="sm" open={dialogOpen} onClose={() => onDialogClose()}>
                            <DialogTitle>Assign Driver</DialogTitle>
                            <DialogContent>
                                <TextField select label="Drivers" onChange={onDriverSelected} variant="outlined" fullWidth size="small">
                                    {drivers.map((driver) => (
                                        <MenuItem key={driver.getId()} value={driver.getId()}>
                                            {driver.getName()}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => onDialogClose()} variant="contained">Cancel</Button>
                                <Button onClick={() => assignDriver()} variant="contained" color="primary">Assign</Button>
                            </DialogActions>
                        </Dialog>
                    </div>}

                {(!jobs || jobs.length === 0) &&
                    <div className="ListingJobsNoActiveJobs">
                        No Active Jobs
                    </div>
                }
            </div>
        </main>
    </Fragment>

}

function mapState(state: any) {
    const { authentication } = state;
    return {
        token: authentication.token,
        groupType: authentication.groupType,
        groupId: authentication.groupId
    };
}

const actionCreators = {
    displaySuccessAlert: alertActions.success,
    displayErrorAlert: alertActions.error
};

const connectedApp = connect(mapState, actionCreators)(Jobs);

export { connectedApp as Jobs };