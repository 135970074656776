import './Navbar.scss';
import logo from '../../assets/bondway-light.svg';
import { MailOutline, ExpandMore, ExpandLess, ExitToApp } from '@material-ui/icons'
import { userActions } from '../../_actions/user.actions';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { NavPages, NavRoute, NavTabs } from '../../_types/nav.types';
import React, { useState, Fragment } from 'react';
import { Button } from '@material-ui/core';

type Props = {
    activeRoute: NavRoute | undefined
    userAdmin: boolean,
    logout: any,
}

const NavBar = (props: Props) => {
    const setActiveTab = (tabName: NavTabs): void => {
        if (tabName === activeTab) {
            _setActiveTab(undefined);
            return;
        }

        _setActiveTab(tabName);
    }

    const [activeTab, _setActiveTab] = useState(props.activeRoute?.parentTab);

    return <nav className="NavContainer">
        <header className="NavHeader">
            <img className="NavLogo" src={logo} alt='Bondway Logo'></img>
        </header>

        <section className="NavSectionContainer">
            <ul className="NavSection">
                <li>
                    <div
                        className={`NavCategory ${activeTab === NavTabs.ORDERS ? 'active' : ''}`}
                        onClick={() => setActiveTab(NavTabs.ORDERS)}>
                        <div className="NavCategoryTitle">
                            <MailOutline className="NavCategoryIcon" />
                            <span className="NavCategoryString">{NavTabs.ORDERS}</span>
                        </div>
                        {(activeTab === NavTabs.ORDERS) ?
                            <ExpandLess className="NavSectionExpandIcon" /> :
                            <ExpandMore className="NavSectionExpandIcon" />
                        }
                    </div>

                    {(activeTab === NavTabs.ORDERS) &&
                        <ul className="NavSubcategoryContainer">
                            <li className="NavSubcategory">Haul Sourcing</li>
                            <ul className="NavSubcategoryItemContainer">
                                <li className={`NavSubcategoryItem ${props.activeRoute?.pageName === NavPages.LISTINGS ? 'active' : ''}`}>
                                    <NavLink className="NavLink" to="/listings">{NavPages.LISTINGS}</NavLink>
                                </li>
                                <li className={`NavSubcategoryItem ${props.activeRoute?.pageName === NavPages.ACTIVE_JOBS ? 'active' : ''}`}>
                                    <NavLink className="NavLink" to="/jobs">{NavPages.ACTIVE_JOBS}</NavLink>
                                </li>
                            </ul>


                            <li className="NavSubcategory">Billing</li>
                            <ul className="NavSubcategoryItemContainer">
                                {/* <li className={`NavSubcategoryItem ${activeRoute?.pageName === NavPages.DASHBOARD ? 'active' : ''}`}>
                                <NavLink className="NavLink" to="/dashboard">{NavPages.DASHBOARD}</NavLink>
                            </li> */}
                                <li className={`NavSubcategoryItem ${props.activeRoute?.pageName === NavPages.FIELD_TICKETS ? 'active' : ''}`}>
                                    <NavLink className="NavLink" to="/field-tickets">{NavPages.FIELD_TICKETS}</NavLink>
                                </li>
                            </ul>

                            {
                                props.userAdmin ?
                                    <Fragment>
                                        <li className="NavSubcategory">Admin Pages</li>
                                        <ul className="NavSubcategoryItemContainer">
                                            <li className={`NavSubcategoryItem ${props.activeRoute?.pageName === NavPages.ADMIN_REPORTS ? 'active' : ''}`}>
                                                <NavLink className="NavLink" to="/admin">{NavPages.ADMIN_REPORTS}</NavLink>
                                            </li>
                                        </ul>
                                    </Fragment>
                                    : null
                            }

                            <ul className="NavSubcategoryContainer">
                                <li className="NavSubcategory">User Profile</li>
                                <ul className="NavSubcategoryItemContainer">
                                    <li className={`NavSubcategoryItem ${props.activeRoute?.pageName === NavPages.USER_PROFILE ? 'active' : ''}`}>
                                        <NavLink className="NavLink" to="/profile">{NavPages.USER_PROFILE}</NavLink>
                                    </li>
                                </ul>
                            </ul>
                        </ul>
                    }
                </li>
                {/* <li className={`NavCategory ${activeTab === NavTabs.EQUIPMENT ? 'active' : ''}`}
                    onClick={() => setActiveTab(NavTabs.EQUIPMENT)}>
                    <div className="NavCategoryTitle">
                        <MailOutline className="NavCategoryIcon" />
                        <span className="NavCategoryString">{NavTabs.EQUIPMENT}</span>
                    </div>
                    {(activeTab === NavTabs.EQUIPMENT) ?
                        <ExpandLess className="NavSectionExpandIcon" /> :
                        <ExpandMore className="NavSectionExpandIcon" />
                    }
                </li> */}
                {/* <li>
                    <div className={`NavCategory ${activeTab === NavTabs.PROFILE ? 'active' : ''}`}
                        onClick={() => setActiveTab(NavTabs.PROFILE)}>
                        <div className="NavCategoryTitle">
                            <MailOutline className="NavCategoryIcon" />
                            <span className="NavCategoryString">{NavTabs.PROFILE}</span>
                        </div>
                        {(activeTab === NavTabs.PROFILE) ?
                            <ExpandLess className="NavSectionExpandIcon" /> :
                            <ExpandMore className="NavSectionExpandIcon" />
                        }
                    </div>

                    {(activeTab === NavTabs.PROFILE) &&
                        <ul className="NavSubcategoryContainer">
                            <li className="NavSubcategory">User Profile</li>
                            <ul className="NavSubcategoryItemContainer">
                                <li className={`NavSubcategoryItem ${props.activeRoute?.pageName === NavPages.USER_PROFILE ? 'active' : ''}`}>
                                    <NavLink className="NavLink" to="/profile">{NavPages.USER_PROFILE}</NavLink>
                                </li>
                            </ul>
                        </ul>
                    }
                </li> */}
            </ul>
        </section>

        <footer className="NavFooter">
            <Button
                style={{
                    width: '100%', height: '100%',
                    borderRadius: 0,
                    textTransform: 'none'
                }}
                color="primary"
                variant="contained"
                onClick={props.logout}>
                Logout
                <ExitToApp className="NavFooterLogoutIcon" fontSize="small"></ExitToApp>
            </Button>
        </footer>

    </nav>
}

function mapState(state: any) {
    const { authentication } = state;
    return { userAdmin: authentication.userAdmin };
}

const actionCreators = {
    logout: userActions.logout
}

const connectedNavBar = connect(mapState, actionCreators)(NavBar)

export { connectedNavBar as NavBar };