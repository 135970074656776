import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component, isAuthenticated, ...rest}: any) => {
    const userIsAuthed = useSelector((state: any) => state.authentication.loggedIn);

    const routeComponent = (props: any) => (
        userIsAuthed
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/login'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

export default PrivateRoute;