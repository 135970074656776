import BigNumber from 'bignumber.js';
import { Money } from '../proto/model_pb';

const UtilService = {
    convertPbMoneyToBigNumber(pbMoney?: Money): BigNumber {
        if (!pbMoney) { return new BigNumber(0) }
        
        return new BigNumber(pbMoney.getUnits() + '.' + UtilService.pad(Math.abs(pbMoney.getNanos()), 9))
    },

    pad(num: number, size: number) {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    },
    msToTime(duration: number): number {
        // Round to nearest second
        const minutes = Math.floor((duration / (1000 * 60)) % 60)
        const hours = Math.floor(duration / (1000 * 60 * 60))

        return hours + (minutes / 60);
    },
     formatMoney(number: number, decPlaces: number): string{
        var decSep = "."
        var thouSep = ","
        var sign = number < 0 ? "-" : "";
        var i: string = String(parseInt((number = Math.abs(Number(number) || 0)).toFixed(decPlaces)));
        var j: number = (j = i.length) > 3 ? j % 3 : 0;
        
        return sign +
          (j ? i.substr(0, j) + thouSep : "") +
          i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
          (decPlaces ? decSep + Math.abs(number - parseInt(i)).toFixed(decPlaces).slice(2) : "");
      }  
}

export default UtilService;