export enum NavTabs {
    ORDERS = 'Orders',
    EQUIPMENT = 'Equipment',
    PROFILE = 'Profile',
}

export enum NavPages {
    LISTINGS = 'Your Listings',
    ACTIVE_JOBS = 'Active Jobs',
    DASHBOARD = 'Dashboard',
    FIELD_TICKETS = 'Field Tickets',
    ADMIN_REPORTS = 'Reports',
    USER_PROFILE = 'Profile'
}

export interface NavRoute {
    parentTab: NavTabs,
    pageName: NavPages,
    route: string,
}

export const NavRoutes: NavRoute[] = [
    {
        parentTab: NavTabs.ORDERS,
        pageName: NavPages.LISTINGS,
        route: '/listings'
    },
    {
        parentTab: NavTabs.ORDERS,
        pageName: NavPages.ACTIVE_JOBS,
        route: '/jobs',
    },
    {
        parentTab: NavTabs.ORDERS,
        pageName: NavPages.DASHBOARD,
        route: '/dashboard'
    },
    {
        parentTab: NavTabs.ORDERS,
        pageName: NavPages.FIELD_TICKETS,
        route: '/field-tickets'
    },
    {
        parentTab: NavTabs.ORDERS,
        pageName: NavPages.ADMIN_REPORTS,
        route: '/admin'
    },
    {
        parentTab: NavTabs.ORDERS,
        pageName: NavPages.USER_PROFILE,
        route: '/profile'
    }
]

export const ListingsRoute = NavRoutes[0];
export const JobsRoute = NavRoutes[1];
export const DashboardRoute = NavRoutes[2];
export const FieldTicketsRoute = NavRoutes[3];
export const AdminReportsRoute = NavRoutes[4];
export const ProfileRoute = NavRoutes[5];