/* eslint-disable */
//@ts-nocheck
// source: model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.proto.AcceptBidRequest', null, global);
goog.exportSymbol('proto.proto.AcceptBidRequest.BidAcceptance', null, global);
goog.exportSymbol('proto.proto.ApprovalRequest', null, global);
goog.exportSymbol('proto.proto.AssignDriverRequest', null, global);
goog.exportSymbol('proto.proto.AssociationRequest', null, global);
goog.exportSymbol('proto.proto.Bid', null, global);
goog.exportSymbol('proto.proto.Bid.BillingType', null, global);
goog.exportSymbol('proto.proto.BidListResponse', null, global);
goog.exportSymbol('proto.proto.BidRequest', null, global);
goog.exportSymbol('proto.proto.BidResponse', null, global);
goog.exportSymbol('proto.proto.BidSuggestion', null, global);
goog.exportSymbol('proto.proto.BidSuggestionRequest', null, global);
goog.exportSymbol('proto.proto.BillingDefaults', null, global);
goog.exportSymbol('proto.proto.BillingDefaultsRequest', null, global);
goog.exportSymbol('proto.proto.BillingDefaultsResponse', null, global);
goog.exportSymbol('proto.proto.BillingSurcharge', null, global);
goog.exportSymbol('proto.proto.ChangePasswordRequest', null, global);
goog.exportSymbol('proto.proto.CompleteJobRequest', null, global);
goog.exportSymbol('proto.proto.ConfirmPickupRequest', null, global);
goog.exportSymbol('proto.proto.Coordinates', null, global);
goog.exportSymbol('proto.proto.CreateGroupRequest', null, global);
goog.exportSymbol('proto.proto.CreateUserRequest', null, global);
goog.exportSymbol('proto.proto.DeadHeadStats', null, global);
goog.exportSymbol('proto.proto.DeadheadRouteResponse', null, global);
goog.exportSymbol('proto.proto.DeadheadRoutingRequest', null, global);
goog.exportSymbol('proto.proto.DenyRequest', null, global);
goog.exportSymbol('proto.proto.DeviceIdRequest', null, global);
goog.exportSymbol('proto.proto.EmptyRequest', null, global);
goog.exportSymbol('proto.proto.Error', null, global);
goog.exportSymbol('proto.proto.ErrorReportRequest', null, global);
goog.exportSymbol('proto.proto.ErrorResponse', null, global);
goog.exportSymbol('proto.proto.EstimateDeviationStats', null, global);
goog.exportSymbol('proto.proto.FieldTicket', null, global);
goog.exportSymbol('proto.proto.FieldTicket.TicketStatus', null, global);
goog.exportSymbol('proto.proto.FieldTicketActionRecord', null, global);
goog.exportSymbol('proto.proto.FieldTicketCharge', null, global);
goog.exportSymbol('proto.proto.FieldTicketComment', null, global);
goog.exportSymbol('proto.proto.FieldTicketCommentRequest', null, global);
goog.exportSymbol('proto.proto.FieldTicketResponse', null, global);
goog.exportSymbol('proto.proto.FieldTicketUpdateRequest', null, global);
goog.exportSymbol('proto.proto.FieldTicketsPageRequest', null, global);
goog.exportSymbol('proto.proto.FieldTicketsPageResponse', null, global);
goog.exportSymbol('proto.proto.GetOpenIdForDeveloperIdentityResponse', null, global);
goog.exportSymbol('proto.proto.Group', null, global);
goog.exportSymbol('proto.proto.Group.GroupBillingLevel', null, global);
goog.exportSymbol('proto.proto.Group.GroupType', null, global);
goog.exportSymbol('proto.proto.GroupListResponse', null, global);
goog.exportSymbol('proto.proto.GroupRequest', null, global);
goog.exportSymbol('proto.proto.HaulListingRequest', null, global);
goog.exportSymbol('proto.proto.HaulingListing', null, global);
goog.exportSymbol('proto.proto.HaulingListing.ListingStatus', null, global);
goog.exportSymbol('proto.proto.HaulingListing.PermitParty', null, global);
goog.exportSymbol('proto.proto.HaulingListing.TowLoad', null, global);
goog.exportSymbol('proto.proto.HaulingListing.Vehicle', null, global);
goog.exportSymbol('proto.proto.HaulingListingListResponse', null, global);
goog.exportSymbol('proto.proto.HaulingListingResponse', null, global);
goog.exportSymbol('proto.proto.IdListRequest', null, global);
goog.exportSymbol('proto.proto.IdRequest', null, global);
goog.exportSymbol('proto.proto.IdResponse', null, global);
goog.exportSymbol('proto.proto.IdleTimeStats', null, global);
goog.exportSymbol('proto.proto.Infraction', null, global);
goog.exportSymbol('proto.proto.InvoiceRequest', null, global);
goog.exportSymbol('proto.proto.Job', null, global);
goog.exportSymbol('proto.proto.Job.Status', null, global);
goog.exportSymbol('proto.proto.JobActionRecord', null, global);
goog.exportSymbol('proto.proto.JobActionRequest', null, global);
goog.exportSymbol('proto.proto.JobCountStats', null, global);
goog.exportSymbol('proto.proto.ListingRoutingRequest', null, global);
goog.exportSymbol('proto.proto.LoginRequest', null, global);
goog.exportSymbol('proto.proto.LoginResponse', null, global);
goog.exportSymbol('proto.proto.MissingSiteReportRequest', null, global);
goog.exportSymbol('proto.proto.Money', null, global);
goog.exportSymbol('proto.proto.PasswordResetRequest', null, global);
goog.exportSymbol('proto.proto.PendingRegistration', null, global);
goog.exportSymbol('proto.proto.PendingRegistrationsResponse', null, global);
goog.exportSymbol('proto.proto.ProvisionAttachmentRequest', null, global);
goog.exportSymbol('proto.proto.ProvisionedURLResponse', null, global);
goog.exportSymbol('proto.proto.QueryRequest', null, global);
goog.exportSymbol('proto.proto.QueryRequest.Ordering', null, global);
goog.exportSymbol('proto.proto.QueryRequest.SortParameter', null, global);
goog.exportSymbol('proto.proto.ResyncResponse', null, global);
goog.exportSymbol('proto.proto.RouteResponse', null, global);
goog.exportSymbol('proto.proto.RouteResponse.Leg', null, global);
goog.exportSymbol('proto.proto.S3Asset', null, global);
goog.exportSymbol('proto.proto.SaveWaypointsRequest', null, global);
goog.exportSymbol('proto.proto.ScheduledJobAction', null, global);
goog.exportSymbol('proto.proto.ScheduledJobAction.ActionType', null, global);
goog.exportSymbol('proto.proto.Site', null, global);
goog.exportSymbol('proto.proto.Site.Status', null, global);
goog.exportSymbol('proto.proto.SiteAccessChangeRequest', null, global);
goog.exportSymbol('proto.proto.SiteAccessPeriod', null, global);
goog.exportSymbol('proto.proto.SiteListResponse', null, global);
goog.exportSymbol('proto.proto.SiteRequest', null, global);
goog.exportSymbol('proto.proto.SiteResponse', null, global);
goog.exportSymbol('proto.proto.SiteRestrictionPeriod', null, global);
goog.exportSymbol('proto.proto.StartJobRequest', null, global);
goog.exportSymbol('proto.proto.StatPairDouble', null, global);
goog.exportSymbol('proto.proto.StatPairInt32', null, global);
goog.exportSymbol('proto.proto.StatsDashboardRequest', null, global);
goog.exportSymbol('proto.proto.StatsOperatorDashboardResponse', null, global);
goog.exportSymbol('proto.proto.StubWaypoint', null, global);
goog.exportSymbol('proto.proto.User', null, global);
goog.exportSymbol('proto.proto.User.UserType', null, global);
goog.exportSymbol('proto.proto.UserListResponse', null, global);
goog.exportSymbol('proto.proto.UserRequest', null, global);
goog.exportSymbol('proto.proto.UserResponse', null, global);
goog.exportSymbol('proto.proto.Vehicles', null, global);
goog.exportSymbol('proto.proto.Waypoint', null, global);
goog.exportSymbol('proto.proto.WaypointResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeadheadRoutingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeadheadRoutingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeadheadRoutingRequest.displayName = 'proto.proto.DeadheadRoutingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListingRoutingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListingRoutingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListingRoutingRequest.displayName = 'proto.proto.ListingRoutingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeadheadRouteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeadheadRouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeadheadRouteResponse.displayName = 'proto.proto.DeadheadRouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RouteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.RouteResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.RouteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RouteResponse.displayName = 'proto.proto.RouteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RouteResponse.Leg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RouteResponse.Leg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RouteResponse.Leg.displayName = 'proto.proto.RouteResponse.Leg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.JobActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.JobActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.JobActionRequest.displayName = 'proto.proto.JobActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatsDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatsDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatsDashboardRequest.displayName = 'proto.proto.StatsDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatsOperatorDashboardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatsOperatorDashboardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatsOperatorDashboardResponse.displayName = 'proto.proto.StatsOperatorDashboardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.EstimateDeviationStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.EstimateDeviationStats.repeatedFields_, null);
};
goog.inherits(proto.proto.EstimateDeviationStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.EstimateDeviationStats.displayName = 'proto.proto.EstimateDeviationStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.JobCountStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.JobCountStats.repeatedFields_, null);
};
goog.inherits(proto.proto.JobCountStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.JobCountStats.displayName = 'proto.proto.JobCountStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeadHeadStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DeadHeadStats.repeatedFields_, null);
};
goog.inherits(proto.proto.DeadHeadStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeadHeadStats.displayName = 'proto.proto.DeadHeadStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.IdleTimeStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.IdleTimeStats.repeatedFields_, null);
};
goog.inherits(proto.proto.IdleTimeStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.IdleTimeStats.displayName = 'proto.proto.IdleTimeStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatPairInt32 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatPairInt32, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatPairInt32.displayName = 'proto.proto.StatPairInt32';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatPairDouble = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatPairDouble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatPairDouble.displayName = 'proto.proto.StatPairDouble';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.IdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.IdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.IdResponse.displayName = 'proto.proto.IdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketActionRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FieldTicketActionRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketActionRecord.displayName = 'proto.proto.FieldTicketActionRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FieldTicketUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketUpdateRequest.displayName = 'proto.proto.FieldTicketUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketsPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FieldTicketsPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketsPageRequest.displayName = 'proto.proto.FieldTicketsPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketsPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.FieldTicketsPageResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.FieldTicketsPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketsPageResponse.displayName = 'proto.proto.FieldTicketsPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FieldTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketResponse.displayName = 'proto.proto.FieldTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.FieldTicket.repeatedFields_, null);
};
goog.inherits(proto.proto.FieldTicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicket.displayName = 'proto.proto.FieldTicket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketCharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FieldTicketCharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketCharge.displayName = 'proto.proto.FieldTicketCharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FieldTicketCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketCommentRequest.displayName = 'proto.proto.FieldTicketCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FieldTicketComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.FieldTicketComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FieldTicketComment.displayName = 'proto.proto.FieldTicketComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.InvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.InvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.InvoiceRequest.displayName = 'proto.proto.InvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DenyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DenyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DenyRequest.displayName = 'proto.proto.DenyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ApprovalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ApprovalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ApprovalRequest.displayName = 'proto.proto.ApprovalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AcceptBidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AcceptBidRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.AcceptBidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AcceptBidRequest.displayName = 'proto.proto.AcceptBidRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AcceptBidRequest.BidAcceptance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AcceptBidRequest.BidAcceptance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AcceptBidRequest.BidAcceptance.displayName = 'proto.proto.AcceptBidRequest.BidAcceptance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BidSuggestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BidSuggestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BidSuggestionRequest.displayName = 'proto.proto.BidSuggestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BidSuggestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BidSuggestion.repeatedFields_, null);
};
goog.inherits(proto.proto.BidSuggestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BidSuggestion.displayName = 'proto.proto.BidSuggestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Bid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Bid.repeatedFields_, null);
};
goog.inherits(proto.proto.Bid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Bid.displayName = 'proto.proto.Bid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BidListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BidListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.BidListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BidListResponse.displayName = 'proto.proto.BidListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BidResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BidResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BidResponse.displayName = 'proto.proto.BidResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BidRequest.displayName = 'proto.proto.BidRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BillingSurcharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BillingSurcharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BillingSurcharge.displayName = 'proto.proto.BillingSurcharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BillingDefaultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BillingDefaultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BillingDefaultsRequest.displayName = 'proto.proto.BillingDefaultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BillingDefaultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.BillingDefaultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BillingDefaultsResponse.displayName = 'proto.proto.BillingDefaultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BillingDefaults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BillingDefaults.repeatedFields_, null);
};
goog.inherits(proto.proto.BillingDefaults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BillingDefaults.displayName = 'proto.proto.BillingDefaults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.HaulListingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.HaulListingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.HaulListingRequest.displayName = 'proto.proto.HaulListingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.HaulingListingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.HaulingListingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.HaulingListingResponse.displayName = 'proto.proto.HaulingListingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.HaulingListingListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.HaulingListingListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.HaulingListingListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.HaulingListingListResponse.displayName = 'proto.proto.HaulingListingListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.HaulingListing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.HaulingListing.repeatedFields_, null);
};
goog.inherits(proto.proto.HaulingListing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.HaulingListing.displayName = 'proto.proto.HaulingListing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProvisionAttachmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ProvisionAttachmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProvisionAttachmentRequest.displayName = 'proto.proto.ProvisionAttachmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProvisionedURLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ProvisionedURLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProvisionedURLResponse.displayName = 'proto.proto.ProvisionedURLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AssignDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AssignDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AssignDriverRequest.displayName = 'proto.proto.AssignDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ScheduledJobAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ScheduledJobAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ScheduledJobAction.displayName = 'proto.proto.ScheduledJobAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.S3Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.S3Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.S3Asset.displayName = 'proto.proto.S3Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StartJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StartJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StartJobRequest.displayName = 'proto.proto.StartJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ConfirmPickupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ConfirmPickupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ConfirmPickupRequest.displayName = 'proto.proto.ConfirmPickupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CompleteJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CompleteJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CompleteJobRequest.displayName = 'proto.proto.CompleteJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Job.repeatedFields_, null);
};
goog.inherits(proto.proto.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Job.displayName = 'proto.proto.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.JobActionRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.JobActionRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.JobActionRecord.displayName = 'proto.proto.JobActionRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.WaypointResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.WaypointResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.WaypointResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.WaypointResponse.displayName = 'proto.proto.WaypointResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Waypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Waypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Waypoint.displayName = 'proto.proto.Waypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SaveWaypointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SaveWaypointsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SaveWaypointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SaveWaypointsRequest.displayName = 'proto.proto.SaveWaypointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ErrorReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ErrorReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ErrorReportRequest.displayName = 'proto.proto.ErrorReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ResyncResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ResyncResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ResyncResponse.displayName = 'proto.proto.ResyncResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PendingRegistrationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PendingRegistrationsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.PendingRegistrationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PendingRegistrationsResponse.displayName = 'proto.proto.PendingRegistrationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PendingRegistration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PendingRegistration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PendingRegistration.displayName = 'proto.proto.PendingRegistration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AssociationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AssociationRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.AssociationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AssociationRequest.displayName = 'proto.proto.AssociationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MissingSiteReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MissingSiteReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MissingSiteReportRequest.displayName = 'proto.proto.MissingSiteReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeviceIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeviceIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeviceIdRequest.displayName = 'proto.proto.DeviceIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Coordinates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Coordinates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Coordinates.displayName = 'proto.proto.Coordinates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Infraction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Infraction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Infraction.displayName = 'proto.proto.Infraction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SiteAccessChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SiteAccessChangeRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SiteAccessChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SiteAccessChangeRequest.displayName = 'proto.proto.SiteAccessChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SiteListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SiteListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SiteListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SiteListResponse.displayName = 'proto.proto.SiteListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SiteResponse.displayName = 'proto.proto.SiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SiteRequest.displayName = 'proto.proto.SiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Site.repeatedFields_, null);
};
goog.inherits(proto.proto.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Site.displayName = 'proto.proto.Site';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StubWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StubWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StubWaypoint.displayName = 'proto.proto.StubWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SiteAccessPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SiteAccessPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SiteAccessPeriod.displayName = 'proto.proto.SiteAccessPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SiteRestrictionPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SiteRestrictionPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SiteRestrictionPeriod.displayName = 'proto.proto.SiteRestrictionPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateGroupRequest.displayName = 'proto.proto.CreateGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GroupRequest.displayName = 'proto.proto.GroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GroupListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GroupListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GroupListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GroupListResponse.displayName = 'proto.proto.GroupListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LoginRequest.displayName = 'proto.proto.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LoginResponse.displayName = 'proto.proto.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetOpenIdForDeveloperIdentityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetOpenIdForDeveloperIdentityResponse.displayName = 'proto.proto.GetOpenIdForDeveloperIdentityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Error.displayName = 'proto.proto.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ErrorResponse.displayName = 'proto.proto.ErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.EmptyRequest.displayName = 'proto.proto.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.IdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.IdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.IdRequest.displayName = 'proto.proto.IdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.IdListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.IdListRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.IdListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.IdListRequest.displayName = 'proto.proto.IdListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryRequest.displayName = 'proto.proto.QueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UserRequest.displayName = 'proto.proto.UserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateUserRequest.displayName = 'proto.proto.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UserListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.UserListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.UserListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UserListResponse.displayName = 'proto.proto.UserListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UserResponse.displayName = 'proto.proto.UserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PasswordResetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PasswordResetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PasswordResetRequest.displayName = 'proto.proto.PasswordResetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ChangePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ChangePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ChangePasswordRequest.displayName = 'proto.proto.ChangePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.User.displayName = 'proto.proto.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Group.repeatedFields_, null);
};
goog.inherits(proto.proto.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Group.displayName = 'proto.proto.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Money = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Money, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Money.displayName = 'proto.proto.Money';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeadheadRoutingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeadheadRoutingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeadheadRoutingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeadheadRoutingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bid: (f = msg.getBid()) && proto.proto.Bid.toObject(includeInstance, f),
    listing: (f = msg.getListing()) && proto.proto.HaulingListing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeadheadRoutingRequest}
 */
proto.proto.DeadheadRoutingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeadheadRoutingRequest;
  return proto.proto.DeadheadRoutingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeadheadRoutingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeadheadRoutingRequest}
 */
proto.proto.DeadheadRoutingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.Bid;
      reader.readMessage(value,proto.proto.Bid.deserializeBinaryFromReader);
      msg.setBid(value);
      break;
    case 3:
      var value = new proto.proto.HaulingListing;
      reader.readMessage(value,proto.proto.HaulingListing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeadheadRoutingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeadheadRoutingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeadheadRoutingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeadheadRoutingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Bid.serializeBinaryToWriter
    );
  }
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.HaulingListing.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.DeadheadRoutingRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeadheadRoutingRequest} returns this
 */
proto.proto.DeadheadRoutingRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Bid bid = 2;
 * @return {?proto.proto.Bid}
 */
proto.proto.DeadheadRoutingRequest.prototype.getBid = function() {
  return /** @type{?proto.proto.Bid} */ (
    jspb.Message.getWrapperField(this, proto.proto.Bid, 2));
};


/**
 * @param {?proto.proto.Bid|undefined} value
 * @return {!proto.proto.DeadheadRoutingRequest} returns this
*/
proto.proto.DeadheadRoutingRequest.prototype.setBid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DeadheadRoutingRequest} returns this
 */
proto.proto.DeadheadRoutingRequest.prototype.clearBid = function() {
  return this.setBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DeadheadRoutingRequest.prototype.hasBid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HaulingListing listing = 3;
 * @return {?proto.proto.HaulingListing}
 */
proto.proto.DeadheadRoutingRequest.prototype.getListing = function() {
  return /** @type{?proto.proto.HaulingListing} */ (
    jspb.Message.getWrapperField(this, proto.proto.HaulingListing, 3));
};


/**
 * @param {?proto.proto.HaulingListing|undefined} value
 * @return {!proto.proto.DeadheadRoutingRequest} returns this
*/
proto.proto.DeadheadRoutingRequest.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DeadheadRoutingRequest} returns this
 */
proto.proto.DeadheadRoutingRequest.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DeadheadRoutingRequest.prototype.hasListing = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListingRoutingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListingRoutingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListingRoutingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListingRoutingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    listing: (f = msg.getListing()) && proto.proto.HaulingListing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListingRoutingRequest}
 */
proto.proto.ListingRoutingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListingRoutingRequest;
  return proto.proto.ListingRoutingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListingRoutingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListingRoutingRequest}
 */
proto.proto.ListingRoutingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.HaulingListing;
      reader.readMessage(value,proto.proto.HaulingListing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListingRoutingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListingRoutingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListingRoutingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListingRoutingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.HaulingListing.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.ListingRoutingRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListingRoutingRequest} returns this
 */
proto.proto.ListingRoutingRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional HaulingListing listing = 2;
 * @return {?proto.proto.HaulingListing}
 */
proto.proto.ListingRoutingRequest.prototype.getListing = function() {
  return /** @type{?proto.proto.HaulingListing} */ (
    jspb.Message.getWrapperField(this, proto.proto.HaulingListing, 2));
};


/**
 * @param {?proto.proto.HaulingListing|undefined} value
 * @return {!proto.proto.ListingRoutingRequest} returns this
*/
proto.proto.ListingRoutingRequest.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListingRoutingRequest} returns this
 */
proto.proto.ListingRoutingRequest.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListingRoutingRequest.prototype.hasListing = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeadheadRouteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeadheadRouteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeadheadRouteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeadheadRouteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    deadheadtojob: (f = msg.getDeadheadtojob()) && proto.proto.RouteResponse.Leg.toObject(includeInstance, f),
    deadheadfromjob: (f = msg.getDeadheadfromjob()) && proto.proto.RouteResponse.Leg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeadheadRouteResponse}
 */
proto.proto.DeadheadRouteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeadheadRouteResponse;
  return proto.proto.DeadheadRouteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeadheadRouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeadheadRouteResponse}
 */
proto.proto.DeadheadRouteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.RouteResponse.Leg;
      reader.readMessage(value,proto.proto.RouteResponse.Leg.deserializeBinaryFromReader);
      msg.setDeadheadtojob(value);
      break;
    case 3:
      var value = new proto.proto.RouteResponse.Leg;
      reader.readMessage(value,proto.proto.RouteResponse.Leg.deserializeBinaryFromReader);
      msg.setDeadheadfromjob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeadheadRouteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeadheadRouteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeadheadRouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeadheadRouteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadtojob();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.RouteResponse.Leg.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadfromjob();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.RouteResponse.Leg.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.DeadheadRouteResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.DeadheadRouteResponse} returns this
*/
proto.proto.DeadheadRouteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DeadheadRouteResponse} returns this
 */
proto.proto.DeadheadRouteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DeadheadRouteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RouteResponse.Leg deadheadToJob = 2;
 * @return {?proto.proto.RouteResponse.Leg}
 */
proto.proto.DeadheadRouteResponse.prototype.getDeadheadtojob = function() {
  return /** @type{?proto.proto.RouteResponse.Leg} */ (
    jspb.Message.getWrapperField(this, proto.proto.RouteResponse.Leg, 2));
};


/**
 * @param {?proto.proto.RouteResponse.Leg|undefined} value
 * @return {!proto.proto.DeadheadRouteResponse} returns this
*/
proto.proto.DeadheadRouteResponse.prototype.setDeadheadtojob = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DeadheadRouteResponse} returns this
 */
proto.proto.DeadheadRouteResponse.prototype.clearDeadheadtojob = function() {
  return this.setDeadheadtojob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DeadheadRouteResponse.prototype.hasDeadheadtojob = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RouteResponse.Leg deadheadFromJob = 3;
 * @return {?proto.proto.RouteResponse.Leg}
 */
proto.proto.DeadheadRouteResponse.prototype.getDeadheadfromjob = function() {
  return /** @type{?proto.proto.RouteResponse.Leg} */ (
    jspb.Message.getWrapperField(this, proto.proto.RouteResponse.Leg, 3));
};


/**
 * @param {?proto.proto.RouteResponse.Leg|undefined} value
 * @return {!proto.proto.DeadheadRouteResponse} returns this
*/
proto.proto.DeadheadRouteResponse.prototype.setDeadheadfromjob = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DeadheadRouteResponse} returns this
 */
proto.proto.DeadheadRouteResponse.prototype.clearDeadheadfromjob = function() {
  return this.setDeadheadfromjob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DeadheadRouteResponse.prototype.hasDeadheadfromjob = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.RouteResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RouteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RouteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RouteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RouteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    legsList: jspb.Message.toObjectList(msg.getLegsList(),
    proto.proto.RouteResponse.Leg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RouteResponse}
 */
proto.proto.RouteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RouteResponse;
  return proto.proto.RouteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RouteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RouteResponse}
 */
proto.proto.RouteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.RouteResponse.Leg;
      reader.readMessage(value,proto.proto.RouteResponse.Leg.deserializeBinaryFromReader);
      msg.addLegs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RouteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RouteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RouteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RouteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getLegsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.RouteResponse.Leg.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RouteResponse.Leg.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RouteResponse.Leg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RouteResponse.Leg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RouteResponse.Leg.toObject = function(includeInstance, msg) {
  var f, obj = {
    geojsonline: jspb.Message.getFieldWithDefault(msg, 2, ""),
    duration: (f = msg.getDuration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RouteResponse.Leg}
 */
proto.proto.RouteResponse.Leg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RouteResponse.Leg;
  return proto.proto.RouteResponse.Leg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RouteResponse.Leg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RouteResponse.Leg}
 */
proto.proto.RouteResponse.Leg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeojsonline(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RouteResponse.Leg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RouteResponse.Leg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RouteResponse.Leg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RouteResponse.Leg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeojsonline();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string geoJsonLine = 2;
 * @return {string}
 */
proto.proto.RouteResponse.Leg.prototype.getGeojsonline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RouteResponse.Leg} returns this
 */
proto.proto.RouteResponse.Leg.prototype.setGeojsonline = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp duration = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.RouteResponse.Leg.prototype.getDuration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.RouteResponse.Leg} returns this
*/
proto.proto.RouteResponse.Leg.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RouteResponse.Leg} returns this
 */
proto.proto.RouteResponse.Leg.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RouteResponse.Leg.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float distance = 4;
 * @return {number}
 */
proto.proto.RouteResponse.Leg.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RouteResponse.Leg} returns this
 */
proto.proto.RouteResponse.Leg.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.RouteResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.RouteResponse} returns this
*/
proto.proto.RouteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RouteResponse} returns this
 */
proto.proto.RouteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RouteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Leg legs = 2;
 * @return {!Array<!proto.proto.RouteResponse.Leg>}
 */
proto.proto.RouteResponse.prototype.getLegsList = function() {
  return /** @type{!Array<!proto.proto.RouteResponse.Leg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.RouteResponse.Leg, 2));
};


/**
 * @param {!Array<!proto.proto.RouteResponse.Leg>} value
 * @return {!proto.proto.RouteResponse} returns this
*/
proto.proto.RouteResponse.prototype.setLegsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.RouteResponse.Leg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.RouteResponse.Leg}
 */
proto.proto.RouteResponse.prototype.addLegs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.RouteResponse.Leg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.RouteResponse} returns this
 */
proto.proto.RouteResponse.prototype.clearLegsList = function() {
  return this.setLegsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.JobActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.JobActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.JobActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.JobActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newrecord: (f = msg.getNewrecord()) && proto.proto.JobActionRecord.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.JobActionRequest}
 */
proto.proto.JobActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.JobActionRequest;
  return proto.proto.JobActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.JobActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.JobActionRequest}
 */
proto.proto.JobActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.JobActionRecord;
      reader.readMessage(value,proto.proto.JobActionRecord.deserializeBinaryFromReader);
      msg.setNewrecord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.JobActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.JobActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.JobActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.JobActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewrecord();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.JobActionRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.JobActionRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.JobActionRequest} returns this
 */
proto.proto.JobActionRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional JobActionRecord newRecord = 2;
 * @return {?proto.proto.JobActionRecord}
 */
proto.proto.JobActionRequest.prototype.getNewrecord = function() {
  return /** @type{?proto.proto.JobActionRecord} */ (
    jspb.Message.getWrapperField(this, proto.proto.JobActionRecord, 2));
};


/**
 * @param {?proto.proto.JobActionRecord|undefined} value
 * @return {!proto.proto.JobActionRequest} returns this
*/
proto.proto.JobActionRequest.prototype.setNewrecord = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.JobActionRequest} returns this
 */
proto.proto.JobActionRequest.prototype.clearNewrecord = function() {
  return this.setNewrecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.JobActionRequest.prototype.hasNewrecord = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatsDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatsDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatsDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatsDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rangebegin: (f = msg.getRangebegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rangeend: (f = msg.getRangeend()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatsDashboardRequest}
 */
proto.proto.StatsDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatsDashboardRequest;
  return proto.proto.StatsDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatsDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatsDashboardRequest}
 */
proto.proto.StatsDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRangebegin(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRangeend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatsDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatsDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatsDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatsDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRangebegin();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRangeend();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.StatsDashboardRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatsDashboardRequest} returns this
 */
proto.proto.StatsDashboardRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp rangeBegin = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.StatsDashboardRequest.prototype.getRangebegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.StatsDashboardRequest} returns this
*/
proto.proto.StatsDashboardRequest.prototype.setRangebegin = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatsDashboardRequest} returns this
 */
proto.proto.StatsDashboardRequest.prototype.clearRangebegin = function() {
  return this.setRangebegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatsDashboardRequest.prototype.hasRangebegin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp rangeEnd = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.StatsDashboardRequest.prototype.getRangeend = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.StatsDashboardRequest} returns this
*/
proto.proto.StatsDashboardRequest.prototype.setRangeend = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatsDashboardRequest} returns this
 */
proto.proto.StatsDashboardRequest.prototype.clearRangeend = function() {
  return this.setRangeend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatsDashboardRequest.prototype.hasRangeend = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatsOperatorDashboardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatsOperatorDashboardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatsOperatorDashboardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    idlestats: (f = msg.getIdlestats()) && proto.proto.IdleTimeStats.toObject(includeInstance, f),
    estimatestats: (f = msg.getEstimatestats()) && proto.proto.EstimateDeviationStats.toObject(includeInstance, f),
    jobcountstats: (f = msg.getJobcountstats()) && proto.proto.JobCountStats.toObject(includeInstance, f),
    deadheadstats: (f = msg.getDeadheadstats()) && proto.proto.DeadHeadStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatsOperatorDashboardResponse}
 */
proto.proto.StatsOperatorDashboardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatsOperatorDashboardResponse;
  return proto.proto.StatsOperatorDashboardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatsOperatorDashboardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatsOperatorDashboardResponse}
 */
proto.proto.StatsOperatorDashboardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.IdleTimeStats;
      reader.readMessage(value,proto.proto.IdleTimeStats.deserializeBinaryFromReader);
      msg.setIdlestats(value);
      break;
    case 3:
      var value = new proto.proto.EstimateDeviationStats;
      reader.readMessage(value,proto.proto.EstimateDeviationStats.deserializeBinaryFromReader);
      msg.setEstimatestats(value);
      break;
    case 4:
      var value = new proto.proto.JobCountStats;
      reader.readMessage(value,proto.proto.JobCountStats.deserializeBinaryFromReader);
      msg.setJobcountstats(value);
      break;
    case 5:
      var value = new proto.proto.DeadHeadStats;
      reader.readMessage(value,proto.proto.DeadHeadStats.deserializeBinaryFromReader);
      msg.setDeadheadstats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatsOperatorDashboardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatsOperatorDashboardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatsOperatorDashboardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getIdlestats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.IdleTimeStats.serializeBinaryToWriter
    );
  }
  f = message.getEstimatestats();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.EstimateDeviationStats.serializeBinaryToWriter
    );
  }
  f = message.getJobcountstats();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.JobCountStats.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadstats();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.DeadHeadStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
*/
proto.proto.StatsOperatorDashboardResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
 */
proto.proto.StatsOperatorDashboardResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IdleTimeStats idleStats = 2;
 * @return {?proto.proto.IdleTimeStats}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.getIdlestats = function() {
  return /** @type{?proto.proto.IdleTimeStats} */ (
    jspb.Message.getWrapperField(this, proto.proto.IdleTimeStats, 2));
};


/**
 * @param {?proto.proto.IdleTimeStats|undefined} value
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
*/
proto.proto.StatsOperatorDashboardResponse.prototype.setIdlestats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
 */
proto.proto.StatsOperatorDashboardResponse.prototype.clearIdlestats = function() {
  return this.setIdlestats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.hasIdlestats = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EstimateDeviationStats estimateStats = 3;
 * @return {?proto.proto.EstimateDeviationStats}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.getEstimatestats = function() {
  return /** @type{?proto.proto.EstimateDeviationStats} */ (
    jspb.Message.getWrapperField(this, proto.proto.EstimateDeviationStats, 3));
};


/**
 * @param {?proto.proto.EstimateDeviationStats|undefined} value
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
*/
proto.proto.StatsOperatorDashboardResponse.prototype.setEstimatestats = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
 */
proto.proto.StatsOperatorDashboardResponse.prototype.clearEstimatestats = function() {
  return this.setEstimatestats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.hasEstimatestats = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional JobCountStats jobCountStats = 4;
 * @return {?proto.proto.JobCountStats}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.getJobcountstats = function() {
  return /** @type{?proto.proto.JobCountStats} */ (
    jspb.Message.getWrapperField(this, proto.proto.JobCountStats, 4));
};


/**
 * @param {?proto.proto.JobCountStats|undefined} value
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
*/
proto.proto.StatsOperatorDashboardResponse.prototype.setJobcountstats = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
 */
proto.proto.StatsOperatorDashboardResponse.prototype.clearJobcountstats = function() {
  return this.setJobcountstats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.hasJobcountstats = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DeadHeadStats deadHeadStats = 5;
 * @return {?proto.proto.DeadHeadStats}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.getDeadheadstats = function() {
  return /** @type{?proto.proto.DeadHeadStats} */ (
    jspb.Message.getWrapperField(this, proto.proto.DeadHeadStats, 5));
};


/**
 * @param {?proto.proto.DeadHeadStats|undefined} value
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
*/
proto.proto.StatsOperatorDashboardResponse.prototype.setDeadheadstats = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatsOperatorDashboardResponse} returns this
 */
proto.proto.StatsOperatorDashboardResponse.prototype.clearDeadheadstats = function() {
  return this.setDeadheadstats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatsOperatorDashboardResponse.prototype.hasDeadheadstats = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.EstimateDeviationStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.EstimateDeviationStats.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.EstimateDeviationStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.EstimateDeviationStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EstimateDeviationStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    pairsList: jspb.Message.toObjectList(msg.getPairsList(),
    proto.proto.StatPairDouble.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.EstimateDeviationStats}
 */
proto.proto.EstimateDeviationStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.EstimateDeviationStats;
  return proto.proto.EstimateDeviationStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.EstimateDeviationStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.EstimateDeviationStats}
 */
proto.proto.EstimateDeviationStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.StatPairDouble;
      reader.readMessage(value,proto.proto.StatPairDouble.deserializeBinaryFromReader);
      msg.addPairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.EstimateDeviationStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.EstimateDeviationStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.EstimateDeviationStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EstimateDeviationStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.StatPairDouble.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatPairDouble pairs = 1;
 * @return {!Array<!proto.proto.StatPairDouble>}
 */
proto.proto.EstimateDeviationStats.prototype.getPairsList = function() {
  return /** @type{!Array<!proto.proto.StatPairDouble>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StatPairDouble, 1));
};


/**
 * @param {!Array<!proto.proto.StatPairDouble>} value
 * @return {!proto.proto.EstimateDeviationStats} returns this
*/
proto.proto.EstimateDeviationStats.prototype.setPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.StatPairDouble=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StatPairDouble}
 */
proto.proto.EstimateDeviationStats.prototype.addPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.StatPairDouble, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.EstimateDeviationStats} returns this
 */
proto.proto.EstimateDeviationStats.prototype.clearPairsList = function() {
  return this.setPairsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.JobCountStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.JobCountStats.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.JobCountStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.JobCountStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.JobCountStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    pairsList: jspb.Message.toObjectList(msg.getPairsList(),
    proto.proto.StatPairInt32.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.JobCountStats}
 */
proto.proto.JobCountStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.JobCountStats;
  return proto.proto.JobCountStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.JobCountStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.JobCountStats}
 */
proto.proto.JobCountStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.StatPairInt32;
      reader.readMessage(value,proto.proto.StatPairInt32.deserializeBinaryFromReader);
      msg.addPairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.JobCountStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.JobCountStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.JobCountStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.JobCountStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.StatPairInt32.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatPairInt32 pairs = 1;
 * @return {!Array<!proto.proto.StatPairInt32>}
 */
proto.proto.JobCountStats.prototype.getPairsList = function() {
  return /** @type{!Array<!proto.proto.StatPairInt32>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StatPairInt32, 1));
};


/**
 * @param {!Array<!proto.proto.StatPairInt32>} value
 * @return {!proto.proto.JobCountStats} returns this
*/
proto.proto.JobCountStats.prototype.setPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.StatPairInt32=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StatPairInt32}
 */
proto.proto.JobCountStats.prototype.addPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.StatPairInt32, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.JobCountStats} returns this
 */
proto.proto.JobCountStats.prototype.clearPairsList = function() {
  return this.setPairsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DeadHeadStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeadHeadStats.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeadHeadStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeadHeadStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeadHeadStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    pairsList: jspb.Message.toObjectList(msg.getPairsList(),
    proto.proto.StatPairDouble.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeadHeadStats}
 */
proto.proto.DeadHeadStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeadHeadStats;
  return proto.proto.DeadHeadStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeadHeadStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeadHeadStats}
 */
proto.proto.DeadHeadStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.StatPairDouble;
      reader.readMessage(value,proto.proto.StatPairDouble.deserializeBinaryFromReader);
      msg.addPairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeadHeadStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeadHeadStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeadHeadStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeadHeadStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.StatPairDouble.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatPairDouble pairs = 1;
 * @return {!Array<!proto.proto.StatPairDouble>}
 */
proto.proto.DeadHeadStats.prototype.getPairsList = function() {
  return /** @type{!Array<!proto.proto.StatPairDouble>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StatPairDouble, 1));
};


/**
 * @param {!Array<!proto.proto.StatPairDouble>} value
 * @return {!proto.proto.DeadHeadStats} returns this
*/
proto.proto.DeadHeadStats.prototype.setPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.StatPairDouble=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StatPairDouble}
 */
proto.proto.DeadHeadStats.prototype.addPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.StatPairDouble, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DeadHeadStats} returns this
 */
proto.proto.DeadHeadStats.prototype.clearPairsList = function() {
  return this.setPairsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.IdleTimeStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.IdleTimeStats.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.IdleTimeStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.IdleTimeStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdleTimeStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    pairsList: jspb.Message.toObjectList(msg.getPairsList(),
    proto.proto.StatPairDouble.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.IdleTimeStats}
 */
proto.proto.IdleTimeStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.IdleTimeStats;
  return proto.proto.IdleTimeStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.IdleTimeStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.IdleTimeStats}
 */
proto.proto.IdleTimeStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.StatPairDouble;
      reader.readMessage(value,proto.proto.StatPairDouble.deserializeBinaryFromReader);
      msg.addPairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.IdleTimeStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.IdleTimeStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.IdleTimeStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdleTimeStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.StatPairDouble.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatPairDouble pairs = 1;
 * @return {!Array<!proto.proto.StatPairDouble>}
 */
proto.proto.IdleTimeStats.prototype.getPairsList = function() {
  return /** @type{!Array<!proto.proto.StatPairDouble>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StatPairDouble, 1));
};


/**
 * @param {!Array<!proto.proto.StatPairDouble>} value
 * @return {!proto.proto.IdleTimeStats} returns this
*/
proto.proto.IdleTimeStats.prototype.setPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.StatPairDouble=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StatPairDouble}
 */
proto.proto.IdleTimeStats.prototype.addPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.StatPairDouble, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.IdleTimeStats} returns this
 */
proto.proto.IdleTimeStats.prototype.clearPairsList = function() {
  return this.setPairsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatPairInt32.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatPairInt32.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatPairInt32} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatPairInt32.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: (f = msg.getGroup()) && proto.proto.Group.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatPairInt32}
 */
proto.proto.StatPairInt32.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatPairInt32;
  return proto.proto.StatPairInt32.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatPairInt32} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatPairInt32}
 */
proto.proto.StatPairInt32.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatPairInt32.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatPairInt32.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatPairInt32} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatPairInt32.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Group group = 1;
 * @return {?proto.proto.Group}
 */
proto.proto.StatPairInt32.prototype.getGroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 1));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.StatPairInt32} returns this
*/
proto.proto.StatPairInt32.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatPairInt32} returns this
 */
proto.proto.StatPairInt32.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatPairInt32.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.proto.StatPairInt32.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StatPairInt32} returns this
 */
proto.proto.StatPairInt32.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatPairDouble.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatPairDouble.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatPairDouble} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatPairDouble.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: (f = msg.getGroup()) && proto.proto.Group.toObject(includeInstance, f),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatPairDouble}
 */
proto.proto.StatPairDouble.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatPairDouble;
  return proto.proto.StatPairDouble.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatPairDouble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatPairDouble}
 */
proto.proto.StatPairDouble.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatPairDouble.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatPairDouble.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatPairDouble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatPairDouble.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional Group group = 1;
 * @return {?proto.proto.Group}
 */
proto.proto.StatPairDouble.prototype.getGroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 1));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.StatPairDouble} returns this
*/
proto.proto.StatPairDouble.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatPairDouble} returns this
 */
proto.proto.StatPairDouble.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatPairDouble.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.proto.StatPairDouble.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StatPairDouble} returns this
 */
proto.proto.StatPairDouble.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.IdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.IdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.IdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inflate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.IdResponse}
 */
proto.proto.IdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.IdResponse;
  return proto.proto.IdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.IdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.IdResponse}
 */
proto.proto.IdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInflate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.IdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.IdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.IdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInflate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.IdResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.IdResponse} returns this
*/
proto.proto.IdResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.IdResponse} returns this
 */
proto.proto.IdResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.IdResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.proto.IdResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.IdResponse} returns this
 */
proto.proto.IdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool inflate = 3;
 * @return {boolean}
 */
proto.proto.IdResponse.prototype.getInflate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.IdResponse} returns this
 */
proto.proto.IdResponse.prototype.setInflate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketActionRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketActionRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketActionRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketActionRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledaction: (f = msg.getScheduledaction()) && proto.proto.ScheduledJobAction.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ticketid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actioncoordinates: (f = msg.getActioncoordinates()) && proto.proto.Coordinates.toObject(includeInstance, f),
    site: (f = msg.getSite()) && proto.proto.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketActionRecord}
 */
proto.proto.FieldTicketActionRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketActionRecord;
  return proto.proto.FieldTicketActionRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketActionRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketActionRecord}
 */
proto.proto.FieldTicketActionRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ScheduledJobAction;
      reader.readMessage(value,proto.proto.ScheduledJobAction.deserializeBinaryFromReader);
      msg.setScheduledaction(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTicketid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 4:
      var value = new proto.proto.Coordinates;
      reader.readMessage(value,proto.proto.Coordinates.deserializeBinaryFromReader);
      msg.setActioncoordinates(value);
      break;
    case 5:
      var value = new proto.proto.Site;
      reader.readMessage(value,proto.proto.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketActionRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketActionRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketActionRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketActionRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.ScheduledJobAction.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTicketid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActioncoordinates();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScheduledJobAction scheduledAction = 1;
 * @return {?proto.proto.ScheduledJobAction}
 */
proto.proto.FieldTicketActionRecord.prototype.getScheduledaction = function() {
  return /** @type{?proto.proto.ScheduledJobAction} */ (
    jspb.Message.getWrapperField(this, proto.proto.ScheduledJobAction, 1));
};


/**
 * @param {?proto.proto.ScheduledJobAction|undefined} value
 * @return {!proto.proto.FieldTicketActionRecord} returns this
*/
proto.proto.FieldTicketActionRecord.prototype.setScheduledaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketActionRecord} returns this
 */
proto.proto.FieldTicketActionRecord.prototype.clearScheduledaction = function() {
  return this.setScheduledaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketActionRecord.prototype.hasScheduledaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 id = 6;
 * @return {number}
 */
proto.proto.FieldTicketActionRecord.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicketActionRecord} returns this
 */
proto.proto.FieldTicketActionRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 ticketId = 2;
 * @return {number}
 */
proto.proto.FieldTicketActionRecord.prototype.getTicketid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicketActionRecord} returns this
 */
proto.proto.FieldTicketActionRecord.prototype.setTicketid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.FieldTicketActionRecord.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.FieldTicketActionRecord} returns this
*/
proto.proto.FieldTicketActionRecord.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketActionRecord} returns this
 */
proto.proto.FieldTicketActionRecord.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketActionRecord.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Coordinates actionCoordinates = 4;
 * @return {?proto.proto.Coordinates}
 */
proto.proto.FieldTicketActionRecord.prototype.getActioncoordinates = function() {
  return /** @type{?proto.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.proto.Coordinates, 4));
};


/**
 * @param {?proto.proto.Coordinates|undefined} value
 * @return {!proto.proto.FieldTicketActionRecord} returns this
*/
proto.proto.FieldTicketActionRecord.prototype.setActioncoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketActionRecord} returns this
 */
proto.proto.FieldTicketActionRecord.prototype.clearActioncoordinates = function() {
  return this.setActioncoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketActionRecord.prototype.hasActioncoordinates = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Site site = 5;
 * @return {?proto.proto.Site}
 */
proto.proto.FieldTicketActionRecord.prototype.getSite = function() {
  return /** @type{?proto.proto.Site} */ (
    jspb.Message.getWrapperField(this, proto.proto.Site, 5));
};


/**
 * @param {?proto.proto.Site|undefined} value
 * @return {!proto.proto.FieldTicketActionRecord} returns this
*/
proto.proto.FieldTicketActionRecord.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketActionRecord} returns this
 */
proto.proto.FieldTicketActionRecord.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketActionRecord.prototype.hasSite = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ticket: (f = msg.getTicket()) && proto.proto.FieldTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketUpdateRequest}
 */
proto.proto.FieldTicketUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketUpdateRequest;
  return proto.proto.FieldTicketUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketUpdateRequest}
 */
proto.proto.FieldTicketUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.FieldTicket;
      reader.readMessage(value,proto.proto.FieldTicket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.FieldTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.FieldTicketUpdateRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FieldTicketUpdateRequest} returns this
 */
proto.proto.FieldTicketUpdateRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FieldTicket ticket = 2;
 * @return {?proto.proto.FieldTicket}
 */
proto.proto.FieldTicketUpdateRequest.prototype.getTicket = function() {
  return /** @type{?proto.proto.FieldTicket} */ (
    jspb.Message.getWrapperField(this, proto.proto.FieldTicket, 2));
};


/**
 * @param {?proto.proto.FieldTicket|undefined} value
 * @return {!proto.proto.FieldTicketUpdateRequest} returns this
*/
proto.proto.FieldTicketUpdateRequest.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketUpdateRequest} returns this
 */
proto.proto.FieldTicketUpdateRequest.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketUpdateRequest.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketsPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketsPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketsPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketsPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryparameters: (f = msg.getQueryparameters()) && proto.proto.QueryRequest.toObject(includeInstance, f),
    includestatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketsPageRequest}
 */
proto.proto.FieldTicketsPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketsPageRequest;
  return proto.proto.FieldTicketsPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketsPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketsPageRequest}
 */
proto.proto.FieldTicketsPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QueryRequest;
      reader.readMessage(value,proto.proto.QueryRequest.deserializeBinaryFromReader);
      msg.setQueryparameters(value);
      break;
    case 2:
      var value = /** @type {!proto.proto.FieldTicket.TicketStatus} */ (reader.readEnum());
      msg.setIncludestatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketsPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketsPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketsPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketsPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryparameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.QueryRequest.serializeBinaryToWriter
    );
  }
  f = message.getIncludestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional QueryRequest queryParameters = 1;
 * @return {?proto.proto.QueryRequest}
 */
proto.proto.FieldTicketsPageRequest.prototype.getQueryparameters = function() {
  return /** @type{?proto.proto.QueryRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryRequest, 1));
};


/**
 * @param {?proto.proto.QueryRequest|undefined} value
 * @return {!proto.proto.FieldTicketsPageRequest} returns this
*/
proto.proto.FieldTicketsPageRequest.prototype.setQueryparameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketsPageRequest} returns this
 */
proto.proto.FieldTicketsPageRequest.prototype.clearQueryparameters = function() {
  return this.setQueryparameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketsPageRequest.prototype.hasQueryparameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FieldTicket.TicketStatus includeStatus = 2;
 * @return {!proto.proto.FieldTicket.TicketStatus}
 */
proto.proto.FieldTicketsPageRequest.prototype.getIncludestatus = function() {
  return /** @type {!proto.proto.FieldTicket.TicketStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.proto.FieldTicket.TicketStatus} value
 * @return {!proto.proto.FieldTicketsPageRequest} returns this
 */
proto.proto.FieldTicketsPageRequest.prototype.setIncludestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.FieldTicketsPageResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketsPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketsPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketsPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketsPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
    proto.proto.FieldTicket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketsPageResponse}
 */
proto.proto.FieldTicketsPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketsPageResponse;
  return proto.proto.FieldTicketsPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketsPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketsPageResponse}
 */
proto.proto.FieldTicketsPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.FieldTicket;
      reader.readMessage(value,proto.proto.FieldTicket.deserializeBinaryFromReader);
      msg.addTickets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketsPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketsPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketsPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketsPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.FieldTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.FieldTicketsPageResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.FieldTicketsPageResponse} returns this
*/
proto.proto.FieldTicketsPageResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketsPageResponse} returns this
 */
proto.proto.FieldTicketsPageResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketsPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FieldTicket tickets = 2;
 * @return {!Array<!proto.proto.FieldTicket>}
 */
proto.proto.FieldTicketsPageResponse.prototype.getTicketsList = function() {
  return /** @type{!Array<!proto.proto.FieldTicket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.FieldTicket, 2));
};


/**
 * @param {!Array<!proto.proto.FieldTicket>} value
 * @return {!proto.proto.FieldTicketsPageResponse} returns this
*/
proto.proto.FieldTicketsPageResponse.prototype.setTicketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.FieldTicket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.FieldTicket}
 */
proto.proto.FieldTicketsPageResponse.prototype.addTickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.FieldTicket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FieldTicketsPageResponse} returns this
 */
proto.proto.FieldTicketsPageResponse.prototype.clearTicketsList = function() {
  return this.setTicketsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    ticket: (f = msg.getTicket()) && proto.proto.FieldTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketResponse}
 */
proto.proto.FieldTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketResponse;
  return proto.proto.FieldTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketResponse}
 */
proto.proto.FieldTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.FieldTicket;
      reader.readMessage(value,proto.proto.FieldTicket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.FieldTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.FieldTicketResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.FieldTicketResponse} returns this
*/
proto.proto.FieldTicketResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketResponse} returns this
 */
proto.proto.FieldTicketResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FieldTicket ticket = 2;
 * @return {?proto.proto.FieldTicket}
 */
proto.proto.FieldTicketResponse.prototype.getTicket = function() {
  return /** @type{?proto.proto.FieldTicket} */ (
    jspb.Message.getWrapperField(this, proto.proto.FieldTicket, 2));
};


/**
 * @param {?proto.proto.FieldTicket|undefined} value
 * @return {!proto.proto.FieldTicketResponse} returns this
*/
proto.proto.FieldTicketResponse.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketResponse} returns this
 */
proto.proto.FieldTicketResponse.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketResponse.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.FieldTicket.repeatedFields_ = [21,22,23,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicket.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trailerlength: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purchaseordernumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ticketuuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    measuredtraveldistancekm: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    approvaltime: (f = msg.getApprovaltime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    disputetime: (f = msg.getDisputetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    vehicle: jspb.Message.getFieldWithDefault(msg, 11, 0),
    trailer: jspb.Message.getFieldWithDefault(msg, 12, 0),
    permit: jspb.Message.getFieldWithDefault(msg, 13, 0),
    status: jspb.Message.getFieldWithDefault(msg, 15, 0),
    group: (f = msg.getGroup()) && proto.proto.Group.toObject(includeInstance, f),
    completinggroup: (f = msg.getCompletinggroup()) && proto.proto.Group.toObject(includeInstance, f),
    bid: (f = msg.getBid()) && proto.proto.Bid.toObject(includeInstance, f),
    originalassigneduser: (f = msg.getOriginalassigneduser()) && proto.proto.User.toObject(includeInstance, f),
    assigneduser: (f = msg.getAssigneduser()) && proto.proto.User.toObject(includeInstance, f),
    listing: (f = msg.getListing()) && proto.proto.HaulingListing.toObject(includeInstance, f),
    job: (f = msg.getJob()) && proto.proto.Job.toObject(includeInstance, f),
    shipperfeerate: (f = msg.getShipperfeerate()) && proto.proto.Money.toObject(includeInstance, f),
    haulerfeerate: (f = msg.getHaulerfeerate()) && proto.proto.Money.toObject(includeInstance, f),
    picturepathsList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    commentsList: jspb.Message.toObjectList(msg.getCommentsList(),
    proto.proto.FieldTicketComment.toObject, includeInstance),
    extrachargesList: jspb.Message.toObjectList(msg.getExtrachargesList(),
    proto.proto.FieldTicketCharge.toObject, includeInstance),
    actionrecordsList: jspb.Message.toObjectList(msg.getActionrecordsList(),
    proto.proto.FieldTicketActionRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicket}
 */
proto.proto.FieldTicket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicket;
  return proto.proto.FieldTicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicket}
 */
proto.proto.FieldTicket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerlength(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseordernumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketuuid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeasuredtraveldistancekm(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setApprovaltime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDisputetime(value);
      break;
    case 11:
      var value = /** @type {!proto.proto.HaulingListing.Vehicle} */ (reader.readEnum());
      msg.setVehicle(value);
      break;
    case 12:
      var value = /** @type {!proto.proto.HaulingListing.TowLoad} */ (reader.readEnum());
      msg.setTrailer(value);
      break;
    case 13:
      var value = /** @type {!proto.proto.HaulingListing.PermitParty} */ (reader.readEnum());
      msg.setPermit(value);
      break;
    case 15:
      var value = /** @type {!proto.proto.FieldTicket.TicketStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 16:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 17:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setCompletinggroup(value);
      break;
    case 18:
      var value = new proto.proto.Bid;
      reader.readMessage(value,proto.proto.Bid.deserializeBinaryFromReader);
      msg.setBid(value);
      break;
    case 19:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setOriginalassigneduser(value);
      break;
    case 20:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setAssigneduser(value);
      break;
    case 14:
      var value = new proto.proto.HaulingListing;
      reader.readMessage(value,proto.proto.HaulingListing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    case 25:
      var value = new proto.proto.Job;
      reader.readMessage(value,proto.proto.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 26:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setShipperfeerate(value);
      break;
    case 27:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setHaulerfeerate(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.addPicturepaths(value);
      break;
    case 22:
      var value = new proto.proto.FieldTicketComment;
      reader.readMessage(value,proto.proto.FieldTicketComment.deserializeBinaryFromReader);
      msg.addComments(value);
      break;
    case 23:
      var value = new proto.proto.FieldTicketCharge;
      reader.readMessage(value,proto.proto.FieldTicketCharge.deserializeBinaryFromReader);
      msg.addExtracharges(value);
      break;
    case 24:
      var value = new proto.proto.FieldTicketActionRecord;
      reader.readMessage(value,proto.proto.FieldTicketActionRecord.deserializeBinaryFromReader);
      msg.addActionrecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTrailerlength();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPurchaseordernumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTicketuuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMeasuredtraveldistancekm();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getApprovaltime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDisputetime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVehicle();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getTrailer();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPermit();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
  f = message.getCompletinggroup();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
  f = message.getBid();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.proto.Bid.serializeBinaryToWriter
    );
  }
  f = message.getOriginalassigneduser();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getAssigneduser();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.proto.HaulingListing.serializeBinaryToWriter
    );
  }
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.proto.Job.serializeBinaryToWriter
    );
  }
  f = message.getShipperfeerate();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getHaulerfeerate();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getPicturepathsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      21,
      f
    );
  }
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.proto.FieldTicketComment.serializeBinaryToWriter
    );
  }
  f = message.getExtrachargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.proto.FieldTicketCharge.serializeBinaryToWriter
    );
  }
  f = message.getActionrecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.proto.FieldTicketActionRecord.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.FieldTicket.TicketStatus = {
  UNSUBMITTED: 0,
  INREVIEW: 1,
  APPROVED: 2,
  DISPUTED: 3,
  INVOICED: 4,
  PAID: 5,
  ARBITRATION: 6
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.FieldTicket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 weight = 2;
 * @return {number}
 */
proto.proto.FieldTicket.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 trailerLength = 3;
 * @return {number}
 */
proto.proto.FieldTicket.prototype.getTrailerlength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setTrailerlength = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string purchaseOrderNumber = 4;
 * @return {string}
 */
proto.proto.FieldTicket.prototype.getPurchaseordernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setPurchaseordernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ticketUUID = 5;
 * @return {string}
 */
proto.proto.FieldTicket.prototype.getTicketuuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setTicketuuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float measuredTravelDistanceKm = 6;
 * @return {number}
 */
proto.proto.FieldTicket.prototype.getMeasuredtraveldistancekm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setMeasuredtraveldistancekm = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp startTime = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.FieldTicket.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp endTime = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.FieldTicket.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp approvalTime = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.FieldTicket.prototype.getApprovaltime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setApprovaltime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearApprovaltime = function() {
  return this.setApprovaltime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasApprovaltime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp disputeTime = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.FieldTicket.prototype.getDisputetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setDisputetime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearDisputetime = function() {
  return this.setDisputetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasDisputetime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional HaulingListing.Vehicle vehicle = 11;
 * @return {!proto.proto.HaulingListing.Vehicle}
 */
proto.proto.FieldTicket.prototype.getVehicle = function() {
  return /** @type {!proto.proto.HaulingListing.Vehicle} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.proto.HaulingListing.Vehicle} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setVehicle = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional HaulingListing.TowLoad trailer = 12;
 * @return {!proto.proto.HaulingListing.TowLoad}
 */
proto.proto.FieldTicket.prototype.getTrailer = function() {
  return /** @type {!proto.proto.HaulingListing.TowLoad} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.proto.HaulingListing.TowLoad} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setTrailer = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional HaulingListing.PermitParty permit = 13;
 * @return {!proto.proto.HaulingListing.PermitParty}
 */
proto.proto.FieldTicket.prototype.getPermit = function() {
  return /** @type {!proto.proto.HaulingListing.PermitParty} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.proto.HaulingListing.PermitParty} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setPermit = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional TicketStatus status = 15;
 * @return {!proto.proto.FieldTicket.TicketStatus}
 */
proto.proto.FieldTicket.prototype.getStatus = function() {
  return /** @type {!proto.proto.FieldTicket.TicketStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.proto.FieldTicket.TicketStatus} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional Group group = 16;
 * @return {?proto.proto.Group}
 */
proto.proto.FieldTicket.prototype.getGroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 16));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Group completingGroup = 17;
 * @return {?proto.proto.Group}
 */
proto.proto.FieldTicket.prototype.getCompletinggroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 17));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setCompletinggroup = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearCompletinggroup = function() {
  return this.setCompletinggroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasCompletinggroup = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Bid bid = 18;
 * @return {?proto.proto.Bid}
 */
proto.proto.FieldTicket.prototype.getBid = function() {
  return /** @type{?proto.proto.Bid} */ (
    jspb.Message.getWrapperField(this, proto.proto.Bid, 18));
};


/**
 * @param {?proto.proto.Bid|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setBid = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearBid = function() {
  return this.setBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasBid = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional User originalAssignedUser = 19;
 * @return {?proto.proto.User}
 */
proto.proto.FieldTicket.prototype.getOriginalassigneduser = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 19));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setOriginalassigneduser = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearOriginalassigneduser = function() {
  return this.setOriginalassigneduser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasOriginalassigneduser = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional User assignedUser = 20;
 * @return {?proto.proto.User}
 */
proto.proto.FieldTicket.prototype.getAssigneduser = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 20));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setAssigneduser = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearAssigneduser = function() {
  return this.setAssigneduser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasAssigneduser = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional HaulingListing listing = 14;
 * @return {?proto.proto.HaulingListing}
 */
proto.proto.FieldTicket.prototype.getListing = function() {
  return /** @type{?proto.proto.HaulingListing} */ (
    jspb.Message.getWrapperField(this, proto.proto.HaulingListing, 14));
};


/**
 * @param {?proto.proto.HaulingListing|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasListing = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Job job = 25;
 * @return {?proto.proto.Job}
 */
proto.proto.FieldTicket.prototype.getJob = function() {
  return /** @type{?proto.proto.Job} */ (
    jspb.Message.getWrapperField(this, proto.proto.Job, 25));
};


/**
 * @param {?proto.proto.Job|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setJob = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearJob = function() {
  return this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasJob = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional Money shipperFeeRate = 26;
 * @return {?proto.proto.Money}
 */
proto.proto.FieldTicket.prototype.getShipperfeerate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 26));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setShipperfeerate = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearShipperfeerate = function() {
  return this.setShipperfeerate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasShipperfeerate = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional Money haulerFeeRate = 27;
 * @return {?proto.proto.Money}
 */
proto.proto.FieldTicket.prototype.getHaulerfeerate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 27));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setHaulerfeerate = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearHaulerfeerate = function() {
  return this.setHaulerfeerate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicket.prototype.hasHaulerfeerate = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * repeated string picturePaths = 21;
 * @return {!Array<string>}
 */
proto.proto.FieldTicket.prototype.getPicturepathsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.setPicturepathsList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.addPicturepaths = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearPicturepathsList = function() {
  return this.setPicturepathsList([]);
};


/**
 * repeated FieldTicketComment comments = 22;
 * @return {!Array<!proto.proto.FieldTicketComment>}
 */
proto.proto.FieldTicket.prototype.getCommentsList = function() {
  return /** @type{!Array<!proto.proto.FieldTicketComment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.FieldTicketComment, 22));
};


/**
 * @param {!Array<!proto.proto.FieldTicketComment>} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setCommentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.proto.FieldTicketComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.FieldTicketComment}
 */
proto.proto.FieldTicket.prototype.addComments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.proto.FieldTicketComment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearCommentsList = function() {
  return this.setCommentsList([]);
};


/**
 * repeated FieldTicketCharge extraCharges = 23;
 * @return {!Array<!proto.proto.FieldTicketCharge>}
 */
proto.proto.FieldTicket.prototype.getExtrachargesList = function() {
  return /** @type{!Array<!proto.proto.FieldTicketCharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.FieldTicketCharge, 23));
};


/**
 * @param {!Array<!proto.proto.FieldTicketCharge>} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setExtrachargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.proto.FieldTicketCharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.FieldTicketCharge}
 */
proto.proto.FieldTicket.prototype.addExtracharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.proto.FieldTicketCharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearExtrachargesList = function() {
  return this.setExtrachargesList([]);
};


/**
 * repeated FieldTicketActionRecord actionRecords = 24;
 * @return {!Array<!proto.proto.FieldTicketActionRecord>}
 */
proto.proto.FieldTicket.prototype.getActionrecordsList = function() {
  return /** @type{!Array<!proto.proto.FieldTicketActionRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.FieldTicketActionRecord, 24));
};


/**
 * @param {!Array<!proto.proto.FieldTicketActionRecord>} value
 * @return {!proto.proto.FieldTicket} returns this
*/
proto.proto.FieldTicket.prototype.setActionrecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.proto.FieldTicketActionRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.FieldTicketActionRecord}
 */
proto.proto.FieldTicket.prototype.addActionrecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.proto.FieldTicketActionRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FieldTicket} returns this
 */
proto.proto.FieldTicket.prototype.clearActionrecordsList = function() {
  return this.setActionrecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketCharge.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketCharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketCharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketCharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chargename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: (f = msg.getAmount()) && proto.proto.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketCharge}
 */
proto.proto.FieldTicketCharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketCharge;
  return proto.proto.FieldTicketCharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketCharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketCharge}
 */
proto.proto.FieldTicketCharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChargename(value);
      break;
    case 5:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketCharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketCharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketCharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketCharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChargename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.FieldTicketCharge.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicketCharge} returns this
 */
proto.proto.FieldTicketCharge.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string chargeName = 2;
 * @return {string}
 */
proto.proto.FieldTicketCharge.prototype.getChargename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FieldTicketCharge} returns this
 */
proto.proto.FieldTicketCharge.prototype.setChargename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Money amount = 5;
 * @return {?proto.proto.Money}
 */
proto.proto.FieldTicketCharge.prototype.getAmount = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 5));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.FieldTicketCharge} returns this
*/
proto.proto.FieldTicketCharge.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketCharge} returns this
 */
proto.proto.FieldTicketCharge.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketCharge.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: (f = msg.getComment()) && proto.proto.FieldTicketComment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketCommentRequest}
 */
proto.proto.FieldTicketCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketCommentRequest;
  return proto.proto.FieldTicketCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketCommentRequest}
 */
proto.proto.FieldTicketCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.FieldTicketComment;
      reader.readMessage(value,proto.proto.FieldTicketComment.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.FieldTicketComment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.FieldTicketCommentRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FieldTicketCommentRequest} returns this
 */
proto.proto.FieldTicketCommentRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FieldTicketComment comment = 2;
 * @return {?proto.proto.FieldTicketComment}
 */
proto.proto.FieldTicketCommentRequest.prototype.getComment = function() {
  return /** @type{?proto.proto.FieldTicketComment} */ (
    jspb.Message.getWrapperField(this, proto.proto.FieldTicketComment, 2));
};


/**
 * @param {?proto.proto.FieldTicketComment|undefined} value
 * @return {!proto.proto.FieldTicketCommentRequest} returns this
*/
proto.proto.FieldTicketCommentRequest.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketCommentRequest} returns this
 */
proto.proto.FieldTicketCommentRequest.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketCommentRequest.prototype.hasComment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FieldTicketComment.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FieldTicketComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FieldTicketComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fieldticketid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    user: (f = msg.getUser()) && proto.proto.User.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FieldTicketComment}
 */
proto.proto.FieldTicketComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FieldTicketComment;
  return proto.proto.FieldTicketComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FieldTicketComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FieldTicketComment}
 */
proto.proto.FieldTicketComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFieldticketid(value);
      break;
    case 3:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FieldTicketComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FieldTicketComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FieldTicketComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FieldTicketComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFieldticketid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.FieldTicketComment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicketComment} returns this
 */
proto.proto.FieldTicketComment.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 fieldTicketId = 2;
 * @return {number}
 */
proto.proto.FieldTicketComment.prototype.getFieldticketid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.FieldTicketComment} returns this
 */
proto.proto.FieldTicketComment.prototype.setFieldticketid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional User user = 3;
 * @return {?proto.proto.User}
 */
proto.proto.FieldTicketComment.prototype.getUser = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 3));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.FieldTicketComment} returns this
*/
proto.proto.FieldTicketComment.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FieldTicketComment} returns this
 */
proto.proto.FieldTicketComment.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FieldTicketComment.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.proto.FieldTicketComment.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FieldTicketComment} returns this
 */
proto.proto.FieldTicketComment.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.InvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.InvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.InvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.InvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ticket: (f = msg.getTicket()) && proto.proto.FieldTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.InvoiceRequest}
 */
proto.proto.InvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.InvoiceRequest;
  return proto.proto.InvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.InvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.InvoiceRequest}
 */
proto.proto.InvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.FieldTicket;
      reader.readMessage(value,proto.proto.FieldTicket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.InvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.InvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.InvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.InvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.FieldTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.InvoiceRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.InvoiceRequest} returns this
 */
proto.proto.InvoiceRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FieldTicket ticket = 2;
 * @return {?proto.proto.FieldTicket}
 */
proto.proto.InvoiceRequest.prototype.getTicket = function() {
  return /** @type{?proto.proto.FieldTicket} */ (
    jspb.Message.getWrapperField(this, proto.proto.FieldTicket, 2));
};


/**
 * @param {?proto.proto.FieldTicket|undefined} value
 * @return {!proto.proto.InvoiceRequest} returns this
*/
proto.proto.InvoiceRequest.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.InvoiceRequest} returns this
 */
proto.proto.InvoiceRequest.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.InvoiceRequest.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DenyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DenyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DenyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DenyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ticket: (f = msg.getTicket()) && proto.proto.FieldTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DenyRequest}
 */
proto.proto.DenyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DenyRequest;
  return proto.proto.DenyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DenyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DenyRequest}
 */
proto.proto.DenyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.FieldTicket;
      reader.readMessage(value,proto.proto.FieldTicket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DenyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DenyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DenyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DenyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.FieldTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.DenyRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DenyRequest} returns this
 */
proto.proto.DenyRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FieldTicket ticket = 2;
 * @return {?proto.proto.FieldTicket}
 */
proto.proto.DenyRequest.prototype.getTicket = function() {
  return /** @type{?proto.proto.FieldTicket} */ (
    jspb.Message.getWrapperField(this, proto.proto.FieldTicket, 2));
};


/**
 * @param {?proto.proto.FieldTicket|undefined} value
 * @return {!proto.proto.DenyRequest} returns this
*/
proto.proto.DenyRequest.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DenyRequest} returns this
 */
proto.proto.DenyRequest.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DenyRequest.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ApprovalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ApprovalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ApprovalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ApprovalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ticket: (f = msg.getTicket()) && proto.proto.FieldTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ApprovalRequest}
 */
proto.proto.ApprovalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ApprovalRequest;
  return proto.proto.ApprovalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ApprovalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ApprovalRequest}
 */
proto.proto.ApprovalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.FieldTicket;
      reader.readMessage(value,proto.proto.FieldTicket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ApprovalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ApprovalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ApprovalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ApprovalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.FieldTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.ApprovalRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ApprovalRequest} returns this
 */
proto.proto.ApprovalRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FieldTicket ticket = 2;
 * @return {?proto.proto.FieldTicket}
 */
proto.proto.ApprovalRequest.prototype.getTicket = function() {
  return /** @type{?proto.proto.FieldTicket} */ (
    jspb.Message.getWrapperField(this, proto.proto.FieldTicket, 2));
};


/**
 * @param {?proto.proto.FieldTicket|undefined} value
 * @return {!proto.proto.ApprovalRequest} returns this
*/
proto.proto.ApprovalRequest.prototype.setTicket = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ApprovalRequest} returns this
 */
proto.proto.ApprovalRequest.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ApprovalRequest.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AcceptBidRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AcceptBidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AcceptBidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AcceptBidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AcceptBidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    acceptedbidsList: jspb.Message.toObjectList(msg.getAcceptedbidsList(),
    proto.proto.AcceptBidRequest.BidAcceptance.toObject, includeInstance),
    listingid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AcceptBidRequest}
 */
proto.proto.AcceptBidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AcceptBidRequest;
  return proto.proto.AcceptBidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AcceptBidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AcceptBidRequest}
 */
proto.proto.AcceptBidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.AcceptBidRequest.BidAcceptance;
      reader.readMessage(value,proto.proto.AcceptBidRequest.BidAcceptance.deserializeBinaryFromReader);
      msg.addAcceptedbids(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setListingid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AcceptBidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AcceptBidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AcceptBidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AcceptBidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAcceptedbidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.AcceptBidRequest.BidAcceptance.serializeBinaryToWriter
    );
  }
  f = message.getListingid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AcceptBidRequest.BidAcceptance.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AcceptBidRequest.BidAcceptance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AcceptBidRequest.BidAcceptance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AcceptBidRequest.BidAcceptance.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    issuedquantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AcceptBidRequest.BidAcceptance}
 */
proto.proto.AcceptBidRequest.BidAcceptance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AcceptBidRequest.BidAcceptance;
  return proto.proto.AcceptBidRequest.BidAcceptance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AcceptBidRequest.BidAcceptance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AcceptBidRequest.BidAcceptance}
 */
proto.proto.AcceptBidRequest.BidAcceptance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssuedquantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AcceptBidRequest.BidAcceptance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AcceptBidRequest.BidAcceptance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AcceptBidRequest.BidAcceptance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AcceptBidRequest.BidAcceptance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIssuedquantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 bidId = 1;
 * @return {number}
 */
proto.proto.AcceptBidRequest.BidAcceptance.prototype.getBidid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AcceptBidRequest.BidAcceptance} returns this
 */
proto.proto.AcceptBidRequest.BidAcceptance.prototype.setBidid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 issuedQuantity = 2;
 * @return {number}
 */
proto.proto.AcceptBidRequest.BidAcceptance.prototype.getIssuedquantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AcceptBidRequest.BidAcceptance} returns this
 */
proto.proto.AcceptBidRequest.BidAcceptance.prototype.setIssuedquantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.AcceptBidRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AcceptBidRequest} returns this
 */
proto.proto.AcceptBidRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated BidAcceptance acceptedBids = 2;
 * @return {!Array<!proto.proto.AcceptBidRequest.BidAcceptance>}
 */
proto.proto.AcceptBidRequest.prototype.getAcceptedbidsList = function() {
  return /** @type{!Array<!proto.proto.AcceptBidRequest.BidAcceptance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AcceptBidRequest.BidAcceptance, 2));
};


/**
 * @param {!Array<!proto.proto.AcceptBidRequest.BidAcceptance>} value
 * @return {!proto.proto.AcceptBidRequest} returns this
*/
proto.proto.AcceptBidRequest.prototype.setAcceptedbidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.AcceptBidRequest.BidAcceptance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AcceptBidRequest.BidAcceptance}
 */
proto.proto.AcceptBidRequest.prototype.addAcceptedbids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.AcceptBidRequest.BidAcceptance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AcceptBidRequest} returns this
 */
proto.proto.AcceptBidRequest.prototype.clearAcceptedbidsList = function() {
  return this.setAcceptedbidsList([]);
};


/**
 * optional int64 listingId = 3;
 * @return {number}
 */
proto.proto.AcceptBidRequest.prototype.getListingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AcceptBidRequest} returns this
 */
proto.proto.AcceptBidRequest.prototype.setListingid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BidSuggestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BidSuggestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BidSuggestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidSuggestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestion: (f = msg.getSuggestion()) && proto.proto.BidSuggestion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BidSuggestionRequest}
 */
proto.proto.BidSuggestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BidSuggestionRequest;
  return proto.proto.BidSuggestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BidSuggestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BidSuggestionRequest}
 */
proto.proto.BidSuggestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.BidSuggestion;
      reader.readMessage(value,proto.proto.BidSuggestion.deserializeBinaryFromReader);
      msg.setSuggestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BidSuggestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BidSuggestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BidSuggestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidSuggestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.BidSuggestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.BidSuggestionRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.BidSuggestionRequest} returns this
 */
proto.proto.BidSuggestionRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BidSuggestion suggestion = 2;
 * @return {?proto.proto.BidSuggestion}
 */
proto.proto.BidSuggestionRequest.prototype.getSuggestion = function() {
  return /** @type{?proto.proto.BidSuggestion} */ (
    jspb.Message.getWrapperField(this, proto.proto.BidSuggestion, 2));
};


/**
 * @param {?proto.proto.BidSuggestion|undefined} value
 * @return {!proto.proto.BidSuggestionRequest} returns this
*/
proto.proto.BidSuggestionRequest.prototype.setSuggestion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BidSuggestionRequest} returns this
 */
proto.proto.BidSuggestionRequest.prototype.clearSuggestion = function() {
  return this.setSuggestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BidSuggestionRequest.prototype.hasSuggestion = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BidSuggestion.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BidSuggestion.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BidSuggestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BidSuggestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidSuggestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bidid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    billingtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    flatrate: (f = msg.getFlatrate()) && proto.proto.Money.toObject(includeInstance, f),
    hourlyrate: (f = msg.getHourlyrate()) && proto.proto.Money.toObject(includeInstance, f),
    surchargesList: jspb.Message.toObjectList(msg.getSurchargesList(),
    proto.proto.BillingSurcharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BidSuggestion}
 */
proto.proto.BidSuggestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BidSuggestion;
  return proto.proto.BidSuggestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BidSuggestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BidSuggestion}
 */
proto.proto.BidSuggestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidid(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.Bid.BillingType} */ (reader.readEnum());
      msg.setBillingtype(value);
      break;
    case 4:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setFlatrate(value);
      break;
    case 5:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setHourlyrate(value);
      break;
    case 6:
      var value = new proto.proto.BillingSurcharge;
      reader.readMessage(value,proto.proto.BillingSurcharge.deserializeBinaryFromReader);
      msg.addSurcharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BidSuggestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BidSuggestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BidSuggestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidSuggestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBidid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBillingtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFlatrate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getHourlyrate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getSurchargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.proto.BillingSurcharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.BidSuggestion.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.BidSuggestion} returns this
 */
proto.proto.BidSuggestion.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 bidId = 2;
 * @return {number}
 */
proto.proto.BidSuggestion.prototype.getBidid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.BidSuggestion} returns this
 */
proto.proto.BidSuggestion.prototype.setBidid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Bid.BillingType billingType = 3;
 * @return {!proto.proto.Bid.BillingType}
 */
proto.proto.BidSuggestion.prototype.getBillingtype = function() {
  return /** @type {!proto.proto.Bid.BillingType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.Bid.BillingType} value
 * @return {!proto.proto.BidSuggestion} returns this
 */
proto.proto.BidSuggestion.prototype.setBillingtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Money flatRate = 4;
 * @return {?proto.proto.Money}
 */
proto.proto.BidSuggestion.prototype.getFlatrate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 4));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.BidSuggestion} returns this
*/
proto.proto.BidSuggestion.prototype.setFlatrate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BidSuggestion} returns this
 */
proto.proto.BidSuggestion.prototype.clearFlatrate = function() {
  return this.setFlatrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BidSuggestion.prototype.hasFlatrate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Money hourlyRate = 5;
 * @return {?proto.proto.Money}
 */
proto.proto.BidSuggestion.prototype.getHourlyrate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 5));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.BidSuggestion} returns this
*/
proto.proto.BidSuggestion.prototype.setHourlyrate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BidSuggestion} returns this
 */
proto.proto.BidSuggestion.prototype.clearHourlyrate = function() {
  return this.setHourlyrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BidSuggestion.prototype.hasHourlyrate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated BillingSurcharge surcharges = 6;
 * @return {!Array<!proto.proto.BillingSurcharge>}
 */
proto.proto.BidSuggestion.prototype.getSurchargesList = function() {
  return /** @type{!Array<!proto.proto.BillingSurcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.BillingSurcharge, 6));
};


/**
 * @param {!Array<!proto.proto.BillingSurcharge>} value
 * @return {!proto.proto.BidSuggestion} returns this
*/
proto.proto.BidSuggestion.prototype.setSurchargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.proto.BillingSurcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.BillingSurcharge}
 */
proto.proto.BidSuggestion.prototype.addSurcharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.proto.BillingSurcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BidSuggestion} returns this
 */
proto.proto.BidSuggestion.prototype.clearSurchargesList = function() {
  return this.setSurchargesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Bid.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Bid.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Bid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Bid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Bid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listingid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    servicequantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flatrate: (f = msg.getFlatrate()) && proto.proto.Money.toObject(includeInstance, f),
    hourlyrate: (f = msg.getHourlyrate()) && proto.proto.Money.toObject(includeInstance, f),
    surchargesList: jspb.Message.toObjectList(msg.getSurchargesList(),
    proto.proto.BillingSurcharge.toObject, includeInstance),
    bidexpirationtime: (f = msg.getBidexpirationtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deadheadstartduration: (f = msg.getDeadheadstartduration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deadheadendduration: (f = msg.getDeadheadendduration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deadheadyard: (f = msg.getDeadheadyard()) && proto.proto.Site.toObject(includeInstance, f),
    billingtype: jspb.Message.getFieldWithDefault(msg, 12, 0),
    haulerfee: (f = msg.getHaulerfee()) && proto.proto.Money.toObject(includeInstance, f),
    listerfee: (f = msg.getListerfee()) && proto.proto.Money.toObject(includeInstance, f),
    declined: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    listersuggestedpricing: (f = msg.getListersuggestedpricing()) && proto.proto.BidSuggestion.toObject(includeInstance, f),
    inflatedgroup: (f = msg.getInflatedgroup()) && proto.proto.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Bid}
 */
proto.proto.Bid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Bid;
  return proto.proto.Bid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Bid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Bid}
 */
proto.proto.Bid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setListingid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServicequantity(value);
      break;
    case 5:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setFlatrate(value);
      break;
    case 6:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setHourlyrate(value);
      break;
    case 7:
      var value = new proto.proto.BillingSurcharge;
      reader.readMessage(value,proto.proto.BillingSurcharge.deserializeBinaryFromReader);
      msg.addSurcharges(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBidexpirationtime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeadheadstartduration(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeadheadendduration(value);
      break;
    case 11:
      var value = new proto.proto.Site;
      reader.readMessage(value,proto.proto.Site.deserializeBinaryFromReader);
      msg.setDeadheadyard(value);
      break;
    case 12:
      var value = /** @type {!proto.proto.Bid.BillingType} */ (reader.readEnum());
      msg.setBillingtype(value);
      break;
    case 13:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setHaulerfee(value);
      break;
    case 14:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setListerfee(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeclined(value);
      break;
    case 16:
      var value = new proto.proto.BidSuggestion;
      reader.readMessage(value,proto.proto.BidSuggestion.deserializeBinaryFromReader);
      msg.setListersuggestedpricing(value);
      break;
    case 17:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setInflatedgroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Bid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Bid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Bid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Bid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getListingid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getServicequantity();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFlatrate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getHourlyrate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getSurchargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.proto.BillingSurcharge.serializeBinaryToWriter
    );
  }
  f = message.getBidexpirationtime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadstartduration();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadendduration();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadyard();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.proto.Site.serializeBinaryToWriter
    );
  }
  f = message.getBillingtype();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getHaulerfee();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getListerfee();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getDeclined();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getListersuggestedpricing();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.proto.BidSuggestion.serializeBinaryToWriter
    );
  }
  f = message.getInflatedgroup();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.Bid.BillingType = {
  FLAT_RATE: 0,
  HOURLY: 1
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.Bid.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 listingId = 2;
 * @return {number}
 */
proto.proto.Bid.prototype.getListingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.setListingid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 groupId = 3;
 * @return {number}
 */
proto.proto.Bid.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 serviceQuantity = 4;
 * @return {number}
 */
proto.proto.Bid.prototype.getServicequantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.setServicequantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Money flatRate = 5;
 * @return {?proto.proto.Money}
 */
proto.proto.Bid.prototype.getFlatrate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 5));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setFlatrate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearFlatrate = function() {
  return this.setFlatrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasFlatrate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Money hourlyRate = 6;
 * @return {?proto.proto.Money}
 */
proto.proto.Bid.prototype.getHourlyrate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 6));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setHourlyrate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearHourlyrate = function() {
  return this.setHourlyrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasHourlyrate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated BillingSurcharge surcharges = 7;
 * @return {!Array<!proto.proto.BillingSurcharge>}
 */
proto.proto.Bid.prototype.getSurchargesList = function() {
  return /** @type{!Array<!proto.proto.BillingSurcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.BillingSurcharge, 7));
};


/**
 * @param {!Array<!proto.proto.BillingSurcharge>} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setSurchargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.proto.BillingSurcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.BillingSurcharge}
 */
proto.proto.Bid.prototype.addSurcharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.proto.BillingSurcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearSurchargesList = function() {
  return this.setSurchargesList([]);
};


/**
 * optional google.protobuf.Timestamp bidExpirationTime = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Bid.prototype.getBidexpirationtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setBidexpirationtime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearBidexpirationtime = function() {
  return this.setBidexpirationtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasBidexpirationtime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp deadheadStartDuration = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Bid.prototype.getDeadheadstartduration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setDeadheadstartduration = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearDeadheadstartduration = function() {
  return this.setDeadheadstartduration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasDeadheadstartduration = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp deadheadEndDuration = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Bid.prototype.getDeadheadendduration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setDeadheadendduration = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearDeadheadendduration = function() {
  return this.setDeadheadendduration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasDeadheadendduration = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Site deadheadYard = 11;
 * @return {?proto.proto.Site}
 */
proto.proto.Bid.prototype.getDeadheadyard = function() {
  return /** @type{?proto.proto.Site} */ (
    jspb.Message.getWrapperField(this, proto.proto.Site, 11));
};


/**
 * @param {?proto.proto.Site|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setDeadheadyard = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearDeadheadyard = function() {
  return this.setDeadheadyard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasDeadheadyard = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional BillingType billingType = 12;
 * @return {!proto.proto.Bid.BillingType}
 */
proto.proto.Bid.prototype.getBillingtype = function() {
  return /** @type {!proto.proto.Bid.BillingType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.proto.Bid.BillingType} value
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.setBillingtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional Money haulerFee = 13;
 * @return {?proto.proto.Money}
 */
proto.proto.Bid.prototype.getHaulerfee = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 13));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setHaulerfee = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearHaulerfee = function() {
  return this.setHaulerfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasHaulerfee = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Money listerFee = 14;
 * @return {?proto.proto.Money}
 */
proto.proto.Bid.prototype.getListerfee = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 14));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setListerfee = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearListerfee = function() {
  return this.setListerfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasListerfee = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool declined = 15;
 * @return {boolean}
 */
proto.proto.Bid.prototype.getDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.setDeclined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional BidSuggestion listerSuggestedPricing = 16;
 * @return {?proto.proto.BidSuggestion}
 */
proto.proto.Bid.prototype.getListersuggestedpricing = function() {
  return /** @type{?proto.proto.BidSuggestion} */ (
    jspb.Message.getWrapperField(this, proto.proto.BidSuggestion, 16));
};


/**
 * @param {?proto.proto.BidSuggestion|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setListersuggestedpricing = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearListersuggestedpricing = function() {
  return this.setListersuggestedpricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasListersuggestedpricing = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Group inflatedGroup = 17;
 * @return {?proto.proto.Group}
 */
proto.proto.Bid.prototype.getInflatedgroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 17));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.Bid} returns this
*/
proto.proto.Bid.prototype.setInflatedgroup = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Bid} returns this
 */
proto.proto.Bid.prototype.clearInflatedgroup = function() {
  return this.setInflatedgroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Bid.prototype.hasInflatedgroup = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BidListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BidListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BidListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BidListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.proto.Bid.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BidListResponse}
 */
proto.proto.BidListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BidListResponse;
  return proto.proto.BidListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BidListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BidListResponse}
 */
proto.proto.BidListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.Bid;
      reader.readMessage(value,proto.proto.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BidListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BidListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BidListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.Bid.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.BidListResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.BidListResponse} returns this
*/
proto.proto.BidListResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BidListResponse} returns this
 */
proto.proto.BidListResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BidListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Bid bids = 2;
 * @return {!Array<!proto.proto.Bid>}
 */
proto.proto.BidListResponse.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.proto.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Bid, 2));
};


/**
 * @param {!Array<!proto.proto.Bid>} value
 * @return {!proto.proto.BidListResponse} returns this
*/
proto.proto.BidListResponse.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Bid}
 */
proto.proto.BidListResponse.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BidListResponse} returns this
 */
proto.proto.BidListResponse.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BidResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BidResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BidResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    bid: (f = msg.getBid()) && proto.proto.Bid.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BidResponse}
 */
proto.proto.BidResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BidResponse;
  return proto.proto.BidResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BidResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BidResponse}
 */
proto.proto.BidResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.Bid;
      reader.readMessage(value,proto.proto.Bid.deserializeBinaryFromReader);
      msg.setBid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BidResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BidResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BidResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getBid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Bid.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.BidResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.BidResponse} returns this
*/
proto.proto.BidResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BidResponse} returns this
 */
proto.proto.BidResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BidResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Bid bid = 2;
 * @return {?proto.proto.Bid}
 */
proto.proto.BidResponse.prototype.getBid = function() {
  return /** @type{?proto.proto.Bid} */ (
    jspb.Message.getWrapperField(this, proto.proto.Bid, 2));
};


/**
 * @param {?proto.proto.Bid|undefined} value
 * @return {!proto.proto.BidResponse} returns this
*/
proto.proto.BidResponse.prototype.setBid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BidResponse} returns this
 */
proto.proto.BidResponse.prototype.clearBid = function() {
  return this.setBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BidResponse.prototype.hasBid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bid: (f = msg.getBid()) && proto.proto.Bid.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BidRequest}
 */
proto.proto.BidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BidRequest;
  return proto.proto.BidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BidRequest}
 */
proto.proto.BidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.Bid;
      reader.readMessage(value,proto.proto.Bid.deserializeBinaryFromReader);
      msg.setBid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Bid.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.BidRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.BidRequest} returns this
 */
proto.proto.BidRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Bid bid = 2;
 * @return {?proto.proto.Bid}
 */
proto.proto.BidRequest.prototype.getBid = function() {
  return /** @type{?proto.proto.Bid} */ (
    jspb.Message.getWrapperField(this, proto.proto.Bid, 2));
};


/**
 * @param {?proto.proto.Bid|undefined} value
 * @return {!proto.proto.BidRequest} returns this
*/
proto.proto.BidRequest.prototype.setBid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BidRequest} returns this
 */
proto.proto.BidRequest.prototype.clearBid = function() {
  return this.setBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BidRequest.prototype.hasBid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BillingSurcharge.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BillingSurcharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BillingSurcharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingSurcharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chargename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rate: (f = msg.getRate()) && proto.proto.Money.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BillingSurcharge}
 */
proto.proto.BillingSurcharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BillingSurcharge;
  return proto.proto.BillingSurcharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BillingSurcharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BillingSurcharge}
 */
proto.proto.BillingSurcharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChargename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 5:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BillingSurcharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BillingSurcharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BillingSurcharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingSurcharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChargename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.BillingSurcharge.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.BillingSurcharge} returns this
 */
proto.proto.BillingSurcharge.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string chargeName = 2;
 * @return {string}
 */
proto.proto.BillingSurcharge.prototype.getChargename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.BillingSurcharge} returns this
 */
proto.proto.BillingSurcharge.prototype.setChargename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.proto.BillingSurcharge.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.BillingSurcharge} returns this
 */
proto.proto.BillingSurcharge.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unit = 4;
 * @return {string}
 */
proto.proto.BillingSurcharge.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.BillingSurcharge} returns this
 */
proto.proto.BillingSurcharge.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Money rate = 5;
 * @return {?proto.proto.Money}
 */
proto.proto.BillingSurcharge.prototype.getRate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 5));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.BillingSurcharge} returns this
*/
proto.proto.BillingSurcharge.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BillingSurcharge} returns this
 */
proto.proto.BillingSurcharge.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BillingSurcharge.prototype.hasRate = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BillingDefaultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BillingDefaultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BillingDefaultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingDefaultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    defaults: (f = msg.getDefaults()) && proto.proto.BillingDefaults.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BillingDefaultsRequest}
 */
proto.proto.BillingDefaultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BillingDefaultsRequest;
  return proto.proto.BillingDefaultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BillingDefaultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BillingDefaultsRequest}
 */
proto.proto.BillingDefaultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.BillingDefaults;
      reader.readMessage(value,proto.proto.BillingDefaults.deserializeBinaryFromReader);
      msg.setDefaults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BillingDefaultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BillingDefaultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BillingDefaultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingDefaultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefaults();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.BillingDefaults.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.BillingDefaultsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.BillingDefaultsRequest} returns this
 */
proto.proto.BillingDefaultsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BillingDefaults defaults = 2;
 * @return {?proto.proto.BillingDefaults}
 */
proto.proto.BillingDefaultsRequest.prototype.getDefaults = function() {
  return /** @type{?proto.proto.BillingDefaults} */ (
    jspb.Message.getWrapperField(this, proto.proto.BillingDefaults, 2));
};


/**
 * @param {?proto.proto.BillingDefaults|undefined} value
 * @return {!proto.proto.BillingDefaultsRequest} returns this
*/
proto.proto.BillingDefaultsRequest.prototype.setDefaults = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BillingDefaultsRequest} returns this
 */
proto.proto.BillingDefaultsRequest.prototype.clearDefaults = function() {
  return this.setDefaults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BillingDefaultsRequest.prototype.hasDefaults = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BillingDefaultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BillingDefaultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BillingDefaultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingDefaultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    defaults: (f = msg.getDefaults()) && proto.proto.BillingDefaults.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BillingDefaultsResponse}
 */
proto.proto.BillingDefaultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BillingDefaultsResponse;
  return proto.proto.BillingDefaultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BillingDefaultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BillingDefaultsResponse}
 */
proto.proto.BillingDefaultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.BillingDefaults;
      reader.readMessage(value,proto.proto.BillingDefaults.deserializeBinaryFromReader);
      msg.setDefaults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BillingDefaultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BillingDefaultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BillingDefaultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingDefaultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getDefaults();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.BillingDefaults.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.BillingDefaultsResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.BillingDefaultsResponse} returns this
*/
proto.proto.BillingDefaultsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BillingDefaultsResponse} returns this
 */
proto.proto.BillingDefaultsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BillingDefaultsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BillingDefaults defaults = 2;
 * @return {?proto.proto.BillingDefaults}
 */
proto.proto.BillingDefaultsResponse.prototype.getDefaults = function() {
  return /** @type{?proto.proto.BillingDefaults} */ (
    jspb.Message.getWrapperField(this, proto.proto.BillingDefaults, 2));
};


/**
 * @param {?proto.proto.BillingDefaults|undefined} value
 * @return {!proto.proto.BillingDefaultsResponse} returns this
*/
proto.proto.BillingDefaultsResponse.prototype.setDefaults = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BillingDefaultsResponse} returns this
 */
proto.proto.BillingDefaultsResponse.prototype.clearDefaults = function() {
  return this.setDefaults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BillingDefaultsResponse.prototype.hasDefaults = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BillingDefaults.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BillingDefaults.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BillingDefaults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BillingDefaults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingDefaults.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    servicecost: (f = msg.getServicecost()) && proto.proto.Money.toObject(includeInstance, f),
    milagerate: (f = msg.getMilagerate()) && proto.proto.Money.toObject(includeInstance, f),
    chargesList: jspb.Message.toObjectList(msg.getChargesList(),
    proto.proto.BillingSurcharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BillingDefaults}
 */
proto.proto.BillingDefaults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BillingDefaults;
  return proto.proto.BillingDefaults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BillingDefaults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BillingDefaults}
 */
proto.proto.BillingDefaults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 2:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setServicecost(value);
      break;
    case 3:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setMilagerate(value);
      break;
    case 4:
      var value = new proto.proto.BillingSurcharge;
      reader.readMessage(value,proto.proto.BillingSurcharge.deserializeBinaryFromReader);
      msg.addCharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BillingDefaults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BillingDefaults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BillingDefaults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BillingDefaults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getServicecost();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getMilagerate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getChargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.BillingSurcharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 groupId = 1;
 * @return {number}
 */
proto.proto.BillingDefaults.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.BillingDefaults} returns this
 */
proto.proto.BillingDefaults.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Money serviceCost = 2;
 * @return {?proto.proto.Money}
 */
proto.proto.BillingDefaults.prototype.getServicecost = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 2));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.BillingDefaults} returns this
*/
proto.proto.BillingDefaults.prototype.setServicecost = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BillingDefaults} returns this
 */
proto.proto.BillingDefaults.prototype.clearServicecost = function() {
  return this.setServicecost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BillingDefaults.prototype.hasServicecost = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Money milageRate = 3;
 * @return {?proto.proto.Money}
 */
proto.proto.BillingDefaults.prototype.getMilagerate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 3));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.BillingDefaults} returns this
*/
proto.proto.BillingDefaults.prototype.setMilagerate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.BillingDefaults} returns this
 */
proto.proto.BillingDefaults.prototype.clearMilagerate = function() {
  return this.setMilagerate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.BillingDefaults.prototype.hasMilagerate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated BillingSurcharge charges = 4;
 * @return {!Array<!proto.proto.BillingSurcharge>}
 */
proto.proto.BillingDefaults.prototype.getChargesList = function() {
  return /** @type{!Array<!proto.proto.BillingSurcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.BillingSurcharge, 4));
};


/**
 * @param {!Array<!proto.proto.BillingSurcharge>} value
 * @return {!proto.proto.BillingDefaults} returns this
*/
proto.proto.BillingDefaults.prototype.setChargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.BillingSurcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.BillingSurcharge}
 */
proto.proto.BillingDefaults.prototype.addCharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.BillingSurcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BillingDefaults} returns this
 */
proto.proto.BillingDefaults.prototype.clearChargesList = function() {
  return this.setChargesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.HaulListingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.HaulListingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.HaulListingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulListingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    listing: (f = msg.getListing()) && proto.proto.HaulingListing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.HaulListingRequest}
 */
proto.proto.HaulListingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.HaulListingRequest;
  return proto.proto.HaulListingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.HaulListingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.HaulListingRequest}
 */
proto.proto.HaulListingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.HaulingListing;
      reader.readMessage(value,proto.proto.HaulingListing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.HaulListingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.HaulListingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.HaulListingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulListingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.HaulingListing.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.HaulListingRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.HaulListingRequest} returns this
 */
proto.proto.HaulListingRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional HaulingListing listing = 2;
 * @return {?proto.proto.HaulingListing}
 */
proto.proto.HaulListingRequest.prototype.getListing = function() {
  return /** @type{?proto.proto.HaulingListing} */ (
    jspb.Message.getWrapperField(this, proto.proto.HaulingListing, 2));
};


/**
 * @param {?proto.proto.HaulingListing|undefined} value
 * @return {!proto.proto.HaulListingRequest} returns this
*/
proto.proto.HaulListingRequest.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulListingRequest} returns this
 */
proto.proto.HaulListingRequest.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulListingRequest.prototype.hasListing = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.HaulingListingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.HaulingListingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.HaulingListingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulingListingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    listing: (f = msg.getListing()) && proto.proto.HaulingListing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.HaulingListingResponse}
 */
proto.proto.HaulingListingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.HaulingListingResponse;
  return proto.proto.HaulingListingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.HaulingListingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.HaulingListingResponse}
 */
proto.proto.HaulingListingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.HaulingListing;
      reader.readMessage(value,proto.proto.HaulingListing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.HaulingListingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.HaulingListingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.HaulingListingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulingListingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.HaulingListing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.HaulingListingResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.HaulingListingResponse} returns this
*/
proto.proto.HaulingListingResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListingResponse} returns this
 */
proto.proto.HaulingListingResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListingResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HaulingListing listing = 2;
 * @return {?proto.proto.HaulingListing}
 */
proto.proto.HaulingListingResponse.prototype.getListing = function() {
  return /** @type{?proto.proto.HaulingListing} */ (
    jspb.Message.getWrapperField(this, proto.proto.HaulingListing, 2));
};


/**
 * @param {?proto.proto.HaulingListing|undefined} value
 * @return {!proto.proto.HaulingListingResponse} returns this
*/
proto.proto.HaulingListingResponse.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListingResponse} returns this
 */
proto.proto.HaulingListingResponse.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListingResponse.prototype.hasListing = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.HaulingListingListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.HaulingListingListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.HaulingListingListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.HaulingListingListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulingListingListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    listingsList: jspb.Message.toObjectList(msg.getListingsList(),
    proto.proto.HaulingListing.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.HaulingListingListResponse}
 */
proto.proto.HaulingListingListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.HaulingListingListResponse;
  return proto.proto.HaulingListingListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.HaulingListingListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.HaulingListingListResponse}
 */
proto.proto.HaulingListingListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.HaulingListing;
      reader.readMessage(value,proto.proto.HaulingListing.deserializeBinaryFromReader);
      msg.addListings(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.HaulingListingListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.HaulingListingListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.HaulingListingListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulingListingListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getListingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.HaulingListing.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.HaulingListingListResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.HaulingListingListResponse} returns this
*/
proto.proto.HaulingListingListResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListingListResponse} returns this
 */
proto.proto.HaulingListingListResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListingListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated HaulingListing listings = 2;
 * @return {!Array<!proto.proto.HaulingListing>}
 */
proto.proto.HaulingListingListResponse.prototype.getListingsList = function() {
  return /** @type{!Array<!proto.proto.HaulingListing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.HaulingListing, 2));
};


/**
 * @param {!Array<!proto.proto.HaulingListing>} value
 * @return {!proto.proto.HaulingListingListResponse} returns this
*/
proto.proto.HaulingListingListResponse.prototype.setListingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.HaulingListing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.HaulingListing}
 */
proto.proto.HaulingListingListResponse.prototype.addListings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.HaulingListing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.HaulingListingListResponse} returns this
 */
proto.proto.HaulingListingListResponse.prototype.clearListingsList = function() {
  return this.setListingsList([]);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.proto.HaulingListingListResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListingListResponse} returns this
 */
proto.proto.HaulingListingListResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.HaulingListing.repeatedFields_ = [29,30,33,36];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.HaulingListing.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.HaulingListing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.HaulingListing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulingListing.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    listingexpirationtime: (f = msg.getListingexpirationtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completedat: (f = msg.getCompletedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxlistingquanity: jspb.Message.getFieldWithDefault(msg, 27, 0),
    remaininglistingquantity: jspb.Message.getFieldWithDefault(msg, 28, 0),
    estimatedtraveldistance: jspb.Message.getFieldWithDefault(msg, 19, 0),
    towlength: jspb.Message.getFieldWithDefault(msg, 20, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    groupid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    item: jspb.Message.getFieldWithDefault(msg, 6, ""),
    note: jspb.Message.getFieldWithDefault(msg, 21, ""),
    bidtoanyone: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    vehicle: jspb.Message.getFieldWithDefault(msg, 8, 0),
    towload: jspb.Message.getFieldWithDefault(msg, 9, 0),
    permitter: jspb.Message.getFieldWithDefault(msg, 10, 0),
    placedbid: (f = msg.getPlacedbid()) && proto.proto.Bid.toObject(includeInstance, f),
    scheduledjobactionsList: jspb.Message.toObjectList(msg.getScheduledjobactionsList(),
    proto.proto.ScheduledJobAction.toObject, includeInstance),
    assignedjobsList: jspb.Message.toObjectList(msg.getAssignedjobsList(),
    proto.proto.Job.toObject, includeInstance),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.proto.Bid.toObject, includeInstance),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.proto.S3Asset.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 37, 0),
    listingcreator: (f = msg.getListingcreator()) && proto.proto.User.toObject(includeInstance, f),
    creatorgroup: (f = msg.getCreatorgroup()) && proto.proto.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.HaulingListing}
 */
proto.proto.HaulingListing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.HaulingListing;
  return proto.proto.HaulingListing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.HaulingListing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.HaulingListing}
 */
proto.proto.HaulingListing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setListingexpirationtime(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletedat(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxlistingquanity(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemaininglistingquantity(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedtraveldistance(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTowlength(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setItem(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBidtoanyone(value);
      break;
    case 8:
      var value = /** @type {!proto.proto.HaulingListing.Vehicle} */ (reader.readEnum());
      msg.setVehicle(value);
      break;
    case 9:
      var value = /** @type {!proto.proto.HaulingListing.TowLoad} */ (reader.readEnum());
      msg.setTowload(value);
      break;
    case 10:
      var value = /** @type {!proto.proto.HaulingListing.PermitParty} */ (reader.readEnum());
      msg.setPermitter(value);
      break;
    case 23:
      var value = new proto.proto.Bid;
      reader.readMessage(value,proto.proto.Bid.deserializeBinaryFromReader);
      msg.setPlacedbid(value);
      break;
    case 29:
      var value = new proto.proto.ScheduledJobAction;
      reader.readMessage(value,proto.proto.ScheduledJobAction.deserializeBinaryFromReader);
      msg.addScheduledjobactions(value);
      break;
    case 30:
      var value = new proto.proto.Job;
      reader.readMessage(value,proto.proto.Job.deserializeBinaryFromReader);
      msg.addAssignedjobs(value);
      break;
    case 33:
      var value = new proto.proto.Bid;
      reader.readMessage(value,proto.proto.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 36:
      var value = new proto.proto.S3Asset;
      reader.readMessage(value,proto.proto.S3Asset.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 37:
      var value = /** @type {!proto.proto.HaulingListing.ListingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 34:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setListingcreator(value);
      break;
    case 35:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setCreatorgroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.HaulingListing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.HaulingListing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.HaulingListing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.HaulingListing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getListingexpirationtime();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletedat();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMaxlistingquanity();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getRemaininglistingquantity();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getEstimatedtraveldistance();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getTowlength();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getItem();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBidtoanyone();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getVehicle();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTowload();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPermitter();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPlacedbid();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.proto.Bid.serializeBinaryToWriter
    );
  }
  f = message.getScheduledjobactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      29,
      f,
      proto.proto.ScheduledJobAction.serializeBinaryToWriter
    );
  }
  f = message.getAssignedjobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      proto.proto.Job.serializeBinaryToWriter
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      33,
      f,
      proto.proto.Bid.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      proto.proto.S3Asset.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      37,
      f
    );
  }
  f = message.getListingcreator();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getCreatorgroup();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.HaulingListing.ListingStatus = {
  POSTED: 0,
  COMPLETE: 1
};

/**
 * @enum {number}
 */
proto.proto.HaulingListing.PermitParty = {
  NOPERMITREQUIRED: 0,
  HAULER: 1,
  SHIPPER: 2
};

/**
 * @enum {number}
 */
proto.proto.HaulingListing.Vehicle = {
  TRUCK: 0,
  TRACTORTRAILER: 1,
  WINCHTRUCK: 2
};

/**
 * @enum {number}
 */
proto.proto.HaulingListing.TowLoad = {
  NOTTOWABLE: 0,
  FLATBED: 1,
  GOOSENECK: 2,
  LOWBOW: 3,
  DROPDECK: 4,
  BOXTRAILER: 5,
  LOWBOY14FT: 6,
  HAULERDISCRETION: 7
};

/**
 * optional google.protobuf.Timestamp created = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.HaulingListing.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.HaulingListing.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp deleted = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.HaulingListing.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp listingExpirationTime = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.HaulingListing.prototype.getListingexpirationtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setListingexpirationtime = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearListingexpirationtime = function() {
  return this.setListingexpirationtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasListingexpirationtime = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.Timestamp completedAt = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.HaulingListing.prototype.getCompletedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setCompletedat = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearCompletedat = function() {
  return this.setCompletedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasCompletedat = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 weight = 7;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 maxListingQuanity = 27;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getMaxlistingquanity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setMaxlistingquanity = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int64 remainingListingQuantity = 28;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getRemaininglistingquantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setRemaininglistingquantity = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int64 estimatedTravelDistance = 19;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getEstimatedtraveldistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setEstimatedtraveldistance = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 towLength = 20;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getTowlength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setTowlength = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 userId = 14;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 groupId = 15;
 * @return {number}
 */
proto.proto.HaulingListing.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string item = 6;
 * @return {string}
 */
proto.proto.HaulingListing.prototype.getItem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setItem = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string note = 21;
 * @return {string}
 */
proto.proto.HaulingListing.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional bool bidToAnyone = 32;
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.getBidtoanyone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setBidtoanyone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional Vehicle vehicle = 8;
 * @return {!proto.proto.HaulingListing.Vehicle}
 */
proto.proto.HaulingListing.prototype.getVehicle = function() {
  return /** @type {!proto.proto.HaulingListing.Vehicle} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.proto.HaulingListing.Vehicle} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setVehicle = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional TowLoad towLoad = 9;
 * @return {!proto.proto.HaulingListing.TowLoad}
 */
proto.proto.HaulingListing.prototype.getTowload = function() {
  return /** @type {!proto.proto.HaulingListing.TowLoad} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.proto.HaulingListing.TowLoad} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setTowload = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional PermitParty permitter = 10;
 * @return {!proto.proto.HaulingListing.PermitParty}
 */
proto.proto.HaulingListing.prototype.getPermitter = function() {
  return /** @type {!proto.proto.HaulingListing.PermitParty} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.proto.HaulingListing.PermitParty} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setPermitter = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Bid placedBid = 23;
 * @return {?proto.proto.Bid}
 */
proto.proto.HaulingListing.prototype.getPlacedbid = function() {
  return /** @type{?proto.proto.Bid} */ (
    jspb.Message.getWrapperField(this, proto.proto.Bid, 23));
};


/**
 * @param {?proto.proto.Bid|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setPlacedbid = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearPlacedbid = function() {
  return this.setPlacedbid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasPlacedbid = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * repeated ScheduledJobAction scheduledJobActions = 29;
 * @return {!Array<!proto.proto.ScheduledJobAction>}
 */
proto.proto.HaulingListing.prototype.getScheduledjobactionsList = function() {
  return /** @type{!Array<!proto.proto.ScheduledJobAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ScheduledJobAction, 29));
};


/**
 * @param {!Array<!proto.proto.ScheduledJobAction>} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setScheduledjobactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 29, value);
};


/**
 * @param {!proto.proto.ScheduledJobAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ScheduledJobAction}
 */
proto.proto.HaulingListing.prototype.addScheduledjobactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 29, opt_value, proto.proto.ScheduledJobAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearScheduledjobactionsList = function() {
  return this.setScheduledjobactionsList([]);
};


/**
 * repeated Job assignedJobs = 30;
 * @return {!Array<!proto.proto.Job>}
 */
proto.proto.HaulingListing.prototype.getAssignedjobsList = function() {
  return /** @type{!Array<!proto.proto.Job>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Job, 30));
};


/**
 * @param {!Array<!proto.proto.Job>} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setAssignedjobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.proto.Job=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Job}
 */
proto.proto.HaulingListing.prototype.addAssignedjobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.proto.Job, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearAssignedjobsList = function() {
  return this.setAssignedjobsList([]);
};


/**
 * repeated Bid bids = 33;
 * @return {!Array<!proto.proto.Bid>}
 */
proto.proto.HaulingListing.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.proto.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Bid, 33));
};


/**
 * @param {!Array<!proto.proto.Bid>} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 33, value);
};


/**
 * @param {!proto.proto.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Bid}
 */
proto.proto.HaulingListing.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 33, opt_value, proto.proto.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * repeated S3Asset attachments = 36;
 * @return {!Array<!proto.proto.S3Asset>}
 */
proto.proto.HaulingListing.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.proto.S3Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.S3Asset, 36));
};


/**
 * @param {!Array<!proto.proto.S3Asset>} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.proto.S3Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.S3Asset}
 */
proto.proto.HaulingListing.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.proto.S3Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * optional ListingStatus status = 37;
 * @return {!proto.proto.HaulingListing.ListingStatus}
 */
proto.proto.HaulingListing.prototype.getStatus = function() {
  return /** @type {!proto.proto.HaulingListing.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {!proto.proto.HaulingListing.ListingStatus} value
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 37, value);
};


/**
 * optional User listingCreator = 34;
 * @return {?proto.proto.User}
 */
proto.proto.HaulingListing.prototype.getListingcreator = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 34));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setListingcreator = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearListingcreator = function() {
  return this.setListingcreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasListingcreator = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional Group creatorGroup = 35;
 * @return {?proto.proto.Group}
 */
proto.proto.HaulingListing.prototype.getCreatorgroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 35));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.HaulingListing} returns this
*/
proto.proto.HaulingListing.prototype.setCreatorgroup = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.HaulingListing} returns this
 */
proto.proto.HaulingListing.prototype.clearCreatorgroup = function() {
  return this.setCreatorgroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.HaulingListing.prototype.hasCreatorgroup = function() {
  return jspb.Message.getField(this, 35) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProvisionAttachmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProvisionAttachmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProvisionAttachmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProvisionAttachmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProvisionAttachmentRequest}
 */
proto.proto.ProvisionAttachmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProvisionAttachmentRequest;
  return proto.proto.ProvisionAttachmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProvisionAttachmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProvisionAttachmentRequest}
 */
proto.proto.ProvisionAttachmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiletype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProvisionAttachmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProvisionAttachmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProvisionAttachmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProvisionAttachmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiletype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.ProvisionAttachmentRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ProvisionAttachmentRequest} returns this
 */
proto.proto.ProvisionAttachmentRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileType = 3;
 * @return {string}
 */
proto.proto.ProvisionAttachmentRequest.prototype.getFiletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ProvisionAttachmentRequest} returns this
 */
proto.proto.ProvisionAttachmentRequest.prototype.setFiletype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.proto.ProvisionAttachmentRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProvisionAttachmentRequest} returns this
 */
proto.proto.ProvisionAttachmentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProvisionedURLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProvisionedURLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProvisionedURLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProvisionedURLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProvisionedURLResponse}
 */
proto.proto.ProvisionedURLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProvisionedURLResponse;
  return proto.proto.ProvisionedURLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProvisionedURLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProvisionedURLResponse}
 */
proto.proto.ProvisionedURLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProvisionedURLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProvisionedURLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProvisionedURLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProvisionedURLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.ProvisionedURLResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.ProvisionedURLResponse} returns this
*/
proto.proto.ProvisionedURLResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ProvisionedURLResponse} returns this
 */
proto.proto.ProvisionedURLResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ProvisionedURLResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.proto.ProvisionedURLResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ProvisionedURLResponse} returns this
 */
proto.proto.ProvisionedURLResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AssignDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AssignDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AssignDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driveruserid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    jobid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AssignDriverRequest}
 */
proto.proto.AssignDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AssignDriverRequest;
  return proto.proto.AssignDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AssignDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AssignDriverRequest}
 */
proto.proto.AssignDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriveruserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AssignDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AssignDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AssignDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssignDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriveruserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getJobid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.AssignDriverRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AssignDriverRequest} returns this
 */
proto.proto.AssignDriverRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 driverUserId = 2;
 * @return {number}
 */
proto.proto.AssignDriverRequest.prototype.getDriveruserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AssignDriverRequest} returns this
 */
proto.proto.AssignDriverRequest.prototype.setDriveruserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 jobId = 3;
 * @return {number}
 */
proto.proto.AssignDriverRequest.prototype.getJobid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AssignDriverRequest} returns this
 */
proto.proto.AssignDriverRequest.prototype.setJobid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ScheduledJobAction.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ScheduledJobAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ScheduledJobAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScheduledJobAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listingid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    note: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scheduledactiontime: (f = msg.getScheduledactiontime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actiontype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    site: (f = msg.getSite()) && proto.proto.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ScheduledJobAction}
 */
proto.proto.ScheduledJobAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ScheduledJobAction;
  return proto.proto.ScheduledJobAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ScheduledJobAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ScheduledJobAction}
 */
proto.proto.ScheduledJobAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setListingid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledactiontime(value);
      break;
    case 7:
      var value = /** @type {!proto.proto.ScheduledJobAction.ActionType} */ (reader.readEnum());
      msg.setActiontype(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 9:
      var value = new proto.proto.Site;
      reader.readMessage(value,proto.proto.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ScheduledJobAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ScheduledJobAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ScheduledJobAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ScheduledJobAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getListingid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScheduledactiontime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActiontype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.proto.Site.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.ScheduledJobAction.ActionType = {
  PICKUP: 0,
  DROPOFF: 1
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.ScheduledJobAction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 listingId = 2;
 * @return {number}
 */
proto.proto.ScheduledJobAction.prototype.getListingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.setListingid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 siteId = 3;
 * @return {number}
 */
proto.proto.ScheduledJobAction.prototype.getSiteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.setSiteid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 order = 4;
 * @return {number}
 */
proto.proto.ScheduledJobAction.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.proto.ScheduledJobAction.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp scheduledActionTime = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ScheduledJobAction.prototype.getScheduledactiontime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ScheduledJobAction} returns this
*/
proto.proto.ScheduledJobAction.prototype.setScheduledactiontime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.clearScheduledactiontime = function() {
  return this.setScheduledactiontime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ScheduledJobAction.prototype.hasScheduledactiontime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ActionType actionType = 7;
 * @return {!proto.proto.ScheduledJobAction.ActionType}
 */
proto.proto.ScheduledJobAction.prototype.getActiontype = function() {
  return /** @type {!proto.proto.ScheduledJobAction.ActionType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.proto.ScheduledJobAction.ActionType} value
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.setActiontype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp deleted = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ScheduledJobAction.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ScheduledJobAction} returns this
*/
proto.proto.ScheduledJobAction.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ScheduledJobAction.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Site site = 9;
 * @return {?proto.proto.Site}
 */
proto.proto.ScheduledJobAction.prototype.getSite = function() {
  return /** @type{?proto.proto.Site} */ (
    jspb.Message.getWrapperField(this, proto.proto.Site, 9));
};


/**
 * @param {?proto.proto.Site|undefined} value
 * @return {!proto.proto.ScheduledJobAction} returns this
*/
proto.proto.ScheduledJobAction.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ScheduledJobAction} returns this
 */
proto.proto.ScheduledJobAction.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ScheduledJobAction.prototype.hasSite = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.S3Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.S3Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.S3Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.S3Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    s3key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    presignedurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.S3Asset}
 */
proto.proto.S3Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.S3Asset;
  return proto.proto.S3Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.S3Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.S3Asset}
 */
proto.proto.S3Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setS3key(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.S3Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.S3Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.S3Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.S3Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getS3key();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPresignedurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string S3Key = 1;
 * @return {string}
 */
proto.proto.S3Asset.prototype.getS3key = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.S3Asset} returns this
 */
proto.proto.S3Asset.prototype.setS3key = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string PresignedURL = 2;
 * @return {string}
 */
proto.proto.S3Asset.prototype.getPresignedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.S3Asset} returns this
 */
proto.proto.S3Asset.prototype.setPresignedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StartJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StartJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StartJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StartJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jobid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startcoordinates: (f = msg.getStartcoordinates()) && proto.proto.Coordinates.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StartJobRequest}
 */
proto.proto.StartJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StartJobRequest;
  return proto.proto.StartJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StartJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StartJobRequest}
 */
proto.proto.StartJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobid(value);
      break;
    case 3:
      var value = new proto.proto.Coordinates;
      reader.readMessage(value,proto.proto.Coordinates.deserializeBinaryFromReader);
      msg.setStartcoordinates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StartJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StartJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StartJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StartJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJobid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStartcoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.Coordinates.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.StartJobRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StartJobRequest} returns this
 */
proto.proto.StartJobRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 jobId = 2;
 * @return {number}
 */
proto.proto.StartJobRequest.prototype.getJobid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StartJobRequest} returns this
 */
proto.proto.StartJobRequest.prototype.setJobid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Coordinates startCoordinates = 3;
 * @return {?proto.proto.Coordinates}
 */
proto.proto.StartJobRequest.prototype.getStartcoordinates = function() {
  return /** @type{?proto.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.proto.Coordinates, 3));
};


/**
 * @param {?proto.proto.Coordinates|undefined} value
 * @return {!proto.proto.StartJobRequest} returns this
*/
proto.proto.StartJobRequest.prototype.setStartcoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StartJobRequest} returns this
 */
proto.proto.StartJobRequest.prototype.clearStartcoordinates = function() {
  return this.setStartcoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StartJobRequest.prototype.hasStartcoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ConfirmPickupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ConfirmPickupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ConfirmPickupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ConfirmPickupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jobid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pickupcoordinates: (f = msg.getPickupcoordinates()) && proto.proto.Coordinates.toObject(includeInstance, f),
    pickuptime: (f = msg.getPickuptime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ConfirmPickupRequest}
 */
proto.proto.ConfirmPickupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ConfirmPickupRequest;
  return proto.proto.ConfirmPickupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ConfirmPickupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ConfirmPickupRequest}
 */
proto.proto.ConfirmPickupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobid(value);
      break;
    case 3:
      var value = new proto.proto.Coordinates;
      reader.readMessage(value,proto.proto.Coordinates.deserializeBinaryFromReader);
      msg.setPickupcoordinates(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPickuptime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ConfirmPickupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ConfirmPickupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ConfirmPickupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ConfirmPickupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJobid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPickupcoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getPickuptime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.ConfirmPickupRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ConfirmPickupRequest} returns this
 */
proto.proto.ConfirmPickupRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 jobId = 2;
 * @return {number}
 */
proto.proto.ConfirmPickupRequest.prototype.getJobid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ConfirmPickupRequest} returns this
 */
proto.proto.ConfirmPickupRequest.prototype.setJobid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Coordinates pickupCoordinates = 3;
 * @return {?proto.proto.Coordinates}
 */
proto.proto.ConfirmPickupRequest.prototype.getPickupcoordinates = function() {
  return /** @type{?proto.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.proto.Coordinates, 3));
};


/**
 * @param {?proto.proto.Coordinates|undefined} value
 * @return {!proto.proto.ConfirmPickupRequest} returns this
*/
proto.proto.ConfirmPickupRequest.prototype.setPickupcoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ConfirmPickupRequest} returns this
 */
proto.proto.ConfirmPickupRequest.prototype.clearPickupcoordinates = function() {
  return this.setPickupcoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ConfirmPickupRequest.prototype.hasPickupcoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp pickupTime = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ConfirmPickupRequest.prototype.getPickuptime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ConfirmPickupRequest} returns this
*/
proto.proto.ConfirmPickupRequest.prototype.setPickuptime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ConfirmPickupRequest} returns this
 */
proto.proto.ConfirmPickupRequest.prototype.clearPickuptime = function() {
  return this.setPickuptime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ConfirmPickupRequest.prototype.hasPickuptime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CompleteJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CompleteJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CompleteJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CompleteJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jobid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dropoffcoordinates: (f = msg.getDropoffcoordinates()) && proto.proto.Coordinates.toObject(includeInstance, f),
    completiontime: (f = msg.getCompletiontime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CompleteJobRequest}
 */
proto.proto.CompleteJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CompleteJobRequest;
  return proto.proto.CompleteJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CompleteJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CompleteJobRequest}
 */
proto.proto.CompleteJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobid(value);
      break;
    case 3:
      var value = new proto.proto.Coordinates;
      reader.readMessage(value,proto.proto.Coordinates.deserializeBinaryFromReader);
      msg.setDropoffcoordinates(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletiontime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CompleteJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CompleteJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CompleteJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CompleteJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJobid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDropoffcoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getCompletiontime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.CompleteJobRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CompleteJobRequest} returns this
 */
proto.proto.CompleteJobRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 jobId = 2;
 * @return {number}
 */
proto.proto.CompleteJobRequest.prototype.getJobid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CompleteJobRequest} returns this
 */
proto.proto.CompleteJobRequest.prototype.setJobid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Coordinates dropOffCoordinates = 3;
 * @return {?proto.proto.Coordinates}
 */
proto.proto.CompleteJobRequest.prototype.getDropoffcoordinates = function() {
  return /** @type{?proto.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.proto.Coordinates, 3));
};


/**
 * @param {?proto.proto.Coordinates|undefined} value
 * @return {!proto.proto.CompleteJobRequest} returns this
*/
proto.proto.CompleteJobRequest.prototype.setDropoffcoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CompleteJobRequest} returns this
 */
proto.proto.CompleteJobRequest.prototype.clearDropoffcoordinates = function() {
  return this.setDropoffcoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CompleteJobRequest.prototype.hasDropoffcoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp completionTime = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.CompleteJobRequest.prototype.getCompletiontime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.CompleteJobRequest} returns this
*/
proto.proto.CompleteJobRequest.prototype.setCompletiontime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CompleteJobRequest} returns this
 */
proto.proto.CompleteJobRequest.prototype.clearCompletiontime = function() {
  return this.setCompletiontime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CompleteJobRequest.prototype.hasCompletiontime = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Job.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startedat: (f = msg.getStartedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endedat: (f = msg.getEndedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 6, 0),
    driveruserid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    assignedbyuserid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    issuedbyuserid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    listingid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    haulergroupid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    fieldticketid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    actionrecordsList: jspb.Message.toObjectList(msg.getActionrecordsList(),
    proto.proto.JobActionRecord.toObject, includeInstance),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.proto.S3Asset.toObject, includeInstance),
    inflatedriver: (f = msg.getInflatedriver()) && proto.proto.User.toObject(includeInstance, f),
    inflatehaulergroup: (f = msg.getInflatehaulergroup()) && proto.proto.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Job}
 */
proto.proto.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Job;
  return proto.proto.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Job}
 */
proto.proto.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndedat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriveruserid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssignedbyuserid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssuedbyuserid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setListingid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHaulergroupid(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFieldticketid(value);
      break;
    case 13:
      var value = /** @type {!proto.proto.Job.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = new proto.proto.JobActionRecord;
      reader.readMessage(value,proto.proto.JobActionRecord.deserializeBinaryFromReader);
      msg.addActionrecords(value);
      break;
    case 15:
      var value = new proto.proto.S3Asset;
      reader.readMessage(value,proto.proto.S3Asset.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 16:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setInflatedriver(value);
      break;
    case 17:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setInflatehaulergroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDriveruserid();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAssignedbyuserid();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIssuedbyuserid();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getListingid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getHaulergroupid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getFieldticketid();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getActionrecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.proto.JobActionRecord.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.proto.S3Asset.serializeBinaryToWriter
    );
  }
  f = message.getInflatedriver();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getInflatehaulergroup();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.Job.Status = {
  UNASSIGNED: 0,
  ASSIGNED: 1,
  STARTED: 2,
  ENDED: 3
};

/**
 * optional google.protobuf.Timestamp created = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Job.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Job.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Job.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Job.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp deleted = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Job.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Job.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp startedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Job.prototype.getStartedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setStartedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearStartedat = function() {
  return this.setStartedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Job.prototype.hasStartedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp endedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Job.prototype.getEndedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setEndedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearEndedat = function() {
  return this.setEndedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Job.prototype.hasEndedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 id = 6;
 * @return {number}
 */
proto.proto.Job.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 driverUserId = 7;
 * @return {number}
 */
proto.proto.Job.prototype.getDriveruserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setDriveruserid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 assignedByUserId = 8;
 * @return {number}
 */
proto.proto.Job.prototype.getAssignedbyuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setAssignedbyuserid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 issuedByUserId = 9;
 * @return {number}
 */
proto.proto.Job.prototype.getIssuedbyuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setIssuedbyuserid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 listingId = 10;
 * @return {number}
 */
proto.proto.Job.prototype.getListingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setListingid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 haulerGroupId = 11;
 * @return {number}
 */
proto.proto.Job.prototype.getHaulergroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setHaulergroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 fieldTicketId = 12;
 * @return {number}
 */
proto.proto.Job.prototype.getFieldticketid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setFieldticketid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional Status status = 13;
 * @return {!proto.proto.Job.Status}
 */
proto.proto.Job.prototype.getStatus = function() {
  return /** @type {!proto.proto.Job.Status} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.proto.Job.Status} value
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * repeated JobActionRecord actionRecords = 14;
 * @return {!Array<!proto.proto.JobActionRecord>}
 */
proto.proto.Job.prototype.getActionrecordsList = function() {
  return /** @type{!Array<!proto.proto.JobActionRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.JobActionRecord, 14));
};


/**
 * @param {!Array<!proto.proto.JobActionRecord>} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setActionrecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.proto.JobActionRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.JobActionRecord}
 */
proto.proto.Job.prototype.addActionrecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.proto.JobActionRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearActionrecordsList = function() {
  return this.setActionrecordsList([]);
};


/**
 * repeated S3Asset attachments = 15;
 * @return {!Array<!proto.proto.S3Asset>}
 */
proto.proto.Job.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.proto.S3Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.S3Asset, 15));
};


/**
 * @param {!Array<!proto.proto.S3Asset>} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.proto.S3Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.S3Asset}
 */
proto.proto.Job.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.proto.S3Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * optional User inflateDriver = 16;
 * @return {?proto.proto.User}
 */
proto.proto.Job.prototype.getInflatedriver = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 16));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setInflatedriver = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearInflatedriver = function() {
  return this.setInflatedriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Job.prototype.hasInflatedriver = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Group inflateHaulerGroup = 17;
 * @return {?proto.proto.Group}
 */
proto.proto.Job.prototype.getInflatehaulergroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 17));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.Job} returns this
*/
proto.proto.Job.prototype.setInflatehaulergroup = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Job} returns this
 */
proto.proto.Job.prototype.clearInflatehaulergroup = function() {
  return this.setInflatehaulergroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Job.prototype.hasInflatehaulergroup = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.JobActionRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.JobActionRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.JobActionRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.JobActionRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledactionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actioncoordinates: (f = msg.getActioncoordinates()) && proto.proto.Coordinates.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.JobActionRecord}
 */
proto.proto.JobActionRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.JobActionRecord;
  return proto.proto.JobActionRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.JobActionRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.JobActionRecord}
 */
proto.proto.JobActionRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledactionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 4:
      var value = new proto.proto.Coordinates;
      reader.readMessage(value,proto.proto.Coordinates.deserializeBinaryFromReader);
      msg.setActioncoordinates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.JobActionRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.JobActionRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.JobActionRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.JobActionRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledactionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getJobid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActioncoordinates();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.Coordinates.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 scheduledActionId = 1;
 * @return {number}
 */
proto.proto.JobActionRecord.prototype.getScheduledactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.JobActionRecord} returns this
 */
proto.proto.JobActionRecord.prototype.setScheduledactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 jobId = 2;
 * @return {number}
 */
proto.proto.JobActionRecord.prototype.getJobid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.JobActionRecord} returns this
 */
proto.proto.JobActionRecord.prototype.setJobid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.JobActionRecord.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.JobActionRecord} returns this
*/
proto.proto.JobActionRecord.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.JobActionRecord} returns this
 */
proto.proto.JobActionRecord.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.JobActionRecord.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Coordinates actionCoordinates = 4;
 * @return {?proto.proto.Coordinates}
 */
proto.proto.JobActionRecord.prototype.getActioncoordinates = function() {
  return /** @type{?proto.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.proto.Coordinates, 4));
};


/**
 * @param {?proto.proto.Coordinates|undefined} value
 * @return {!proto.proto.JobActionRecord} returns this
*/
proto.proto.JobActionRecord.prototype.setActioncoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.JobActionRecord} returns this
 */
proto.proto.JobActionRecord.prototype.clearActioncoordinates = function() {
  return this.setActioncoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.JobActionRecord.prototype.hasActioncoordinates = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.WaypointResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.WaypointResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.WaypointResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.WaypointResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.WaypointResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.proto.Waypoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.WaypointResponse}
 */
proto.proto.WaypointResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.WaypointResponse;
  return proto.proto.WaypointResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.WaypointResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.WaypointResponse}
 */
proto.proto.WaypointResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.Waypoint;
      reader.readMessage(value,proto.proto.Waypoint.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.WaypointResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.WaypointResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.WaypointResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.WaypointResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.WaypointResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.WaypointResponse} returns this
*/
proto.proto.WaypointResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.WaypointResponse} returns this
 */
proto.proto.WaypointResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.WaypointResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Waypoint waypoints = 2;
 * @return {!Array<!proto.proto.Waypoint>}
 */
proto.proto.WaypointResponse.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.proto.Waypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Waypoint, 2));
};


/**
 * @param {!Array<!proto.proto.Waypoint>} value
 * @return {!proto.proto.WaypointResponse} returns this
*/
proto.proto.WaypointResponse.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.Waypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Waypoint}
 */
proto.proto.WaypointResponse.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.Waypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.WaypointResponse} returns this
 */
proto.proto.WaypointResponse.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Waypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Waypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Waypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Waypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    accuracy: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Waypoint}
 */
proto.proto.Waypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Waypoint;
  return proto.proto.Waypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Waypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Waypoint}
 */
proto.proto.Waypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeading(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAccuracy(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Waypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Waypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Waypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Waypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getJobid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAccuracy();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.Waypoint.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 jobId = 2;
 * @return {number}
 */
proto.proto.Waypoint.prototype.getJobid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.setJobid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Waypoint.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Waypoint} returns this
*/
proto.proto.Waypoint.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Waypoint.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double latitude = 4;
 * @return {number}
 */
proto.proto.Waypoint.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double longitude = 5;
 * @return {number}
 */
proto.proto.Waypoint.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double heading = 6;
 * @return {number}
 */
proto.proto.Waypoint.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double accuracy = 7;
 * @return {number}
 */
proto.proto.Waypoint.prototype.getAccuracy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.setAccuracy = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double speed = 8;
 * @return {number}
 */
proto.proto.Waypoint.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Waypoint} returns this
 */
proto.proto.Waypoint.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SaveWaypointsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SaveWaypointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SaveWaypointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SaveWaypointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SaveWaypointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.proto.Waypoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SaveWaypointsRequest}
 */
proto.proto.SaveWaypointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SaveWaypointsRequest;
  return proto.proto.SaveWaypointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SaveWaypointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SaveWaypointsRequest}
 */
proto.proto.SaveWaypointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.Waypoint;
      reader.readMessage(value,proto.proto.Waypoint.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SaveWaypointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SaveWaypointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SaveWaypointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SaveWaypointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.SaveWaypointsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SaveWaypointsRequest} returns this
 */
proto.proto.SaveWaypointsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Waypoint waypoints = 2;
 * @return {!Array<!proto.proto.Waypoint>}
 */
proto.proto.SaveWaypointsRequest.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.proto.Waypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Waypoint, 2));
};


/**
 * @param {!Array<!proto.proto.Waypoint>} value
 * @return {!proto.proto.SaveWaypointsRequest} returns this
*/
proto.proto.SaveWaypointsRequest.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.Waypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Waypoint}
 */
proto.proto.SaveWaypointsRequest.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.Waypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SaveWaypointsRequest} returns this
 */
proto.proto.SaveWaypointsRequest.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ErrorReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ErrorReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ErrorReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ErrorReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ErrorReportRequest}
 */
proto.proto.ErrorReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ErrorReportRequest;
  return proto.proto.ErrorReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ErrorReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ErrorReportRequest}
 */
proto.proto.ErrorReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ErrorReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ErrorReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ErrorReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ErrorReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.ErrorReportRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ErrorReportRequest} returns this
 */
proto.proto.ErrorReportRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.proto.ErrorReportRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ErrorReportRequest} returns this
 */
proto.proto.ErrorReportRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ResyncResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ResyncResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ResyncResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResyncResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    shouldresync: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ResyncResponse}
 */
proto.proto.ResyncResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ResyncResponse;
  return proto.proto.ResyncResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ResyncResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ResyncResponse}
 */
proto.proto.ResyncResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldresync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ResyncResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ResyncResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ResyncResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ResyncResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getShouldresync();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.ResyncResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.ResyncResponse} returns this
*/
proto.proto.ResyncResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ResyncResponse} returns this
 */
proto.proto.ResyncResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ResyncResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool shouldResync = 2;
 * @return {boolean}
 */
proto.proto.ResyncResponse.prototype.getShouldresync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ResyncResponse} returns this
 */
proto.proto.ResyncResponse.prototype.setShouldresync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PendingRegistrationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PendingRegistrationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PendingRegistrationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PendingRegistrationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PendingRegistrationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    registrationsList: jspb.Message.toObjectList(msg.getRegistrationsList(),
    proto.proto.PendingRegistration.toObject, includeInstance),
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PendingRegistrationsResponse}
 */
proto.proto.PendingRegistrationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PendingRegistrationsResponse;
  return proto.proto.PendingRegistrationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PendingRegistrationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PendingRegistrationsResponse}
 */
proto.proto.PendingRegistrationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PendingRegistration;
      reader.readMessage(value,proto.proto.PendingRegistration.deserializeBinaryFromReader);
      msg.addRegistrations(value);
      break;
    case 2:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PendingRegistrationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PendingRegistrationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PendingRegistrationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PendingRegistrationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PendingRegistration.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingRegistration registrations = 1;
 * @return {!Array<!proto.proto.PendingRegistration>}
 */
proto.proto.PendingRegistrationsResponse.prototype.getRegistrationsList = function() {
  return /** @type{!Array<!proto.proto.PendingRegistration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PendingRegistration, 1));
};


/**
 * @param {!Array<!proto.proto.PendingRegistration>} value
 * @return {!proto.proto.PendingRegistrationsResponse} returns this
*/
proto.proto.PendingRegistrationsResponse.prototype.setRegistrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PendingRegistration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PendingRegistration}
 */
proto.proto.PendingRegistrationsResponse.prototype.addRegistrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PendingRegistration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PendingRegistrationsResponse} returns this
 */
proto.proto.PendingRegistrationsResponse.prototype.clearRegistrationsList = function() {
  return this.setRegistrationsList([]);
};


/**
 * optional Error error = 2;
 * @return {?proto.proto.Error}
 */
proto.proto.PendingRegistrationsResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 2));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.PendingRegistrationsResponse} returns this
*/
proto.proto.PendingRegistrationsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PendingRegistrationsResponse} returns this
 */
proto.proto.PendingRegistrationsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PendingRegistrationsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PendingRegistration.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PendingRegistration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PendingRegistration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PendingRegistration.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    groupid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accesscode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    id: jspb.Message.getFieldWithDefault(msg, 10, 0),
    expiresat: (f = msg.getExpiresat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PendingRegistration}
 */
proto.proto.PendingRegistration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PendingRegistration;
  return proto.proto.PendingRegistration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PendingRegistration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PendingRegistration}
 */
proto.proto.PendingRegistration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.User.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesscode(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresat(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PendingRegistration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PendingRegistration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PendingRegistration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PendingRegistration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAccesscode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getExpiresat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.proto.PendingRegistration.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phoneNumber = 2;
 * @return {string}
 */
proto.proto.PendingRegistration.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional User.UserType userType = 3;
 * @return {!proto.proto.User.UserType}
 */
proto.proto.PendingRegistration.prototype.getUsertype = function() {
  return /** @type {!proto.proto.User.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.User.UserType} value
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 groupId = 4;
 * @return {number}
 */
proto.proto.PendingRegistration.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string accessCode = 5;
 * @return {string}
 */
proto.proto.PendingRegistration.prototype.getAccesscode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.setAccesscode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 id = 10;
 * @return {number}
 */
proto.proto.PendingRegistration.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp expiresAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.PendingRegistration.prototype.getExpiresat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.PendingRegistration} returns this
*/
proto.proto.PendingRegistration.prototype.setExpiresat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.clearExpiresat = function() {
  return this.setExpiresat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PendingRegistration.prototype.hasExpiresat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp created = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.PendingRegistration.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.PendingRegistration} returns this
*/
proto.proto.PendingRegistration.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PendingRegistration.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.PendingRegistration.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.PendingRegistration} returns this
*/
proto.proto.PendingRegistration.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PendingRegistration.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp deleted = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.PendingRegistration.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.PendingRegistration} returns this
*/
proto.proto.PendingRegistration.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PendingRegistration} returns this
 */
proto.proto.PendingRegistration.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PendingRegistration.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AssociationRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AssociationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AssociationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AssociationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssociationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    associatedgroupsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AssociationRequest}
 */
proto.proto.AssociationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AssociationRequest;
  return proto.proto.AssociationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AssociationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AssociationRequest}
 */
proto.proto.AssociationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAssociatedgroups(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AssociationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AssociationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AssociationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AssociationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAssociatedgroupsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.AssociationRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AssociationRequest} returns this
 */
proto.proto.AssociationRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 groupId = 2;
 * @return {number}
 */
proto.proto.AssociationRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AssociationRequest} returns this
 */
proto.proto.AssociationRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 associatedGroups = 3;
 * @return {!Array<number>}
 */
proto.proto.AssociationRequest.prototype.getAssociatedgroupsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AssociationRequest} returns this
 */
proto.proto.AssociationRequest.prototype.setAssociatedgroupsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AssociationRequest} returns this
 */
proto.proto.AssociationRequest.prototype.addAssociatedgroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AssociationRequest} returns this
 */
proto.proto.AssociationRequest.prototype.clearAssociatedgroupsList = function() {
  return this.setAssociatedgroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MissingSiteReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MissingSiteReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MissingSiteReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MissingSiteReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MissingSiteReportRequest}
 */
proto.proto.MissingSiteReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MissingSiteReportRequest;
  return proto.proto.MissingSiteReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MissingSiteReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MissingSiteReportRequest}
 */
proto.proto.MissingSiteReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MissingSiteReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MissingSiteReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MissingSiteReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MissingSiteReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.MissingSiteReportRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MissingSiteReportRequest} returns this
 */
proto.proto.MissingSiteReportRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.proto.MissingSiteReportRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MissingSiteReportRequest} returns this
 */
proto.proto.MissingSiteReportRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeviceIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeviceIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeviceIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeviceIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    platformid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeviceIdRequest}
 */
proto.proto.DeviceIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeviceIdRequest;
  return proto.proto.DeviceIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeviceIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeviceIdRequest}
 */
proto.proto.DeviceIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatformid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeviceIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeviceIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeviceIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeviceIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlatformid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.DeviceIdRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeviceIdRequest} returns this
 */
proto.proto.DeviceIdRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deviceId = 2;
 * @return {string}
 */
proto.proto.DeviceIdRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeviceIdRequest} returns this
 */
proto.proto.DeviceIdRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string platformId = 3;
 * @return {string}
 */
proto.proto.DeviceIdRequest.prototype.getPlatformid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeviceIdRequest} returns this
 */
proto.proto.DeviceIdRequest.prototype.setPlatformid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Coordinates.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Coordinates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Coordinates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Coordinates.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Coordinates}
 */
proto.proto.Coordinates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Coordinates;
  return proto.proto.Coordinates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Coordinates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Coordinates}
 */
proto.proto.Coordinates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Coordinates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Coordinates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Coordinates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Coordinates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.proto.Coordinates.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Coordinates} returns this
 */
proto.proto.Coordinates.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.proto.Coordinates.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Coordinates} returns this
 */
proto.proto.Coordinates.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Infraction.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Infraction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Infraction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Infraction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    infractiontimestamp: (f = msg.getInfractiontimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    userid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    infractiontype: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Infraction}
 */
proto.proto.Infraction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Infraction;
  return proto.proto.Infraction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Infraction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Infraction}
 */
proto.proto.Infraction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInfractiontimestamp(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfractiontype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Infraction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Infraction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Infraction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Infraction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInfractiontimestamp();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getInfractiontype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.Infraction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Infraction.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Infraction} returns this
*/
proto.proto.Infraction.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Infraction.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Infraction.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Infraction} returns this
*/
proto.proto.Infraction.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Infraction.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp deleted = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Infraction.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Infraction} returns this
*/
proto.proto.Infraction.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Infraction.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp infractionTimestamp = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Infraction.prototype.getInfractiontimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Infraction} returns this
*/
proto.proto.Infraction.prototype.setInfractiontimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.clearInfractiontimestamp = function() {
  return this.setInfractiontimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Infraction.prototype.hasInfractiontimestamp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional double latitude = 12;
 * @return {number}
 */
proto.proto.Infraction.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double longitude = 13;
 * @return {number}
 */
proto.proto.Infraction.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int64 userId = 15;
 * @return {number}
 */
proto.proto.Infraction.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string infractionType = 9;
 * @return {string}
 */
proto.proto.Infraction.prototype.getInfractiontype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Infraction} returns this
 */
proto.proto.Infraction.prototype.setInfractiontype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SiteAccessChangeRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SiteAccessChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SiteAccessChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SiteAccessChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteAccessChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accessperiodsList: jspb.Message.toObjectList(msg.getAccessperiodsList(),
    proto.proto.SiteAccessPeriod.toObject, includeInstance),
    restrictionperiodsList: jspb.Message.toObjectList(msg.getRestrictionperiodsList(),
    proto.proto.SiteRestrictionPeriod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SiteAccessChangeRequest}
 */
proto.proto.SiteAccessChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SiteAccessChangeRequest;
  return proto.proto.SiteAccessChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SiteAccessChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SiteAccessChangeRequest}
 */
proto.proto.SiteAccessChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteid(value);
      break;
    case 3:
      var value = new proto.proto.SiteAccessPeriod;
      reader.readMessage(value,proto.proto.SiteAccessPeriod.deserializeBinaryFromReader);
      msg.addAccessperiods(value);
      break;
    case 4:
      var value = new proto.proto.SiteRestrictionPeriod;
      reader.readMessage(value,proto.proto.SiteRestrictionPeriod.deserializeBinaryFromReader);
      msg.addRestrictionperiods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SiteAccessChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SiteAccessChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SiteAccessChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteAccessChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccessperiodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.proto.SiteAccessPeriod.serializeBinaryToWriter
    );
  }
  f = message.getRestrictionperiodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.proto.SiteRestrictionPeriod.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.SiteAccessChangeRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SiteAccessChangeRequest} returns this
 */
proto.proto.SiteAccessChangeRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 siteId = 2;
 * @return {number}
 */
proto.proto.SiteAccessChangeRequest.prototype.getSiteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SiteAccessChangeRequest} returns this
 */
proto.proto.SiteAccessChangeRequest.prototype.setSiteid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated SiteAccessPeriod accessPeriods = 3;
 * @return {!Array<!proto.proto.SiteAccessPeriod>}
 */
proto.proto.SiteAccessChangeRequest.prototype.getAccessperiodsList = function() {
  return /** @type{!Array<!proto.proto.SiteAccessPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SiteAccessPeriod, 3));
};


/**
 * @param {!Array<!proto.proto.SiteAccessPeriod>} value
 * @return {!proto.proto.SiteAccessChangeRequest} returns this
*/
proto.proto.SiteAccessChangeRequest.prototype.setAccessperiodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.proto.SiteAccessPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SiteAccessPeriod}
 */
proto.proto.SiteAccessChangeRequest.prototype.addAccessperiods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.proto.SiteAccessPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SiteAccessChangeRequest} returns this
 */
proto.proto.SiteAccessChangeRequest.prototype.clearAccessperiodsList = function() {
  return this.setAccessperiodsList([]);
};


/**
 * repeated SiteRestrictionPeriod restrictionPeriods = 4;
 * @return {!Array<!proto.proto.SiteRestrictionPeriod>}
 */
proto.proto.SiteAccessChangeRequest.prototype.getRestrictionperiodsList = function() {
  return /** @type{!Array<!proto.proto.SiteRestrictionPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SiteRestrictionPeriod, 4));
};


/**
 * @param {!Array<!proto.proto.SiteRestrictionPeriod>} value
 * @return {!proto.proto.SiteAccessChangeRequest} returns this
*/
proto.proto.SiteAccessChangeRequest.prototype.setRestrictionperiodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.proto.SiteRestrictionPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SiteRestrictionPeriod}
 */
proto.proto.SiteAccessChangeRequest.prototype.addRestrictionperiods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.proto.SiteRestrictionPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SiteAccessChangeRequest} returns this
 */
proto.proto.SiteAccessChangeRequest.prototype.clearRestrictionperiodsList = function() {
  return this.setRestrictionperiodsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SiteListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SiteListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SiteListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SiteListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    siteList: jspb.Message.toObjectList(msg.getSiteList(),
    proto.proto.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SiteListResponse}
 */
proto.proto.SiteListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SiteListResponse;
  return proto.proto.SiteListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SiteListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SiteListResponse}
 */
proto.proto.SiteListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.Site;
      reader.readMessage(value,proto.proto.Site.deserializeBinaryFromReader);
      msg.addSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SiteListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SiteListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SiteListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getSiteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.SiteListResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.SiteListResponse} returns this
*/
proto.proto.SiteListResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteListResponse} returns this
 */
proto.proto.SiteListResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Site site = 2;
 * @return {!Array<!proto.proto.Site>}
 */
proto.proto.SiteListResponse.prototype.getSiteList = function() {
  return /** @type{!Array<!proto.proto.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Site, 2));
};


/**
 * @param {!Array<!proto.proto.Site>} value
 * @return {!proto.proto.SiteListResponse} returns this
*/
proto.proto.SiteListResponse.prototype.setSiteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Site}
 */
proto.proto.SiteListResponse.prototype.addSite = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SiteListResponse} returns this
 */
proto.proto.SiteListResponse.prototype.clearSiteList = function() {
  return this.setSiteList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    site: (f = msg.getSite()) && proto.proto.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SiteResponse}
 */
proto.proto.SiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SiteResponse;
  return proto.proto.SiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SiteResponse}
 */
proto.proto.SiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.Site;
      reader.readMessage(value,proto.proto.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.SiteResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.SiteResponse} returns this
*/
proto.proto.SiteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteResponse} returns this
 */
proto.proto.SiteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Site site = 2;
 * @return {?proto.proto.Site}
 */
proto.proto.SiteResponse.prototype.getSite = function() {
  return /** @type{?proto.proto.Site} */ (
    jspb.Message.getWrapperField(this, proto.proto.Site, 2));
};


/**
 * @param {?proto.proto.Site|undefined} value
 * @return {!proto.proto.SiteResponse} returns this
*/
proto.proto.SiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteResponse} returns this
 */
proto.proto.SiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    site: (f = msg.getSite()) && proto.proto.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SiteRequest}
 */
proto.proto.SiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SiteRequest;
  return proto.proto.SiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SiteRequest}
 */
proto.proto.SiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.Site;
      reader.readMessage(value,proto.proto.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.SiteRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SiteRequest} returns this
 */
proto.proto.SiteRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Site site = 2;
 * @return {?proto.proto.Site}
 */
proto.proto.SiteRequest.prototype.getSite = function() {
  return /** @type{?proto.proto.Site} */ (
    jspb.Message.getWrapperField(this, proto.proto.Site, 2));
};


/**
 * @param {?proto.proto.Site|undefined} value
 * @return {!proto.proto.SiteRequest} returns this
*/
proto.proto.SiteRequest.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteRequest} returns this
 */
proto.proto.SiteRequest.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteRequest.prototype.hasSite = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Site.repeatedFields_ = [7,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastactive: (f = msg.getLastactive()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    autoremovefrommapdate: (f = msg.getAutoremovefrommapdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    note: jspb.Message.getFieldWithDefault(msg, 9, ""),
    roadclosurecomment: jspb.Message.getFieldWithDefault(msg, 19, ""),
    groupid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    restrictionperiodList: jspb.Message.toObjectList(msg.getRestrictionperiodList(),
    proto.proto.SiteRestrictionPeriod.toObject, includeInstance),
    stubwaypointsList: jspb.Message.toObjectList(msg.getStubwaypointsList(),
    proto.proto.StubWaypoint.toObject, includeInstance),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    stubbed: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    roadclosure: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    addressbased: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    directionsunverified: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    showonmap: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    status: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Site}
 */
proto.proto.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Site;
  return proto.proto.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Site}
 */
proto.proto.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastactive(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAutoremovefrommapdate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoadclosurecomment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGroupid(value);
      break;
    case 7:
      var value = new proto.proto.SiteRestrictionPeriod;
      reader.readMessage(value,proto.proto.SiteRestrictionPeriod.deserializeBinaryFromReader);
      msg.addRestrictionperiod(value);
      break;
    case 15:
      var value = new proto.proto.StubWaypoint;
      reader.readMessage(value,proto.proto.StubWaypoint.deserializeBinaryFromReader);
      msg.addStubwaypoints(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStubbed(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRoadclosure(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddressbased(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirectionsunverified(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowonmap(value);
      break;
    case 21:
      var value = /** @type {!proto.proto.Site.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastactive();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAutoremovefrommapdate();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRoadclosurecomment();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getRestrictionperiodList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.proto.SiteRestrictionPeriod.serializeBinaryToWriter
    );
  }
  f = message.getStubwaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.proto.StubWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getStubbed();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getRoadclosure();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getAddressbased();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getDirectionsunverified();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getShowonmap();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.Site.Status = {
  RIG: 0,
  FRACK: 1,
  OTHER: 2
};

/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.proto.Site.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Site.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Site} returns this
*/
proto.proto.Site.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Site.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Site.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Site} returns this
*/
proto.proto.Site.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Site.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp deleted = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Site.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Site} returns this
*/
proto.proto.Site.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Site.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp lastActive = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Site.prototype.getLastactive = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Site} returns this
*/
proto.proto.Site.prototype.setLastactive = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.clearLastactive = function() {
  return this.setLastactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Site.prototype.hasLastactive = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Timestamp autoRemoveFromMapDate = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Site.prototype.getAutoremovefrommapdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Site} returns this
*/
proto.proto.Site.prototype.setAutoremovefrommapdate = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.clearAutoremovefrommapdate = function() {
  return this.setAutoremovefrommapdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Site.prototype.hasAutoremovefrommapdate = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional double latitude = 2;
 * @return {number}
 */
proto.proto.Site.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double longitude = 3;
 * @return {number}
 */
proto.proto.Site.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.proto.Site.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string note = 9;
 * @return {string}
 */
proto.proto.Site.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string roadClosureComment = 19;
 * @return {string}
 */
proto.proto.Site.prototype.getRoadclosurecomment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setRoadclosurecomment = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional uint64 groupId = 6;
 * @return {number}
 */
proto.proto.Site.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated SiteRestrictionPeriod restrictionPeriod = 7;
 * @return {!Array<!proto.proto.SiteRestrictionPeriod>}
 */
proto.proto.Site.prototype.getRestrictionperiodList = function() {
  return /** @type{!Array<!proto.proto.SiteRestrictionPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SiteRestrictionPeriod, 7));
};


/**
 * @param {!Array<!proto.proto.SiteRestrictionPeriod>} value
 * @return {!proto.proto.Site} returns this
*/
proto.proto.Site.prototype.setRestrictionperiodList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.proto.SiteRestrictionPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SiteRestrictionPeriod}
 */
proto.proto.Site.prototype.addRestrictionperiod = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.proto.SiteRestrictionPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.clearRestrictionperiodList = function() {
  return this.setRestrictionperiodList([]);
};


/**
 * repeated StubWaypoint stubWaypoints = 15;
 * @return {!Array<!proto.proto.StubWaypoint>}
 */
proto.proto.Site.prototype.getStubwaypointsList = function() {
  return /** @type{!Array<!proto.proto.StubWaypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StubWaypoint, 15));
};


/**
 * @param {!Array<!proto.proto.StubWaypoint>} value
 * @return {!proto.proto.Site} returns this
*/
proto.proto.Site.prototype.setStubwaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.proto.StubWaypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StubWaypoint}
 */
proto.proto.Site.prototype.addStubwaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.proto.StubWaypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.clearStubwaypointsList = function() {
  return this.setStubwaypointsList([]);
};


/**
 * optional bool active = 13;
 * @return {boolean}
 */
proto.proto.Site.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool hidden = 14;
 * @return {boolean}
 */
proto.proto.Site.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool stubbed = 16;
 * @return {boolean}
 */
proto.proto.Site.prototype.getStubbed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setStubbed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool roadClosure = 18;
 * @return {boolean}
 */
proto.proto.Site.prototype.getRoadclosure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setRoadclosure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool addressBased = 20;
 * @return {boolean}
 */
proto.proto.Site.prototype.getAddressbased = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setAddressbased = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool directionsUnverified = 23;
 * @return {boolean}
 */
proto.proto.Site.prototype.getDirectionsunverified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setDirectionsunverified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool showOnMap = 22;
 * @return {boolean}
 */
proto.proto.Site.prototype.getShowonmap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setShowonmap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional Status status = 21;
 * @return {!proto.proto.Site.Status}
 */
proto.proto.Site.prototype.getStatus = function() {
  return /** @type {!proto.proto.Site.Status} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.proto.Site.Status} value
 * @return {!proto.proto.Site} returns this
 */
proto.proto.Site.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StubWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StubWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StubWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StubWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    latitude: jspb.Message.getFieldWithDefault(msg, 4, 0),
    longitude: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rigstub: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StubWaypoint}
 */
proto.proto.StubWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StubWaypoint;
  return proto.proto.StubWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StubWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StubWaypoint}
 */
proto.proto.StubWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatitude(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRigstub(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StubWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StubWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StubWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StubWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSiteid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRigstub();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.StubWaypoint.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StubWaypoint} returns this
 */
proto.proto.StubWaypoint.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 siteId = 2;
 * @return {number}
 */
proto.proto.StubWaypoint.prototype.getSiteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StubWaypoint} returns this
 */
proto.proto.StubWaypoint.prototype.setSiteid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 order = 3;
 * @return {number}
 */
proto.proto.StubWaypoint.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StubWaypoint} returns this
 */
proto.proto.StubWaypoint.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 latitude = 4;
 * @return {number}
 */
proto.proto.StubWaypoint.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StubWaypoint} returns this
 */
proto.proto.StubWaypoint.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 longitude = 5;
 * @return {number}
 */
proto.proto.StubWaypoint.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StubWaypoint} returns this
 */
proto.proto.StubWaypoint.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool rigStub = 6;
 * @return {boolean}
 */
proto.proto.StubWaypoint.prototype.getRigstub = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.StubWaypoint} returns this
 */
proto.proto.StubWaypoint.prototype.setRigstub = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SiteAccessPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SiteAccessPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SiteAccessPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteAccessPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    activedays: jspb.Message.getFieldWithDefault(msg, 6, ""),
    displaytimezone: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SiteAccessPeriod}
 */
proto.proto.SiteAccessPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SiteAccessPeriod;
  return proto.proto.SiteAccessPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SiteAccessPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SiteAccessPeriod}
 */
proto.proto.SiteAccessPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivedays(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaytimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SiteAccessPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SiteAccessPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SiteAccessPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteAccessPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActivedays();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisplaytimezone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.SiteAccessPeriod.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SiteAccessPeriod} returns this
 */
proto.proto.SiteAccessPeriod.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.SiteAccessPeriod.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.SiteAccessPeriod} returns this
*/
proto.proto.SiteAccessPeriod.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteAccessPeriod} returns this
 */
proto.proto.SiteAccessPeriod.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteAccessPeriod.prototype.hasStart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp end = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.SiteAccessPeriod.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.SiteAccessPeriod} returns this
*/
proto.proto.SiteAccessPeriod.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteAccessPeriod} returns this
 */
proto.proto.SiteAccessPeriod.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteAccessPeriod.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string activeDays = 6;
 * @return {string}
 */
proto.proto.SiteAccessPeriod.prototype.getActivedays = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SiteAccessPeriod} returns this
 */
proto.proto.SiteAccessPeriod.prototype.setActivedays = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string displayTimeZone = 7;
 * @return {string}
 */
proto.proto.SiteAccessPeriod.prototype.getDisplaytimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SiteAccessPeriod} returns this
 */
proto.proto.SiteAccessPeriod.prototype.setDisplaytimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SiteRestrictionPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SiteRestrictionPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SiteRestrictionPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteRestrictionPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    activedays: jspb.Message.getFieldWithDefault(msg, 7, ""),
    displaytimezone: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SiteRestrictionPeriod}
 */
proto.proto.SiteRestrictionPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SiteRestrictionPeriod;
  return proto.proto.SiteRestrictionPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SiteRestrictionPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SiteRestrictionPeriod}
 */
proto.proto.SiteRestrictionPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivedays(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaytimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SiteRestrictionPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SiteRestrictionPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SiteRestrictionPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SiteRestrictionPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActivedays();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDisplaytimezone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.SiteRestrictionPeriod.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SiteRestrictionPeriod} returns this
 */
proto.proto.SiteRestrictionPeriod.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.SiteRestrictionPeriod.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.SiteRestrictionPeriod} returns this
*/
proto.proto.SiteRestrictionPeriod.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteRestrictionPeriod} returns this
 */
proto.proto.SiteRestrictionPeriod.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteRestrictionPeriod.prototype.hasStart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp end = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.SiteRestrictionPeriod.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.SiteRestrictionPeriod} returns this
*/
proto.proto.SiteRestrictionPeriod.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SiteRestrictionPeriod} returns this
 */
proto.proto.SiteRestrictionPeriod.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SiteRestrictionPeriod.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string activeDays = 7;
 * @return {string}
 */
proto.proto.SiteRestrictionPeriod.prototype.getActivedays = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SiteRestrictionPeriod} returns this
 */
proto.proto.SiteRestrictionPeriod.prototype.setActivedays = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string displayTimeZone = 8;
 * @return {string}
 */
proto.proto.SiteRestrictionPeriod.prototype.getDisplaytimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SiteRestrictionPeriod} returns this
 */
proto.proto.SiteRestrictionPeriod.prototype.setDisplaytimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    picture: msg.getPicture_asB64(),
    grouptype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateGroupRequest}
 */
proto.proto.CreateGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateGroupRequest;
  return proto.proto.CreateGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateGroupRequest}
 */
proto.proto.CreateGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPicture(value);
      break;
    case 4:
      var value = /** @type {!proto.proto.Group.GroupType} */ (reader.readEnum());
      msg.setGrouptype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPicture_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getGrouptype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.CreateGroupRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateGroupRequest} returns this
 */
proto.proto.CreateGroupRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.proto.CreateGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateGroupRequest} returns this
 */
proto.proto.CreateGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes picture = 3;
 * @return {string}
 */
proto.proto.CreateGroupRequest.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes picture = 3;
 * This is a type-conversion wrapper around `getPicture()`
 * @return {string}
 */
proto.proto.CreateGroupRequest.prototype.getPicture_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPicture()));
};


/**
 * optional bytes picture = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPicture()`
 * @return {!Uint8Array}
 */
proto.proto.CreateGroupRequest.prototype.getPicture_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPicture()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.proto.CreateGroupRequest} returns this
 */
proto.proto.CreateGroupRequest.prototype.setPicture = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional Group.GroupType groupType = 4;
 * @return {!proto.proto.Group.GroupType}
 */
proto.proto.CreateGroupRequest.prototype.getGrouptype = function() {
  return /** @type {!proto.proto.Group.GroupType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.proto.Group.GroupType} value
 * @return {!proto.proto.CreateGroupRequest} returns this
 */
proto.proto.CreateGroupRequest.prototype.setGrouptype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    group: (f = msg.getGroup()) && proto.proto.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GroupRequest}
 */
proto.proto.GroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GroupRequest;
  return proto.proto.GroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GroupRequest}
 */
proto.proto.GroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.GroupRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GroupRequest} returns this
 */
proto.proto.GroupRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Group group = 2;
 * @return {?proto.proto.Group}
 */
proto.proto.GroupRequest.prototype.getGroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 2));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.GroupRequest} returns this
*/
proto.proto.GroupRequest.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GroupRequest} returns this
 */
proto.proto.GroupRequest.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GroupRequest.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GroupListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GroupListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GroupListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GroupListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GroupListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.proto.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GroupListResponse}
 */
proto.proto.GroupListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GroupListResponse;
  return proto.proto.GroupListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GroupListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GroupListResponse}
 */
proto.proto.GroupListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GroupListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GroupListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GroupListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GroupListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.GroupListResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.GroupListResponse} returns this
*/
proto.proto.GroupListResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GroupListResponse} returns this
 */
proto.proto.GroupListResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GroupListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Group groups = 2;
 * @return {!Array<!proto.proto.Group>}
 */
proto.proto.GroupListResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.proto.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Group, 2));
};


/**
 * @param {!Array<!proto.proto.Group>} value
 * @return {!proto.proto.GroupListResponse} returns this
*/
proto.proto.GroupListResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Group}
 */
proto.proto.GroupListResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GroupListResponse} returns this
 */
proto.proto.GroupListResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    devicetype: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LoginRequest}
 */
proto.proto.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LoginRequest;
  return proto.proto.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LoginRequest}
 */
proto.proto.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDevicetype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.proto.LoginRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LoginRequest} returns this
 */
proto.proto.LoginRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.proto.LoginRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LoginRequest} returns this
 */
proto.proto.LoginRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deviceId = 3;
 * @return {string}
 */
proto.proto.LoginRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LoginRequest} returns this
 */
proto.proto.LoginRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string deviceType = 4;
 * @return {string}
 */
proto.proto.LoginRequest.prototype.getDevicetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LoginRequest} returns this
 */
proto.proto.LoginRequest.prototype.setDevicetype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    awsauthresponse: (f = msg.getAwsauthresponse()) && proto.proto.GetOpenIdForDeveloperIdentityResponse.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.proto.User.toObject(includeInstance, f),
    usergroup: (f = msg.getUsergroup()) && proto.proto.Group.toObject(includeInstance, f),
    terms: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LoginResponse}
 */
proto.proto.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LoginResponse;
  return proto.proto.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LoginResponse}
 */
proto.proto.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = new proto.proto.GetOpenIdForDeveloperIdentityResponse;
      reader.readMessage(value,proto.proto.GetOpenIdForDeveloperIdentityResponse.deserializeBinaryFromReader);
      msg.setAwsauthresponse(value);
      break;
    case 4:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 5:
      var value = new proto.proto.Group;
      reader.readMessage(value,proto.proto.Group.deserializeBinaryFromReader);
      msg.setUsergroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAwsauthresponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.GetOpenIdForDeveloperIdentityResponse.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getUsergroup();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.Group.serializeBinaryToWriter
    );
  }
  f = message.getTerms();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.LoginResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.LoginResponse} returns this
*/
proto.proto.LoginResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LoginResponse} returns this
 */
proto.proto.LoginResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LoginResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.proto.LoginResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LoginResponse} returns this
 */
proto.proto.LoginResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GetOpenIdForDeveloperIdentityResponse awsAuthResponse = 3;
 * @return {?proto.proto.GetOpenIdForDeveloperIdentityResponse}
 */
proto.proto.LoginResponse.prototype.getAwsauthresponse = function() {
  return /** @type{?proto.proto.GetOpenIdForDeveloperIdentityResponse} */ (
    jspb.Message.getWrapperField(this, proto.proto.GetOpenIdForDeveloperIdentityResponse, 3));
};


/**
 * @param {?proto.proto.GetOpenIdForDeveloperIdentityResponse|undefined} value
 * @return {!proto.proto.LoginResponse} returns this
*/
proto.proto.LoginResponse.prototype.setAwsauthresponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LoginResponse} returns this
 */
proto.proto.LoginResponse.prototype.clearAwsauthresponse = function() {
  return this.setAwsauthresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LoginResponse.prototype.hasAwsauthresponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User user = 4;
 * @return {?proto.proto.User}
 */
proto.proto.LoginResponse.prototype.getUser = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 4));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.LoginResponse} returns this
*/
proto.proto.LoginResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LoginResponse} returns this
 */
proto.proto.LoginResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LoginResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Group userGroup = 5;
 * @return {?proto.proto.Group}
 */
proto.proto.LoginResponse.prototype.getUsergroup = function() {
  return /** @type{?proto.proto.Group} */ (
    jspb.Message.getWrapperField(this, proto.proto.Group, 5));
};


/**
 * @param {?proto.proto.Group|undefined} value
 * @return {!proto.proto.LoginResponse} returns this
*/
proto.proto.LoginResponse.prototype.setUsergroup = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LoginResponse} returns this
 */
proto.proto.LoginResponse.prototype.clearUsergroup = function() {
  return this.setUsergroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LoginResponse.prototype.hasUsergroup = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string terms = 6;
 * @return {string}
 */
proto.proto.LoginResponse.prototype.getTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LoginResponse} returns this
 */
proto.proto.LoginResponse.prototype.setTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetOpenIdForDeveloperIdentityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetOpenIdForDeveloperIdentityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cognitoidentityid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cognitotoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetOpenIdForDeveloperIdentityResponse}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetOpenIdForDeveloperIdentityResponse;
  return proto.proto.GetOpenIdForDeveloperIdentityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetOpenIdForDeveloperIdentityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetOpenIdForDeveloperIdentityResponse}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCognitoidentityid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCognitotoken(value);
      break;
    case 3:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetOpenIdForDeveloperIdentityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetOpenIdForDeveloperIdentityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCognitoidentityid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCognitotoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cognitoIdentityId = 1;
 * @return {string}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.getCognitoidentityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetOpenIdForDeveloperIdentityResponse} returns this
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.setCognitoidentityid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cognitoToken = 2;
 * @return {string}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.getCognitotoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetOpenIdForDeveloperIdentityResponse} returns this
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.setCognitotoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Error error = 3;
 * @return {?proto.proto.Error}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 3));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.GetOpenIdForDeveloperIdentityResponse} returns this
*/
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetOpenIdForDeveloperIdentityResponse} returns this
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetOpenIdForDeveloperIdentityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Error}
 */
proto.proto.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Error;
  return proto.proto.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Error}
 */
proto.proto.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatuscode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 statusCode = 1;
 * @return {number}
 */
proto.proto.Error.prototype.getStatuscode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Error} returns this
 */
proto.proto.Error.prototype.setStatuscode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.proto.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Error} returns this
 */
proto.proto.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ErrorResponse}
 */
proto.proto.ErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ErrorResponse;
  return proto.proto.ErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ErrorResponse}
 */
proto.proto.ErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.ErrorResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.ErrorResponse} returns this
*/
proto.proto.ErrorResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ErrorResponse} returns this
 */
proto.proto.ErrorResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ErrorResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.EmptyRequest}
 */
proto.proto.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.EmptyRequest;
  return proto.proto.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.EmptyRequest}
 */
proto.proto.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.EmptyRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.EmptyRequest} returns this
 */
proto.proto.EmptyRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.IdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.IdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.IdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inflate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.IdRequest}
 */
proto.proto.IdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.IdRequest;
  return proto.proto.IdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.IdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.IdRequest}
 */
proto.proto.IdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInflate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.IdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.IdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.IdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInflate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.IdRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.IdRequest} returns this
 */
proto.proto.IdRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.proto.IdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.IdRequest} returns this
 */
proto.proto.IdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool inflate = 3;
 * @return {boolean}
 */
proto.proto.IdRequest.prototype.getInflate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.IdRequest} returns this
 */
proto.proto.IdRequest.prototype.setInflate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.IdListRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.IdListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.IdListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.IdListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.IdListRequest}
 */
proto.proto.IdListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.IdListRequest;
  return proto.proto.IdListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.IdListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.IdListRequest}
 */
proto.proto.IdListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.IdListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.IdListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.IdListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IdListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.IdListRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.IdListRequest} returns this
 */
proto.proto.IdListRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 ids = 2;
 * @return {!Array<number>}
 */
proto.proto.IdListRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.IdListRequest} returns this
 */
proto.proto.IdListRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.IdListRequest} returns this
 */
proto.proto.IdListRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.IdListRequest} returns this
 */
proto.proto.IdListRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deltatimestamp: (f = msg.getDeltatimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    like: jspb.Message.getFieldWithDefault(msg, 6, ""),
    inflate: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    sortby: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ordering: jspb.Message.getFieldWithDefault(msg, 8, 0),
    onlyactivelistings: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryRequest}
 */
proto.proto.QueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryRequest;
  return proto.proto.QueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryRequest}
 */
proto.proto.QueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeltatimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLike(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInflate(value);
      break;
    case 7:
      var value = /** @type {!proto.proto.QueryRequest.SortParameter} */ (reader.readEnum());
      msg.setSortby(value);
      break;
    case 8:
      var value = /** @type {!proto.proto.QueryRequest.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyactivelistings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDeltatimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLike();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInflate();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSortby();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getOnlyactivelistings();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.QueryRequest.SortParameter = {
  DEFAULTSORT: 0,
  DATE: 1,
  NAME: 2
};

/**
 * @enum {number}
 */
proto.proto.QueryRequest.Ordering = {
  DEFAULTORDER: 0,
  DESCENDING: 1,
  ASCENDING: 2
};

/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.QueryRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.QueryRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 offset = 3;
 * @return {number}
 */
proto.proto.QueryRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp deltaTimestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.QueryRequest.prototype.getDeltatimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.QueryRequest} returns this
*/
proto.proto.QueryRequest.prototype.setDeltatimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.clearDeltatimestamp = function() {
  return this.setDeltatimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.QueryRequest.prototype.hasDeltatimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string like = 6;
 * @return {string}
 */
proto.proto.QueryRequest.prototype.getLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setLike = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool inflate = 5;
 * @return {boolean}
 */
proto.proto.QueryRequest.prototype.getInflate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setInflate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional SortParameter sortBy = 7;
 * @return {!proto.proto.QueryRequest.SortParameter}
 */
proto.proto.QueryRequest.prototype.getSortby = function() {
  return /** @type {!proto.proto.QueryRequest.SortParameter} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.proto.QueryRequest.SortParameter} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setSortby = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Ordering ordering = 8;
 * @return {!proto.proto.QueryRequest.Ordering}
 */
proto.proto.QueryRequest.prototype.getOrdering = function() {
  return /** @type {!proto.proto.QueryRequest.Ordering} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.proto.QueryRequest.Ordering} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool onlyActiveListings = 9;
 * @return {boolean}
 */
proto.proto.QueryRequest.prototype.getOnlyactivelistings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.QueryRequest} returns this
 */
proto.proto.QueryRequest.prototype.setOnlyactivelistings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.proto.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UserRequest}
 */
proto.proto.UserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UserRequest;
  return proto.proto.UserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UserRequest}
 */
proto.proto.UserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.UserRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UserRequest} returns this
 */
proto.proto.UserRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.proto.User}
 */
proto.proto.UserRequest.prototype.getUser = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 2));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.UserRequest} returns this
*/
proto.proto.UserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UserRequest} returns this
 */
proto.proto.UserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    groupid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    usertype: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateUserRequest}
 */
proto.proto.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateUserRequest;
  return proto.proto.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateUserRequest}
 */
proto.proto.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGroupid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getUsertype();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.CreateUserRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateUserRequest} returns this
 */
proto.proto.CreateUserRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.proto.CreateUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateUserRequest} returns this
 */
proto.proto.CreateUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phoneNumber = 7;
 * @return {string}
 */
proto.proto.CreateUserRequest.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateUserRequest} returns this
 */
proto.proto.CreateUserRequest.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.proto.CreateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateUserRequest} returns this
 */
proto.proto.CreateUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.proto.CreateUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateUserRequest} returns this
 */
proto.proto.CreateUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 groupId = 5;
 * @return {number}
 */
proto.proto.CreateUserRequest.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateUserRequest} returns this
 */
proto.proto.CreateUserRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string userType = 6;
 * @return {string}
 */
proto.proto.CreateUserRequest.prototype.getUsertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateUserRequest} returns this
 */
proto.proto.CreateUserRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.UserListResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UserListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UserListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UserListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UserListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    userList: jspb.Message.toObjectList(msg.getUserList(),
    proto.proto.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UserListResponse}
 */
proto.proto.UserListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UserListResponse;
  return proto.proto.UserListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UserListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UserListResponse}
 */
proto.proto.UserListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.addUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UserListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UserListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UserListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UserListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getUserList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.UserListResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.UserListResponse} returns this
*/
proto.proto.UserListResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UserListResponse} returns this
 */
proto.proto.UserListResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UserListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User user = 2;
 * @return {!Array<!proto.proto.User>}
 */
proto.proto.UserListResponse.prototype.getUserList = function() {
  return /** @type{!Array<!proto.proto.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.User, 2));
};


/**
 * @param {!Array<!proto.proto.User>} value
 * @return {!proto.proto.UserListResponse} returns this
*/
proto.proto.UserListResponse.prototype.setUserList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.User}
 */
proto.proto.UserListResponse.prototype.addUser = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.UserListResponse} returns this
 */
proto.proto.UserListResponse.prototype.clearUserList = function() {
  return this.setUserList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.proto.Error.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.proto.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UserResponse}
 */
proto.proto.UserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UserResponse;
  return proto.proto.UserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UserResponse}
 */
proto.proto.UserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Error;
      reader.readMessage(value,proto.proto.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.proto.User;
      reader.readMessage(value,proto.proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Error.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional Error error = 1;
 * @return {?proto.proto.Error}
 */
proto.proto.UserResponse.prototype.getError = function() {
  return /** @type{?proto.proto.Error} */ (
    jspb.Message.getWrapperField(this, proto.proto.Error, 1));
};


/**
 * @param {?proto.proto.Error|undefined} value
 * @return {!proto.proto.UserResponse} returns this
*/
proto.proto.UserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UserResponse} returns this
 */
proto.proto.UserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.proto.User}
 */
proto.proto.UserResponse.prototype.getUser = function() {
  return /** @type{?proto.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.proto.User, 2));
};


/**
 * @param {?proto.proto.User|undefined} value
 * @return {!proto.proto.UserResponse} returns this
*/
proto.proto.UserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UserResponse} returns this
 */
proto.proto.UserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PasswordResetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PasswordResetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PasswordResetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PasswordResetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PasswordResetRequest}
 */
proto.proto.PasswordResetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PasswordResetRequest;
  return proto.proto.PasswordResetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PasswordResetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PasswordResetRequest}
 */
proto.proto.PasswordResetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PasswordResetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PasswordResetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PasswordResetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PasswordResetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.proto.PasswordResetRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PasswordResetRequest} returns this
 */
proto.proto.PasswordResetRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ChangePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ChangePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ChangePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ChangePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    old: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pb_new: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ChangePasswordRequest}
 */
proto.proto.ChangePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ChangePasswordRequest;
  return proto.proto.ChangePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ChangePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ChangePasswordRequest}
 */
proto.proto.ChangePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOld(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNew(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ChangePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ChangePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ChangePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ChangePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOld();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNew();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.proto.ChangePasswordRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ChangePasswordRequest} returns this
 */
proto.proto.ChangePasswordRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string old = 2;
 * @return {string}
 */
proto.proto.ChangePasswordRequest.prototype.getOld = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ChangePasswordRequest} returns this
 */
proto.proto.ChangePasswordRequest.prototype.setOld = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new = 3;
 * @return {string}
 */
proto.proto.ChangePasswordRequest.prototype.getNew = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ChangePasswordRequest} returns this
 */
proto.proto.ChangePasswordRequest.prototype.setNew = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.User.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    admin: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    groupid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    textalerts: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    lastactivehaulingorderid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    acceptedterms: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    emailnotifications: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.User}
 */
proto.proto.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.User;
  return proto.proto.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.User}
 */
proto.proto.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 9:
      var value = /** @type {!proto.proto.User.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupid(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTextalerts(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastactivehaulingorderid(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAcceptedterms(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailnotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getGroupid();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTextalerts();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLastactivehaulingorderid();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getAcceptedterms();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getEmailnotifications();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.User.UserType = {
  DRIVER: 0,
  DISPATCHER: 1,
  REQUESTER: 2
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.User.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.User} returns this
*/
proto.proto.User.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.User.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.User.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.User} returns this
*/
proto.proto.User.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.User.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp deleted = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.User.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.User} returns this
*/
proto.proto.User.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.User.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.proto.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.proto.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phoneNumber = 8;
 * @return {string}
 */
proto.proto.User.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string deviceId = 12;
 * @return {string}
 */
proto.proto.User.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional UserType userType = 9;
 * @return {!proto.proto.User.UserType}
 */
proto.proto.User.prototype.getUsertype = function() {
  return /** @type {!proto.proto.User.UserType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.proto.User.UserType} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool admin = 10;
 * @return {boolean}
 */
proto.proto.User.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 groupId = 11;
 * @return {number}
 */
proto.proto.User.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool textAlerts = 13;
 * @return {boolean}
 */
proto.proto.User.prototype.getTextalerts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setTextalerts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 lastActiveHaulingOrderId = 14;
 * @return {number}
 */
proto.proto.User.prototype.getLastactivehaulingorderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setLastactivehaulingorderid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool acceptedTerms = 15;
 * @return {boolean}
 */
proto.proto.User.prototype.getAcceptedterms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setAcceptedterms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool emailNotifications = 16;
 * @return {boolean}
 */
proto.proto.User.prototype.getEmailnotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.User} returns this
 */
proto.proto.User.prototype.setEmailnotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Group.repeatedFields_ = [12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    grouptype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    internallinksList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    externallinksList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    bondwayfeerate: (f = msg.getBondwayfeerate()) && proto.proto.Money.toObject(includeInstance, f),
    presignedpdflogourl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    presignedsvglogourl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    billinglevel: jspb.Message.getFieldWithDefault(msg, 16, 0),
    trialexpirationdate: (f = msg.getTrialexpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Group}
 */
proto.proto.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Group;
  return proto.proto.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Group}
 */
proto.proto.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {!proto.proto.Group.GroupType} */ (reader.readEnum());
      msg.setGrouptype(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInternallinks(values[i]);
      }
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExternallinks(values[i]);
      }
      break;
    case 11:
      var value = new proto.proto.Money;
      reader.readMessage(value,proto.proto.Money.deserializeBinaryFromReader);
      msg.setBondwayfeerate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedpdflogourl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedsvglogourl(value);
      break;
    case 16:
      var value = /** @type {!proto.proto.Group.GroupBillingLevel} */ (reader.readEnum());
      msg.setBillinglevel(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTrialexpirationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGrouptype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getInternallinksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getExternallinksList();
  if (f.length > 0) {
    writer.writePackedInt64(
      13,
      f
    );
  }
  f = message.getBondwayfeerate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.proto.Money.serializeBinaryToWriter
    );
  }
  f = message.getPresignedpdflogourl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPresignedsvglogourl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBillinglevel();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getTrialexpirationdate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.Group.GroupType = {
  HAULER: 0,
  OPERATOR: 1,
  SERVICE: 2,
  DIRECTIONS: 3
};

/**
 * @enum {number}
 */
proto.proto.Group.GroupBillingLevel = {
  FREE: 0,
  BRONZE: 1,
  SILVER: 2,
  GOLD: 3,
  PLATINUM: 4,
  UNLIMITED: 5
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.proto.Group.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Group.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Group} returns this
*/
proto.proto.Group.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Group.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Group.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Group} returns this
*/
proto.proto.Group.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Group.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp deleted = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Group.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Group} returns this
*/
proto.proto.Group.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Group.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.proto.Group.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional GroupType groupType = 7;
 * @return {!proto.proto.Group.GroupType}
 */
proto.proto.Group.prototype.getGrouptype = function() {
  return /** @type {!proto.proto.Group.GroupType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.proto.Group.GroupType} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setGrouptype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated int64 internalLinks = 12;
 * @return {!Array<number>}
 */
proto.proto.Group.prototype.getInternallinksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setInternallinksList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.addInternallinks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.clearInternallinksList = function() {
  return this.setInternallinksList([]);
};


/**
 * repeated int64 externalLinks = 13;
 * @return {!Array<number>}
 */
proto.proto.Group.prototype.getExternallinksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setExternallinksList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.addExternallinks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.clearExternallinksList = function() {
  return this.setExternallinksList([]);
};


/**
 * optional Money bondwayFeeRate = 11;
 * @return {?proto.proto.Money}
 */
proto.proto.Group.prototype.getBondwayfeerate = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, proto.proto.Money, 11));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.Group} returns this
*/
proto.proto.Group.prototype.setBondwayfeerate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.clearBondwayfeerate = function() {
  return this.setBondwayfeerate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Group.prototype.hasBondwayfeerate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string presignedPDFLogoURL = 14;
 * @return {string}
 */
proto.proto.Group.prototype.getPresignedpdflogourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setPresignedpdflogourl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string presignedSVGLogoURL = 15;
 * @return {string}
 */
proto.proto.Group.prototype.getPresignedsvglogourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setPresignedsvglogourl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional GroupBillingLevel billingLevel = 16;
 * @return {!proto.proto.Group.GroupBillingLevel}
 */
proto.proto.Group.prototype.getBillinglevel = function() {
  return /** @type {!proto.proto.Group.GroupBillingLevel} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.proto.Group.GroupBillingLevel} value
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.setBillinglevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp trialExpirationDate = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Group.prototype.getTrialexpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Group} returns this
*/
proto.proto.Group.prototype.setTrialexpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Group} returns this
 */
proto.proto.Group.prototype.clearTrialexpirationdate = function() {
  return this.setTrialexpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Group.prototype.hasTrialexpirationdate = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Money.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Money.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Money} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Money.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    units: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nanos: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Money}
 */
proto.proto.Money.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Money;
  return proto.proto.Money.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Money} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Money}
 */
proto.proto.Money.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnits(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNanos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Money.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Money.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Money} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Money.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnits();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNanos();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.proto.Money.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Money} returns this
 */
proto.proto.Money.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 units = 2;
 * @return {number}
 */
proto.proto.Money.prototype.getUnits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Money} returns this
 */
proto.proto.Money.prototype.setUnits = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 nanos = 3;
 * @return {number}
 */
proto.proto.Money.prototype.getNanos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Money} returns this
 */
proto.proto.Money.prototype.setNanos = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.proto.Vehicles = {
  PICKUP: 0,
  BIGGER: 1
};

goog.object.extend(exports, proto.proto);
