import React, { Fragment, useEffect, useState } from "react";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import logo from '../../assets/bondway-primary.svg';
import './Login.scss';
import { userActions } from '../../_actions/user.actions';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { AuthResponse } from '../../_types/user.types';

type Props = {
    login: any,
    acceptTerms: any,
    loggingIn: boolean
    displayingTerms: boolean,
    tempUser: AuthResponse
}

const Login = (props: Props) => {

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsOpen, setTermsOpen] = useState(false);

    useEffect(() => {
        if (props.displayingTerms) {
            setTermsOpen(true);
        }
    }, [props.displayingTerms])

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    }

    const toggleShowPassword = (): void => {
        setShowPassword(!showPassword);
    }

    const submitLogin = (event: React.FormEvent): void => {
        event.preventDefault();

        props.login(email, password);
    }

    const acceptTerms = () => {
        props.acceptTerms(props.tempUser);
    }

    return <Fragment>
        <header className="LoginHeader">
            <img className="LoginLogo" src={logo} alt='Bondway Logo'></img>
        </header>

        <main className="LoginPageContainer">
            <section className="LoginContainer">
                <form noValidate onSubmit={submitLogin}>
                    <h1 className="LoginHeading">Sign Into BondWay</h1>

                    <div className="LoginFormInput">
                        <TextField label="Email Address" variant="outlined" size="small" fullWidth
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>

                    <div className="LoginFormInput">
                        <TextField label="Password" variant="outlined" size="small" type={showPassword ? 'text' : 'password'} fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </div>

                    <div className="LoginFormActions">
                        <a>Forgot Password</a>
                        <Button variant="contained" color="primary" type="submit">Sign In</Button>
                    </div>
                </form>

            </section>
        </main>

        <Dialog open={termsOpen} fullWidth>
            <DialogTitle>Terms and Conditions</DialogTitle>
            <DialogContent>
                You must agree to the <a className="LoginTermsLink" href="https://bondway.us/terms-and-conditions.pdf" target="_blank"> terms and conditions</a> before using the Bondway platform.
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setTermsOpen(false)} variant="contained">Cancel</Button>
                <Button onClick={() => acceptTerms()} variant="contained" color="primary">Accept</Button>
            </DialogActions>
        </Dialog>
    </Fragment>

}

function mapState(state: any) {
    const { loggingIn, displayingTerms, tempUser } = state.authentication;
    return { loggingIn, displayingTerms, tempUser }
}

const actionCreators = {
    login: userActions.login,
    acceptTerms: userActions.acceptTerms
}

const connectedLoginPage = connect(mapState, actionCreators)(Login)

export { connectedLoginPage as Login };