import './Jobs.scss';
import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { NavBar } from '../../components/navbar/Navbar';
import { HaulingListing, HaulingListingListResponse, Job, JobActionRecord, ScheduledJobAction, User, UserListResponse } from '../../proto/model_pb';
import ListingsService, { ListingRequest } from '../../services/listings.service';
import { alertActions } from '../../_actions/alert.actions';
import { JobsRoute } from '../../_types/nav.types';
import { GroupType } from '../../_types/user.types';
import { LocalShipping, Person } from '@material-ui/icons';
import ListingMappings from '../../_mappings/listing.mappings';
import JobMappings from '../../_mappings/job.mappings';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core';

type Props = {
    token: string,
    groupType: GroupType,
    groupId: number,
    displayErrorAlert: any,
    displaySuccessAlert: any
}

type ActiveJobForDate = {
    date: string,
    jobs: ActiveJob[]
}

type ActiveJob = {
    listing: HaulingListing,
    job: Job
}

const ActiveJobs = (props: Props) => {

    const [activeJobsForDate, setActiveJobsForDate] = useState<ActiveJobForDate[]>([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [drivers, setDrivers] = useState<User[]>([]);
    const [jobForDialog, setJobForDialog] = useState<Job | undefined>(undefined);
    const [selectedDriver, setSelectedDriver] = useState<User | undefined>(undefined);


    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        const request: ListingRequest = {
            token: props.token,
            limit: 25,
            offset: 0
        }
        ListingsService.getListings(request)
            .then(
                (response: HaulingListingListResponse) => {
                    const activeJobs: ActiveJob[] = [];

                    const listings: HaulingListing[] = response.getListingsList();
                    listings.forEach(listing => {
                        listing.getAssignedjobsList().forEach(job => {
                            activeJobs.push({
                                listing,
                                job
                            });
                        })
                    })

                    const jobsForDate = sortJobsByDate(activeJobs);

                    setActiveJobsForDate(jobsForDate);
                    setLoading(false);

                    if (props.groupType === GroupType.HAULER) {
                        ListingsService.getDrivers(props.token, props.groupId)
                            .then(
                                (response: UserListResponse) => {
                                    setDrivers(response.getUserList());
                                },
                                err => {
                                    console.error('Could not load drivers');
                                }
                            )
                        }
                },
                error => {
                    props.displayErrorAlert(error.toString());
                })
    }, []);

    const sortJobsByDate = (jobs: ActiveJob[]): ActiveJobForDate[] => {
        const activeJobForDate: ActiveJobForDate[] = [];

        jobs.forEach(activeJob => {
            if (isJobActive(activeJob)) {
                const timestamp = activeJob.listing.getScheduledjobactionsList()[0]!.getScheduledactiontime()!.toDate()
                const dateString = timestamp.toLocaleString('default', { month: 'short', day: '2-digit' });

                const dateWithJobs = activeJobForDate.find((dateWithJob) => {
                    return dateWithJob.date === dateString;
                })

                if (!dateWithJobs) {
                    activeJobForDate.push({
                        date: dateString,
                        jobs: [activeJob]
                    })
                } else {
                    dateWithJobs.jobs = [...dateWithJobs.jobs, activeJob]
                }
            }
        })

        activeJobForDate.forEach(activeJob => {
            activeJob.jobs.sort((a, b) => {
                const timestamp1 = a.listing.getScheduledjobactionsList()[0].getScheduledactiontime();
                const timestamp2 = b.listing.getScheduledjobactionsList()[0].getScheduledactiontime();

                if (timestamp1! < timestamp2!) {
                    return -1
                } else {
                    return 1;
                }
            })
        })

        activeJobForDate.sort((a, b) => {
            if (new Date(a.date) < new Date(b.date)) {
                return -1
            } else {
                return 1;
            }
        })

        return activeJobForDate;
    }

    const isJobActive = (activeJob: ActiveJob) => {
        if (!activeJob.listing.getScheduledjobactionsList() || !activeJob.listing.getScheduledjobactionsList()[0] || activeJob.job.getStatus() === Job.Status.ENDED) {
            console.log('false');
            return false;
        }

        const actionTimestamp = activeJob.listing.getScheduledjobactionsList()[0].getScheduledactiontime()?.getSeconds();
        const currentTime = Date.now() / 1000;

        if (!actionTimestamp) return false;

        return (actionTimestamp < (currentTime + (14 * 24 * 60 * 60)))
    }

    const viewListing = (listing: HaulingListing) => {
        const path = '/listings/edit';

        history.push(path, { listing })
    }

    const onDialogOpen = (activeJob: Job) => {
        setJobForDialog(activeJob);
        setDialogOpen(true);
    }

    const onDialogClose = () => {
        setJobForDialog(undefined);
        setDialogOpen(false);
    }

    const onDriverSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const driver = drivers.find(driver => {
            return driver.getId() === +event.target.value;
        })
        setSelectedDriver(driver);
    }

    const assignDriver = () => {
        if (selectedDriver && jobForDialog) {
            ListingsService.assignDriver(props.token, selectedDriver.getId(), jobForDialog.getId())
                .then(
                    (response: any) => {
                        jobForDialog.setInflatedriver(selectedDriver);
                        setSelectedDriver(undefined);
                        props.displaySuccessAlert('Driver successfully assigned');
                        setJobForDialog(undefined);
                        setDialogOpen(false);
                    },
                    err => {
                        props.displayErrorAlert('Could not assign driver to job');
                        setJobForDialog(undefined);
                        setDialogOpen(false);
                    }
                )
        }
    }

    const getVehicleType = (listing?: HaulingListing): string | undefined => {
        if (!listing) { return undefined }

        return ListingMappings.getVehicleType(listing.getVehicle());
    }

    const getTrailerType = (listing?: HaulingListing): string | undefined => {
        if (!listing) { return undefined }

        return ListingMappings.getTrailerType(listing.getTowload());
    }

    const getJobStatus = (job: Job): string | undefined => {
        if (!job) { return undefined }

        return JobMappings.getStatusType(job.getStatus());
    }

    const getJobCurrentDuration = (job: Job): string => {
        if (!job || !job.getStartedat()) { return 'Not Started' };

        const startedAt = +job.getStartedat()!.toDate();
        const currentTime = +new Date();
        let timeDiff: number;

        if (job && job.getEndedat()) {
            const endedAt = +job.getEndedat()!.toDate();
            timeDiff = (endedAt - startedAt)
        } else {
            timeDiff = (currentTime - startedAt);
        }

        const diffHours = Math.floor(timeDiff / 86400000 / 3600000);
        const diffMins = Math.floor(((timeDiff % 86400000) % 3600000) / 60000);

        if (diffHours === 0) {
            return `${diffMins} mins`
        } else {
            return `${diffHours} hrs ${diffMins} mins`;
        }
    }

    const getJobNextStep = (job: Job, listing: HaulingListing): string | undefined => {
        if (!job || !job.getStartedat()) { return 'Job Not Started' }

        const actionRecord: JobActionRecord[] = job.getActionrecordsList();
        const scheduledJobActions: ScheduledJobAction[] = listing.getScheduledjobactionsList();
        let nextAction: ScheduledJobAction | undefined;

        scheduledJobActions.every((scheduledAction, index) => {
            if (!actionRecord[index]) {
                nextAction = scheduledAction;
                return false;
            }

            return true;
        })

        if (!nextAction) {
            return 'Job Completed'
        }

        const actionType = nextAction.getActiontype();
        const siteName = nextAction.getSite()?.getName();

        switch (actionType) {
            case ScheduledJobAction.ActionType.PICKUP:
                return `Picking Up From ${siteName}`
            case ScheduledJobAction.ActionType.DROPOFF:
                return `Dropping Off At ${siteName}`
            default:
                return `Job Status Unknown`
        }

    }

    return <Fragment>
        <NavBar activeRoute={JobsRoute} />

        <main className="PageContainer EditListingsContainer">
            <header className="EditListingsHeader">
                <div className="EditListingsHeaderReturn">
                    Viewing Active Jobs
                    </div>
            </header>

            <div className="ActiveJobsContainer">
                {(loading) &&
                    <div className="ActiveJobsLoading">
                        <CircularProgress></CircularProgress>
                    </div>
                }

                {((!activeJobsForDate || activeJobsForDate.length === 0) && !loading) &&
                    <div className="ActiveJobsNoJobs">No Active Jobs</div>
                }

                {((activeJobsForDate.length > 0) && !loading) &&
                    <div>
                        {activeJobsForDate.map(jobsForDate => (
                            <Fragment>
                                <div className="ActiveJobsDateHeader">
                                    {jobsForDate.date}
                                </div>

                                <div className="ListingJobsCardContainer">

                                    {jobsForDate.jobs.map(job => (
                                        <div className="ListingJobsCard">
                                            <div className="ActiveJobsCardHeader">
                                                <div className="ActiveJobCardHeaderCompany">
                                                    <img className="ListingJobGroupIcon" src={job.job.getInflatehaulergroup()?.getPresignedsvglogourl()} alt="" />
                                                    {job.listing?.getItem()}
                                                </div>
                                                <span className="ActiveJobsCardHeaderLink" onClick={() => viewListing(job.listing)}>View Listing</span>
                                            </div>

                                            <div className="ListingJobsCardSection">
                                                <div className="ListingJobsCardTopSection">
                                                    <div className="ListingJobsCardTopSectionHeader">Truck Overview</div>
                                                    <div className="ListingJobsCardTopSectionContent">
                                                        <div className="ListingJobsCardTopSectionPill">{getVehicleType(job.listing) || 'N/A'}</div>
                                                        <div className="ListingJobsCardTopSectionPill">{getTrailerType(job.listing) || 'N/A'}</div>
                                                    </div>
                                                </div>

                                                <div className="ListingJobsCardTopSection">
                                                    <div className="ListingJobsCardTopSectionHeader">Route Overview</div>
                                                    <div className="ListingJobsCardTopSectionContent">
                                                        <div className="ListingJobsCardTopSectionPill">{getJobCurrentDuration(job.job)}</div>
                                                        <div className="ListingJobsCardTopSectionPill">{getJobStatus(job.job)}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ListingJobsCardSection">

                                                <div className="ListingJobsCardBottomSection">
                                                    <LocalShipping color="primary" />

                                                    <div className="ListingJobsCardBottomSectionDetails">
                                                        <div className="ListingJobsCardBottomSectionDetailsPrimary">
                                                            {getJobNextStep(job.job, job.listing)}
                                                        </div>
                                                        {/* <div className="ListingJobsCardBottomSectionDetailsSecondary">
                                                        Estimated Arrival <span className="bold">{ }</span>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ListingJobsCardSection">
                                                <div className="ListingJobsCardBottomSection">
                                                    <Person color="primary" />

                                                    <div className="ListingJobsCardBottomSectionDetails">
                                                        <div className="ListingJobsCardBottomSectionDetailsPrimary">
                                                            {job.job.getInflatedriver()?.getName() || 'Not Assigned'}
                                                        </div>
                                                        <div className="ListingJobsCardBottomSectionDetailsSecondary">
                                                            {job.job.getInflatedriver()?.getPhonenumber() || 'Driver needs assigned'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {(props.groupType === GroupType.HAULER) && <div className="ListingJobsCardActions">
                                                {!!(job.job.getDriveruserid()) && <div className="ListingJobsCardAction">
                                                    <span>Call Driver</span>
                                                </div>
                                                }
                                                {!!(!job.job.getDriveruserid()) && <div onClick={() => onDialogOpen(job.job)} className="ListingJobsCardAction">
                                                    <span>Assign Driver</span>
                                                </div>
                                                }
                                                {/* <div className="ListingJobsCardAction">Detail View</div> */}
                                            </div>}
                                        </div>

                                    ))}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                }

                <Dialog fullWidth maxWidth="sm" open={dialogOpen} onClose={() => onDialogClose()}>
                    <DialogTitle>Assign Driver</DialogTitle>
                    <DialogContent>
                        <TextField select label="Drivers" onChange={onDriverSelected} variant="outlined" fullWidth size="small">
                            {drivers.map((driver) => (
                                <MenuItem key={driver.getId()} value={driver.getId()}>
                                    {driver.getName()}
                                </MenuItem>
                            ))}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onDialogClose()} variant="contained">Cancel</Button>
                        <Button onClick={() => assignDriver()} variant="contained" color="primary">Assign</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </main>
    </Fragment>

}

function mapState(state: any) {
    const { authentication } = state;
    return {
        token: authentication.token,
        groupType: authentication.groupType,
        groupId: authentication.groupId
    };
}

const actionCreators = {
    displayErrorAlert: alertActions.error,
    displaySuccessAlert: alertActions.success
};

const connectedApp = connect(mapState, actionCreators)(ActiveJobs);

export { connectedApp as ActiveJobs };