import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { NavBar } from '../../components/navbar/Navbar';
import { User, UserResponse } from '../../proto/model_pb';
import AccountService from '../../services/account.service';
import { alertActions } from '../../_actions/alert.actions';
import { ProfileRoute } from '../../_types/nav.types';
import './Profile.scss';

type Props = {
    displayErrorAlert: any,
    displaySuccessAlert: any
}

const Profile = (props: Props) => {
    const [user, setUser] = useState<User>();
    const [emailNotifications, setEmailNotifications] = useState<boolean>(false);

    useEffect(() => {
        getUser();
    }, [])

    const getUser = () => {
        AccountService.getUser()
            .then((resp: UserResponse) => {
                const user = resp.getUser();

                setUser(user);
                setEmailNotifications(user?.getEmailnotifications()!);
            }, (err: any) => {
                props.displayErrorAlert('Error loading user credentials');
            });
    }

    const updateNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;

        user?.setEmailnotifications(checked);
        setEmailNotifications(checked);

        AccountService.updateUser(user!)
            .then((resp: UserResponse) => {
                props.displaySuccessAlert('Notification settings updated');
            }, (err: any) => {
                props.displayErrorAlert('Error updating notification settings');
            })

    }

    return <Fragment>
        <NavBar activeRoute={ProfileRoute} />

        <main className="PageContainer">
            <section className="UserSection">
                <h1>Notifications</h1>
                <FormControlLabel control = {
                    <Checkbox
                        color="primary"
                        checked={emailNotifications}
                        onChange={updateNotification}
                    />
                }
                label="Receive email notifications"
                />
            </section>
        </main>
    </Fragment>
}

function mapState(state: any) {
    return {}
}

const actionCreators = {
    displayErrorAlert: alertActions.error,
    displaySuccessAlert: alertActions.success
};

const connectedProfile = connect(mapState, actionCreators)(Profile);

export { connectedProfile as Profile };