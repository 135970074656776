import React from "react";
import { NavBar } from '../../components/navbar/Navbar';
import { DashboardRoute } from '../../_types/nav.types';

class Dashboard extends React.Component {
    
    render() {
        return <NavBar activeRoute={DashboardRoute} />
    }
}

export default Dashboard;