// Mappings for protobuf models
const trailerTypes = [
    { value: 0, label: "None" },
    { value: 1, label: "Flatbed" },
    { value: 2, label: "Gooseneck" },
    { value: 3, label: "Low Boy" },
    { value: 4, label: "Double Drop" },
    { value: 5, label: "Box Trailer" },
]

const vehicleTypes = [
    { value: 0, label: "Truck" },
    { value: 1, label: "Tractor Trailer" },
]

const permitTypes = [
    { value: 0, label: "None" },
    { value: 1, label: "Hauler" },
    { value: 2, label: "Shipper" },
]

const ListingMappings = {
    getTrailerType: (val: number): string | undefined => {
        return trailerTypes.find(trailerType => trailerType.value === val)?.label;
    },

    getVehicleType: (val: number): string | undefined => {
        return vehicleTypes.find(vehicleType => vehicleType.value === val)?.label;
    },
    
    getPermitType: (val: number): string | undefined => {
        return permitTypes.find(permitType => permitType.value === val)?.label;
    }
}

export default ListingMappings;