import React from 'react';
import './FieldTicketSectionSelector.css';
import arrow from '../../assets/arrow_doc.svg'
class FieldTicketSectionSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  handlechangeSearch(event) {
    // need some kind of callback to trigger reloading in the super-component
    // this.props.searchCallback(this.state.search)
    event.preventDefault();
  }

  render() {
    return  <div className="SectionSelectorsBox">
            <FieldTicketSectionItem title="Completed" index={0} selectedSection={this.props.selectedSection} handleSelectionClick={this.props.handleSelectionClick} />
            <FieldTicketSectionItem title="In Review" index={1} selectedSection={this.props.selectedSection} handleSelectionClick={this.props.handleSelectionClick}/>
            <FieldTicketSectionItem title="Disputed" index={3} selectedSection={this.props.selectedSection} handleSelectionClick={this.props.handleSelectionClick}/>
            <FieldTicketSectionItem title="Arbitration" index={6} selectedSection={this.props.selectedSection} handleSelectionClick={this.props.handleSelectionClick}/>
            <FieldTicketSectionItem title="Approved" index={2} selectedSection={this.props.selectedSection} handleSelectionClick={this.props.handleSelectionClick}/>
            <FieldTicketSectionItem title="Invoiced" index={4} selectedSection={this.props.selectedSection} handleSelectionClick={this.props.handleSelectionClick}/>
            <FieldTicketSectionItem title="Paid" index={5} selectedSection={this.props.selectedSection} handleSelectionClick={this.props.handleSelectionClick}/>
            </div>
  }
}

export default FieldTicketSectionSelector


export class FieldTicketSectionItem extends React.Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handleSelectionClick(this.props.index)
  }

  render() {    
    return  <div className={this.props.selectedSection === this.props.index ? "SectionSelector-item-selected" : "SectionSelector-item"} onClick={this.handleClick}>
              <img src={arrow} className="SectionSelector-arrow" alt="glass" />
              {this.props.title}
            </div>
  }
}
