import { ArrowForwardIos } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { HaulingListing } from '../../proto/model_pb';
import { GroupType } from '../../_types/user.types';

export enum NavStates {
    LISTING = "listing",
    BIDS = "bids",
    JOBS = "jobs",
}

type NavState = "listing" | "bids" | "jobs";

type Props = {
    navState: NavState,
    groupType: GroupType,
    listing: HaulingListing | null,
}

function EditListingNav(props: Props) {
    const [navState, setNavState] = useState(props.navState);
    const history = useHistory();

    const navigate = (state: NavState): void => {
        if (state === navState) {
            return;
        }

        if (!props.listing || !props.listing.getId || !props.listing.getId()) {
            return;
        }

        switch (state) {
            case NavStates.LISTING: {
                history.push(`/listings/${props.listing.getId()}`, {listing: props.listing});
                setNavState(NavStates.LISTING);
                
                break;
            }
            
            case NavStates.BIDS: {
                history.push(`/listings/${props.listing.getId()}/bids`, {listing: props.listing});
                setNavState(NavStates.BIDS);
                break;
            }

            case NavStates.JOBS: {
                history.push(`/listings/${props.listing.getId()}/jobs`, {listing: props.listing});
                setNavState(NavStates.JOBS);
                break;
            }
        }
    }

    return <nav className="EditListingNavContainer">
        <a  onClick={() => navigate(NavStates.LISTING)}
            className={"EditListingNavItem " + (navState === NavStates.LISTING ? 'active' : '')} >
            <div className="EditListingNavStage">1</div>
            Listing
        </a>
        <ArrowForwardIos/>
        <a  onClick={() => navigate(NavStates.BIDS)}
            className={"EditListingNavItem " + (navState === NavStates.BIDS ? 'active' : '')}>
            <div className="EditListingNavStage">2</div>
            {(props.groupType === GroupType.OPERATOR || props.groupType === GroupType.SERVICE) && <span>Bids</span>}
            {(props.groupType === GroupType.HAULER) && <span>Your Bid</span>}
        </a>
        <ArrowForwardIos/>
        <a  onClick={() => navigate(NavStates.JOBS)}
            className={"EditListingNavItem " + (navState === NavStates.JOBS ? 'active' : '')}>
            <div className="EditListingNavStage">3</div>
            Active Jobs
        </a>
    </nav>
}

export default EditListingNav;